import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import parseDate from 'date-fns/parse';
import { UITEXT } from '../../../helpers/misc';

export const tableSchema = [
    {
      id: 'createTimestamp',
      title: 'Timestamp',
      sortable: true
    },
    {
      id: 'userId',
      title: 'User',
      sortable: true
    },
    {
      id: 'operationType',
      title: 'Type of Operation',
      sortable: true
    },
    {
      id: 'playerName',
      title: 'Display Name',
      sortable: true
    },
    {
      id: 'frameName',
      title: 'Frame Name',
      sortable: true,
    },
    {
      id: 'framesOrder',
      title: 'Frames Order'
    },
    {
      id: 'description',
      title: 'Description'
    },
    {
      id: 'resolution',
      title: 'Resolution'
    }
  ];

  export const formatLogs = (schedulingLogs = []) => {
    const tableDataSelected = [];
    schedulingLogs.forEach((item, rowIndex) => {
  
      tableDataSelected[rowIndex] = {
        id: rowIndex
      };
  
      const formattedTimestamp = String(parseDate(item.createTimestamp));
  
      tableDataSelected[rowIndex].createTimestamp = (
        <div className="ellipsis">
          <Tooltip position="bottom" followCursor title={formattedTimestamp}>
            {formattedTimestamp}
          </Tooltip>
        </div>
      );
  
      // Name
      tableDataSelected[rowIndex].userId = (
        <Tooltip position="bottom" followCursor title={item.user.email}>
          <a href={`mailto:${item.user.email}`}>
            {item.user.userId}
            {/* {item.user.lastName}, {item.user.firstName} */}
          </a>
        </Tooltip>
      );
  
      // Type of operation
      tableDataSelected[rowIndex].operationType = item.operationType;
  
      // Display Name
      tableDataSelected[rowIndex].playerName = (
        <div>
          <NavLink target="_blank" to={`/schedules/${item.playerId}/${item.playerName}`}>
            <Tooltip
              position="bottom"
              followCursor
              title={item.playerMetadata || UITEXT.playerNameTooltip}
            >
              {item.playerName}
            </Tooltip>
          </NavLink>
        </div>
      );
  
      tableDataSelected[rowIndex].frameName = item.frameName;
      tableDataSelected[rowIndex].framesOrder = item.framesOrder;
      tableDataSelected[rowIndex].description = item.description;
      tableDataSelected[rowIndex].resolution = item.resolution;
    });
  
    return tableDataSelected;
  };