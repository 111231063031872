/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { GlButton, GlInput, GlCallout } from '@adl/foundation';
import get from 'lodash-es/get';

import { ALLOWED_CHARACTERS_COUNT_MSG, allowedCharactersCount } from '../../helpers/validators';
import { UITEXT } from '../../helpers/misc';
import Table from '../commons/Table/Table';
import DeleteModal from '../Modals/DeleteModal';
import PlaylistEditModal from '../Modals/PlaylistEditModal';
import ShowUsageModal from '../Modals/ShowUsageModal';
import DuplicatePlaylistModal from '../Modals/DuplicatePlaylistModal';
import SelectMediaToPlaylists from '../Modals/SelectMediaToPlaylists';
import RemoveMediaFromPlaylistModal from '../Modals/RemoveMediaFromPlaylistModal';
import { tableSchema, formatPlaylistItems } from './schema';
import { selectItems as selectItemsAction } from '../../actions/table';
import * as modalActions from '../../actions/modal';
import {
  trimInputValue,
  filterOutPlaylists,
  findPlaylistInPlayers,
  calculatePlaylistDuration,
  prettifyDuration
} from '../../helpers/utils';
import './PlaylistSelected.scss';
import PreviewItemsModal from '../Modals/PreviewItemsModal';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi, callUpdatePlaylistv2Api } from '../../serviceLayer/services';

const tableId = 'playlistselected';

class PlaylistSelected extends Component {
  state = {
    playlistData: [],
    playlist: {},
    isFetching: true,
    isSaving: false,
    isNameEdited: false,
    saveError: false,
    isError: false,
    isValid: {
      name: true
    },
    errorMessage: '',
    isLoading: true,
    saveOrderSuccess: false,
    usageCount: 0,
    fetchingUsageCount: false,
    notFound: false
  };

  componentDidMount = async () => {
    window.addEventListener('beforeunload', this.handlePageRefresh);

    // document.title = pageTitle.playlistSelected('');
    const { selectItems, pid } = this.props;
    selectItems('playlistselected', []);
    this.fetchItem();

    // make an API call to fetch the player usage
    this.setState({ fetchingUsageCount: true });
    try {
      const usageOfPlaylist = await findPlaylistInPlayers(Number(pid));
      this.setState({
        fetchingUsageCount: false,
        usageCount: usageOfPlaylist.length
      });
    } catch (err) {
      this.setState({
        fetchingUsageCount: false
      });
    }
  };

  componentWillUnmount() {
    const { preventReload, playlistNotFound } = this.props;

    preventReload(false);
    playlistNotFound(false);
    this.removePreventEvent();
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  refresh = () => {
    this.fetchItem();
  };

  fetchItem = () => {
    const { playlistNotFound } = this.props;
    this.setState({
      isFetching: true
    });

    const { pid } = this.props;

    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }))
      .then(res => {
        // document.title = pageTitle.playlistSelected(res.data.name);
        const filteredItems = filterOutPlaylists(get(res, 'playlistItems', []));
        const totalDuration = calculatePlaylistDuration([...filteredItems]);
        this.setState(next => ({
          ...next,
          playlist: res,
          playlistData: filteredItems,
          isFetching: false,
          totalDuration: prettifyDuration(totalDuration)
        }));
      })
      .catch(e => {
        const notFound = get(e, 'response.data.code', '') === 'PlayListNotfound';
        if (notFound) {
          playlistNotFound(true);
        }

        this.setState({
          isFetching: false,
          isError: true,
          notFound
        });
      });
  };

  activeModal = modalId => {
    const { pid, history, selectItems, closeModal, preventReload } = this.props;
    const { playlist, saveOrderSuccess } = this.state;
    const { id, name: playlistName } = playlist;

    switch (modalId) {
      case 'deleteItems':
        return (
          <DeleteModal
            refreshHandler={() => {
              this.removePreventEvent();
              preventReload(false);
              history.push({ pathname: '/playlists' });
            }}
          />
        );
      case 'editPlaylist':
        return (
          <PlaylistEditModal
            closeHandler={() => {
              this.fetchItem();
            }}
          />
        );
      case 'selectMediaToPlaylists':
        return (
          <SelectMediaToPlaylists
            refreshHandler={() => {
              this.fetchItem();
            }}
          />
        );
      case 'duplicatePlaylist':
        return (
          <DuplicatePlaylistModal
            closeModal={() => closeModal()}
            closeHandler={() => {
              this.fetchItem();
            }}
          />
        );
      case 'previewItems':
        return <PreviewItemsModal />;
      case 'removeMedia':
        return (
          <RemoveMediaFromPlaylistModal
            pid={pid}
            refreshHandler={() => {
              selectItems('playlistselected', []);
              this.fetchItem();
            }}
          />
        );
      case 'saving':
        return (
          <Modal size="small" open modalCloseHandler={() => closeModal()}>
            <Loader />
          </Modal>
        );
      case 'showUsage':
        return <ShowUsageModal />;
      case 'saveOrderCompleted':
        return (
          <Modal
            size="small"
            open
            enableOutsideClick
            modalCloseHandler={() => {
              closeModal();
              this.fetchItem();
            }}
          >
            <h4>Edit Playlist</h4>
            <p>Please check the status below:</p>
            <Table
              tableData={[
                {
                  id,
                  name: (
                    <div className="ellipsis" title={playlistName}>
                      {playlistName}
                    </div>
                  ),
                  status: saveOrderSuccess ? (
                    <span className="validation-success">Edited</span>
                  ) : (
                    <span className="validation-error">Not Authorized</span>
                  )
                }
              ]}
              isFilterable
              tableSchema={[
                {
                  id: 'name',
                  title: 'Playlist'
                },
                {
                  id: 'status',
                  title: 'Status'
                }
              ]}
              tableId="results"
            />
          </Modal>
        );
      default:
        return null;
    }
  };

  rowOrderHandler = ({ oldIndex, newIndex }) => {
    const { preventReload } = this.props;
    const { playlistData } = this.state;
    if (newIndex >= 0 && newIndex < playlistData.length && oldIndex !== newIndex) {
      const copiedList = [...playlistData];
      const element = copiedList.splice(oldIndex, 1)[0];
      copiedList.splice(newIndex, 0, element);
      preventReload(true);
      this.setState({
        playlistData: copiedList.map((el, index) => ({ ...el, sortOrder: index + 1 }))
      });
    }
  };

  saveOrderHandler = () => {
    const { playlist, playlistData } = this.state;
    const { openModal, closeModal, preventReload } = this.props;
    const { id, name, description } = playlist;
    const MODAL_ID = 'saving';

    this.setState({
      isSaving: true,
      saveError: false
    });

    openModal(MODAL_ID);
    const newPlaylistData = {
      id,
      name,
      description
    };
    const newPlaylistOrder = [];
    playlistData.map(pl => newPlaylistOrder.push({ id: pl.id, sortOrder: pl.sortOrder }));
    newPlaylistData.playlistItems = newPlaylistOrder;
    getAccessTokenAndMakeCalls(token =>
      callUpdatePlaylistv2Api(token, {
        playlistId: playlist.id,
        ...newPlaylistData
      })
    )
      .then(() => {
        this.setState({
          isSaving: false,
          isNameEdited: false,
          saveOrderSuccess: true
        });
        preventReload(false);
        this.removePreventEvent();
        closeModal(MODAL_ID);
        openModal('saveOrderCompleted');
      })
      .catch(err => {
        this.setState({
          isSaving: false,
          saveError: true,
          errorMessage: get(err, 'response.data.cmsResponse.description'),
          saveOrderSuccess: false
        });
        closeModal(MODAL_ID);
        openModal('saveOrderCompleted');
      });
  };

  nameEditHandler = () => {
    this.setState({ isNameEdited: true });
  };

  handleInfoChange = (value, _isValid) => {
    const { playlist } = this.state;
    const { preventReload } = this.props;
    const playlistCopy = { ...playlist };
    playlistCopy.name = value.name;
    this.setState({
      playlist: playlistCopy,
      isValid: { name: _isValid }
    });
    preventReload(true);
  };

  render() {
    const {
      playlistData,
      isFetching,
      isError,
      playlist,
      isNameEdited,
      isValid,
      saveError,
      errorMessage,
      usageCount,
      fetchingUsageCount,
      notFound,
      totalDuration
    } = this.state;

    const { activeModalId, pid, openModal, selectedItems, isReloadPrevented } = this.props;

    const {
      id,
      name: playlistName,
      lastModified,
      itemCount,
      createdDate,
      CFE_CreatedBy: cfeCreatedBy,
      CFE_LastModifiedBy: cfeLastModifiedBy
    } = playlist;

    const noItems = !isFetching && !!playlistData && !playlistData.length;

    const hasRowOrdering = playlistData.length !== 1;

    if (isFetching) {
      return (
        <div className="marginTop ">
          <Loader />
        </div>
      );
    }

    const showUsage = usageCount > 0;

    if (isError) {
      return (
        <div className="generic-container list--noitems">
          <GlCallout type="urgent">
            <h5>{notFound ? UITEXT.playlistNotFound : UITEXT.playlistLoadError}</h5>
          </GlCallout>
        </div>
      );
    }

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />
        <div className="generic-container">
          {saveError && <p className="validation-error marginLeft marginTop">{errorMessage}</p>}
          <div className="playlist-selected__header">
            <div className="playlist-selected__header-inputs">
              {isNameEdited ? (
                <div className="inputs-wrapper">
                  <div>
                    <div className="gl-label gl-label--large gl-vspacing-s">Name</div>
                    <GlInput
                      className="generic-table__input playlist-selected_input"
                      value={playlistName}
                      required
                      hint={ALLOWED_CHARACTERS_COUNT_MSG('Name', 6, 100)}
                      valid={isValid.name}
                      onChange={e => {
                        const { value } = e.target;
                        this.handleInfoChange(
                          {
                            name: value
                          },
                          allowedCharactersCount(trimInputValue(value), 6, 100)
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <h4 data-testid="playlist-selected-page-title">{playlistName}</h4>
              )}
              {playlistName &&
                ((!isNameEdited && (
                  <button
                    type="button"
                    className="header-actions__icon playlist-selected__icon"
                    onClick={this.nameEditHandler}
                    data-testid="playlist-selected-edit-button"
                  >
                    <EditIcon />
                  </button>
                )) || (
                  <button
                    type="button"
                    onClick={() => this.setState({ isNameEdited: false })}
                    data-testid="playlist-selected-close-edit-button"
                  >
                    <CloseIcon className="header-actions__icon playlist-selected__icon" />
                  </button>
                ))}
            </div>

            {isReloadPrevented && (
              <GlButton
                onClick={this.saveOrderHandler}
                type="type"
                disabled={!isValid.name}
                className="gl-cta gl-cta--primary gl-cta--margin-right-top"
                data-testid="playlist-selected-save-button"
              >
                Save
              </GlButton>
            )}
          </div>
          <div
            className="playlist-selected__header--data"
            style={{ marginBottom: selectedItems.length ? 30 : 0 }}
          >
            <span data-testid="playlist-selected-items">
              <b>Assets:</b> {itemCount}
            </span>
            {createdDate && (
              <span data-testid="playlist-selected-created">
                <b>Created:</b> {createdDate} {cfeCreatedBy && `by ${cfeCreatedBy}`}
              </span>
            )}
            {lastModified && (
              <span data-testid="playlist-selected-last-modified">
                <b>Last Modified:</b> {lastModified}{' '}
                {cfeLastModifiedBy && `by ${cfeLastModifiedBy}`}
              </span>
            )}
            {totalDuration && (
              <span data-testid="playlist-selected-duration">
                <b>Duration:</b> {totalDuration}
              </span>
            )}
            {fetchingUsageCount && <span>Fetching Usage...</span>}
            {showUsage && (
              <span data-testid="playlist-selected-used-in">
                <b>Used in: </b>
                <GlButton
                  tertiary
                  aria-label="Show Usage"
                  onClick={() => {
                    openModal('showUsage', { find: 'playlist', id });
                  }}
                >
                  {usageCount} {usageCount > 1 ? 'players' : 'player'}
                </GlButton>
              </span>
            )}
          </div>
          <Table
            id="PlayListSelected"
            tableData={formatPlaylistItems(playlistData)}
            tableSchema={tableSchema}
            tableId={tableId}
            isFilterable
            isSelectable
            hasColumnOptions
            hasRowOrdering={hasRowOrdering}
            rowOrderHandler={this.rowOrderHandler}
            isFetching={isFetching}
            isError={isError}
            refreshHandler={this.refresh}
            noItems={
              noItems && (
                <>
                  <p>
                    <GlButton
                      primary
                      onClick={() =>
                        openModal('selectMediaToPlaylists', {
                          items: [{ id: pid }]
                        })
                      }
                      data-testid="playlist-selected-add-media-button"
                    >
                      Click here to add media/ subplaylist(s)
                    </GlButton>
                  </p>
                </>
              )
            }
          />
          {this.activeModal(activeModalId)}
        </div>
      </>
    );
  }
}

PlaylistSelected.propTypes = {
  selectItems: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  pid: PropTypes.string,
  activeModalId: PropTypes.string,
  history: PropTypes.shape({}),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  isReloadPrevented: PropTypes.bool,
  preventReload: PropTypes.func,
  playlistNotFound: PropTypes.func
};

const mapStateToProps = state => ({
  activeModalId: state.modals.activeModal.modalId,
  selectedItems: state.table[tableId].selected,
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  selectItems: selectItemsAction,
  ...modalActions
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlaylistSelected)
);
