import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import parseDate from 'date-fns/parse';
import isBefore from 'date-fns/is_before';

import { shortenStringWithElipsis } from '../../../helpers/utils';
import { datesTimes, UITEXT } from '../../../helpers/misc';

export const tableSchema = [
  {
    id: 'createTimestamp',
    title: 'Timestamp',
    sortable: true
  },
  {
    id: 'userId',
    title: 'User',
    sortable: true
  },
  {
    id: 'operationType',
    title: 'Type of Operation',
    sortable: true
  },
  {
    id: 'playerName',
    title: 'Display Name',
    sortable: true
  },
  {
    id: 'frameName',
    title: 'Frame Name',
    sortable: true,
  },
  {
    id: 'playListName',
    title: 'Playlist Name',
    sortable: true
  },
  {
    id: 'scheduleType',
    title: 'Type of Schedule',
    sortable: true
  },
  {
    id: 'validFrom',
    title: 'Start',
    sortable: true
  },
  {
    id: 'validTo',
    title: 'End',
    sortable: true
  }
];

export const formatLogs = (schedulingLogs = []) => {
  const tableDataSelected = [];
  schedulingLogs.forEach((item, rowIndex) => {
    const maxChar = 40;

    tableDataSelected[rowIndex] = {
      id: rowIndex
    };

    const formattedTimestamp = String(parseDate(item.timestamp));

    tableDataSelected[rowIndex].createTimestamp = (
      <div className="ellipsis">
        <Tooltip position="bottom" followCursor title={formattedTimestamp}>
          {formattedTimestamp}
        </Tooltip>
      </div>
    );

    // Name
    tableDataSelected[rowIndex].userId = (
      <Tooltip position="bottom" followCursor title={item.user.email}>
        <a href={`mailto:${item.user.email}`}>
          {item.user.userId}
          {/* {item.user.lastName}, {item.user.firstName} */}
        </a>
      </Tooltip>
    );

    // Type of operation
    tableDataSelected[rowIndex].operationType = item.operationType;

    // Player Name
    tableDataSelected[rowIndex].playerName = (
      <div>
        <NavLink target="_blank" to={`/schedules/${item.playerId}/${item.playerName}`}>
          <Tooltip
            position="bottom"
            followCursor
            title={item.playerMetadata || UITEXT.playerNameTooltip}
          >
            {item.playerName}
          </Tooltip>
        </NavLink>
      </div>
    );

    const shortenedName = shortenStringWithElipsis(item.playlistName, maxChar);
    const playlistLink = (
      <NavLink to={`/playlists/${item.playlistId}`} target="_blank" rel="noopener noreferrer">
        {shortenedName}
      </NavLink>
    );

    // frame name
    tableDataSelected[rowIndex].frameName = item.frameName;
    // playlist name
    tableDataSelected[rowIndex].playListName = (
      <div>
        {item.playlistName.length >= maxChar ? (
          <>
            <Tooltip
              position="bottom"
              followCursor
              html={<div className="tooltipWidth">{item.playlistName}</div>}
            >
              {playlistLink}
            </Tooltip>
          </>
        ) : (
          <>{playlistLink}</>
        )}
      </div>
    );

    // Type of Schedule
    tableDataSelected[rowIndex].scheduleType = item.scheduleType;

    // start
    tableDataSelected[rowIndex].validFrom = item.validFrom;

    // end
    const validToDate = isBefore(item.validTo.slice(0, 10), datesTimes.scalaNeverEndsDate) ? item.validTo : 'never ends';
    tableDataSelected[rowIndex].validTo = validToDate;
  });

  return tableDataSelected;
};