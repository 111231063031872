import React from 'react';
export const tableSchema = [
  {
    id: 'name',
    title: 'name'
  },
  {
    id: 'lastModified',
    title: 'Last Modified'
  }
];

export const formTemplates = (items, onClickHandler) => {
  const formatedObj = [];
  items.forEach((item, rowIndex) => {
    let name = (
      <div>
        <button
          type="button"
          className="linkButton"
          onClick={() => {
            onClickHandler({ id: item.id, name: item.name });
          }}
        >
          {item.label || item.name}
        </button>
      </div>
    );
    formatedObj[rowIndex] = {
      id: item.id,
      name: name,
      lastModified: item.lastModified
    };
    formatedObj[rowIndex].selectInfo = {
      hideCheckbox: false,
      id: item.id,
      name: item.label || item.name
    };
  });
  return formatedObj;
};
