import React from 'react';
import PropTypes from 'prop-types';
import PlayerHealthLog from '../../components/PlayerHealthLog/PlayerHealthLog';

const PlayerHealth = ({
  match: {
    params: { playerId, playerName }
  }
}) => (
  <div className="generic-container">
    <h1 className="gl-heading--m">Player Health - {playerName}</h1>
    <PlayerHealthLog playerId={playerId} />
  </div>
);

PlayerHealth.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      playerId: PropTypes.string,
      playerName: PropTypes.string
    })
  })
};
export default PlayerHealth;
