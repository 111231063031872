import React from 'react';
import { NavLink } from 'react-router-dom';
import { GlButton } from '@adl/foundation';
import { Tooltip } from 'react-tippy';

import { transformCloudinaryImage, shortenStringWithElipsis } from '../../helpers/utils';
import { cloudinaryThumbnailTransforms } from '../../helpers/misc';
import store from '../../stores';
import { openModal } from '../../actions/modal';
import Thumbnail from '../Thumbnail/Thumbnail';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi } from '../../serviceLayer/services';

export const tableSchema = [
  {
    id: 'thumbnail',
    title: 'Thumbnail'
  },
  {
    id: 'name',
    title: 'Playlist Name',
    placeholder: 'Search for playlists',
    filter: true,
    sortable: true
    // autocomplete: true,
  },
  {
    id: 'duration',
    title: 'Duration'
  },
  {
    id: 'createdDate',
    title: 'Creation Date',
    //filter: true,
    sortable: true
  },
  {
    id: 'createdBy',
    title: 'Created By',
    filter: true,
    sortable: true
  },
  {
    id: 'lastModified',
    title: 'Last Modified',
    sortable: true
  },
  {
    id: 'lastModifiedBy',
    title: 'Last Updated By',
    filter: true,
    sortable: true
  },
  {
    id: 'activeOn',
    title: 'Active On'
  }
];

export const fetchPlaylistItemData = async pid =>
  getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }));

export const formatApiPlaylist = (apiRes, tableId) => {
  const tableData = [];

  if (!apiRes || apiRes.length < 1) {
    return [];
  }

  apiRes.forEach((item, rowIndex) => {
    tableData[rowIndex] = {
      id: item.id
    };

    const { secureUrl, resourceType } = item;

    const isEmpty = item.emptyOrExpiredItems; // checkEmptyPlaylist(playlistItems);
    tableData[rowIndex].hideCheckbox = isEmpty && tableId === 'dashboard:2';

    let thumbnailUrl = '';
    const format = secureUrl && secureUrl.split('.')[secureUrl.split('.').length - 1];
    const cloudinaryData = { secure_url: secureUrl, resource_type: resourceType, format };
    thumbnailUrl = transformCloudinaryImage(
      cloudinaryData,
      cloudinaryThumbnailTransforms.medialist
    );

    tableData[rowIndex].thumbnail = (
      <Thumbnail
        item={item}
        thumbnailUrl={thumbnailUrl}
        type="playlist"
        findId={tableId === 'selectPlaylistsToAddMedia' ? 'playlist' : null}
        cloudinaryData={cloudinaryData}
      />
    );

    const maxChar = 40;
    const shortenedName = shortenStringWithElipsis(item.name, maxChar);

    const playlistLink = (
      <NavLink
        className="ellipsis"
        target={tableId === 'selectPlaylistsToAddMedia' ? '_blank' : ''}
        to={`/playlists/${item.id}${tableId === 'selectPlaylistsToAddMedia' ? '?newTab=true' : ''}`}
      >
        <div className="core-green__main">{shortenedName}</div>
      </NavLink>
    );
    // name
    // Removing the Playlist type shown below the Playlist name.
    tableData[rowIndex].name = (
      <div>
        {item.name.length >= maxChar ? (
          <>
            <Tooltip
              position="bottom"
              followCursor
              html={<div className="tooltipWidth">{item.name}</div>}
            >
              {playlistLink}
            </Tooltip>
          </>
        ) : (
          <>
            {playlistLink}
            {isEmpty && tableId === 'dashboard:2' && (
              <div className="expiration-badge expiration-badge--expired">
                <Tooltip
                  title="Playlist might be empty or its assets may have expired"
                  position="bottom"
                  followCursor
                >
                  Playlist cannot be selected
                </Tooltip>
              </div>
            )}

            {isEmpty && tableId === 'playlists' && (
              <div className="expiration-badge expiration-badge--expired">
                Playlist is empty or its assets have expired
              </div>
            )}
          </>
        )}
      </div>
    );
    tableData[rowIndex].duration = item.duration;

    // items
    // let { itemCount } = item;
    // if (itemCount > 0 && item.playlistItems && item.playlistItems.length > 0) {
    //   const usedItemCount = item.playlistItems.filter(p => p.disabled === false).length;
    //   itemCount = `${item.itemCount} / ${usedItemCount}`;
    // }
    // tableData[rowIndex].items = itemCount;

    // created by
    tableData[rowIndex].createdBy = item.createdBy || '';

    // created date
    const createdDate =
      item.createdDate
        .split('.')[0]
        .split('T')
        .join(' ') || item.createdDate;
    tableData[rowIndex].createdDate = createdDate;

    // updated by
    tableData[rowIndex].lastModifiedBy = item.lastModifiedBy || '';

    // update date
    const lastModified =
      item.lastModified
        .split('.')[0]
        .split('T')
        .join(' ') || item.lastModified;
    tableData[rowIndex].lastModified = lastModified;

    tableData[rowIndex].selectInfo = {
      hideCheckbox: isEmpty && tableId === 'dashboard:2',
      id: item.id,
      name: item.name
    };

    // active on
    tableData[rowIndex].activeOn = (
      <GlButton
        tertiary
        aria-label="Show Usage"
        onClick={() => {
          store.dispatch(openModal('showUsage', { find: 'playlist', id: item.id }));
        }}
      >
        Show Usage
      </GlButton>
    );
    tableData[rowIndex].search = {
      name: `${item.name} ${item.playlistType}`
    };
  });
  return tableData;
};
