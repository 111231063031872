import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import Autocomplete from 'react-autocomplete';
import { withRouter } from 'react-router-dom';
import get from 'lodash-es/get';
import { isBefore, format } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';

import DatePicker from 'react-datepicker';
import { ReactComponent as IconSortAsc } from '../../../assets/icons/caret-up.svg';
import { ReactComponent as IconSortDesc } from '../../../assets/icons/caret-down.svg';
import { parseAssetName } from '../../../helpers/utils';
import './Table.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { getAccessTokenAndMakeCalls } from '../../../serviceLayer/utils';
import { callGetAutocompleteApi } from '../../../serviceLayer/services';

class TableFilter extends Component {
  state = {
    initialized: false,
    autocompleteList: [],
    startDate: null, // subDays(new Date(), 3),
    endDate: null, // new Date(),
    startcreatedDate: null,
    endcreatedDate: null,
    createdDate: null
  };

  debounceFetch = debounce(() => {
    const result = {};
    const { filterHandler, tableId } = this.props;
    const filterKeys = cloneDeep(this.state);
    if (tableId !== 'media' && tableId !== 'playlists' && tableId !== 'dashboard:2') {
      delete filterKeys.startDate;
      delete filterKeys.endDate;
      delete filterKeys.createdDate;
    }

    if (
      (tableId === 'media' || tableId === 'playlists' || tableId === 'dashboard:2') &&
      filterKeys
    ) {
      if (filterKeys.startcreatedDate && !filterKeys.endcreatedDate) {
        // filterKeys.endcreatedDate = filterKeys.startcreatedDate;
        delete filterKeys.startcreatedDate;
        return;
      }
      if (filterKeys.startcreatedDate && filterKeys.endcreatedDate) {
        filterKeys.createdDate = `${format(filterKeys.startcreatedDate, 'YYYY-MM-DD')},${format(
          filterKeys.endcreatedDate,
          'YYYY-MM-DD'
        )}`;
        delete filterKeys.startcreatedDate;
        delete filterKeys.endcreatedDate;
      }
      if (filterKeys.startDate && !filterKeys.endDate) {
        // filterKeys.endDate = filterKeys.startDate;
        delete filterKeys.startDate;
        return;
      }
      if (filterKeys.startDate && filterKeys.endDate) {
        filterKeys.lastModified = `${format(filterKeys.startDate, 'YYYY-MM-DD')},${format(
          filterKeys.endDate,
          'YYYY-MM-DD'
        )}`;
        delete filterKeys.startDate;
        delete filterKeys.endDate;
      }
    }
    Object.keys(filterKeys)
      .filter(key => {
        const { [key]: value } = filterKeys;
        return value != null && value !== '' && key !== 'initialized' && key !== 'autocompleteList';
      })
      .forEach(key => {
        const { [key]: value } = filterKeys;
        result[key] = value;
      });
    filterHandler(result);
  }, 500);

  static getDerivedStateFromProps(nextProps, prevState) {
    const { filteredInit } = nextProps;
    const { initialized } = prevState;

    if (!initialized && filteredInit && Object.keys(filteredInit).length > 0) {
      return { ...filteredInit, initialized: true };
    }

    return prevState;
  }

  isMediaTable = () => {
    const { tableId } = this.props;
    return ['media', 'selectMediaToPlaylists'].includes(tableId);
  };

  fetchAutocomplete = (search, columnId) => {
    if (columnId !== 'name') {
      return;
    }

    const filters = this.isMediaTable()
      ? '{"type":{"values":["MESSAGE"]},"workgroups":null}'
      : '{}';

    if (search.length < 3) {
      this.setState({ autocompleteList: [] });
      return;
    }

    getAccessTokenAndMakeCalls(token =>
      callGetAutocompleteApi(token, {
        search,
        filters,
        tableType: this.isMediaTable() ? 'media' : 'playlists'
      })
    ).then(res => this.setState({ autocompleteList: get(res, 'list', []) }));
  };

  render() {
    const {
      tableSchema,
      sortHandler,
      sort: { by: sortBy, order: sortOrder },
      tableId
    } = this.props;
    const { autocompleteList, startDate, endDate, startcreatedDate, endcreatedDate } = this.state;
    const onDateSelect = (date, column) => {
      if (column === 'lastModified') {
        if ((startDate && endDate) || (!startDate && !endDate)) {
          this.setState({ startDate: date, endDate: null });
        } else if (startDate) {
          if (isBefore(date, startDate)) {
            this.setState({ startDate: date });
          } else {
            this.setState({ endDate: date });
          }
        }
      } else if (column === 'createdDate') {
        if ((startcreatedDate && endcreatedDate) || (!startcreatedDate && !endcreatedDate)) {
          this.setState({ startcreatedDate: date, endcreatedDate: null });
        } else if (startcreatedDate) {
          if (isBefore(date, startcreatedDate)) {
            this.setState({ startcreatedDate: date });
          } else {
            this.setState({ endcreatedDate: date });
          }
        }
      }
    };

    const onDateChange = (date, column) => {
      if (column === 'lastModified' && date === null) {
        this.setState({ startDate: null, endDate: null, lastModified: null });
      } else if (column === 'createdDate' && date === null) {
        this.setState({ startcreatedDate: null, endcreatedDate: null, createdDate: null });
      }
    };

    const autocompleteListArr = [];
    if (autocompleteList) {
      autocompleteList.map(item =>
        autocompleteListArr.push({ id: item.id, label: parseAssetName(item.name) })
      );
    }

    const tableSchemaCopy = [...tableSchema];

    const isAutocompleteEmpty =
      autocompleteList !== undefined && autocompleteList.length > 0 ? 'block' : 'none';

    const autocompleteMenuStyle = {
      borderRadius: '3px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 12px',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0px',
      fontSize: '13px',
      position: 'fixed',
      maxHeight: '50%',
      left: 'unset',
      top: 'unset',
      width: '45vw',
      wordBreak: 'break-all'
    };

    const filters = tableSchemaCopy.map(column => {
      const columnId = column.id;
      const { [columnId]: value } = this.state;
      const { autocomplete } = column;
      const showDatePicker =
        (tableId === 'media' || tableId === 'playlists' || tableId === 'dashboard:2') &&
        (columnId === 'lastModified' || columnId === 'createdDate');
      const showDatelastModified =
        (tableId === 'media' || tableId === 'playlists' || tableId === 'dashboard:2') &&
        columnId === 'lastModified';
      const isAsc = sortBy === columnId && sortOrder === 'asc';
      const isDesc = sortBy === columnId && sortOrder === 'desc';

      return (
        <th
          id={`table-header-${columnId.toLowerCase()}`}
          className={`gl-table__label generic-table__label--${columnId.toLowerCase()}`}
          key={columnId}
        >
          <div className={`generic-table__th header_table--${columnId.toLowerCase()}`}>
            {column.sortable && (
              <div className="generic-table__sort">
                <div className="generic-table__sort-icons">
                  <button
                    disabled={isAsc}
                    onClick={() => sortHandler({ by: columnId, order: 'asc' })}
                    type="button"
                    data-testid={`sort-${columnId}-asc`}
                  >
                    <IconSortAsc
                      title="sort Ascending"
                      className={isAsc ? 'generic-table__sort--active' : ''}
                    />
                  </button>
                  <button
                    disabled={isDesc}
                    onClick={() => sortHandler({ by: columnId, order: 'desc' })}
                    type="button"
                    data-testid={`sort-${columnId}-desc`}
                  >
                    <IconSortDesc
                      className={
                        sortBy === columnId && sortOrder === 'desc'
                          ? 'generic-table__sort--active'
                          : ''
                      }
                    />
                  </button>
                </div>
              </div>
            )}
            {column.filter ? (
              <>
                <div className="gl-form-item gl-form-item--required">
                  {autocomplete ? (
                    <Autocomplete
                      wrapperProps={{ className: 'generic-table__input gl-input' }}
                      inputProps={{
                        className: 'gl-input__field',
                        placeholder:
                          (column.placeholder && column.placeholder.toUpperCase()) ||
                          (column.title && column.title.toUpperCase())
                      }}
                      getItemValue={item => item.label}
                      items={autocompleteListArr}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className="autocomplete-list"
                          key={item.label}
                          style={{ background: isHighlighted ? '#a7c1b9' : 'white' }}
                        >
                          {item.label}
                        </div>
                      )}
                      renderMenu={children => (
                        <div
                          className="menu"
                          style={{ ...autocompleteMenuStyle, display: isAutocompleteEmpty }}
                        >
                          {children}
                        </div>
                      )}
                      value={value}
                      onChange={e => {
                        this.fetchAutocomplete(e.target.value, columnId);
                        this.setState({ [columnId]: e.target.value });
                        this.debounceFetch();
                      }}
                      onSelect={_value => {
                        this.setState({ [columnId]: _value });
                        this.fetchAutocomplete(_value, columnId);
                        this.debounceFetch();
                      }}
                    />
                  ) : (
                    <input
                      className="gl-input gl-input__field"
                      value={value || ''}
                      placeholder={
                        (column.placeholder && column.placeholder.toUpperCase()) ||
                        (column.title && column.title.toUpperCase())
                      }
                      onChange={e => {
                        this.setState({ [columnId]: e.target.value });
                        this.debounceFetch();
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                {showDatePicker ? (
                  <>
                    {showDatelastModified ? (
                      <DatePicker
                        input
                        selected={startDate}
                        placeholderText="Last Modified"
                        onSelect={date => {
                          onDateSelect(date, 'lastModified');
                          this.debounceFetch();
                        }}
                        onChange={date => {
                          onDateChange(date, 'lastModified');
                          this.debounceFetch();
                        }}
                        isClearable
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        selectsStart
                        selectsEnd
                        shouldCloseOnSelect={startDate && !endDate}
                        maxDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        onChangeRaw={e => {
                          e.preventDefault();
                        }}
                        closeOnScroll
                        todayButton="Today"
                      />
                    ) : (
                      <DatePicker
                        selected={startcreatedDate}
                        onSelect={date => {
                          onDateSelect(date, 'createdDate');
                          this.debounceFetch();
                        }}
                        onChange={date => {
                          onDateChange(date, 'createdDate');
                          this.debounceFetch();
                        }}
                        isClearable
                        startDate={startcreatedDate}
                        endDate={endcreatedDate}
                        selectsRange
                        selectsStart
                        selectsEnd
                        shouldCloseOnSelect={startcreatedDate && !endcreatedDate}
                        maxDate={new Date()}
                        placeholderText="Upload Date"
                        dateFormat="yyyy-MM-dd"
                        onChangeRaw={e => {
                          e.preventDefault();
                        }}
                        todayButton="Today"
                      />
                    )}
                  </>
                ) : (
                  <div className="generic-table__sort--center">{column.render || column.title}</div>
                )}
              </>
            )}
          </div>
        </th>
      );
    });

    return (
      <thead>
        <tr>{filters}</tr>
      </thead>
    );
  }
}

TableFilter.propTypes = {
  tableId: PropTypes.string,
  tableSchema: PropTypes.arrayOf(PropTypes.object),
  filterHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  filteredInit: PropTypes.shape({}),
  location: PropTypes.shape({ pathname: PropTypes.string }),
  sort: PropTypes.shape({
    by: PropTypes.string,
    order: PropTypes.string
  })
};

export default withRouter(TableFilter);
