import React from 'react';

import './NavTab.scss';

const NavTab = ({ text, isActive, ...props }) => (
  <li className={`NavTab ${isActive ? 'NavTab--active' : ''}`} {...props}>
    {text}
  </li>
);

export default NavTab;
