import axiosOrg from 'axios';
import get from 'lodash-es/get';
import qs from 'qs';
import formatDate from 'date-fns/format';
import isBefore from 'date-fns/is_before';
import addDays from 'date-fns/add_days';
import addMonths from 'date-fns/add_months';

import { parseMetadata } from '../../../helpers/utils';
import { datesTimes, UITEXT } from '../../../helpers/misc';
import constants from '../../../helpers/constants';

import { getAccessTokenAndMakeCalls } from '../../../serviceLayer/utils';
import {
  callGetChannelApi,
  callGetPlayerApi,
  callGetPlaylistApi,
  callUpdatePlaylistApi
} from '../../../serviceLayer/services';

export const axios = axiosOrg.create({
  baseURL: 'https://adicontentmanager.digital-signage.adidas.com/ContentManager',
  paramsSerializer: params =>
    qs.stringify(params, {
      encodeValuesOnly: true,
      encoder: str => encodeURI(str)
    })
});

const thr = text => {
  throw new Error(text);
};

export const getChannelInfo = (channelId, params = {}) =>
  getAccessTokenAndMakeCalls(token =>
    callGetChannelApi(token, {
      channelId,
      params: {
        ...params
      }
    })
  );

export const updatePlaylistInfo = (playlistId, newPlaylistInfo) =>
  getAccessTokenAndMakeCalls(token =>
    callUpdatePlaylistApi(token, { playlistId, ...newPlaylistInfo })
  );

export const getTimeslots = async (channelId, frameId, params = {}) =>
  axios.get(`/api/rest/channels/${channelId}/frames/${frameId}/timeslots`, {
    params: {
      fromDate: '1900-01-01',
      toDate: '2030-01-02',
      ...params
    }
  });

export const getPlaylistInfo = playlistId =>
  getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId }));

export const getPlayerInfo = (playerId, params = {}) =>
  getAccessTokenAndMakeCalls(token =>
    callGetPlayerApi(token, {
      playerId,
      params
    })
  );

export const getPlayerSchedule = async playerId => {
  const playerInfo = await getPlayerInfo(playerId)
    .then(res => res.data)
    .catch(() => thr('Oops! Something went wrong.'));

  const { name: playerName, metadataValue: metadata } = playerInfo;
  const parsedMetadata = parseMetadata(metadata);

  const channelId = get(playerInfo, 'playerDisplays.0.channel.id', null);
  if (!channelId) {
    thr(UITEXT.noChannelAssignedMessage);
  }

  const channelInfo = await getChannelInfo(channelId)
    .then(resChannel => resChannel.data)
    .catch(() => thr('Oops! Something went wrong.'));

  const frames = get(channelInfo, 'frameset.frames', []);
  const mainFrame = frames.find(f => f.name === 'Main');
  const mainFrameId = mainFrame && mainFrame.id;

  if (!mainFrameId) {
    thr('No mainframe found');
  }
  const allTimeslots = await getTimeslots(channelId, mainFrameId).then(resTimeslots =>
    get(resTimeslots, 'data.timeslots', [])
  );

  const playlist = allTimeslots.find(slot => slot.playlist.name === playerName);
  const playlistId = get(playlist, 'playlist.id', null);

  const nonMps = allTimeslots.filter(slot => slot.playlist.name !== playerName);

  if (!playlistId) {
    thr('No playlist found');
  }

  const playlistInfo = await getPlaylistInfo(playlistId).then(resPlaylist => resPlaylist.data);
  if (!playlistInfo) {
    thr('There was an error loading playlist info');
  }

  const allSubplaylists = get(playlistInfo, 'playlistItems', []).filter(
    item => item.playlistItemType === 'SUB_PLAYLIST'
  );
  const allExceptCis = allSubplaylists.filter(item => item.subplaylist.id !== constants.cisId);
  const cis = allSubplaylists.find(item => item.subplaylist.id === constants.cisId);
  const playlistSchedules = {};
  const activeItemsIds = [];
  const disabledItemsIds = [];
  const allItems = {};
  const toBeSkipped = [];
  const allGroupings = {};

  allExceptCis.forEach((playlistItem, index) => {
    if (toBeSkipped.includes(playlistItem.id)) {
      return;
    }
    const secondItem = allExceptCis[index + 1];
    const thirdItem = allExceptCis[index + 2];
    const isDisabled = playlistItem.disabled;
    const startTime = get(playlistItem, 'timeSchedules.0.startTime', '00:00');
    let endTime = get(playlistItem, 'timeSchedules.0.endTime', '23:59');

    const defaultNeverEndsDate = formatDate(
      new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999)),
      datesTimes.scalaDateFormat
    );

    let endDate = get(playlistItem, 'endValidDate', datesTimes.scalaNeverEndsDate);

    endTime = endTime === '24:00' ? '23:59' : endTime;
    const fromStr =
      playlistItem.startValidDate || formatDate(new Date(), datesTimes.scalaDateFormat);

    const groupName = playlistItem.id;

    let grouping = [];

    if (
      secondItem &&
      secondItem.subplaylist.id === playlistItem.subplaylist.id &&
      secondItem.startValidDate ===
        formatDate(addDays(new Date(endDate), 1), datesTimes.scalaDateFormat) &&
      get(secondItem, 'timeSchedules.0.startTime', '00:00') === '00:00'
    ) {
      toBeSkipped.push(secondItem.id);
      if (
        thirdItem &&
        thirdItem.subplaylist.id === secondItem.subplaylist.id &&
        get(secondItem, 'endValidDate', datesTimes.scalaNeverEndsDate) !==
          datesTimes.scalaNeverEndsDate &&
        thirdItem.startValidDate ===
          formatDate(addDays(new Date(secondItem.endValidDate), 1), datesTimes.scalaDateFormat)
      ) {
        endDate = thirdItem.endValidDate;
        endTime = get(thirdItem, 'timeSchedules.0.endTime', '23:59');

        toBeSkipped.push(thirdItem.id);
        grouping = [playlistItem.id, secondItem.id, thirdItem.id];
      } else {
        endDate = secondItem.endValidDate;
        endTime = get(secondItem, 'timeSchedules.0.endTime', '23:59');
        grouping = [playlistItem.id, secondItem.id];
      }
    } else {
      grouping = [playlistItem.id];
    }
    allGroupings[groupName] = grouping;
    let neverEnds = false;
    if (endDate === datesTimes.scalaNeverEndsDate) {
      endDate = defaultNeverEndsDate;
      neverEnds = true;
    }

    const hasExpired = isBefore(`${endDate} ${endTime}`, new Date());

    const schedule = {
      validFrom: new Date(`${fromStr} ${startTime}`),
      validTo: new Date(`${endDate} ${endTime}`),
      neverEnds,
      selectedDays: [],
      hasExpired
    };

    if (isDisabled || hasExpired) {
      disabledItemsIds.push(playlistItem.id);
    } else {
      activeItemsIds.push(playlistItem.id);
    }
    playlistSchedules[playlistItem.id] = schedule;
    allItems[playlistItem.id] = playlistItem;
  });

  return {
    playerInfo,
    playerId,
    playlistId,
    playlistInfo,
    allItems,
    cis,
    activeItemsIds,
    disabledItemsIds,
    schedules: playlistSchedules,
    grouping: allGroupings,
    skipped: toBeSkipped,
    // takeoverPlaylist,
    mainFrameId,
    channelId,
    metadata: parsedMetadata,
    extraTimeslots: nonMps,
    channelInfo
  };
};
