import * as modalActions from '../actions/modal';

const initialState = {
  activeModal: {
    modalId: null
  },
  isDirty: false,
  notFound: false
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return {
        ...state,
        activeModal: {
          modalId: action.modalId,
          ...action.data
        }
      };
    case modalActions.CLOSE_MODAL:
      return {
        ...state,
        activeModal: {}
      };
    case modalActions.PREVENT_MODAL:
      return {
        ...state,
        isDirty: action.isDirty
      };
    case modalActions.PLAYLIST_NOT_FOUND:
      return {
        ...state,
        notFound: action.notFound
      };

    default:
      return state;
  }
};

export default modalReducer;
