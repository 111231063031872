import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CheckboxTreeContainer from '../commons/CheckboxTreeContainer/CheckboxTreeContainer';

class SelectUsers extends Component {
  state = {
    users: [],
    selectedUsersIds: []
  };

  onCheckedHanlder = selectedUsersIds => {
    const { selectUsers } = this.props;
    selectUsers(selectedUsersIds);
  };

  render() {
    const { users, preselectedUsers } = this.props;

    return (
      <div>
        <>
          Select Users:
          <CheckboxTreeContainer
            initChecked={preselectedUsers.map(el => el.toString())}
            data={users}
            checkHandler={this.onCheckedHanlder}
            showPlayersSelected={false}
            preSelectedNodes={preselectedUsers}
          />
        </>
      </div>
    );
  }
}

SelectUsers.propTypes = {
  selectUsers: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  preselectedUsers: state.adminPanel.selectedUsers || []
})

export default withRouter(connect(mapStateToProps, null)(SelectUsers));
