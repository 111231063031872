/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const CustomCheckboxItem = ({
  hasChildren,
  item,
  icons,
  expanded,
  halfChecked,
  checked,
  onCheck,
  onExpand,
  checkCounter,
  singleSelect,
  id
}) => {
  let icon;

  if (checked) {
    icon = icons.check;
  } else if (halfChecked) {
    icon = icons.halfCheck;
  } else {
    icon = icons.uncheck;
  }

  const isVisible = item.visible === 'true';

  const getButtonContent = () => {
    return (
      <div className="customCheckboxItem--label">
        {item.label}{' '}
        {hasChildren && !singleSelect ? (
          <span className="customCheckboxItem--label__counter">
            ({checkCounter[item.id] || 0}/{item.children.length})
          </span>
        ) : null}
        {item.description && (
          <span className="customCheckboxItem--label__counter">{` ${item.description}`}</span>
        )}
      </div>
    );
  };

  return (
    <div className="customCheckboxItem">
      <button
        type="button"
        onClick={(hasChildren && (() => onExpand(item.value, id))) || null}
        className={`customCheckboxItem--colapse ${
          hasChildren ? 'customCheckboxItem--colapse__clickable' : ''
        }`}
      >
        {hasChildren && (!expanded ? icons.expandClose : icons.expandOpen)}
      </button>
      {!item.visible && (
        <button
          type="button"
          className="customCheckboxItem--container"
          style={{ wordBreak: 'break-word' }}
          onClick={() => onCheck(item.value)}
        >
          <div className="customCheckboxItem--checkbox">{icon}</div>
          {getButtonContent()}
        </button>
      )}
      {item.visible && isVisible && (
        <button
          type="button"
          className="customCheckboxItem--container"
          style={{ wordBreak: 'break-word' }}
          onClick={() => onCheck(item.value)}
        >
          <div className="customCheckboxItem--checkbox">{icon}</div>
          {getButtonContent()}
        </button>
      )}
      {item.visible && !isVisible && (
        <button
          type="button"
          className="customCheckboxItem--container"
          style={{ wordBreak: 'break-word' }}
        >
          {getButtonContent()}
        </button>
      )}
    </div>
  );
};

CustomCheckboxItem.propTypes = {
  item: PropTypes.object,
  icons: PropTypes.object,
  expanded: PropTypes.bool,
  checked: PropTypes.bool,
  hasChildren: PropTypes.bool,
  halfChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  onExpand: PropTypes.func,
  checkCounter: PropTypes.object,
  singleSelect: PropTypes.bool
};

export default CustomCheckboxItem;
