import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GlCheckbox, GlCallout, GlButton } from '@adl/foundation';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import Pagination from './Pagination/Pagination';
import TableFilter from './TableFilter';
import { selectItems as selectItemsAction } from '../../../actions/table';
import Loader from '../Loader/Loader';
import {
  saveColumnOptions,
  transformFilterObjectToQueryParams,
  appendQueryParam
} from '../../../helpers/utils';

import './Table.scss';
import '../../../containers/Dashboard/Dashboard.scss';
import ColumnOptions from './ColumnOptions/ColumnOptions';

const SortableItem = sortableElement(
  ({ row, isChecked, rowClassNames, children, selectHandler }) => (
    <tr
      className={rowClassNames}
      key={row.id}
      onClick={e => {
        const tag = e.target.tagName.toLowerCase();
        const isThumb = e.target.closest('.generic-table__column--thumbnail');
        const isSorting = e.target.closest('.generic-table__sort');
        if (['button', 'a'].indexOf(tag) > -1 || isThumb || isSorting) {
          return;
        }

        selectHandler({
          ...row.selectInfo,
          isSelected: !isChecked
        });
      }}
    >
      {children}
    </tr>
  )
);

const SortableContainer = sortableContainer(({ children }) => <tbody>{children}</tbody>);

class Table extends PureComponent {
  state = {
    height: 400
  };

  int = 0;

  componentDidMount() {
    const { tableId, hasColumnOptions, hasRowOrdering, rowOrderHandler } = this.props;
    if (hasColumnOptions && tableId == null) {
      throw Error(
        'When activing hasColumnOptions flag on <Table /> Component. You Must specify tableId!!'
      );
    }
    if (hasRowOrdering && rowOrderHandler == null) {
      throw Error(
        'When activing Error flag on <Table /> Component. You Must specify rowOrderHandler!!'
      );
    }

    this.int = setInterval(this.setHeight, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.int);
  }

  setHeight = () => {
    const { height } = this.state;

    const calculatedHeight = this.calculateHeight();
    if (height === calculatedHeight) return;
    this.setState({ height: calculatedHeight });
  };

  calculateHeight = () => {
    const { tableId } = this.props;
    const windowHeight = window.innerHeight;
    const Header = document.querySelector('.Header');
    const headerHeight = Header ? Header.clientHeight : 87;

    const headingHeight = tableId.includes('dashboard') ? 175 : 105;
    const pagination = document.querySelector('.table-pagination');
    const paginationHeight = pagination ? 15 : 0;

    const tableHeight = windowHeight - headingHeight - headerHeight - paginationHeight;

    return tableHeight;
  };

  filterDisabledItems = row => {
    if (row && !row.hideCheckbox) {
      return row.selectInfo;
    }
  };

  selectHandler = (data, selectAll = false, unselectAll = false) => {
    const { selectItems, tableId, selectedItems: selectedAll } = this.props;
    let selectedNew = [];
    if (!unselectAll) {
      const { isSelected, hideCheckbox, ...itemInfo } = data;
      if (hideCheckbox) {
        return;
      }
      const { tableData } = this.props;
      if (selectAll) {
        const thisAllIds = tableData.map(r => r.selectInfo.id);
        const withoutThisPage = selectedAll.filter(sel => thisAllIds.indexOf(sel.id) < 0);
        if (isSelected) {
          let newTableData = [];
          if (tableId === 'selectsubPlaylistsToPlaylists') {
            // eslint-disable-next-line array-callback-return
            newTableData = tableData.filter(r => {
              if (r && !r.hideCheckbox) {
                return r.selectInfo;
              }
            });
          } else {
            newTableData = tableData.map(r => r.selectInfo);
          }
          selectedNew = [...withoutThisPage, ...newTableData];
        } else {
          selectedNew = withoutThisPage;
        }
      } else {
        selectedNew = selectedAll.filter(s => s.id !== itemInfo.id);
        if (isSelected) {
          selectedNew = [...selectedNew, itemInfo];
        }
      }
    }
    if (tableId === 'dashboard:2') {
      selectedNew = selectedNew.filter(item => !item.hideCheckbox);
    }
    selectItems(tableId, selectedNew);
  };

  isCheckBoxHidden = (isEmpty, tableId) => isEmpty && tableId === 'dashboard:2';

  hideColumnHandler = (id, isHidden) => {
    const { tableId, settings } = this.props;
    const currentSettings = { ...settings[tableId] };
    const { hiddenColumns: hiddenColumnsCopy = {} } = currentSettings;
    const newSettings = {
      ...settings,
      [tableId]: {
        ...currentSettings,
        hiddenColumns: {
          ...hiddenColumnsCopy,
          [id]: isHidden
        }
      }
    };

    saveColumnOptions(tableId, { ...newSettings });
  };

  columnOrderHandler = newSchema => {
    const { tableId, settings } = this.props;
    const currentSettings = { ...settings[tableId] };
    const newSettings = {
      ...settings,
      [tableId]: {
        ...currentSettings,
        columnsOrder: newSchema.reduce((prev, curr, i) => ({ ...prev, [curr.id]: i }), {})
      }
    };

    saveColumnOptions(tableId, newSettings);
  };

  unselectAllHandler = () => {
    this.selectHandler(null, false, true);
  };

  filterHandler = (filtered = {}) => {
    const { filterHandler, history, tableId, hasQueryParamsHandling } = this.props;

    if (hasQueryParamsHandling) {
      appendQueryParam(tableId, history, transformFilterObjectToQueryParams(filtered));
    }

    filterHandler({ filtered });
  };

  sortHandler = sort => {
    const { sortHandler, tableId, history, hasQueryParamsHandling } = this.props;

    if (hasQueryParamsHandling) {
      appendQueryParam(tableId, history, { page: 1, sortBy: sort.by, order: sort.order });
    }

    sortHandler({ sort });
  };

  paginationHandler = index => {
    const { paginationHandler, tableId, history, hasQueryParamsHandling } = this.props;
    const newPage = { page: index + 1 };

    if (hasQueryParamsHandling) {
      appendQueryParam(tableId, history, newPage);
    }

    paginationHandler(newPage);
  };

  getTableBody = (children, hasRowOrdering, rowOrderHandler, tableId) =>
    hasRowOrdering ? (
      <SortableContainer
        lockAxis="y"
        lockToContainerEdges
        helperClass="while-sorting"
        onSortEnd={rowOrderHandler}
        shouldCancelStart={element => {
          const tag = element.target.tagName.toLowerCase();
          let forbiddens = [
            'svg',
            'path',
            'input',
            'textarea',
            'select',
            'option',
            'button',
            'span'
          ];
          if (tableId === 'scheduleDetails') {
            forbiddens.push('div', 'ul', 'a', 'li');
            forbiddens = forbiddens.filter(ele => ele !== 'span');
          } else if (tableId === 'playlistselected') {
            forbiddens.push('div');
          }
          const isThumb = element.target.closest('.generic-table__column--thumbnail');

          if (isThumb || forbiddens.includes(tag)) {
            return true;
          }
          return false;
        }}
      >
        {children}
      </SortableContainer>
    ) : (
      <tbody>{children}</tbody>
    );

  prepareColumnSchema(
    tableSchema,
    isSelectable,
    areAllSelected,
    hiddenColumns,
    columnsOrder,
    disableCheckbox
  ) {
    let tableSchemaCopy = [...tableSchema];
    if (isSelectable) {
      tableSchemaCopy = [
        {
          id: 'checkbox',
          title: 'Checkbox',
          disableHiding: true,
          disableOrdering: true,
          render: (
            <div className="checkbox__container">
              <GlCheckbox
                isChecked={areAllSelected}
                inputProps={{ disabled: disableCheckbox }}
                onChange={e =>
                  this.selectHandler(
                    {
                      isSelected: e.target.checked
                    },
                    true
                  )
                }
              />
            </div>
          )
        },
        ...tableSchemaCopy
      ];
    }

    if (Object.keys(columnsOrder).length > 0) {
      tableSchemaCopy = tableSchemaCopy.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        columnsOrder[a.id] > columnsOrder[b.id]
          ? 1
          : columnsOrder[a.id] < columnsOrder[b.id]
          ? -1
          : 0
      );
    }

    return tableSchemaCopy.map(column => {
      const isHidden = hiddenColumns[column.id] != null ? hiddenColumns[column.id] : false;
      return { ...column, isHidden };
    });
  }

  mapCells(row, column, isChecked) {
    let tdTemplate;
    const { hideCheckbox } = row;
    const columnClassnames = classNames(
      'generic-table__column',
      `generic-table__column--${column.id.toLowerCase()}`
    );
    switch (column.id) {
      case 'checkbox':
        tdTemplate = (
          <td className="generic-table__column generic-table__column--checkbox" key="checkbox">
            {!hideCheckbox && (
              <GlCheckbox
                className="generic-table__checkbox"
                isChecked={isChecked}
                onChange={e => {}}
              />
            )}
          </td>
        );
        break;
      default:
        tdTemplate = (
          <td className={columnClassnames} key={column.id}>
            {row[column.id]}
          </td>
        );
        break;
    }

    return tdTemplate;
  }

  renderTable(
    data,
    schema,
    selectedIdsMap,
    hasRowOrdering,
    rowOrderHandler,
    hiddenColumns,
    isSelectable
  ) {
    const table = data.map((row, index) => {
      const isChecked = selectedIdsMap[row.id];
      const isDisabled = row.disabled;
      const rowClassNames = classNames({
        'generic-table__row': true,
        'generic-table__row--sortable': hasRowOrdering && !row.subHeader,
        'generic-table__row--expires-soon': row.expiryStatus === 'expires-soon',
        'generic-table__row--expired': row.expiryStatus === 'expired',
        'generic-table__row--disabled': isDisabled,
        'generic-table__row--selected': isChecked
      });

      const columnsParsed = schema
        .filter(column => !hiddenColumns[column.id])
        .map(column => this.mapCells(row, column, isChecked));

      // can show a subheader with all columns merged, pass subHeader and colSpan
      // this needs to be moved under sortable items but not droppable or unselectable
      if (row.subHeader) {
        const columnClassnames = classNames(
          'generic-table__column',
          'sub-column-header',
          `generic-table__column--${row.id.toLowerCase()}`
        );
        return (
          <tr
            className={rowClassNames}
            key={row.id}
            id={`row__${row.id}`}
            colSpan={row.colSpan}
            onClick={e => {
              if (!isSelectable) {
              }
            }}
          >
            <td className={columnClassnames} colSpan={row.colSpan} key={row.id}>
              {row.subHeader}
            </td>
          </tr>
        );
      }

      return !hasRowOrdering ? (
        <tr
          className={rowClassNames}
          key={row.id}
          id={`row__${row.id}`}
          onClick={e => {
            if (!isSelectable) {
              return;
            }
            const tag = e.target.tagName.toLowerCase();
            const isThumb = e.target.closest('.generic-table__column--thumbnail');
            const isSorting = e.target.closest('.generic-table__sort');
            if (['button', 'a'].indexOf(tag) > -1 || isThumb || isSorting || row.hideCheckbox) {
              return;
            }

            this.selectHandler({
              ...row.selectInfo,
              isSelected: !isChecked
            });
          }}
        >
          {columnsParsed}
        </tr>
      ) : (
        <SortableItem
          key={row.id}
          index={index}
          row={row}
          selectHandler={this.selectHandler}
          isChecked={isChecked}
          rowClassNames={rowClassNames}
        >
          {columnsParsed}
        </SortableItem>
      );
    });
    return table;
  }

  render() {
    const {
      tableId,
      tableSchema,
      isFilterable,
      isSelectable,
      selectedItems,
      sort,
      pageInfo,
      isFetching,
      tableData,
      hasRowOrdering,
      noItems,
      rowOrderHandler,
      isError,
      hasColumnOptions,
      filteredInit,
      hideRefreshOnError,
      settings,
      className,
      preSelectedItems,
      selectedItemsFirst
    } = this.props;
    const { hiddenColumns = {}, columnsOrder = {} } = settings[tableId] || {};
    const pageLimit = pageInfo.limit || 20;
    const selectedIdsMap = selectedItems.reduce((prev, curr) => ({ ...prev, [curr.id]: true }), {});
    const tableDataIds = tableData.map(row => row.id);

    // preSelected && selectedItems.push(...preSelected);
    if (preSelectedItems) {
      for (const row of preSelectedItems) {
        if (row && row.id && tableDataIds.includes(row.id)) {
          selectedIdsMap[row.id] = true;
        }
      }
    }

    const disableCheckbox = tableData.length <= 0 || isFetching;
    const areAllSelected =
      !isFetching &&
      !disableCheckbox &&
      (tableId === 'selectsubPlaylistsToPlaylists'
        ? tableData.filter(el => selectedIdsMap[el.id]).length ===
          tableData.filter(el => !el.hideCheckbox).length
        : tableData.filter(el => selectedIdsMap[el.id]).length === tableData.length);

    const showNoItems = !tableData.length && !isFetching && !isError;
    const showError = isError;
    const showPagination = pageInfo.total > 1 && !isFetching && !showError;

    const noItemsContent = (
      <div className="list--noitems">
        <GlCallout type="urgent">{noItems}</GlCallout>
      </div>
    );

    const fetchingError = (
      <div className="list--error">
        <GlCallout>
          <h5 className="callout-error__text">
            Could not connect to server. Please try again later.
          </h5>
          {!hideRefreshOnError && (
            <GlButton onClick={() => this.filterHandler()} aria-label="Refresh">
              Refresh
            </GlButton>
          )}
        </GlCallout>
      </div>
    );

    const noColumnsError = (
      <div className="list--error">
        <GlCallout>
          <h5 className="callout-error__text">
            All Columns are hidden. Select columns you want to display.
          </h5>
        </GlCallout>
      </div>
    );

    const columnsSchema = this.prepareColumnSchema(
      tableSchema,
      isSelectable,
      areAllSelected,
      hiddenColumns,
      columnsOrder,
      disableCheckbox
    );

    let tableRendered = [];

    if (selectedItemsFirst && selectedItems.length > 0 && !hasRowOrdering) {
      const filteredSelectedData = [];
      selectedItems.forEach(selectedItem => {
        tableData.forEach(data => {
          if (selectedItem.id === data.id) filteredSelectedData.push(data);
        });
      });
      tableRendered = this.renderTable(
        filteredSelectedData,
        columnsSchema,
        selectedIdsMap,
        hasRowOrdering,
        rowOrderHandler,
        hiddenColumns,
        isSelectable
      );
    }
    let filteredTableData = [];
    if (selectedItemsFirst && !hasRowOrdering) {
      tableData.forEach(data => {
        if (!selectedIdsMap[data.id]) filteredTableData.push(data);
      });
    } else filteredTableData = tableData;
    const table = this.renderTable(
      filteredTableData,
      columnsSchema,
      selectedIdsMap,
      hasRowOrdering,
      rowOrderHandler,
      hiddenColumns,
      isSelectable
    );
    tableRendered = tableRendered.concat(table);

    const areAllComunsHidden = columnsSchema.filter(key => !hiddenColumns[key]).length === 0;
    const showUnselectAll =
      selectedItems.length > 0 && tableId !== 'results' && tableId !== 'scheduleDetails';
    const children = !isFetching ? (
      tableRendered
    ) : (
      <tr>
        <td
          className="gl-table__loader--centered"
          colSpan={columnsSchema.filter(el => !el.isHidden).length}
        >
          <Loader />
        </td>
      </tr>
    );

    // const hideOverflowX = showNoItems || isFetching || showError;

    const { height } = this.state;
    const totalCount = this.props.count ? `/${this.props.count}` : '';
    return (
      <>
        {showUnselectAll && (
          <div style={{ position: 'relative', height: 1 }}>
            <button
              type="button"
              onClick={this.unselectAllHandler}
              className="checkbox__unselect-all"
              data-testid={`${tableId}-unselect`}
            >
              {`Unselect all (${selectedItems.length}${totalCount})`}
            </button>
          </div>
        )}
        <div
          className={`genetic-table-container ${tableId} ${className}`}
          style={{ maxHeight: height, height }}
        >
          <div className="column-options">
            {hasColumnOptions && (
              <ColumnOptions
                columnOrderHandler={this.columnOrderHandler}
                hideColumnHandler={this.hideColumnHandler}
                schema={columnsSchema}
                tableId={tableId}
              />
            )}
          </div>
          <div className="gl-table">
            <div className="gl-table__container">
              {/* <div className="" style={{ overflowX: hideOverflowX ? 'hidden' : 'auto' }}> */}
              <div className="">
                <table className="generic-table">
                  {isFilterable && (
                    <TableFilter
                      tableSchema={columnsSchema.filter(el => !el.isHidden)}
                      filterHandler={this.filterHandler}
                      filteredInit={filteredInit}
                      sortHandler={this.sortHandler}
                      sort={sort}
                      tableId={tableId}
                    />
                  )}
                  {this.getTableBody(children, hasRowOrdering, rowOrderHandler, tableId)}
                </table>
                {areAllComunsHidden && noColumnsError}
                {showNoItems && noItemsContent}
                {showError && !isFetching && fetchingError}
              </div>
            </div>
          </div>
        </div>
        {showPagination && (
          <div className="table-pagination">
            <Pagination
              key={`${pageInfo.total}`}
              totalRecords={pageInfo.total}
              pageLimit={pageLimit}
              currentPage={pageInfo.current}
              pageNeighbours={1}
              onPageChanged={({ currentPage }) => this.paginationHandler(currentPage - 1)}
            />
          </div>
        )}
      </>
    );
  }
}

Table.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  tableSchema: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  tableId: PropTypes.string,
  className: PropTypes.string,
  isFilterable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  hasRowOrdering: PropTypes.bool,
  hasColumnOptions: PropTypes.bool,
  // for making params work we need table id and/or sortHandler, filterHandler, paginationHandler and filteredInit
  hasQueryParamsHandling: PropTypes.bool,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  hideRefreshOnError: PropTypes.bool,
  selectItems: PropTypes.func,
  filteredInit: PropTypes.shape({}),
  settings: PropTypes.shape({}),
  sortHandler: PropTypes.func,
  rowOrderHandler: PropTypes.func,
  filterHandler: PropTypes.func,
  paginationHandler: PropTypes.func,
  location: PropTypes.shape({}),
  history: PropTypes.shape({}),
  noItems: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  sort: PropTypes.shape({
    by: PropTypes.string,
    order: PropTypes.string
  }),
  pageInfo: PropTypes.shape({
    limit: PropTypes.number,
    total: PropTypes.number,
    current: PropTypes.number
  })
};

Table.defaultProps = {
  sort: {
    by: 'lastModified',
    order: 'desc'
  },
  pageInfo: {
    total: 1,
    current: 1
  },
  className: '',
  paginationHandler() {},
  sortHandler() {},
  filterHandler() {},
  noItems: <h5>No items Found !</h5>
};

const mapStateToProps = (state, { tableId }) => ({
  selectedItems: state.table[tableId] ? state.table[tableId].selected : [],
  settings: state.table.settings || {}
});

const mapDispatchToProps = {
  selectItems: selectItemsAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
