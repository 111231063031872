/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton } from '@adl/foundation';
import { Container, Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import * as modalActions from '../../actions/modal';
import { arrayToObjectByIds } from '../../helpers/utils';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import './Modals.scss';
import { UITEXT } from '../../helpers/misc';
import CheckboxTreeContainer from '../commons/CheckboxTreeContainer/CheckboxTreeContainer';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callUserBuildRequestApi,
  callUserInfoApi,
  callGetLocationApi
} from '../../serviceLayer/services';

class UserWorkgroupModal extends Component {
  state = {
    workgroups: [],
    initExpandedMap: {},
    loading: false,
    selectedWorkgroupsMap: {},
    finished: false,
    error: false,
    expanded: {}
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.fetchData();
  }

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  fetchData = () => {
    this.setState({ loading: true });
    const { selectedUsers } = this.props;
    const selectedUsersByIds = arrayToObjectByIds(selectedUsers);
    const promises = [
      getAccessTokenAndMakeCalls(token => callGetLocationApi(token)),
      ...selectedUsers.map(usr =>
        getAccessTokenAndMakeCalls(token =>
          callUserInfoApi(token, {
            userId: usr.value
          })
        )
      )
    ];

    Promise.all(promises)
      .then(res => {
        const selectedWorkgroupsMap = {};
        const initExpandedMap = {};
        for (let i = 1; i < res.length; i += 1) {
          const data = res[i];
          const nodes = res[0];
          if (data && data.length > 0) {
            const { userId } = data[0];

            if (
              selectedUsersByIds &&
              selectedUsersByIds[userId] &&
              selectedUsersByIds[userId].isAdmin
            ) {
              selectedWorkgroupsMap[userId] = nodes
                .map(el => el.id)
                .reduce((prev, curr) => [...prev, curr], []);
            } else {
              selectedWorkgroupsMap[userId] = data
                .map(el => el.locationId)
                .reduce((prev, curr) => [...prev, curr], []);
            }

            initExpandedMap[userId] = data
              .map(el => el.locationPath)
              .filter(path => path != null)
              .map(path => path.split('/').filter(el => el !== '' && el !== 'null'))
              .reduce((prev, curr) => [...prev, ...curr], []);
          }
        }
        this.setState({
          workgroups: this.formItems(res[0]),
          selectedWorkgroupsMap,
          initExpandedMap,
          loading: false,
          selectedUsersByIds
        });
      })
      .catch(() => {
        this.setState({
          workgroups: [],
          initExpandedMap: {},
          selectedWorkgroupsMap: {},
          loading: false
        });
      });
  };

  formItems = items =>
    items.map(item => {
      const formatedObj = {
        label: item.name,
        value: item.id,
        id: item.id,
        parentId: item.parentId,
        children: item.children ? this.formItems(item.children) : null,
        type: item.type,
        description: item.playerDescription,
        visible: item.visible
      };

      return formatedObj;
    });

  submitForm = () => {
    const { selectedWorkgroupsMap } = this.state;
    this.setState({ loading: true });
    const keys = Object.keys(selectedWorkgroupsMap);
    Promise.all(keys.map(id => this.buildUserRequest(id, selectedWorkgroupsMap[id])))
      .then(() => {
        this.setState({ loading: false, finished: true });
      })
      .catch(() => {
        this.setState({ loading: false, error: true });
      });
  };

  buildUserRequest = (userId, groups) => {
    getAccessTokenAndMakeCalls(token =>
      callUserBuildRequestApi(token, {
        userId,
        params: {
          groups
        }
      })
    );
  };

  onCheckHanlder = (currentSelected, id) => {
    const { preventReload } = this.props;
    const { selectedWorkgroupsMap } = this.state;
    const copy = { ...selectedWorkgroupsMap };
    copy[id] = currentSelected;

    this.setState({ selectedWorkgroupsMap: copy });

    preventReload(true);
  };

  onExpandHanlder = (value, id) => {
    const { expanded } = this.state;
    const isChildrenExpanded = expanded[id] && expanded[id][value];
    const updatedExpanded = {
      ...expanded,
      [id]: {
        ...expanded[id],
        [value]: !isChildrenExpanded
      }
    };

    this.setState({ expanded: updatedExpanded });
  };

  expandAll = id => {
    const { expanded, workgroups } = this.state;

    const findAllByKey = (obj, keyToFind) =>
      Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value)
            : typeof value === 'object' && value
            ? acc.concat(findAllByKey(value, keyToFind))
            : acc,
        []
      ) || [];

    const ids = findAllByKey(workgroups, 'id');
    // eslint-disable-next-line no-sequences
    const reducedIds = ids.reduce((acc, curr) => ((acc[curr] = true), acc), {});
    const expandedData = { ...expanded, [id]: reducedIds };

    this.setState({ expanded: expandedData });
  };

  collapseAll = id => {
    const { expanded } = this.state;
    const expandedData = { ...expanded, [id]: {} };

    this.setState({ expanded: expandedData });
  };

  render() {
    const { closeModal, selectedUsers, isReloadPrevented } = this.props;
    const {
      workgroups,
      selectedWorkgroupsMap,
      loading,
      error,
      finished,
      selectedUsersByIds,
      expanded
    } = this.state;
    const colSize = 12;
    const noOfAdmins = selectedUsers.filter(u => u && u.isAdmin === true);
    const allAdmins = selectedUsers && noOfAdmins && selectedUsers.length === noOfAdmins.length;

    if (loading) {
      return (
        <Modal size="small" open modalCloseHandler={() => closeModal()}>
          <Loader />
        </Modal>
      );
    }

    if (finished) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
          }}
        >
          <h4>Success</h4>
          <p className="validation-success">Wrokgroups assigned successfully.</p>
        </Modal>
      );
    }

    if (error) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
          }}
        >
          <h4>Workgroups</h4>
          <p className="validation-error">Something went wrong.</p>
        </Modal>
      );
    }

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />
        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          <h4>Assign Workgroups</h4>

          <Container fluid>
            <Row align="start" justify="start">
              {selectedUsers.map(usr => (
                <Col
                  className="edit-workgroup-border"
                  key={usr.value}
                  xl={colSize}
                  style={{ marginBottom: '20px' }}
                >
                  {selectedUsersByIds && selectedUsersByIds[usr.id].isAdmin && <h5>{usr.label}</h5>}
                  {selectedUsersByIds && !selectedUsersByIds[usr.id].isAdmin && (
                    <div className="validity-row__adminExpandAll">
                      <h5>{usr.label}</h5>
                      <div className="expand-collapse-all--buttons">
                        <Tooltip
                          position="bottom"
                          followCursor
                          html={<div className="tooltipWidth">{UITEXT.expandAllButton}</div>}
                        >
                          <GlButton
                            aria-label="label"
                            icon="arrow-down"
                            noBorder
                            onClick={() => this.expandAll(usr.id)}
                          />
                        </Tooltip>
                        <Tooltip
                          position="bottom"
                          followCursor
                          html={<div className="tooltipWidth">{UITEXT.collapseAllButton}</div>}
                        >
                          <GlButton
                            aria-label="label"
                            icon="arrow-up"
                            noBorder
                            onClick={() => this.collapseAll(usr.id)}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  Assign workgroup to user:
                  <CheckboxTreeContainer
                    id={usr.id}
                    initChecked={
                      (selectedWorkgroupsMap[usr.value] &&
                        selectedWorkgroupsMap[usr.value].map(val => val.toString())) ||
                      null
                    }
                    // initExpanded={initExpandedMap[usr.value] || null}
                    data={workgroups}
                    checkHandler={selectedNodes => this.onCheckHanlder(selectedNodes, usr.value)}
                    showPlayersSelected
                    selectedUsersByIds={selectedUsersByIds ? selectedUsersByIds[usr.id] : null}
                    onExpandHanlder={this.onExpandHanlder}
                    expanded={(expanded[usr.id] && expanded[usr.id]) || {}}
                  />
                </Col>
              ))}
            </Row>
          </Container>

          <div className="custom-modal__button-container">
            <GlButton
              disabled={allAdmins}
              className="custom-modal__button custom-modal__button--primary"
              onClick={this.submitForm}
            >
              Save
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => closeModal()}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

UserWorkgroupModal.propTypes = {
  closeModal: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.object),
  preventReload: PropTypes.func,
  isReloadPrevented: PropTypes.bool
};

const mapState = state => ({
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  ...modalActions
};

export default connect(
  mapState,
  mapDispatchToProps
)(UserWorkgroupModal);
