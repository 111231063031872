import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/check-square.svg';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/square-regular.svg';
import { ReactComponent as Openend } from '../../../assets/icons/caret-right-solid.svg';
import { ReactComponent as Closed } from '../../../assets/icons/caret-down.svg';
import { ReactComponent as HalfCheck } from '../../../assets/icons/check-indeterminate.svg';

import './CheckboxTreeContainer.scss';
import CustomCheckboxTree from './CustomCheckboxTree/CustomCheckboxTree';

const CheckboxTreeContainer = ({
  data,
  checkHandler,
  initChecked,
  singleSelect,
  initExpanded,
  showPlayersSelected,
  selectedUsersByIds,
  isLCM,
  requestArea,
  resourceId,
  storePreviousIds,
  flattenedNodes,
  expanded,
  onExpandHanlder,
  id
}) => (
  <div className="checkboxTreeContainer">
    {data.length === 0 ? (
      <b>There are no items available.</b>
    ) : (
      <CustomCheckboxTree
        id={id}
        expanded={expanded}
        onExpandHanlder={onExpandHanlder}
        nodes={data}
        singleSelect={singleSelect}
        initChecked={initChecked}
        initExpanded={initExpanded}
        onCheck={checkedNodes => checkHandler(checkedNodes)}
        showPlayersSelected={showPlayersSelected}
        icons={{
          check: <CheckboxCheckedIcon />,
          uncheck: <CheckboxIcon />,
          expandClose: <Openend />,
          expandOpen: <Closed />,
          halfCheck: <HalfCheck />
        }}
        selectedUsersByIds={selectedUsersByIds}
        isLCM={isLCM}
        requestArea={requestArea}
        resourceId={resourceId}
        storePreviousIds={storePreviousIds}
        flattenedNodes={flattenedNodes}
      />
    )}
  </div>
);

CheckboxTreeContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  initChecked: PropTypes.arrayOf(PropTypes.string),
  initExpanded: PropTypes.arrayOf(PropTypes.string),
  checkHandler: PropTypes.func,
  singleSelect: PropTypes.bool,
  showPlayersSelected: PropTypes.bool,
  selectedUsersByIds: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.number,
    isAdmin: PropTypes.bool,
    label: PropTypes.string,
    parentId: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.number
  }),
  isLCM: PropTypes.bool,
  requestArea: PropTypes.string,
  resourceId: PropTypes.number,
  storePreviousIds: PropTypes.func,
  flattenedNodes: PropTypes.arrayOf(String),
  onExpandHanlder: PropTypes.func,
  expanded: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number
};
export default CheckboxTreeContainer;
