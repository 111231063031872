import React, { Component } from 'react';

import WithTooltip from '../WithTooltip/WithTooltip';

import './ActionButton.scss';

class ActionButton extends Component {
  state = {
    message: this.props.startMessage
  };

  handleClick = () => {
    const { click, successMessage } = this.props;
    try {
      click();
      this.setState({ message: successMessage });
    } catch (err) {
      this.setState({ message: 'Something went wrong' });
    }
  };

  render() {
    const { message } = this.state;
    const { children, startMessage } = this.props;

    return (
      <WithTooltip
        type="button"
        tooltip={message}
        onClick={this.handleClick}
        onMouseLeave={() => this.setState({ message: startMessage })}
        additionalClassName="action-button"
      >
        {children}
      </WithTooltip>
    );
  }
}

export default ActionButton;
