import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatDate from 'date-fns/format';
import { addMonths, differenceInSeconds } from 'date-fns';
import { GlCheckbox, GlButton, GlCallout } from '@adl/foundation';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import subDays from 'date-fns/sub_days';
import isBefore from 'date-fns/is_before';

import { datesTimes, UITEXT } from '../../helpers/misc';
import { parseMetadata } from '../../helpers/utils';
import SetMultipleDisplayPlaylists from './SetMultipleDisplayPlaylists';
import * as scheduleActions from '../../actions/scheduleV2';
import Loader from '../commons/Loader/Loader';
import Modal from '../commons/Modal/Modal';
import './SetMultipleDisplaySchedules.scss';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callGetFrameDetailsApi,
  callGetChannelApi,
  callGetPlayerApi,
  callTimeslotsDataApi
} from '../../serviceLayer/services';

class SetMultipleDisplaySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      framesData: {},
      isFetching: true,
      channelData: {},
      playerData: [],
      metadata: [],
      playerDimensions: {},
      showSuccessError: true,
      selectedFrames: {},
      showCopyConfirmation: false,
      frameDataToBeCopied: {}
    };
    this.updatePlaylistData = this.updatePlaylistData.bind(this);
  }

  confirmCopySchedule(frameData) {
    this.setState({ frameDataToBeCopied: frameData, showCopyConfirmation: true });
  }

  copySchedule() {
    const self = this;
    const { selectedFrames, frameDataToBeCopied } = this.state;
    const { selectedPlayers } = this.props;
    const selectedPlayerIds = selectedPlayers.map(sp => sp.id);
    const dataToSchedule = cloneDeep(self.props.dataToSchedule);
    dataToSchedule.forEach(data => {
      if (selectedFrames[data.channelId] === data.frameId)
        data.playlists = cloneDeep(frameDataToBeCopied.playlists);
    });
    this.setState({ showCopyConfirmation: false, frameDataToBeCopied: {} });
    self.props.updateDataToSchedule(dataToSchedule, selectedPlayerIds);
  }

  updateSelectedFrames(channelId, frameId) {
    const { selectedFrames } = this.state;
    selectedFrames[channelId] = frameId;
    this.setState({ selectedFrames });
  }

  updatePlaylistData(playerId, frameId, playlistId, attribute, value) {
    const self = this;
    const dataToSchedule = cloneDeep(self.props.dataToSchedule);
    const { selectedPlayers } = this.props;
    const selectedPlayerIds = selectedPlayers.map(sp => sp.id);
    dataToSchedule.forEach(data => {
      const playlistSelected = [];
      if (data.frameId === frameId && data.playerId === playerId) {
        switch (attribute) {
          case 'deactivate':
            data.deactivate = !data.deactivate;
            if (data.deactivate) data.advanceDeactivate = false;
            break;
          case 'advanceDeactivate':
            data.advanceDeactivate = !data.advanceDeactivate;
            if (data.advanceDeactivate) data.deactivate = false;
            break;
          case 'rowsRearranged':
            data.playlists = [...value];
            break;
          default:
            let takeOverUpdated = false;
            data.playlists.forEach(playlist => {
              if (playlist.playlistId === playlistId) {
                switch (attribute) {
                  case 'playlistSelected':
                    playlist.playlistSelected = !playlist.playlistSelected;
                    break;
                  case 'neverEnds':
                    playlist.playlistSelected = true;
                    playlist.neverEnds = !playlist.neverEnds;
                    if (playlist.neverEnds) {
                      formatDate(
                        addMonths(new Date(playlist.startDate), 3),
                        datesTimes.scalaDateFormat
                      );
                      playlist.endTime = '23:59';
                      playlist.takeOver = false;
                    }
                    break;
                  case 'takeOver':
                    takeOverUpdated = true;
                    playlist.playlistSelected = true;
                    playlist.takeOver = !playlist.takeOver;
                    if (playlist.takeOver) {
                      playlist.neverEnds = false;
                    }
                    break;
                  case 'startDateAndTime':
                    playlist.playlistSelected = true;
                    playlist.startDate = formatDate(value, datesTimes.scalaDateFormat);
                    playlist.startTime = formatDate(value, datesTimes.scalaTimeFormat);
                    if (
                      differenceInSeconds(
                        new Date(`${playlist.endDate} ${playlist.endTime}`),
                        value
                      ) < 1
                    ) {
                      playlist.endDate = formatDate(
                        addMonths(value, 3),
                        datesTimes.scalaDateFormat
                      );
                    }
                    break;
                  case 'endDateAndTime':
                    playlist.playlistSelected = true;
                    playlist.endDate = formatDate(value, datesTimes.scalaDateFormat);
                    playlist.endTime = formatDate(value, datesTimes.scalaTimeFormat);
                    break;
                  default:
                    break;
                }
                const maxAllowedValidFromDate = new Date(
                  subDays(new Date(), 7).setHours(0, 0, 0, 0)
                );
                // date cannot be before 7 days, we update the date
                let validFrom = new Date(`${playlist.startDate} ${playlist.startTime}`);
                if (isBefore(validFrom, maxAllowedValidFromDate)) {
                  validFrom = maxAllowedValidFromDate;
                  playlist.startDate = formatDate(validFrom, datesTimes.scalaDateFormat);
                  playlist.startTime = formatDate(validFrom, datesTimes.scalaTimeFormat);
                }
              }
              if (playlist.playlistSelected) playlistSelected.push(playlist.playlistId);
            });
            if (attribute === 'takeOver' && takeOverUpdated) {
              data.playlists.forEach(playlist => {
                if (playlist.playlistId !== playlistId) playlist.takeOver = false;
              });
            }
            break;
        }
      }
    });
    self.props.updateDataToSchedule(dataToSchedule, selectedPlayerIds);
  }

  componentDidMount() {
    this.fetchPlayerInfo();
  }

  async fetchPlayerInfo() {
    const self = this;
    const { selectedPlayers, selectedPlaylists } = this.props;
    const sortedPlayers = selectedPlayers.sort((a, b) => a.name.localeCompare(b.name));
    const sortedPlaylists = selectedPlaylists; // .sort((a, b) => {return a.name.localeCompare(b.name)})
    const selectedPlayerIds = sortedPlayers.map(sp => sp.id);
    const selectedPlaylistIds = sortedPlaylists.map(sp => sp.id);
    const playerData = [];
    const noChannelAssigned = [];
    const channelData = {};
    const playerDimensions = {};
    const metadata = [];
    const playersWithErrors = [];
    if (selectedPlayerIds.length && selectedPlaylistIds.length) {
      const fetchedChannelIds = selectedPlayerIds.map(async player => {
        const playerInfo = await getAccessTokenAndMakeCalls(token =>
          callGetPlayerApi(token, { playerId: player })
        );
        const meta = get(playerInfo, 'metadataValue', []);
        const channelId = get(playerInfo, 'playerDisplays.0.channel.id', null);
        if (channelId !== null) {
          playerData.push({ ...playerInfo, metadata: parseMetadata(meta) });
          const channelName = get(playerInfo, 'playerDisplays.0.channel.name', null);
          const playerId = playerInfo.id;
          const playerName = playerInfo.name;
          if (channelName !== playerName) playersWithErrors.push(playerName);
          channelData[channelId] = channelName;
          const channelInfo = await getAccessTokenAndMakeCalls(token =>
            callGetChannelApi(token, { channelId })
          );
          playerDimensions[playerId] = {
            width: channelInfo.frameset.width,
            height: channelInfo.frameset.height
          };
          return channelId;
        }
        noChannelAssigned.push(playerInfo);
        return Promise.resolve('noChannelId');
      });
      Promise.all(fetchedChannelIds).then(async channelIds => {
        channelIds = channelIds.filter(value => value !== 'noChannelId');
        const fetchedFramesData = await getAccessTokenAndMakeCalls(token =>
          callGetFrameDetailsApi(token, { channelIds: channelIds.join() })
        );
        const playlists = selectedPlaylists.map(playlist => ({
          playlistId: playlist.id,
          playlistName: playlist.name,
          playlistSelected: false,
          startDate: formatDate(new Date(), datesTimes.scalaDateFormat),
          endDate: formatDate(addMonths(new Date(), 3), datesTimes.scalaDateFormat),
          startTime: '00:00',
          endTime: '23:59',
          neverEnds: true,
          takeOver: false
        }));
        const toBeScheduled = playerData.map(async player => {
          const channelId = get(player, 'playerDisplays.0.channel.id', null);
          const channelName = get(player, 'playerDisplays.0.channel.name', null);
          const playerId = player.id;
          const playerName = player.name;
          if (channelId !== null) {
            const scheduledDataFrame = fetchedFramesData[channelId].map(async frame => {
              const masterPlaylistName = `${playerName}_${frame.name}`;
              /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "getTimeslotsData" }] */
              const getTimeslotsData = await getAccessTokenAndMakeCalls(token =>
                callTimeslotsDataApi(token, { channelId, frameId: frame.frameId })
              );
              const timeslots = get(getTimeslotsData, 'timeslots', []).filter(
                slot => slot.playlist.name === masterPlaylistName
              );
              const sorted = timeslots.sort((a, b) => a.sortOrder - b.sortOrder);
              const mplistName = get(sorted, '0.playlist.name', null);
              if (mplistName !== masterPlaylistName) {
                if (playersWithErrors.indexOf(playerName) === -1)
                  playersWithErrors.push(playerName);
              }
              let updatedPlaylists = cloneDeep(playlists);
              if (fetchedFramesData[channelId].length === 1 && playerData.length === 1) {
                updatedPlaylists = updatedPlaylists.map(playlist => ({
                  ...playlist,
                  playlistSelected: true
                }));
              }
              return {
                frameId: frame.frameId,
                frameName: frame.name,
                channelName,
                channelId: Number(channelId),
                playerId,
                playerName,
                deactivate: false,
                advanceDeactivate: false,
                playlists: updatedPlaylists
              };
            });
            return Promise.all(scheduledDataFrame).then(async dataFrame => dataFrame);
          }
        });
        Promise.all(toBeScheduled).then(async dataToBeScheduled => {
          dataToBeScheduled = dataToBeScheduled.flat(3);
          if (playersWithErrors.length <= 0) self.props.updateWarningAcknowledged(true);
          self.props.updateDataToSchedule(dataToBeScheduled, selectedPlayerIds);
          self.setState({
            framesData: fetchedFramesData,
            isFetching: false,
            channelData,
            playerData: playerData.sort((a, b) => a.name.localeCompare(b.name)),
            noChannelAssigned: noChannelAssigned.sort((a, b) => a.name.localeCompare(b.name)),
            playerDimensions,
            metadata,
            playersWithErrors: playersWithErrors.sort()
          });
        });
      });
    }
  }

  render() {
    const {
      channelData,
      framesData,
      isFetching,
      playerData,
      noChannelAssigned,
      metadata,
      playersWithErrors,
      showSuccessError,
      playerDimensions,
      showCopyConfirmation,
      frameDataToBeCopied
    } = this.state;
    const {
      selectedPlaylists,
      dataToSchedule,
      isSaving,
      completed,
      warningAcknowledged,
      successfullyScheduled,
      errorWhileScheduling,
      optimizedScheduleList
    } = this.props;
    if (isFetching) {
      return (
        <div className="main-container">
          <Loader />
        </div>
      );
    }
    const list = [];
    const overallSuccess = [];
    const overallError = [];
    const successChannelwise = {};
    const errorChannelwise = {};
    Object.keys(framesData).forEach(channelId => {
      const success = [];
      const error = [];
      const framesList = {};
      optimizedScheduleList.forEach(frame => {
        if (frame.channelId.toString() === channelId.toString())
          framesList[frame.frameId] = frame.frameName;
      });
      Object.keys(framesList).forEach(frameId => {
        // frames for that channel, check which are successful
        if (successfullyScheduled.includes(frameId.toString())) {
          success.push(framesList[frameId]);
          overallSuccess.push(framesList[frameId]);
        } else {
          error.push(framesList[frameId]);
          overallError.push(framesList[frameId]);
        }
      });
      successChannelwise[channelId] = { channelName: channelData[channelId], success };
      errorChannelwise[channelId] = { channelName: channelData[channelId], error };
    });
    const message = () => {
      list.push(<h4>{UITEXT.schedulingCompleted}</h4>);
      // successfullyScheduled success frameIds list
      // errorWhileScheduling error frameIds list
      // get frameslist as per channelId
      // if any or all frames have error - show in error section with channel and frameName
      // since some have errors show which frames were successful in error section
      if (overallSuccess.length === successfullyScheduled.length && overallError.length === 0) {
        list.push(<p className="validation-success">{UITEXT.scheduleSuccessMessage}</p>);
        Object.keys(successChannelwise).forEach(channelId => {
          successChannelwise[channelId] &&
            successChannelwise[channelId].success &&
            successChannelwise[channelId].success.length > 0 &&
            list.push(
              <div>
                {`${successChannelwise[channelId].channelName}: ${successChannelwise[
                  channelId
                ].success.join(', ')}`}
              </div>
            );
        });
      } else if (
        overallError.length === errorWhileScheduling.length &&
        overallSuccess.length === 0
      ) {
        list.push(<p className="validation-error">{UITEXT.scheduleErrorMessage}</p>);
        Object.keys(errorChannelwise).forEach(channelId => {
          errorChannelwise[channelId] &&
            errorChannelwise[channelId].error &&
            errorChannelwise[channelId].error.length > 0 &&
            list.push(
              <div>
                {`${errorChannelwise[channelId].channelName}: ${errorChannelwise[
                  channelId
                ].error.join(', ')}`}
              </div>
            );
        });
      } else {
        successfullyScheduled.length !== 0 && list.push(<h5>{`${UITEXT.success}:`}</h5>);
        Object.keys(successChannelwise).forEach(channelId => {
          successChannelwise[channelId] &&
            successChannelwise[channelId].success &&
            successChannelwise[channelId].success.length > 0 &&
            list.push(
              <div>
                {`${successChannelwise[channelId].channelName}: ${successChannelwise[
                  channelId
                ].success.join(', ')}`}
              </div>
            );
        });
        list.push(<h5>{`${UITEXT.error}:`}</h5>);
        Object.keys(errorChannelwise).forEach(channelId => {
          errorChannelwise[channelId] &&
            errorChannelwise[channelId].error &&
            errorChannelwise[channelId].error.length > 0 &&
            list.push(
              <div>
                {`${errorChannelwise[channelId].channelName}: ${errorChannelwise[
                  channelId
                ].error.join(', ')}`}
              </div>
            );
        });
      }
      return list;
    };
    const multiplePlayers = playerData.length > 1;
    return (
      <div>
        <Modal
          size="medium"
          className=""
          open={showSuccessError && (isSaving || completed)}
          enableOutsideClick
          modalCloseHandler={() => {
            this.setState({ showSuccessError: false });
          }}
        >
          {isSaving && <Loader />}
          {completed && message()}
        </Modal>
        <Modal
          size="medium"
          className=""
          open={showCopyConfirmation}
          enableOutsideClick
          modalCloseHandler={() => {
            this.setState({ showCopyConfirmation: false });
          }}
        >
          <>
            <h4>{UITEXT.applyScheduleHeader}</h4>
            <p>
              {`${UITEXT.applyScheduleConfirmation} ${frameDataToBeCopied &&
                frameDataToBeCopied.frameName} frame of ${frameDataToBeCopied &&
                frameDataToBeCopied.channelName &&
                frameDataToBeCopied.channelName.replace(
                  /_/g,
                  ' '
                )} display to all selected frames?`}
            </p>
            <div>
              <GlButton primary className="margin-right-15" onClick={() => this.copySchedule()}>
                {UITEXT.continue}
              </GlButton>

              <GlButton secondary onClick={() => this.setState({ showCopyConfirmation: false })}>
                {UITEXT.cancel}
              </GlButton>
            </div>
          </>
        </Modal>
        {noChannelAssigned && noChannelAssigned.length > 0 && (
          <div className="list--error" style={{ padding: '10px' }}>
            <GlCallout>
              <h5 className="callout-error__text" style={{ padding: '0px' }}>
                {noChannelAssigned.length > 1
                  ? UITEXT.noChannelsAssignedMessage
                  : UITEXT.noChannelAssignedMessage}
              </h5>
              {noChannelAssigned && noChannelAssigned.map(detail => detail.name).join(', ')}
            </GlCallout>
          </div>
        )}
        {playersWithErrors && playersWithErrors.length > 0 && (
          <div className="scheduling-needs-attention" style={{ display: 'block' }}>
            <div>
              <ul>
                {playersWithErrors.map((playerName, index) => (
                  <li key={index}>
                    <strong>{playerName}</strong>
                  </li>
                ))}
              </ul>
              <p>
                {playersWithErrors.length === 1
                  ? UITEXT.scheduleCautionSingle
                  : UITEXT.scheduleCaution}
              </p>
              <div>
                <GlCheckbox
                  className="schedule-warning"
                  label={UITEXT.scheduleCautionCheckboxLabel}
                  isChecked={warningAcknowledged}
                  inputProps={{ disabled: this.props.isSaving || this.props.completed }}
                  onChange={() => this.props.updateWarningAcknowledged(!warningAcknowledged)}
                />
              </div>
            </div>
          </div>
        )}
        {playerData &&
          playerData.map(player => (
            <SetMultipleDisplayPlaylists
              key={player.id}
              player={player}
              framesData={framesData}
              metadata={metadata}
              selectedPlaylists={selectedPlaylists}
              dataToSchedule={dataToSchedule}
              isSaving={isSaving}
              completed={completed}
              updatePlaylistData={this.updatePlaylistData}
              playerDimensions={playerDimensions[player.id]}
              updateSelectedFrames={(channelId, toggleValue) =>
                this.updateSelectedFrames(channelId, toggleValue)
              }
              confirmCopySchedule={framesData => this.confirmCopySchedule(framesData)}
              multiplePlayers={multiplePlayers}
              successChannelwise={
                successChannelwise[get(player, 'playerDisplays.0.channel.id', null)]
              }
              errorChannelwise={errorChannelwise[get(player, 'playerDisplays.0.channel.id', null)]}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedPlayers: state.table['dashboard:1'].selected,
  selectedPlaylists: state.table['dashboard:2'].selected,
  warningAcknowledged: state.scheduleV2.warningAcknowledged,
  isSaving: state.scheduleV2.isSaving,
  completed: state.scheduleV2.completed,
  dataToSchedule: state.scheduleV2.dataToSchedule,
  successfullyScheduled: state.scheduleV2.successfullyScheduled,
  errorWhileScheduling: state.scheduleV2.errorWhileScheduling,
  optimizedScheduleList: state.scheduleV2.optimizedScheduleList
});

const mapDispatchToProps = {
  updateWarningAcknowledged: scheduleActions.updateWarningAcknowledged,
  updateDataToSchedule: scheduleActions.updateDataToSchedule
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetMultipleDisplaySchedules)
);
