import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';

import Table from '../commons/Table/Table';
import { tableSchema } from './schema';
import './PlayersList.scss';

const PlayersList = ({
  tableId,
  updateFiltersOnPlayers,
  filtered,
  sort,
  list,
  isFetching,
  pageInfo,
  isError,
  count,
  goToPage
}) => {
  // Right now we are filtering at client side using below two func and Fuse.js,
  // plans is to move it to server side in upcoming sprints (as we have close to 1200 players now in prod)
  let playerListData = applyFiltersOnDisplayLibrary(filtered, list);
  playerListData = applySortingOnDisplayLibrary(sort, playerListData);
  return (
    <div className="generic-container">
      <h1 className="gl-heading--m page-title" data-testid="players-scheduling-page-title">
        Display Library
      </h1>
      <Table
        key={tableId}
        tableData={playerListData}
        isFetching={isFetching}
        tableSchema={tableSchema}
        tableId={tableId}
        filteredInit={filtered}
        isFilterable
        hideRefreshOnError
        hasColumnOptions
        hasQueryParamsHandling={false}
        isSelectable
        filterHandler={updateFiltersOnPlayers}
        isError={isError}
        sort={sort}
        sortHandler={updateFiltersOnPlayers}
        pageInfo={pageInfo}
        paginationHandler={goToPage}
        count={count}
        selectedItemsFirst
      />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  const {
    isFetching: newIsFetching,
    pageInfo: { current: newCurrent },
    sort: newSort,
    filtered: newFiltered
  } = nextProps;
  const {
    isFetching,
    pageInfo: { current },
    sort,
    filtered
  } = prevProps;

  return (
    newSort === sort &&
    newFiltered === filtered &&
    newCurrent === current &&
    newIsFetching === isFetching
  );
}

const applySortingOnDisplayLibrary = (sort, list) => {
  const { by, order } = sort;
  return list.sort((a, b) => {
    const avalue = a.index[by] || a[by] || '';
    const bvalue = b.index[by] || b[by] || '';
    return order === 'asc' ? avalue.localeCompare(bvalue) : bvalue.localeCompare(avalue);
  });
};

const applyFiltersOnDisplayLibrary = (filtered, _list) => {
  let result = [..._list];

  Object.entries(filtered).forEach(([key, value]) => {
    const options = {
      keys: [`index.${key}`],
      shouldSort: true,
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true
    };
    const fuse = new Fuse(result, options);
    result = fuse.search(value);
  });

  return result;
};

PlayersList.propTypes = {
  tableId: PropTypes.string,
  location: PropTypes.shape({}),
  history: PropTypes.shape({}),
  updateFiltersOnPlayers: PropTypes.func,
  goToPage: PropTypes.func,

  filtered: PropTypes.shape({}),
  sort: PropTypes.shape({}),

  isFetching: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  pageInfo: PropTypes.shape({
    total: PropTypes.number,
    limit: PropTypes.number,
    current: PropTypes.number
  }),
  isError: PropTypes.bool,
  count: PropTypes.number
};

export default withRouter(PlayersList, areEqual);
