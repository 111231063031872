import * as uploadActions from '../actions/upload';

const initialState = {
  files: [],
  rejected: [],
  filesData: []
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadActions.UPLOAD_FILES:
      return {
        ...state,
        files: action.payload.accepted,
        rejected: action.payload.rejected
      };
    case uploadActions.UPLOADING_FILES_DATA:
      return {
        ...state,
        filesData: action.fileData
      }
    default:
      return state;
  }
};

export default uploadReducer;
