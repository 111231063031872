import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlCallout, GlButton } from '@adl/foundation';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { Container, Row, Col } from 'react-grid-system';

import { findPlaylistInPlayers, generatePlan } from '../../helpers/utils';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import { closeModal as closeModalAction } from '../../actions/modal';
import './Modals.scss';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistsSearchApi } from '../../serviceLayer/services';

class ShowUsageModal extends Component {
  state = {
    isFetching: true,
    items: [],
    loadError: false,
    fetched: false
  };

  componentDidMount() {
    const { find, id } = this.props;

    if (find === 'media') {
      this.findMediaInPlaylists(id);
    } else if (find === 'playlist') {
      this.findPlaylistInPlayers(id);
    }
  }

  findMediaInPlaylists = mediaId => {
    const { closeModal } = this.props;
    getAccessTokenAndMakeCalls(token =>
      callGetPlaylistsSearchApi(token, {
        params: {
          filters: `{'media' : {'values':[${mediaId}], comparator : 'eq'}}`,
          fields: 'id,name',
          limit: 1000
        }
      })
    ).then(res => {
      const list = get(res, 'list', []);
      const items = list.map(({ name, id: itemId }) => (
        <p key={itemId} className="breakWord">
          <NavLink to={`/playlists/${itemId}`} onClick={() => closeModal()}>
            <div className="core-green__main">{name}</div>
          </NavLink>
        </p>
      ));
      this.setState({ items, isFetching: false, fetched: true });
    });
  };

  findPlaylistInPlayers = async playlistId => {
    const { closeModal } = this.props;

    const players = await findPlaylistInPlayers(playlistId);

    const items = players.map(({ name, id: itemId }) => (
      <p key={itemId} className="breakWord">
        <NavLink to={`/schedules/${itemId}/${name}`} onClick={() => closeModal()}>
          <div className="core-green__main">{name}</div>
        </NavLink>
      </p>
    ));
    this.setState({ items, isFetching: false, fetched: true, players });
  };

  render() {
    const { closeModal, find, customClose } = this.props;
    const { isFetching, items, loadError, fetched, players } = this.state;

    const notUsed = items.length < 1 && fetched && !loadError;
    const showLoading = isFetching;
    const showList = fetched && items.length > 0;
    const itemIn = find === 'playlist' ? 'playlist' : 'media';
    const findIn = find === 'playlist' ? 'players' : 'playlists';
    return (
      <Modal
        open
        enableOutsideClick
        modalCloseHandler={() => (customClose != null ? customClose() : closeModal())}
      >
        {showLoading && <Loader />}
        {loadError && (
          <GlCallout type="very-urgent">
            <h5>Something went wrong.</h5>
            <p>There was an error loading usage information</p>
          </GlCallout>
        )}
        {notUsed && (
          <GlCallout type="urgent">
            <h5>Not used</h5>
            <p>
              Selected {find} is not used in any {findIn}
            </p>
          </GlCallout>
        )}
        {showList && (
          <div>
            <Container>
              <Row>
                <Col>
                  <h5>{itemIn} Usage</h5>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {find === 'playlist' && (
                    <GlButton tertiary onClick={() => generatePlan(players.map(p => p.id))}>
                      Generate plan
                    </GlButton>
                  )}
                </Col>
              </Row>
            </Container>

            <p>
              This {itemIn} is used in following {findIn}:
            </p>
            {items}
          </div>
        )}
      </Modal>
    );
  }
}

ShowUsageModal.propTypes = {
  id: PropTypes.number,
  find: PropTypes.string,
  closeModal: PropTypes.func,
  customClose: PropTypes.func
};

const mapStateToProps = state => ({
  find: state.modals.activeModal.find,
  id: state.modals.activeModal.id
});
const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowUsageModal);
