import { UITEXT } from '../../helpers/misc';

export const tableSchema = [
  {
    id: 'name',
    title: UITEXT.name
  },
  {
    id: 'left',
    title: UITEXT.left
  },
  {
    id: 'top',
    title: UITEXT.top
  },
  {
    id: 'width',
    title: UITEXT.width
  },
  {
    id: 'height',
    title: UITEXT.height
  },
  {
    id: 'autoscale',
    title: UITEXT.autoscale
  },
  {
    id: 'action',
    title: UITEXT.action
  }
];
export const applyToAllTableSchema = [
  {
    id: 'name',
    title: UITEXT.name
  },
  {
    id: 'left',
    title: UITEXT.left
  },
  {
    id: 'top',
    title: UITEXT.top
  },
  {
    id: 'width',
    title: UITEXT.width
  },
  {
    id: 'height',
    title: UITEXT.height
  },
  {
    id: 'autoscale',
    title: UITEXT.autoscale
  }
];
export const defaultFrame = {
  name: '',
  left: '',
  top: '',
  width: '',
  height: '',
  autoscale: ''
};
export const frameColors = [
  '#e32b2b',
  '#00aa55',
  '#da1a7b',
  '#2ada71',
  '#ede734',
  '#00a2a2',
  '#0071ae'
];
export const getRandomColor = (data) => {

  let colors = data.map(frame => frame.color)
  let difference = frameColors.filter(x => !colors.includes(x));
  if (difference.length > 0) return difference[randomIntFromInterval(0, difference.length - 1)];
  return frameColors[randomIntFromInterval(0, frameColors.length - 1)];
};
export const validationMessages = {
  name: UITEXT.nameValidationMessage,
  height: UITEXT.heightValidationMessage,
  width: UITEXT.widthValidationMessage,
  top: UITEXT.topValidationMessage,
  left: UITEXT.leftValidationMessage
};
export const errorMessages = {
  name: UITEXT.nameErrorMessage,
  height: UITEXT.heightErrorMessage,
  width: UITEXT.widthErrorMessage,
  top: UITEXT.topErrorMessage,
  left: UITEXT.leftErrorMessage
};
export const autoscaleOptions = [
  {
    label: UITEXT.fitInside,
    value: 'FIT_INSIDE'
  },
  {
    label: UITEXT.fillExactly,
    value: 'FILL_EXACTLY'
  },
  {
    label: UITEXT.fillAndTrim,
    value: 'FILL_AND_TRIM'
  }
];
export const framePresetDimensions = [
  {
    label: '768 x 1024',
    value: '768x1024'
  },
  {
    label: '1024 x 768',
    value: '1024x768'
  },
  {
    label: '1080 x 1920',
    value: '1080x1920'
  },
  {
    label: '1440 x 1920',
    value: '1440x1920'
  },
  {
    label: '1600 x 1024',
    value: '1600x1024'
  },
  {
    label: '1600 x 1200',
    value: '1600x1200'
  },
  {
    label: '1920 x 1080',
    value: '1920x1080'
  },
  {
    label: '2160 x 3840',
    value: '2160x3840'
  },
  {
    label: '3840 x 2160',
    value: '3840x2160'
  }
];
export const maxScreenDimensions = 16588800;
export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const setFrameValidations = data => {
  const frameValidations = {};
  Object.keys(data).forEach(channelId => {
    frameValidations[channelId] = {};
    data[channelId].forEach(frame => {
      frameValidations[channelId][frame.frameId] = {};
    });
  });
  frameValidations['apply-all'] = {};
  frameValidations['apply-all']['frame-id'] = {};
  return frameValidations;
};

export const confirmationModalMessage = modalId => {
  switch (modalId) {
    case 'cancelAddFrameToAll':
      return UITEXT.cancelAddFrameToAllConfirmationMessage;
    case 'cancelFrameConfigurationNoChanges':
      return UITEXT.cancelFrameConfigurationNoChangesConfirmationMessage;
    case 'cancelFrameConfiguration':
      return UITEXT.cancelFrameConfigurationConfirmationMessage;
    case 'addFrameToAll':
      return UITEXT.addFrameToAllConfirmationMessage;
    case 'deleteFrame':
      return UITEXT.deleteFrameConfirmationMessage;
    case 'saveFrameData':
      return UITEXT.saveFrameDataConfirmationMessage;
    case 'resetFrameData':
      return UITEXT.resetFrameDataConfirmationMessage;
    default:
  }
};

export const getValidationError = (valueType, val, frameId, frames) => {
  const value = val.toString(); // incase value is initialy a number
  let error = false;
  switch (valueType) {
    case 'name':
      const firstCharAlphanumeric = /^[A-Za-z0-9]\w*/;
      const checkConditionFirstChar = value.match(firstCharAlphanumeric);
      const specialChar = /[A-Za-z0-9-_ ]/g;
      const checkConditionSpecialChar = value.match(specialChar);
      const whiteSpace = /^(\w+\s?-*)*\s*$/;
      const checkWhiteSpace = value.match(whiteSpace);
      if (!value || value.length === 0) error = validationMessages[valueType];
      else if (checkConditionFirstChar === null) error = UITEXT.nameValidationMessageFirstChar;
      else if (checkConditionSpecialChar && checkConditionSpecialChar.length !== value.length) error = UITEXT.nameValidationMessageSpecialChars;
      else if (checkWhiteSpace === null) error = UITEXT.nameValidationMessageWhiteSpace;
      const frameNames = [];
      frameId !== 'frame-id' &&
        frames.forEach(frame => {
          if (frameId !== frame.frameId) frameNames.push(frame.name.toLowerCase());
        });
      if (frameNames.includes(value.toLowerCase().trim())) error = errorMessages[valueType];
      break;
    case 'height':
    case 'width':
      if (isNaN(value) || value.length === 0) {
        error = validationMessages[valueType];
      } else if (value < 1 || value > 64000) error = errorMessages[valueType];
      break;
    case 'top':
    case 'left':
      if (isNaN(value) || value.length === 0) error = validationMessages[valueType];
      else if (value < 0 || value > 64000) error = errorMessages[valueType];
      break;
    default:
      break;
  }
  return error;
};

export const isInputValid = (channelId, frameId, placeholder, frameValidations) => {
  if (channelId && frameId && placeholder) {
    const error = frameValidations[channelId][frameId][placeholder];
    if (error === undefined) return null;
    return frameValidations[channelId][frameId][placeholder];
  }
};

export const getFailedSuccessChannelNames = (
  data,
  channelData,
  failedChannels,
  successChannelIds
) => {
  let failedChannelNames = [];
  let successChannelNames = [];
  Object.keys(data).forEach(channelId => {
    failedChannels.forEach(failedChannel => {
      if (Number(channelId) === failedChannel.channelId)
        channelData[channelId] &&
          channelData[channelId].channelName &&
          failedChannelNames.push(
            `${channelData[channelId].channelName.replace(/_/g, ' ')}: ${failedChannel.message}`
          );
    });
    if (successChannelIds.includes(Number(channelId)))
      channelData[channelId] &&
        channelData[channelId].channelName &&
        successChannelNames.push(channelData[channelId].channelName.replace(/_/g, ' '));
  });
  failedChannelNames = failedChannelNames.sort();
  successChannelNames = successChannelNames.sort();
  return { failed: failedChannelNames, success: successChannelNames };
};

export const isAnyFrameValueEmpty = data => {
  if (
    data.name === '' ||
    data.height === '' ||
    data.width === '' ||
    data.left === '' ||
    data.top === '' ||
    data.autoscale === ''
  )
    return true;
  return false;
};

export const canFramesBeSaved = (frameValidations, channelData, data, updatedChannels, isDirty) => {
  const some = [];
  // Validations check
  Object.keys(frameValidations).forEach(channelId => {
    const heightValue =
      channelData[channelId] &&
      channelData[channelId].height &&
      channelData[channelId].height.toString();
    const widthValue =
      channelData[channelId] &&
      channelData[channelId].width &&
      channelData[channelId].width.toString();

    const maxDimensionsError =
      channelData[channelId] &&
      Number(Number(channelData[channelId].height) * Number(channelData[channelId].width)) >
        Number(maxScreenDimensions);
    if (
      channelId !== 'apply-all' &&
      (heightValue === '' ||
        widthValue === '' ||
        isNaN(Number(heightValue)) ||
        isNaN(Number(widthValue)) ||
        maxDimensionsError)
    )
      some.push(channelId);
    if (channelId !== 'apply-all') {
      const framesData = data[channelId];
      framesData &&
        Object.keys(framesData).forEach(index => {
          if (
            framesData[index].name === '' ||
            framesData[index].width === '' ||
            framesData[index].height === '' ||
            framesData[index].top === '' ||
            framesData[index].left === ''
          )
            some.push(channelId);
        });
    }

    Object.keys(frameValidations[channelId]).forEach(frame => {
      Object.keys(frameValidations[channelId][frame]).forEach(attribute => {
        const keyValue = frameValidations[channelId][frame][attribute];
        if (keyValue || keyValue === '') some.push(frame);
      });
    });
  });

  if (some.length > 0) return true;

  // no change to values in screen size check
  let totalDimensionChangeToChannels = 0;
  if (some.length === 0 && channelData) {
    Object.keys(channelData).forEach(channelId => {
      if (
        channelData[channelId].width.toString() === channelData[channelId].ogWidth.toString() &&
        channelData[channelId].height.toString() === channelData[channelId].ogHeight.toString()
      )
        totalDimensionChangeToChannels += 1;
    });
    const numberOfChannels = Object.keys(channelData).length;
    // values havent changed for all channels and there are no updates
    if (numberOfChannels > totalDimensionChangeToChannels) {
      return false; // even one channel's screen size is updated enable save
    }
    if (numberOfChannels === totalDimensionChangeToChannels) { // no updates to screen size
      if (!isDirty) {
        return true;
      }
      return false;
    }
  }
};

export const updateOrderOfFrames = (frames, originalFrameOrder) => {
  const updatedFrames = frames.map((frame, index) => {
    if (frame.frameId !== originalFrameOrder[index]) frame.updatedOrder = true;
    else frame.updatedOrder = false;
    return frame;
  });
  return updatedFrames;
};
