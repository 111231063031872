import React, { Component } from 'react';
import { GlInput, GlButton } from '@adl/foundation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import * as modalActions from '../../actions/modal';
import { checkEmail } from '../../helpers/validators';
import { createUserResponses } from '../../helpers/misc';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callUserCreationApi } from '../../serviceLayer/services';

class UserCreation extends Component {
  state = {
    mailId: '',
    isValidMail: null,
    inProgress: false,
    responseMessage: '',
    responseClass: null
  };

  handleOnChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
      isValidMail: checkEmail(value)
    });
  };

  createUser = () => {
    // api call to  create user
    this.setState({
      inProgress: true
    });
    const { fetchUsers } = this.props;
    const body = { email: this.state.mailId };
    getAccessTokenAndMakeCalls(token =>
      callUserCreationApi(token, {
        ...body
      })
    )
      .then(res => {
        if (res && res.response && res.response.data && res.response.data.message) {
          this.setState({
            responseMessage:
              (res && res.response && res.response.data && res.response.data.message) ||
              'There is some issue while creating user',
            responseClass: 'user-created-failure-message',
            inProgress: false
          });
        } else {
          this.setState({
            responseMessage: 'User created successfully',
            responseClass: 'user-created-success-message',
            inProgress: false
          });
          fetchUsers();
        }
      })
      .catch(error => {
        let message;
        if (error.message === 'Network Error') {
          message = 'There was a network error';
        } else if (error.message) {
          message = error.message;
        } else {
          const status = error.response.status;
          message = createUserResponses[status]
            ? createUserResponses[status]
            : 'There is some issue while creating user';
        }
        this.setState({
          responseMessage: message,
          responseClass: 'user-created-failure-message',
          inProgress: false
        });
      });
  };

  render() {
    const { mailId, isValidMail, inProgress, responseMessage, responseClass } = this.state;
    const { closeModal, isReloadPrevented } = this.props;
    return (
      <Modal
        size="medium"
        open
        enableOutsideClick
        modalCloseHandler={() => {
          if (isReloadPrevented) {
            return;
          }
          closeModal();
        }}
      >
        <h4>Create User</h4>
        <div className="container">
          <div className="row">
            <div className="gl-vspace-bpall-small container-row-input-mail">
              <GlInput
                required
                id="mail-id"
                placeholder="email"
                value={mailId}
                valid={isValidMail}
                name="mailId"
                onChange={this.handleOnChange}
              />
            </div>
          </div>
          <div className="custom-modal__button-container">
            <GlButton
              aria-label="Create User"
              className="custom-modal__button custom-modal__button--primary"
              disabled={!isValidMail}
              onClick={this.createUser}
            >
              Create
            </GlButton>
          </div>
        </div>
        {responseMessage && (
          <p className={responseClass}>
            <strong>{responseMessage}</strong>
          </p>
        )}
        {inProgress && (
          <Modal size="small" open modalCloseHandler={() => {}}>
            <Loader />
          </Modal>
        )}
      </Modal>
    );
  }
}

UserCreation.propTypes = {
  closeModal: PropTypes.func,
  isReloadPrevented: PropTypes.bool,
  preventReload: PropTypes.func,
  fetchUsers: PropTypes.func
};

const mapState = state => ({
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  ...modalActions
};
export default connect(
  mapState,
  mapDispatchToProps
)(UserCreation);
