import React, { Component } from 'react';
import { GlToggle, GlCallout, GlCheckbox, GlTooltip, GlButton } from '@adl/foundation';
import { Link } from 'react-router-dom';
import getUniques from 'lodash-es/uniq';
import { Tooltip } from 'react-tippy';
import DatePicker from 'react-datepicker';
import formatDate from 'date-fns/format';
import { addMonths, differenceInSeconds } from 'date-fns';
import subDays from 'date-fns/sub_days';
import isBefore from 'date-fns/is_before';

import { datesTimes, UITEXT } from '../../helpers/misc';
import { shortenStringWithElipsis, fetchItemsByIds, checkEmptyPlaylist } from '../../helpers/utils';

import Table from '../commons/Table/Table';
import { ReactComponent as DragIcon } from '../../assets/icons/drag-handle.svg';
import { ReactComponent as DragIconGreen } from '../../assets/icons/drag-handle-green.svg';
import { tableSchema } from './schema';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tippy/dist/tippy.css';
import '../SetMultipleDisplaySchedules/SetMultipleDisplayPlaylists.scss';

class SetSingleDisplayPlaylists extends Component {
  state = {
    toggleValue: '0',
    cantbeActivated: [],
    noChannelIds: []
  };

  componentDidMount() {
    const { currentSchedules } = this.props;
    const frameNames = currentSchedules ? currentSchedules.map(frame => frame.name) : [];
    const frameIndex = frameNames.findIndex(frameName => frameName.toLowerCase() === 'main');
    const { cantbeActivated = [] } = this.findEmptyPlaylistItems();
    this.setState({ toggleValue: frameIndex < 0 ? '0' : frameIndex.toString(), cantbeActivated });
  }

  changeToggle(event) {
    const toggleValue = (event && event.target && event.target.value) || '0';
    this.setState({
      toggleValue
    });
  }

  findEmptyPlaylistItems = () => {
    let cantbeActivated = [];
    let error = false;
    const { currentSchedules } = this.props;
    const { toggleValue } = this.state;
    if (currentSchedules && currentSchedules.length > 0) {
      const playlists = currentSchedules[toggleValue].playlists;
      const playlistIds = getUniques(playlists.map(di => di.playlistId));
      if (playlistIds.length) {
        fetchItemsByIds('playlists', playlistIds)
          .then(res => res.list || [])
          .then(list => {
            const empties = [];
            list.forEach(item => {
              const { playlistItems = [], id } = item;

              if (checkEmptyPlaylist(playlistItems)) {
                empties.push(id);
              }
            });
            cantbeActivated = empties;
          })
          .catch(() => {
            error = true;
          });
      }
    }
    return { cantbeActivated, error };
  };

  handleActivateDeactivate(id, type) {
    const { currentSchedules } = this.props;
    const { toggleValue } = this.state;
    const { playlists } = currentSchedules[toggleValue];
    let playlistToBeMoved = {};
    const activePlaylists = [];
    const inactivePlaylists = [];
    playlists.forEach((playlist, index) => {
      if (playlist.id === id) playlistToBeMoved = playlists.splice(index, 1)[0];
    });
    playlists.forEach(playlist => {
      if (playlist.active) activePlaylists.push(playlist);
      else inactivePlaylists.push(playlist);
    });
    playlistToBeMoved.updated = true;
    playlistToBeMoved.active = !playlistToBeMoved.active;
    switch (type) {
      case 'activate':
        // check if not neverEnds, date is before today or has expired tag, then make never ends true and take over false
        if (
          !playlistToBeMoved.neverEnds &&
          (playlistToBeMoved.expired ||
            isBefore(
              new Date(`${playlistToBeMoved.endDate} ${playlistToBeMoved.endTime}`),
              new Date()
            ))
        ) {
          playlistToBeMoved.neverEnds = true;
          playlistToBeMoved.takeOver = false;
        }
        if (playlistToBeMoved.endDate === datesTimes.scalaNeverEndsDate)
          playlistToBeMoved.endDate = formatDate(
            addMonths(playlistToBeMoved.startDate, 3),
            datesTimes.scalaDateFormat
          );
        activePlaylists.push(playlistToBeMoved);
        currentSchedules[toggleValue].updated = true;
        break;
      case 'deactivate':
        inactivePlaylists.unshift(playlistToBeMoved);
        currentSchedules[toggleValue].updated = true;
        break;
      default:
        break;
    }
    currentSchedules[toggleValue].playlists = [...activePlaylists, ...inactivePlaylists];
    this.updateSchedule(currentSchedules);
  }

  handleChange(id, type, value) {
    const { currentSchedules } = this.props;
    const { toggleValue } = this.state;
    const { playlists } = currentSchedules[toggleValue];
    let takeOverUpdated = false;
    playlists.forEach(playlist => {
      if (playlist.id === id) {
        if (!playlist.startDate)
          playlist.startDate =
            playlist.startDate || formatDate(new Date(), datesTimes.scalaDateFormat);
        if (!playlist.endDate)
          playlist.endDate = playlist.endDate || formatDate(new Date(), datesTimes.scalaDateFormat);
        switch (type) {
          case 'activate':
          case 'deactivate':
            this.handleActivateDeactivate(id, type);
            break;
          case 'startDateAndTime':
            playlist.updated = true;
            playlist.startDate = formatDate(value, datesTimes.scalaDateFormat);
            playlist.startTime = formatDate(value, datesTimes.scalaTimeFormat);
            if (
              differenceInSeconds(new Date(`${playlist.endDate} ${playlist.endTime}`), value) < 1
            ) {
              playlist.endDate = formatDate(addMonths(value, 3), datesTimes.scalaDateFormat);
            }
            currentSchedules[toggleValue].updated = true;
            break;
          case 'endDateAndTime':
            playlist.updated = true;
            playlist.endDate = formatDate(value, datesTimes.scalaDateFormat);
            playlist.endTime = formatDate(value, datesTimes.scalaTimeFormat);
            currentSchedules[toggleValue].updated = true;
            break;
          case 'neverEnds':
            playlist.updated = true;
            playlist.neverEnds = !playlist.neverEnds;
            if (!playlist.neverEnds) {
              playlist.endDate = formatDate(
                addMonths(new Date(playlist.startDate), 3),
                datesTimes.scalaDateFormat
              );
            }
            if (playlist.neverEnds) {
              playlist.takeOver = false;
            }
            currentSchedules[toggleValue].updated = true;
            break;
          case 'takeOver':
            playlist.updated = true;
            playlist.takeOver = !playlist.takeOver;
            if (playlist.takeOver) {
              takeOverUpdated = true;
              playlist.neverEnds = false;
              if (playlist.endDate === datesTimes.scalaNeverEndsDate)
                playlist.endDate = formatDate(
                  addMonths(playlist.startDate, 3),
                  datesTimes.scalaDateFormat
                );
            }
            currentSchedules[toggleValue].updated = true;
            break;
          default:
            break;
        }
        const maxAllowedValidFromDate = new Date(subDays(new Date(), 7).setHours(0, 0, 0, 0));
        // date cannot be before 7 days, we update the date
        if (playlist.updated && type !== 'deactivate') {
          let validFrom = new Date(`${playlist.startDate} ${playlist.startTime}`);
          if (isBefore(validFrom, maxAllowedValidFromDate)) {
            validFrom = maxAllowedValidFromDate;
            playlist.startDate = formatDate(validFrom, datesTimes.scalaDateFormat);
            playlist.startTime = formatDate(validFrom, datesTimes.scalaTimeFormat);
            currentSchedules[toggleValue].updated = true;
          }
        }
      }
    });
    let someUpdateHappened = false;
    if (type === 'takeOver' && takeOverUpdated) {
      playlists.forEach(playlist => {
        if (playlist.id !== id && playlist.active && playlist.takeOver) {
          playlist.takeOver = false;
          playlist.updated = true;
          someUpdateHappened = true;
        }
      });
    }
    if (someUpdateHappened) currentSchedules[toggleValue].updated = true;
    this.updateSchedule(currentSchedules);
  }

  formatTableData(frameId, frameName, playlists) {
    const { cantbeActivated, channelId, updatePlaylistToBeRemoved, openModal } = this.props;
    const activeTableData = [];
    const inactiveTableData = [];
    // using unique id AS WE CAN HAVE SAME PLAYLIST TWICE
    playlists &&
      playlists.forEach(playlist => {
        const validFrom = playlist.startTime
          ? new Date(`${playlist.startDate} ${playlist.startTime}`)
          : new Date(`${playlist.startDate}`);
        const validTo = playlist.endTime
          ? new Date(`${playlist.endDate} ${playlist.endTime}`)
          : new Date(`${playlist.endDate}`);
        const startLabel = `${formatDate(validFrom, datesTimes.scalaDateWithTimePretty)}`;
        const endLabel = `${formatDate(validTo, datesTimes.scalaDateWithTimePretty)}`;
        const data = { id: playlist.id, playlistId: playlist.playlistId };
        // duplicate playlists case hightlight it doesnt work as expected, need a new logic for this
        if (playlist.active) {
          data.action = (
            <span style={{ display: 'flex' }}>
              <span style={{ marginTop: '3%', marginRight: '-10%' }}>
                {playlist.updated ? <DragIconGreen /> : <DragIcon />}
              </span>
              <GlButton
                className="m-auto deactivate-button"
                tertiary
                onClick={() => this.handleChange(playlist.id, 'deactivate')}
              >
                {UITEXT.deactivate}
              </GlButton>
            </span>
          );
          data.playlistName = (
            <Tooltip
              position="bottom"
              followCursor
              html={<div className="tooltipWidth">{UITEXT.playlistNameTooltip}</div>}
            >
              <Link to={`/playlists/${playlist.playlistId}?newTab=true`} target="_blank">
                <div className="core-green__main">
                  {shortenStringWithElipsis(playlist.playlistName, 40)}
                </div>
              </Link>
            </Tooltip>
          );
          data.startDate = (
            <div className="schedule-playlist-info__column gl-tooltip">
              {playlist.startDate && (
                <GlTooltip label={startLabel} size="large">
                  <DatePicker
                    inline
                    todayButton="Today"
                    selected={validFrom}
                    showTimeSelect
                    timeIntervals={15}
                    injectTimes={[new Date().setHours(23, 59, 59, 999)]}
                    timeFormat="HH:mm"
                    timeCaption="Start time"
                    onChange={date => this.handleChange(playlist.id, 'startDateAndTime', date)}
                  />
                </GlTooltip>
              )}
            </div>
          );
          if (playlist.neverEnds) data.endDate = null;
          else {
            data.endDate = (
              <span className="schedule-playlist-info__column gl-tooltip">
                {playlist.endDate && (
                  <GlTooltip label={endLabel} size="large">
                    <DatePicker
                      inline
                      todayButton="Today"
                      selected={validTo}
                      showTimeSelect
                      timeIntervals={15}
                      injectTimes={[new Date().setHours(23, 59, 59, 999)]}
                      timeFormat="HH:mm"
                      timeCaption="End time"
                      onChange={date => this.handleChange(playlist.id, 'endDateAndTime', date)}
                    />
                  </GlTooltip>
                )}
              </span>
            );
          }
          data.playForever = (
            <span className="schedule-playlist-info__column checkbox-styling-no-center">
              <GlCheckbox
                className="schedule-playlist-info__column-checkbox"
                isChecked={playlist.neverEnds}
                onChange={() => this.handleChange(playlist.id, 'neverEnds')}
              />
              <span className="m-auto">{UITEXT.neverEnds}</span>
            </span>
          );
          data.takeOver = (
            <span className="schedule-playlist-info__column checkbox-styling-no-center">
              <GlCheckbox
                className="schedule-playlist-info__column-checkbox"
                isChecked={playlist.takeOver}
                onChange={() => this.handleChange(playlist.id, 'takeOver')}
              />
              <span className="m-auto">{UITEXT.takeOver}</span>
            </span>
          );
          activeTableData.push(data);
        } else {
          const isHealthy = cantbeActivated && !cantbeActivated.includes(playlist.playlistId);
          const activate =
            isHealthy || cantbeActivated === undefined ? (
              <div
                className="playlist-info__column--center"
                style={{ margin: 'auto', width: 'max-content' }}
              >
                <GlButton tertiary onClick={() => this.handleChange(playlist.id, 'activate')}>
                  {UITEXT.activate}
                </GlButton>
                <div style={{ padding: '0 10px' }}>/</div>
                <GlButton
                  tertiary
                  onClick={() => {
                    updatePlaylistToBeRemoved({
                      channelId,
                      frameId,
                      playlistId: playlist.playlistId,
                      frameName,
                      entryIds: playlist.entryIds.join(',')
                    });
                    openModal('removeSinglePlaylist');
                  }}
                >
                  {UITEXT.remove}
                </GlButton>
              </div>
            ) : (
              <Tooltip position="bottom" followCursor title={UITEXT.cannotActivateTooltip}>
                {UITEXT.cannotActivateLink}
              </Tooltip>
            );
          data.action = <>{activate}</>;
          data.playlistName = (
            <Tooltip
              position="bottom"
              followCursor
              html={<div className="tooltipWidth">{UITEXT.playlistNameTooltip}</div>}
            >
              <Link to={`/playlists/${playlist.playlistId}?newTab=true`} target="_blank">
                <div className="core-green__main">
                  {shortenStringWithElipsis(playlist.playlistName, 40)}
                </div>
              </Link>
            </Tooltip>
          );
          data.startDate = <div>{playlist.startDate && startLabel}</div>;
          if (playlist.neverEnds) data.endDate = null;
          else {
            data.endDate = (
              <span className="schedule-playlist-info__column gl-tooltip">
                {playlist.endDate && endLabel}
                {playlist.expired && (
                  <div>
                    <span
                      className="expiration-badge expiration-badge--expired"
                      style={{ marginTop: '0px' }}
                    >
                      {UITEXT.expired}
                    </span>
                  </div>
                )}
              </span>
            );
          }
          data.playForever = (
            <span className="schedule-playlist-info__column checkbox-styling-no-center">
              <GlCheckbox
                className="schedule-playlist-info__column-checkbox"
                isChecked={playlist.neverEnds}
                inputProps={{ disabled: true }}
                onChange={() => {}}
              />
              <span className="m-auto">{UITEXT.neverEnds}</span>
            </span>
          );
          data.takeOver = (
            <span className="schedule-playlist-info__column checkbox-styling-no-center">
              <GlCheckbox
                className="schedule-playlist-info__column-checkbox"
                isChecked={playlist.takeOver}
                inputProps={{ disabled: true }}
                onChange={() => {}}
              />
              <span className="m-auto">{UITEXT.takeOver}</span>
            </span>
          );
          inactiveTableData.push(data);
        }
      });
    return { activeTableData, inactiveTableData };
  }

  updateSchedule(schedules) {
    this.props.updateChangesToSchedule(schedules);
  }

  renderPlaylistsTable(frameId, frameName, currentSchedules) {
    const { toggleValue } = this.state;
    const { originalData } = this.props;
    const { playlists } = currentSchedules[toggleValue];
    const rowOrderHandler = ({ oldIndex, newIndex }) => {
      if (newIndex >= 0 && newIndex < playlists.length && oldIndex !== newIndex) {
        const copiedList = [...playlists];
        const element = copiedList.splice(oldIndex, 1)[0];
        copiedList.splice(newIndex, 0, element);
        const updatedPlaylists = copiedList.map((el, index) => {
          if (originalData[toggleValue].playlists[index].id !== el.id) el.updated = true;
          // else el.updated = false; // incorrect indicator in scenario where prev chnages and then reordered to default
          return { ...el };
        });
        currentSchedules[toggleValue].playlists = updatedPlaylists;
        currentSchedules[toggleValue].updated = true;
        this.updateSchedule(currentSchedules);
      }
    };
    const tableData = this.formatTableData(frameId, frameName, playlists);
    return (
      <div className="schedule-details-table">
        <h5 className="player-sections-header">{UITEXT.activePlaylists}</h5>
        {tableData.activeTableData.length === 0 ? (
          <GlCallout>{UITEXT.noActivePlaylistsMessage}</GlCallout>
        ) : (
          <Table
            tableId="scheduleDetails"
            tableData={tableData.activeTableData}
            tableSchema={tableSchema}
            isFilterable
            hasRowOrdering={playlists.length > 1}
            rowOrderHandler={rowOrderHandler}
          />
        )}
        <h5 className="player-sections-header player-sections-header-inactive">
          {UITEXT.inactivePlaylists}
        </h5>
        {tableData.inactiveTableData.length === 0 ? (
          <GlCallout>{UITEXT.noArchivedPlaylistsMessage}</GlCallout>
        ) : (
          <Table
            tableId="scheduleDetails2"
            tableData={tableData.inactiveTableData}
            tableSchema={tableSchema}
            isFilterable
            className="playlist-info playlist-info--archived"
          />
        )}
      </div>
    );
  }

  render() {
    const { toggleValue } = this.state;
    const { currentSchedules, playerId, getSchedules, responseError } = this.props;
    currentSchedules &&
      currentSchedules.length > 0 &&
      currentSchedules.sort((a, b) => a.zOrder - b.zOrder);
    const frameNames =
      currentSchedules && currentSchedules.length > 0 && currentSchedules.map(frame => frame.name);
    const frameId =
      currentSchedules && currentSchedules.length > 0 && currentSchedules[toggleValue].frameId;
    const frameName =
      currentSchedules && currentSchedules.length > 0 && currentSchedules[toggleValue].name;
    if (responseError) {
      return (
        <div className="">
          <div className="list--error">
            <GlCallout>
              <h5 className="callout-error__text">{responseError}</h5>
              <GlButton onClick={() => getSchedules(playerId)} aria-label="Refresh">
                {UITEXT.refresh}
              </GlButton>
            </GlCallout>
          </div>
        </div>
      );
    }
    if (currentSchedules) {
      return (
        <>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div className="select-frame">{UITEXT.selectFrame}</div>
            <GlToggle
              items={frameNames}
              onChange={e => this.changeToggle(e)}
              value={toggleValue || '0'}
            />
          </div>
          {currentSchedules && currentSchedules.length > 0 && (
            <div className="player-container">
              {this.renderPlaylistsTable(frameId, frameName, currentSchedules)}
            </div>
          )}
        </>
      );
    }
    return (
      <div className="">
        <div className="list--error">
          <GlCallout>
            <h5 className="callout-error__text">{UITEXT.noSchedules}</h5>
            <GlButton onClick={() => getSchedules(playerId)} aria-label="Refresh">
              {UITEXT.refresh}
            </GlButton>
          </GlCallout>
        </div>
      </div>
    );
  }
}

export default SetSingleDisplayPlaylists;
