
let CANCEL_TOKEN;

export const INIT_DATA = 'INIT_DATA';
export const SET_COUNT = 'SET_COUNT';
export const FAILED_REQUEST = 'FAILED_REQUEST';
export const GO_TO_PAGE = 'GO_TO_PAGE';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const APPLY_SORTING = 'APPLY_SORTING';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CANCEL_PENDING_REQUESTS = 'CANCEL_PENDING_REQUESTS';
export const UPDATE_DISPLAY_KEY = 'UPDATE_DISPLAY_KEY';

export const initData = list => ({
  type: INIT_DATA,
  list,
  count: list.length
});

export const failedRequest = payload => ({
  type: FAILED_REQUEST
});

export const goToPage = data => ({
  type: GO_TO_PAGE,
  page: data.page
});

export const clearAllFiltersForPlayers = () => ({
  type: CLEAR_FILTERS
});

export const updateFiltersOnPlayers = optns => ({
  type: UPDATE_FILTER,
  optns
});


export const cancelPendingPlayersRequest = () => {
  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel('Operation canceled due to change requests.');
  }
  return {
    type: CANCEL_PENDING_REQUESTS
  };
};
