import React, { Component } from 'react';
import { GlButton, GlCallout } from '@adl/foundation';
import formatDate from 'date-fns/format';
import Loader from '../commons/Loader/Loader';
import Modal from '../commons/Modal/Modal';
import CarouselSlider from '../commons/CarouselSlider/CarouselSlider';
import './Modals.scss';
import { UITEXT, datesTimes, requestStages } from '../../helpers/misc';
import constants from '../../helpers/constants';
import { closeModal as closeModalAction } from '../../actions/modal';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';

import { axiosOrg } from '../../helpers/utils';

class ViewScreenshotsModal extends Component {
  constructor(props) {
    super(props);
    this.eventSource = null;
    this.state = {
      status: '',
      screenshotData: [],
      message: null,
      buttonStatus: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.startFetchScreenshot();
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.endFetchScreenshot();
  }
  startFetchScreenshot() {
    const { playerData } = this.props;

    let screenshotTokenUrl = constants.GET_SCREENSHOT_TOKEN_URL;

    axiosOrg
      .get(`${screenshotTokenUrl}?playerId=${playerData.id}`)
      .then(response => {
        // handle success
        let token = response.data.token;
        let processScreenshotURL = constants.PROCESS_SCREENSHOT_URL + '?ci_token=' + token;
        this.eventSource = new EventSource(processScreenshotURL); //token also carries the screenshot request information.

        this.eventSource.addEventListener('message', event => {
          var data = JSON.parse(event.data);
          this.onReceivingDataFromEvent(data);
        });

        this.eventSource.addEventListener('error', err => {
          console.log('eventSource error');
          this.setState({
            status: '',
            screenshotData: [],
            message: null
          });
        });
      })
      .catch(error => {
        this.setState({
          status: '',
          screenshotData: [],
          message: null
        });
      });
  }
  endFetchScreenshot() {
    this.eventSource.close();
  }
  delayButton() {
    if (this._isMounted) {
      // no setstate if component is unmounted
      setTimeout(
        function() {
          this.setState({ buttonStatus: false });
        }.bind(this),
        10000
      );
    }
  }
  onReceivingDataFromEvent = data => {
    let status = data.message;
    let { screenshotData, buttonStatus } = this.state;
    let message = null;
    switch (status) {
      case requestStages.REQUEST_ACKNOWLEDGED:
      case requestStages.REQUEST_IN_PROCESS:
        buttonStatus = true;
        break;
      case requestStages.SSE_TIMEOUT:
      case requestStages.REQUEST_FAILED: // need to set message for request failed here
        let description =
          status === requestStages.REQUEST_FAILED
            ? UITEXT.requestFailed
            : UITEXT.somethingWentWrong;
        screenshotData.unshift({
          error: (
            <div className="screenshot-error-screen">
              <GlCallout type="urgent">
                <h5 className="callout-error__text">{data.description || description}</h5>
              </GlCallout>
            </div>
          ),
          timestamp: data.epoch_timestamp
        });
        this.endFetchScreenshot();
        this.delayButton();
        break;
      case requestStages.REQUEST_COMPLETE:
        message = UITEXT.requestCompletedSuccessfully;
        screenshotData.unshift({
          imageName: data.screenshot_imageName,
          timestamp: data.epoch_timestamp
        });
        this.endFetchScreenshot();
        this.delayButton();
        break;
      default:
        break;
    }
    this.setState({
      status,
      screenshotData,
      message,
      buttonStatus
    });
  };

  getScreenshotButtonStatus() {
    const { status } = this.state;
    switch (status) {
      case requestStages.REQUEST_ACKNOWLEDGED:
      case requestStages.REQUEST_IN_PROCESS:
        return true;
      case requestStages.SSE_TIMEOUT:
      case requestStages.REQUEST_FAILED:
      case requestStages.REQUEST_COMPLETE:
        return false;
      default:
        return true;
    }
  }
  renderContent() {
    const { status } = this.state;

    switch (status) {
      case requestStages.REQUEST_ACKNOWLEDGED:
        return (
          <div>
            <Loader />
            <h5>{UITEXT.requestAcknowledged}</h5>
          </div>
        );
      case requestStages.HEARTBEAT:
      case requestStages.REQUEST_IN_PROCESS:
        return (
          <div>
            <Loader />
            <h5>{UITEXT.requestInProcess}</h5>
          </div>
        );
      case requestStages.SSE_TIMEOUT:
      case requestStages.REQUEST_FAILED:
      case requestStages.REQUEST_COMPLETE:
        const { message } = this.state;
        const items = this.state.screenshotData.map((item, index) => {
          let url = item && item.imageName && constants.SCREENSHOT_URL + '/' + item.imageName;
          return (
            <div key={index}>
              <div className="screenshot-image-container">
                {item.error || <img className="screenshot-image" src={url} alt="Screenshot" />}
              </div>
              <div className="display-flex margin-top-15">
                {item.timestamp && (
                  <div className="width-100">
                    {formatDate(item.timestamp * 1000, datesTimes.dateTimeHoursMinutesSeconds)}
                  </div>
                )}
                {item.imageName && (
                  <div className="display-flex margin-left-auto">
                    <div
                      className="link-style"
                      onClick={() => FileSaver.saveAs(url, 'Screenshot_' + item.imageName)}
                    >
                      {UITEXT.downloadImage}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        });
        return (
          <div className="width-100">
            {items.length > 1 ? (
              <CarouselSlider
                showCount
                items={items}
                actionOnClick={() => this.setState({ message: null })}
              />
            ) : (
              items
            )}
            {message && (
              <div
                className={
                  status === requestStages.REQUEST_FAILED
                    ? 'validation-error'
                    : 'validation-success'
                }
              >
                {message}
              </div>
            )}
          </div>
        );
      default:
        return <h5>{UITEXT.errorConnectionToServer}</h5>;
    }
  }
  render() {
    const { playerData } = this.props;
    return (
      <Modal
        className="custom-modal--fullscreen preview-modal"
        size="medium"
        open
        enableOutsideClick
        modalCloseHandler={() => this.props.closeModal()}
      >
        <h4>{UITEXT.playerScreenshots + ' ' + playerData.name}</h4>
        <div className="screenshot-content">{this.renderContent()}</div>
        <GlButton
          aria-label="Get Latest Screenshots"
          className="custom-modal__button custom-modal__button--primary"
          disabled={this.state.buttonStatus}
          onClick={() => this.startFetchScreenshot()}
        >
          {UITEXT.getSceenshot}
        </GlButton>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  const { modals, auth } = state;
  const playerData = {
    id: modals && modals.activeModal && modals.activeModal.id,
    name: modals && modals.activeModal && modals.activeModal.name
  };
  return { playerData, name: auth.name };
};
const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewScreenshotsModal);
