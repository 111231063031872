import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import { GlCallout, GlButton } from '@adl/foundation';
import formatDate from 'date-fns/format';
import isBefore from 'date-fns/is_before';

import Loader from '../commons/Loader/Loader';
import PlayerStatus from '../SelectedSchedule/PlayerStatus';
import AddEditFramesModal from '../Modals/AddEditFramesModal';
import SetSingleDisplayPlaylists from './SetSingleDisplayPlaylists';

import { pageTitle } from '../../helpers/pageTitles';
import * as scheduleUtils from '../SetMultipleDisplaySchedules/scheduleUtils';
import { parseMetadata } from '../../helpers/utils';
import {
  UITEXT,
  datesTimes,
  ActivityLogScheduleTypes,
  ActivityLogOperationTypes
} from '../../helpers/misc';

import { updateGeneratePlanText, clickedPlayer } from '../../actions/table';
import Modal from '../commons/Modal/Modal';
import { closeModal, openModal } from '../../actions/modal';
import { checkErrorsWithPlayer, setPlayerData } from '../../actions/schedule';
import { setUserPrivilege } from '../../actions/auth';

class SetSinglesDisplaySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorText: 'There was an error loading schedule',
      error: false,
      isSaving: false,
      responseError: false,
      completed: false,
      isRemoving: false,
      removed: false,
      removeError: false,
      playlistToBeRemoved: '',
      changesToSchedule: false,
      currentSchedules: [],
      originalData: [],
      scheduleResponse: {}
    };
  }

  componentDidMount() {
    document.title = pageTitle.scheduleSelected('');
    const {
      match: {
        params: { playerId, playerName }
      },
      setPlayerData,
      clickedPlayer
    } = this.props;
    this.setState({ playerId });
    this.getSchedules(playerId);
    clickedPlayer('dashboard:1', { id: playerId, name: playerName });
    setPlayerData(playerId, playerName);
    window.addEventListener('beforeunload', this.handlePageRefresh);
  }

  componentWillUnmount = () => {
    this.props.clickedPlayer('dashboard:1');
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    e.preventDefault();
    const { changesToSchedule } = this.state;
    if (changesToSchedule) {
      // e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  getSchedules = async playerId => {
    const { setUserPrivilege } = this.props;

    // resetting data
    this.setState({
      isLoading: true,
      errorText: 'There was an error loading schedule',
      error: false,
      isSaving: false,
      changesToSchedule: false,
      currentSchedules: [],
      originalData: [],
      responseError: false,
      completed: false,
      scheduleResponse: {}
    });
    setUserPrivilege({ isAllowed: false });
    try {
      const playerInfo = await scheduleUtils.getPlayerInfo(playerId);

      const channelId = get(playerInfo, 'playerDisplays.0.channel.id', null);
      const channelName = get(playerInfo, 'playerDisplays.0.channel.name', null);
      if (!channelId) {
        this.setState({
          playerInfo,
          isLoading: false,
          responseError: UITEXT.noChannelAssignedMessage
        });
      } else if (playerInfo.name !== channelName) {
        this.setState({
          playerInfo,
          isLoading: false,
          responseError: UITEXT.incorrectChannelAssignedMessage
        });
      } else {
        const channelData = await scheduleUtils.getChannelInfo(channelId);
        const dimensions = {
          width: channelData && channelData.frameset && channelData.frameset.width,
          height: channelData && channelData.frameset && channelData.frameset.height
        };
        const currentSchedules = await scheduleUtils.getChannelSchedule(channelId);
        // sort active playlists first to handlw playlists created from scala
        const rearrangedSchedules = currentSchedules.map(frameData => {
          const activePlaylists = [];
          const inactivePlaylists = [];

          frameData.playlists.forEach(playlist => {
            if (playlist.active) activePlaylists.push(playlist);
            else inactivePlaylists.push(playlist);
          });
          return {
            ...frameData,
            playlists: [...activePlaylists, ...inactivePlaylists]
          };
        });
        if (
          currentSchedules &&
          currentSchedules.response &&
          currentSchedules.response.status &&
          currentSchedules.response.status !== 200
        ) {
          this.setState({
            isLoading: false,
            responseError:
              (currentSchedules.response && currentSchedules.response.message) ||
              'There was an error loading schedule',
            playerInfo,
            channelInfo: {
              id: channelId,
              name: channelName,
              width: dimensions.width,
              height: dimensions.height
            },
            currentSchedules: []
          });
          setUserPrivilege({ isAllowed: false });
        } else {
          this.setState({
            isLoading: false,
            error: false,
            playerInfo,
            channelInfo: {
              id: channelId,
              name: channelName,
              width: dimensions.width,
              height: dimensions.height
            },
            currentSchedules: rearrangedSchedules,
            originalData: cloneDeep(rearrangedSchedules)
          });
          setUserPrivilege({ isAllowed: true });
        }
      }
      document.title = pageTitle.scheduleSelected(playerInfo.name);
    } catch (e) {
      document.title = 'Error loading schedule';
      this.setState({ isLoading: false, error: true, errorText: e.message });
    }
  };

  updatePlaylistToBeRemoved(playlistToBeRemoved) {
    this.setState({ playlistToBeRemoved });
  }

  async removePlaylist() {
    const { playlistToBeRemoved } = this.state;
    const removePlaylist = await scheduleUtils.removePlaylist(playlistToBeRemoved);
    if (removePlaylist && removePlaylist !== 'SUCCESS') {
      this.setState({
        isRemoving: false,
        removeError:
          (removePlaylist.response && removePlaylist.response.message) || 'Something went wrong',
        removed: true
      });
    } else this.setState({ isRemoving: false, removeError: false, removed: true });
  }

  activeModal(modalId) {
    const { clicked, closeModal } = this.props;
    const {
      isSaving,
      completed,
      scheduleResponse,
      playerId,
      removeError,
      removed,
      isRemoving
    } = this.state;
    let unsucessful = false;
    Object.keys(scheduleResponse).forEach(frameId => {
      if (scheduleResponse[frameId] !== 'SUCCESS') {
        unsucessful = true;
      }
    });
    const continueButton = (
      <div style={{ marginLeft: 'auto' }}>
        <GlButton
          className="schedule-change-save"
          onClick={() => {
            closeModal();
            this.getSchedules(playerId);
          }}
        >
          {UITEXT.continue}
        </GlButton>
      </div>
    );
    const message = () => (
      <div>
        {unsucessful ? (
          <div>
            <h4>{UITEXT.schedulingCompleted}</h4>
            <p className="validation-error">{UITEXT.singleScheduleErrorMessage}</p>
          </div>
        ) : (
          <div>
            <h4>{UITEXT.schedulingCompleted}</h4>
            <p className="validation-success">{UITEXT.singleScheduleSuccessMessage}</p>
          </div>
        )}
        {continueButton}
      </div>
    );
    switch (modalId) {
      case 'addEditFrames':
        return (
          <AddEditFramesModal
            clickedPlayer={clicked}
            showOnlyClickedPlayer
            getSchedules={this.getSchedules}
            playerId={playerId}
          />
        );
      case 'savedSchedule':
        return (
          <Modal
            size="medium"
            className=""
            open
            enableOutsideClick
            modalCloseHandler={() => {
              closeModal();
              this.getSchedules(playerId);
            }}
          >
            {isSaving && <Loader />}
            {!isSaving && completed && message()}
          </Modal>
        );
      case 'removeSinglePlaylist':
        return (
          <Modal
            open
            size="medium"
            enableOutsideClick
            modalCloseHandler={() => {
              closeModal();
              this.setState({ isRemoving: false, removed: false, removeError: false });
              this.getSchedules(playerId);
            }}
          >
            <h4>{UITEXT.removePlaylist}</h4>
            {!removed && (
              <>
                <p>{UITEXT.removePlaylistConfirmation}</p>
                <p style={{ marginTop: 40 }}>
                  <span style={{ marginRight: 30 }}>
                    <GlButton
                      loading={isRemoving}
                      onClick={() => {
                        this.setState({ isRemoving: true });
                        this.removePlaylist();
                      }}
                    >
                      {UITEXT.remove}
                    </GlButton>
                  </span>
                  <GlButton
                    tertiary
                    onClick={() => {
                      closeModal();
                      this.setState({ isRemoving: false, removed: false, removeError: false });
                    }}
                  >
                    {UITEXT.cancel}
                  </GlButton>
                </p>
              </>
            )}

            {removed && !removeError && (
              <p className="validation-success">{UITEXT.removePlaylistSuccess}</p>
            )}
            {removed && removeError && (
              <p className="validation-error">{UITEXT.removePlaylistError}</p>
            )}
          </Modal>
        );
      default:
        return null;
    }
  }

  updateChangesToSchedule(schedules) {
    this.setState({ changesToSchedule: true, currentSchedules: schedules });
  }

  async saveSchedules() {
    const { currentSchedules, originalData, playerInfo, channelInfo } = this.state;
    const { loggedInUserInfo } = this.props;
    const toBeScheduled = [];
    const framesActivityLog = [];
    currentSchedules.forEach((frameData, j) => {
      if (frameData.updated) {
        let updatedPlaylists = [];
        const activePlaylists = [];
        const inactivePlaylists = [];
        const formattedPlaylists = [];
        if (frameData.playlists) {
          const originalPlaylistsData = originalData[j].playlists;
          frameData.playlists.forEach((playlist, index) => {
            playlist.activate = playlist.active;
            playlist.deactivate = !playlist.active;
            playlist.order = index;
            if (playlist.neverEnds) {
              playlist.endDate = datesTimes.scalaNeverEndsDate;
              playlist.endTime = datesTimes.scalaNeverEndsTime;
            } else if (
              !isBefore(playlist.endDate, datesTimes.scalaNeverEndsDate) &&
              !playlist.takeOver
            ) {
              playlist.neverEnds = true;
            }
            const copyOfPlaylist = cloneDeep(playlist);
            delete copyOfPlaylist.updated;
            delete copyOfPlaylist.active;
            if (playlist.active) activePlaylists.push(copyOfPlaylist);
            else inactivePlaylists.push(copyOfPlaylist);
            // for activity log
            if (playlist.updated) {
              let operationType = '';
              let originalPlaylist = {};
              originalPlaylistsData.forEach(ogPlaylist => {
                if (ogPlaylist.id === playlist.id) originalPlaylist = ogPlaylist;
              });
              if (originalPlaylist.active === playlist.active)
                operationType = ActivityLogOperationTypes.edit;
              // only if active playlist is updated then edit op type
              else
                operationType = playlist.active
                  ? ActivityLogOperationTypes.activate
                  : ActivityLogOperationTypes.deactivate;
              formattedPlaylists.push({
                operationType,
                playlistId: playlist.playlistId,
                playlistName: playlist.playlistName,
                scheduleType: playlist.takeOver
                  ? ActivityLogScheduleTypes.takeover
                  : ActivityLogScheduleTypes.normal,
                validTo: playlist.neverEnds
                  ? formatDate(
                      datesTimes.scalaNeverEndsDateTime,
                      datesTimes.scalaDateWithTimePretty
                    )
                  : formatDate(
                      `${playlist.endDate} ${playlist.endTime}`,
                      datesTimes.scalaDateWithTimePretty
                    ),
                validFrom: formatDate(
                  `${playlist.startDate} ${playlist.startTime}`,
                  datesTimes.scalaDateWithTimePretty
                )
              });
            }
            updatedPlaylists = [...activePlaylists, ...inactivePlaylists];
          });
        } else updatedPlaylists.push([]);
        if (updatedPlaylists.length > 0) {
          toBeScheduled.push({
            frameId: frameData.frameId,
            frameName: frameData.name,
            channelName: channelInfo.name,
            channelId: channelInfo.id,
            playerId: playerInfo.id,
            playerName: playerInfo.name,
            playlists: updatedPlaylists
          });
          framesActivityLog.push({
            playerId: playerInfo.id,
            playerName: playerInfo.name,
            channelName: channelInfo.name,
            frameId: frameData.frameId,
            frameName: frameData.name,
            playlists: formattedPlaylists
          });
        }
      }
    });
    const successfulFramesActivityLog = [];
    const schedule = await scheduleUtils.saveChannelSchedule(toBeScheduled);
    if ((schedule && schedule !== undefined) || (schedule.status && schedule.status !== 404)) {
      Object.keys(schedule).forEach(frameId => {
        if (schedule[frameId] === 'SUCCESS') {
          successfulFramesActivityLog.push(
            framesActivityLog.filter(frame => frame.frameId.toString() === frameId.toString())[0]
          );
        }
      });
      this.setState({ scheduleResponse: schedule, completed: true, isSaving: false });
      const activityLog = {};
      activityLog.timestamp = new Date();
      if (loggedInUserInfo) {
        activityLog.user = {
          firstName: loggedInUserInfo.firstName || loggedInUserInfo.name,
          lastName: loggedInUserInfo.lastName || loggedInUserInfo.name,
          email: loggedInUserInfo.email,
          userId: loggedInUserInfo.name
        };
      }
      activityLog.frames = framesActivityLog;
      if (framesActivityLog.length > 0)
        await scheduleUtils.sendLog(activityLog).catch(e => console.log(e));
    } else {
      this.setState({ completed: true, isSaving: false });
    }
  }

  render() {
    const {
      playerId,
      playerInfo,
      error,
      errorText,
      isLoading,
      currentSchedules,
      originalData,
      isSaving,
      changesToSchedule,
      channelInfo,
      responseError
    } = this.state;
    if (isLoading) {
      return (
        <div className="generic-container">
          <Loader />
        </div>
      );
    }
    if (error) {
      return (
        <div className="generic-container">
          <div className="list--error">
            <GlCallout>
              <h5 className="callout-error__text">{errorText}</h5>
              <GlButton onClick={() => this.getSchedules(playerId)} aria-label="Refresh">
                Refresh
              </GlButton>
            </GlCallout>
          </div>
        </div>
      );
    }
    if (playerId && playerInfo) {
      const { updateGeneratePlanText, activeModalId, openModal } = this.props;
      const {
        name: playerName,
        description: playerDescription,
        metadataValue: metadata
      } = playerInfo;
      const parsedMetadata = parseMetadata(metadata);
      const {
        market = '',
        country = '',
        city = '',
        environment = '',
        storename = '',
        resolution = ''
      } = parsedMetadata;

      const saveButton = (
        <div style={{ marginLeft: 'auto' }}>
          <GlButton
            loading={isSaving}
            disabled={!(isSaving || changesToSchedule)}
            className="schedule-change-save"
            onClick={() => {
              this.setState({ isSaving: true });
              this.saveSchedules();
              this.props.openModal('savedSchedule');
            }}
          >
            {isSaving ? 'saving' : 'Save'}
          </GlButton>
        </div>
      );
      let locationMetadata = [];
      let noMetadata = false;
      locationMetadata.push(environment, market, country, city, storename);
      locationMetadata = locationMetadata.filter(item => item);
      if (!market && !country && !city && !environment && !storename)
        noMetadata = 'No metadata present for this player.';
      return (
        <>
          <Prompt when={changesToSchedule} message={UITEXT.scheduleAway} />
          <div className="generic-container">
            <PlayerStatus
              playerId={String(playerId)}
              playerName={playerName.replace(/_/g, ' ')}
              playerDescription={playerDescription}
              playerTitle={noMetadata || locationMetadata.join(' / ')}
              resolution={resolution}
              saveButton={currentSchedules && saveButton}
              updateGeneratePlanText={updateGeneratePlanText}
              width={channelInfo && channelInfo.width}
              height={channelInfo && channelInfo.height}
              responseError={responseError}
            />
            <SetSingleDisplayPlaylists
              currentSchedules={currentSchedules}
              originalData={originalData}
              updateSchedule={() => this.updateSchedule()}
              updateChangesToSchedule={schedules => this.updateChangesToSchedule(schedules)}
              getSchedules={this.getSchedules}
              playerId={playerId}
              responseError={responseError}
              openModal={openModal}
              updatePlaylistToBeRemoved={id => this.updatePlaylistToBeRemoved(id)}
              channelId={channelInfo && channelInfo.id}
            />
          </div>
          {this.activeModal(activeModalId)}
        </>
      );
    }
    return (
      <div className="generic-container">
        <div className="list--error">
          <GlCallout>
            <h5 className="callout-error__text">{errorText}</h5>
            <GlButton onClick={() => this.getSchedules(playerId)} aria-label="Refresh">
              Refresh
            </GlButton>
          </GlCallout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUserInfo: state.auth,
  activeModalId: state.modals.activeModal.modalId,
  clicked: state.table['dashboard:1'] ? state.table['dashboard:1'].clicked : []
});

const mapDispatchToProps = {
  updateGeneratePlanText,
  closeModal,
  openModal,
  clickedPlayer,
  setPlayerData,
  checkErrorsWithPlayer,
  setUserPrivilege
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetSinglesDisplaySchedules)
);
