import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton } from '@adl/foundation';
import { connect } from 'react-redux';
import produce from 'immer';
import get from 'lodash-es/get';
import { Tooltip } from 'react-tippy';

import { fetchItemsByIds, findPlaylistInPlayers } from '../../helpers/utils';
import { removePlaylistFromMetadata } from '../SetMultipleDisplaySchedules/scheduleUtils';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import Table from '../commons/Table/Table';
import { closeModal as closeModalAction } from '../../actions/modal';
import './Modals.scss';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callDeleteFromAMSApi,
  callDeletePlaylistApi,
  callGetFilteredPlaylistsApi,
  callGetPlaylistApi
} from '../../serviceLayer/services';

class DeleteModal extends Component {
  state = {
    itemsDeleted: [],
    someItemsInUse: false,
    itemsInUse: {},
    deleteCompleted: false,
    isFetchingUsage: true,
    isDeleting: false,
    playlistName: '',
    mediaUsedInPlaylists: [],
    notAuthToDeleteItems: []
  };

  componentDidMount() {
    const { items, itemType } = this.props;

    if (!items.length || !itemType) {
      return;
    }

    if (itemType === 'media') {
      this.getUsage(items, 'media');
    }

    if (itemType === 'playlists') {
      this.getUsage(items, 'playlists');
    }

    if (itemType === 'playlists' && items.length === 1) {
      this.getPlaylistName(items[0].id);
    }
    this.getAllItemsUsage();
  }

  async getAllItemsUsage() {
    const usageTypes = [
      'messagesCount',
      'playlistsCount',
      'templatesCount',
      'channelsCount',
      'asSubPlaylistsCount'
    ];

    const { items, itemType } = this.props;

    const itemsByIds = items.map(item => item.id);

    fetchItemsByIds(itemType, itemsByIds).then(resp => {
      const list = get(resp, 'data.list', []);
      const usage = {};
      list.forEach(item => {
        usage[item.id] = {};
        usageTypes.forEach(us => {
          if (item[us] > 0) {
            usage[item.id][us] = item[us];
          }
        });
      });

      this.setState({
        isFetchingUsage: false,
        itemsInUse: usage
      });
    });
  }

  deleteAllItems = () => {
    const { items, itemType } = this.props;
    this.setState({ isDeleting: true });

    if (items.length) {
      Promise.all(
        items.map(({ id, name, originalName, cloudinary }) => {
          if (itemType === 'media') {
            // while deleting media type is needed
            const mediaType = (cloudinary && cloudinary.resource_type) || 'message';
            return this.deleteSingleItem(itemType, id, originalName, mediaType);
          }
          return this.deleteSingleItem(itemType, id, name);
        })
      ).then(() => {
        this.setState({ isDeleting: false, deleteCompleted: true });
      });
    }
  };

  formatTableItems = (items = [], itemsDeleted, notAuthToDeleteItems) => {
    const { playlistName } = this.state;
    const result = [];
    const hasNoName = items.length === 1 && !items[0].name;

    items.forEach((item, rowIndex) => {
      result[rowIndex] = {
        id: item.id,
        name: (
          <Tooltip position="bottom" title={hasNoName ? playlistName : item.name}>
            <div className="ellipsis">{hasNoName ? playlistName : item.name}</div>
          </Tooltip>
        ),
        status:
          // eslint-disable-next-line no-nested-ternary
          itemsDeleted.indexOf(item.id) > -1 ? (
            <span className="validation-success">Deleted</span>
          ) : notAuthToDeleteItems.indexOf(item.id) > -1 ? (
            <span className="validation-error">Not Authorized</span>
          ) : (
            <span className="validation-error">Error</span>
          )
      };
    });

    return result;
  };

  getPlaylistName = playlistId => {
    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId }))
      .then(res => {
        this.setState({ playlistName: res.name });
      })
      .catch(() => {
        console.log('err');
      });
  };

  getUsage = (itemsIds, type) => {
    const usage = {};

    if (type === 'playlists') {
      itemsIds.forEach(async item =>
        this.setState({
          mediaUsedInPlaylists: await findPlaylistInPlayers(item.id)
        })
      );
    } else {
      itemsIds.forEach(item => {
        getAccessTokenAndMakeCalls(token =>
          callGetFilteredPlaylistsApi(token, { id: item.id })
        ).then(res => {
          const list = get(res, 'list', []);
          usage[item.id] = list;
          this.setState({ mediaUsedInPlaylists: usage });
        });
      });
    }
  };

  async deleteSingleItem(itemType, itemId, itemName, mediaType) {
    if (itemType === 'playlists') {
      const players = await findPlaylistInPlayers(itemId);
      return getAccessTokenAndMakeCalls(token => callDeletePlaylistApi(token, { id: itemId }))
        .then(async () => {
          this.setState(next =>
            produce(next, draft => {
              draft.itemsDeleted.push(itemId);
            })
          );
          await removePlaylistFromMetadata(players.map(item => item.id), itemId);
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            (error.response.status === 401 || error.response.status === 402)
          ) {
            this.setState(next =>
              produce(next, draft => {
                draft.notAuthToDeleteItems.push(itemId);
              })
            );
          }
        });
    }

    return getAccessTokenAndMakeCalls(token =>
      callDeleteFromAMSApi(token, {
        data: {
          public_id: itemName,
          media_id: itemId,
          type: mediaType
        }
      })
    )
      .then(() => {
        this.setState(next =>
          produce(next, draft => {
            draft.itemsDeleted.push(itemId);
          })
        );
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          (error.response.status === 401 || error.response.status === 402)
        ) {
          this.setState(next =>
            produce(next, draft => {
              draft.notAuthToDeleteItems.push(itemId);
            })
          );
        }
      });
  }

  render() {
    const { itemType, items, closeModal, refreshHandler } = this.props;
    const {
      isFetchingUsage,
      someItemsInUse,
      isDeleting,
      deleteCompleted,
      itemsDeleted,
      playlistName,
      itemsInUse,
      mediaUsedInPlaylists,
      notAuthToDeleteItems
    } = this.state;

    const custom = {
      media: {
        header: 'Confirm Deletion',
        message: (
          <>
            <strong> Delete these media asset(s)?</strong>
            <br />
            You cannot undo this action!
          </>
        )
      },
      playlists: {
        header: 'Confirm Deletion',
        message: (
          <>
            <strong>Are you sure you want to delete these playlist(s)?</strong>
            <br />
            You cannot undo this action!
          </>
        )
      }
    };
    const { header, message } = custom[itemType];

    const tableSchema = [
      {
        id: 'name',
        title: itemType === 'media' ? 'Media' : 'Playlist'
      },
      {
        id: 'status',
        title: 'Status'
      }
    ];

    const newItems = [...items];

    const usageMediaTooltipProps = item => {
      if (mediaUsedInPlaylists[item.id]) {
        return (
          <ul>
            {mediaUsedInPlaylists[item.id].map(mapItem => (
              <li className="discList" key={mapItem.id}>
                {mapItem.name}
              </li>
            ))}
          </ul>
        );
      }

      if (itemType === 'playlists') {
        return (
          <ul>
            {mediaUsedInPlaylists.map(mapItem => (
              <li className="discList" key={mapItem.id}>
                {mapItem.name}
              </li>
            ))}
          </ul>
        );
      }
      return '';
    };

    if (isDeleting) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    if (deleteCompleted) {
      return (
        <Modal
          open
          size="large"
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
            refreshHandler();
          }}
        >
          <h4>Delete {itemType === 'media' ? 'Media' : 'Playlist'}</h4>
          <p>Please check the status below:</p>
          <Table
            tableData={this.formatTableItems(items, itemsDeleted, notAuthToDeleteItems)}
            isFilterable
            tableSchema={tableSchema}
            tableId="results"
          />
        </Modal>
      );
    }

    return (
      <>
        <Modal open enableOutsideClick size="large" modalCloseHandler={() => closeModal()}>
          {isFetchingUsage ? (
            <Loader />
          ) : (
            <>
              <h4>{header}</h4>
              <div>
                {someItemsInUse && (
                  <div>
                    <p className="validation-error">
                      <br /> Some items are already in use!
                    </p>
                  </div>
                )}
                {message}
                <table className="custom-modal__list">
                  <tbody>
                    {newItems.map(item => (
                      <tr className="custom-modal__item" key={item.id}>
                        <td>
                          <div className="custom-modal__name">
                            {playlistName || item.name || item.id}{' '}
                            {itemsInUse &&
                              itemsInUse[item.id] &&
                              Object.keys(itemsInUse[item.id]).length > 0 && (
                                <span className="validation-error">
                                  <Tooltip
                                    followCursor
                                    size="big"
                                    position="right"
                                    html={
                                      <div className="tooltipWidth">
                                        {usageMediaTooltipProps(item)}
                                      </div>
                                    }
                                  >
                                    {itemType === 'media' &&
                                      mediaUsedInPlaylists[item.id] &&
                                      mediaUsedInPlaylists[item.id].length > 0 && (
                                        <span>
                                          Used in {mediaUsedInPlaylists[item.id].length}
                                          {mediaUsedInPlaylists[item.id].length === 1
                                            ? ' playlist'
                                            : ' playlists'}
                                        </span>
                                      )}
                                    {itemType === 'playlists' &&
                                      mediaUsedInPlaylists &&
                                      mediaUsedInPlaylists.length > 0 && (
                                        <span>
                                          Used in {mediaUsedInPlaylists.length}
                                          {mediaUsedInPlaylists.length === 1
                                            ? ' player'
                                            : ' players'}
                                        </span>
                                      )}
                                  </Tooltip>
                                </span>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div>
                <GlButton
                  aria-label="Delete"
                  className="custom-modal__button custom-modal__button--primary"
                  onClick={() => {
                    this.deleteAllItems();
                  }}
                >
                  Delete
                </GlButton>
                <GlButton
                  secondary
                  aria-label="Cancel"
                  className="custom-modal__button"
                  onClick={() => closeModal()}
                >
                  Cancel
                </GlButton>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

DeleteModal.propTypes = {
  itemType: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  refreshHandler: PropTypes.func
};

DeleteModal.defaultProps = {
  refreshHandler() {}
};

const mapStateToProps = state => ({
  itemType: state.modals.activeModal.itemType,
  items: state.modals.activeModal.items
});
const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteModal);
