import React, { Component } from 'react';
import { tableSchema, formTemplates } from './schema';
import Table from '../commons/Table/Table';
import Loader from '../commons/Loader/Loader';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetTemplatesApi } from '../../serviceLayer/services';

class TemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateList: [],
      loading: false,
      pageInfo: {
        total: 1,
        current: 1,
        limit: 10
      },
      isError: false
    };
    this.createMessage = this.createMessage.bind(this);
  }

  componentDidMount() {
    const { selectItems, tableId, selectedTemplateId } = this.props;
    selectItems(tableId, selectedTemplateId);
    const params = {
      page: 1
    };
    this.fetchTemplated(params);
  }

  fetchTemplated = async queryParams => {
    const page = queryParams.page ? queryParams.page : 1;
    const limit = this.state.pageInfo.limit;

    const params = {
      page,
      size: limit
    };
    this.setState({
      loading: true
    });
    getAccessTokenAndMakeCalls(token => callGetTemplatesApi(token, params))
      .then(res => {
        this.setState(next => ({
          templateList: formTemplates(res.content, this.createMessage),
          loading: false,
          isError: false,
          pageInfo: {
            ...next.pageInfo,
            current: page,
            total: res.totalElements
          }
        }));
      })
      .catch(error => {
        this.setState({
          isError: true,
          loading: false,
          templateList: []
        });
      });
  };

  createMessage(selectedTemplate) {
    this.props.selectItems('templatesList', [selectedTemplate]); // only one will be selected - mocking table on select
    this.props.createMessage();
  }

  render() {
    const { tableId } = this.props;
    const { templateList, loading, pageInfo, isError } = this.state;
    return (
      <>
        <h4>Template List</h4>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table
              tableSchema={tableSchema}
              tableData={templateList}
              tableId={tableId}
              id={tableId}
              isFilterable
              pageInfo={pageInfo}
              paginationHandler={this.fetchTemplated}
              isError={isError}
              hasQueryParamsHandling
              filterHandler={this.fetchTemplated}
            />
          </>
        )}
      </>
    );
  }
}
export default TemplateList;
