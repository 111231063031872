const constants = {
  envVars: {
    Development: {
      SCALA_API_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      DOWNLOAD_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/download-proxy/api/download/zip',
      UPLOAD_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/media/upload',
      USER_PREFERENCES_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/user/preferences',
      DELETE_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/cloudinary/delete',
      LOCATION_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/location',
      USER_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/user',
      MEDIA_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/media',
      ROLE_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/role',
      LOGIN_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/adfs/openid-login',
      LOGOUT_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/user/sign-out',
      LOGGED_INFO:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/user/check-signed-in',

      THUMBNAIL_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      ACTIVITY_LOG_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling',
      SCHEDULING_ACTIVITY_LOG_SEARCH_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/search',
      SCHEDULING_ACTIVITY_LOG_FETCH_FILTERS:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/filterValues',
      FRAME_MANAGEMENT_ACTIVITY_LOG_SEARCH_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/search',
      FRAME_MANAGEMENT_ACTIVITY_LOG_FETCH_FILTERS:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/filterValues',
      TEMPLATE_FIELD_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/template',
      TEMPLATE_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/templates',
      TAXONOMY_TAGS:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/category/tags',
      GET_SCREENSHOT_TOKEN_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/user/requestScreenshotToken',
      PROCESS_SCREENSHOT_URL: 'https://adi.link/screenshot-service-dev/sse/connect',
      SCREENSHOT_URL: 'https://adi.link/screenshot-service-dev/screenshot',
      PLAYERS_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/players/all',
      GET_MEDIA_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/media/all',
      FRAMES_UPDATE_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/update',
      FRAMES_DETAILS_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      SCHEDULE_PLAYLISTS:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedule',
      ADD_SUBPLAYLISTS_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/',
      GET_PLAYER_SCHEDULES:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedules',
      UPDATE_PLAYER_SCHEDULES:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames/update/schedule',
      REMOVE_PLAYLIST: 'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      GET_PLAYLIST_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/all',
      UPDATE_PLAYLIST_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/update',
      CREATE_PLAYLIST_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/create',
      DELETE_PLAYLIST_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/delete',
      DUPLICATE_PLAYLIST_URL:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/playlist',
      GET_TOKEN:
        'https://dev-adicontentmanager.digital-signage.adidas.com/disipapi/azure/new-token',
      REDIRECT_URL: 'https://dev-adicontentmanager.digital-signage.adidas.com',
      CLIENT_ID: 'cb0492b7-a29f-475a-8d3e-4c105b8e702d',
      framesetTempIds: {
        '1080x1920': 34327,
        '1280x1024': 34440,
        '1920x1080': 34324,
        '2160x3840': 34443,
        '3840x2160': 34446
      },
      dataDownloaderId: 82,
      cisId: 74,
      playerMetadata: {
        ScheduledPlaylistIds: 209255,
        ScheduledPlaylistNames: 209254
      }
    },
    Staging: {
      SCALA_API_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      DOWNLOAD_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/download-proxy/api/download/zip',
      UPLOAD_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/media/upload',
      DELETE_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/cloudinary/delete',
      LOGIN_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/adfs/openid-login',
      LOCATION_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/location',
      USER_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/user',
      MEDIA_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/media',
      ROLE_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/role',
      LOGOUT_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/user/sign-out',
      LOGGED_INFO:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/user/check-signed-in',
      USER_PREFERENCES_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/user/preferences',
      THUMBNAIL_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      ACTIVITY_LOG_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling',
      SCHEDULING_ACTIVITY_LOG_SEARCH_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/search',
      SCHEDULING_ACTIVITY_LOG_FETCH_FILTERS:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/filterValues',
      FRAME_MANAGEMENT_ACTIVITY_LOG_SEARCH_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/search',
      FRAME_MANAGEMENT_ACTIVITY_LOG_FETCH_FILTERS:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/filterValues',
      TEMPLATE_FIELD_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/template',
      TEMPLATE_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/templates',
      TAXONOMY_TAGS:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/category/tags',
      GET_SCREENSHOT_TOKEN_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/user/requestScreenshotToken',
      PROCESS_SCREENSHOT_URL: 'https://adi.link/screenshot-service-stage/sse/connect',
      SCREENSHOT_URL: 'https://adi.link/screenshot-service-stage/screenshot',
      PLAYERS_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/players/all',
      GET_MEDIA_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/media/all',
      FRAMES_UPDATE_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/update',
      FRAMES_DETAILS_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      SCHEDULE_PLAYLISTS:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedule',
      ADD_SUBPLAYLISTS_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/',
      GET_PLAYER_SCHEDULES:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedules',
      UPDATE_PLAYER_SCHEDULES:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames/update/schedule',
      REMOVE_PLAYLIST: 'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      GET_PLAYLIST_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/all',
      UPDATE_PLAYLIST_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/update',
      CREATE_PLAYLIST_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/create',
      DELETE_PLAYLIST_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist/delete',
      DUPLICATE_PLAYLIST_URL:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/playlist',
      GET_TOKEN:
        'https://stage-adicontentmanager.digital-signage.adidas.com/disipapi/azure/new-token',
      REDIRECT_URL: 'https://stage-adicontentmanager.digital-signage.adidas.com',
      CLIENT_ID: 'a1e19e01-f59c-4721-b766-fa5730add0a4',
      framesetTempIds: {
        '1080x1920': 20007154,
        '1280x1024': 20007157,
        '1920x1080': 20007163,
        '2160x3840': 20007142,
        '3840x2160': 20007149
      },
      dataDownloaderId: 10000080,
      cisId: 10000081,
      playerMetadata: {
        ScheduledPlaylistIds: 10191139,
        ScheduledPlaylistNames: 10191140
      }
    },
    Production: {
      SCALA_API_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      DOWNLOAD_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/download-proxy/api/download/zip',
      UPLOAD_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/media/upload',
      DELETE_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/cloudinary/delete',
      LOGIN_URL: 'https://adicontentmanager.digital-signage.adidas.com/adfs/openid-login',
      LOCATION_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/location',
      USER_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/user',
      MEDIA_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/media',
      ROLE_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/role',
      LOGOUT_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/user/sign-out',
      LOGGED_INFO:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/user/check-signed-in',
      USER_PREFERENCES_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/user/preferences',
      THUMBNAIL_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/proxy/ContentManager',
      ACTIVITY_LOG_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling',
      SCHEDULING_ACTIVITY_LOG_SEARCH_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/search',
      SCHEDULING_ACTIVITY_LOG_FETCH_FILTERS:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/activity/scheduling/filterValues',
      FRAME_MANAGEMENT_ACTIVITY_LOG_SEARCH_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/search',
      FRAME_MANAGEMENT_ACTIVITY_LOG_FETCH_FILTERS:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/activity/filterValues',
      TEMPLATE_FIELD_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/template',
      TEMPLATE_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/templates',
      TAXONOMY_TAGS: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/category/tags',
      GET_SCREENSHOT_TOKEN_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/user/requestScreenshotToken',
      PROCESS_SCREENSHOT_URL: 'https://adi.link/screenshot-service/sse/connect',
      SCREENSHOT_URL: 'https://adi.link/screenshot-service/screenshot',
      PLAYERS_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/players/all',
      GET_MEDIA_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/media/all',
      FRAMES_UPDATE_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/update',
      FRAMES_DETAILS_URL: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      SCHEDULE_PLAYLISTS:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedule',
      ADD_SUBPLAYLISTS_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist/',
      GET_PLAYER_SCHEDULES:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/schedules',
      UPDATE_PLAYER_SCHEDULES:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames/update/schedule',
      REMOVE_PLAYLIST: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/frames',
      GET_PLAYLIST_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist/all',
      UPDATE_PLAYLIST_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist/update',
      CREATE_PLAYLIST_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist/create',
      DELETE_PLAYLIST_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist/delete',
      DUPLICATE_PLAYLIST_URL:
        'https://adicontentmanager.digital-signage.adidas.com/disipapi/playlist',
      GET_TOKEN: 'https://adicontentmanager.digital-signage.adidas.com/disipapi/azure/new-token',
      REDIRECT_URL: 'https://adicontentmanager.digital-signage.adidas.com',
      CLIENT_ID: '4e5bac23-4135-4d79-854d-effd016c4a43',
      framesetTempIds: {
        '1080x1920': 20035821,
        '1280x1024': 20035825,
        '1920x1080': 20035828,
        '2160x3840': 20035815,
        '3840x2160': 20035818
      },
      dataDownloaderId: 10000041,
      cisId: 10000044,
      playerMetadata: {
        ScheduledPlaylistIds: 10182277,
        ScheduledPlaylistNames: 10182276
      }
    }
  }
};

export default constants.envVars[process.env.REACT_APP_ENVIRONMENT];
