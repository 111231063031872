/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GlIcon } from '@adl/foundation';
import { withRouter, Route } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import { ReactComponent as UploadIcon } from '../../assets/icons/cloud-upload-alt.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/cloud-download-alt.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as AddToPlaylistIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as CloneIcon } from '../../assets/icons/clone.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import { openModal as openModalAction } from '../../actions/modal';
import { uploadFiles as uploadFilesAction } from '../../actions/upload';
import { updateStep } from '../../actions/scheduleV2';
import { selectItems as selectItemsAction } from '../../actions/table';
import { downloadCloudinaryItems, generatePlan } from '../../helpers/utils';
import { UITEXT } from '../../helpers/misc';
import './ActionBar.scss';

const ActionBar = props => {
  const {
    openModal,
    selectedMediaItems,
    selectedPlaylistItems,
    selectedItemsInPlaylist,
    selectedUsers,
    resetUploadFiles,
    isReloadPrevented,
    pNotFound,
    selectedPlayers,
    step,
    roles,
    currentPlayerId,
    currentPlayerName,
    updateStep,
    userPrivilege,
    selectItems,
    generatePlanText,
    selectedUsersAdminPanel
  } = props;
  const cantDownloadMsg = [UITEXT.cantDownloadItems];
  const filesToDownload = selectedMediaItems.filter(item => {
    const test = item.cloudinary && item.cloudinary.secure_url;
    if (!test) {
      cantDownloadMsg.push(item.name);
    }

    return test;
  });

  const isAdmin = roles.includes('ROLE_ADMIN');
  const isPowerUser = roles.includes('ROLE_POWER_USER');

  const handleClick = callback => {
    if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
      return;
    }
    callback();
  };

  const downloadTooltipProps = {};
  const cannotEditPlaylists = selectedPlaylistItems.length > 10;

  if (selectedMediaItems.length > 10) {
    downloadTooltipProps.title = UITEXT.cantDownloadMoreThan10Assets;
  } else if (cantDownloadMsg.length > 1) {
    downloadTooltipProps.html = (
      <ul>
        {cantDownloadMsg.map(el => (
          <li key={el}>{el}</li>
        ))}
      </ul>
    );
  } else {
    downloadTooltipProps.title = '';
  }

  const editButton = (
    <button
      type="button"
      disabled={cannotEditPlaylists}
      className={(cannotEditPlaylists && 'header-actions__button--disabled') || null}
      onClick={() => openModal('editPlaylist', { items: selectedPlaylistItems })}
    >
      <li className="header-actions__item">
        <EditIcon className="header-actions__icon header-actions__icon--smaller" />
        <span className="header-actions__item-text" data-testid="playlists-edit">
          {`${UITEXT.editPlaylist}${selectedPlaylistItems.length > 1 ? 's' : ''}${
            selectedPlaylistItems.length > 1 ? ` (${selectedPlaylistItems.length})` : ''
          }`}
        </span>
      </li>
    </button>
  );

  const showEditRoles =
    (selectedUsers && selectedUsers.length > 0) ||
    (selectedUsersAdminPanel && selectedUsersAdminPanel.length > 0);

  return (
    <div className="header-action-bar">
      <ul className="header-actions">
        {(isAdmin || isPowerUser) && step === 1 && selectedPlayers.length > 0 && (
          <Route
            path="/"
            exact
            render={() => (
              <>
                <button
                  type="button"
                  onClick={() => {
                    openModal('addEditFrames');
                  }}
                >
                  <li className="header-actions__item">
                    <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span className="header-actions__item-text" data-testid="add-edit-frames">
                      {UITEXT.frameConfiguration}
                    </span>
                  </li>
                </button>
              </>
            )}
          />
        )}
        <Route
          path="/playlists"
          exact
          render={() => (
            <>
              <button type="button" onClick={() => openModal('newPlaylist')}>
                <li className="header-actions__item">
                  <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                  <span className="header-actions__item-text" data-testid="playlists-new">
                    {UITEXT.newPlaylist}
                  </span>
                </li>
              </button>
              {selectedPlaylistItems.length === 1 && (
                <>
                  <button
                    type="button"
                    onClick={() => openModal('duplicatePlaylist', { items: selectedPlaylistItems })}
                  >
                    <li className="header-actions__item">
                      <CloneIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span className="header-actions__item-text" data-testid="playlists-duplicate">
                        {UITEXT.duplicate}
                      </span>
                    </li>
                  </button>
                </>
              )}
              {selectedPlaylistItems.length > 0 && (
                <>
                  <Tooltip
                    position="bottom"
                    followCursor
                    disabled={!cannotEditPlaylists}
                    title={UITEXT.cantEditMoreThan10Playlists}
                  >
                    {editButton}
                  </Tooltip>

                  <button
                    type="button"
                    onClick={() =>
                      openModal('deleteItems', {
                        itemType: 'playlists',
                        items: selectedPlaylistItems
                      })
                    }
                  >
                    <li className="header-actions__item">
                      <DeleteIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span className="header-actions__item-text" data-testid="playlists-delete">
                        {`${UITEXT.deletePlaylist}${selectedPlaylistItems.length > 1 ? 's' : ''}${
                          selectedPlaylistItems.length > 1
                            ? ` (${selectedPlaylistItems.length})`
                            : ''
                        }`}
                      </span>
                    </li>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      openModal('selectMediaToPlaylists', { items: selectedPlaylistItems })
                    }
                  >
                    <li className="header-actions__item">
                      <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span className="header-actions__item-text" data-testid="playlists-add-media">
                        {`${UITEXT.addMediaToSelectedPlaylist}${
                          selectedPlaylistItems.length > 1 ? 's' : ''
                        }${selectedPlaylistItems.length > 0 &&
                          ` (${selectedPlaylistItems.length})`}`}
                      </span>
                    </li>
                  </button>
                </>
              )}
            </>
          )}
        />
        {!pNotFound && (
          <Route
            path="/playlists/:pid"
            exact
            render={({
              match: {
                params: { pid }
              },
              history: { push }
            }) => (
              <>
                {window.location.search.indexOf('newTab=true') < 0 && (
                  <button
                    type="button"
                    onClick={() => {
                      push('/playlists');
                    }}
                  >
                    <li className="header-actions__item">
                      <GlIcon name="arrow-left" className="header-actions__icon arrow-left" />
                      <span
                        className="header-actions__item-text"
                        data-testid="playlist-selected-back-button"
                      >
                        {UITEXT.backToList}
                      </span>
                    </li>
                  </button>
                )}
                <button
                  type="button"
                  onClick={() =>
                    handleClick(() =>
                      openModal('selectMediaToPlaylists', {
                        items: [{ id: pid }]
                      })
                    )
                  }
                >
                  <li className="header-actions__item">
                    <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="playlist-selected-add-media"
                    >
                      {UITEXT.addNewItems}
                    </span>
                  </li>
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleClick(() => openModal('duplicatePlaylist', { items: [{ id: pid }] }))
                  }
                >
                  <li className="header-actions__item">
                    <CloneIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="playlist-selected-duplicate"
                    >
                      {UITEXT.duplicate}
                    </span>
                  </li>
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleClick(() => openModal('editPlaylist', { items: [{ id: pid }] }))
                  }
                >
                  <li className="header-actions__item">
                    <EditIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="playlist-selected-edit"
                    >
                      {UITEXT.editPlaylist}
                    </span>
                  </li>
                </button>
                <button
                  type="button"
                  onClick={() =>
                    openModal('deleteItems', {
                      itemType: 'playlists',
                      items: [
                        {
                          id: pid
                        }
                      ]
                    })
                  }
                >
                  <li className="header-actions__item">
                    <DeleteIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="playlist-selected-delete"
                    >
                      {UITEXT.deletePlaylist}
                    </span>
                  </li>
                </button>
                {selectedItemsInPlaylist.length > 0 && (
                  <>
                    <li className="header-actions__separator" />
                    <button
                      type="button"
                      onClick={() =>
                        handleClick(() =>
                          openModal('removeMedia', { items: selectedItemsInPlaylist })
                        )
                      }
                    >
                      <li className="header-actions__item">
                        <DeleteIcon className="header-actions__icon header-actions__icon--smaller" />
                        <span
                          className="header-actions__item-text"
                          data-testid="playlist-selected-remove-media"
                        >
                          {`${UITEXT.removeSelectedAsset}${
                            selectedItemsInPlaylist.length > 1 ? 's' : ''
                          }${
                            selectedItemsInPlaylist.length > 1
                              ? ` (${selectedItemsInPlaylist.length})`
                              : ''
                          }`}
                        </span>
                      </li>
                    </button>
                  </>
                )}
              </>
            )}
          />
        )}

        <Route
          path="/media"
          exact
          render={({ history: { push } }) => (
            <>
              <li className="header-actions__item">
                <button type="button" onClick={() => openModal('createTemplate')}>
                  <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                  <span className="header-actions__item-text" data-testid="template-configure">
                    {UITEXT.configureTemplate}
                  </span>
                </button>
              </li>
              <li className="header-actions__item">
                <button
                  type="button"
                  onClick={() => {
                    push('/media/upload');
                    resetUploadFiles([]);
                  }}
                >
                  <UploadIcon className="header-actions__icon header-actions__icon--smaller" />
                  <span className="header-actions__item-text" data-testid="media-upload">
                    {UITEXT.upload}
                  </span>
                </button>
              </li>

              {selectedMediaItems.length > 0 && (
                <>
                  <li className="header-actions__item">
                    <Tooltip
                      key={selectedMediaItems.map(el => el.name).join(',')}
                      position="bottom"
                      followCursor
                      {...downloadTooltipProps}
                    >
                      <button
                        type="button"
                        disabled={selectedMediaItems.length > 10}
                        className={
                          ((selectedMediaItems.length > 10 || filesToDownload.length <= 0) &&
                            'header-actions__button--disabled') ||
                          null
                        }
                        onClick={() => {
                          if (filesToDownload.length > 0) {
                            openModal('download');
                            downloadCloudinaryItems(selectedMediaItems);
                          }
                        }}
                      >
                        <DownloadIcon className="header-actions__icon header-actions__icon--smaller" />
                        <span className="header-actions__item-text" data-testid="media-download">
                          {`${UITEXT.downloadAsset}${filesToDownload.length > 1 ? 's' : ''}${
                            filesToDownload.length > 1 ? ` (${filesToDownload.length})` : ''
                          }`}
                        </span>
                      </button>
                    </Tooltip>
                  </li>
                  <li className="header-actions__item">
                    <Tooltip
                      position="bottom"
                      followCursor
                      title={
                        (selectedMediaItems.length > 10 && UITEXT.cantEditMoreThan10Assets) || ''
                      }
                    >
                      <button
                        type="button"
                        disabled={selectedMediaItems.length > 10}
                        className={
                          (selectedMediaItems.length > 10 && 'header-actions__button--disabled') ||
                          null
                        }
                        onClick={() => openModal('editMedia', { items: selectedMediaItems })}
                      >
                        <EditIcon className="header-actions__icon header-actions__icon--smaller" />
                        <span className="header-actions__item-text" data-testid="media-edit">
                          {`${UITEXT.editAsset}${selectedMediaItems.length > 1 ? 's' : ''}${
                            selectedMediaItems.length > 1 ? ` (${selectedMediaItems.length})` : ''
                          }`}
                        </span>
                      </button>
                    </Tooltip>
                  </li>
                  <li className="header-actions__item">
                    <button
                      type="button"
                      onClick={() =>
                        openModal('deleteItems', {
                          itemType: 'media',
                          items: selectedMediaItems
                        })
                      }
                    >
                      <DeleteIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span className="header-actions__item-text" data-testid="media-delete">
                        {`${UITEXT.deleteAsset}${selectedMediaItems.length > 1 ? 's' : ''}${
                          selectedMediaItems.length > 1 ? ` (${selectedMediaItems.length})` : ''
                        }`}
                      </span>
                    </button>
                  </li>
                  {selectedMediaItems.length > 0 && (
                    <li className="header-actions__item">
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            openModal('createPlaylistFromMedia', { items: selectedMediaItems })
                          }
                        >
                          <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                          <span
                            className="header-actions__item-text"
                            data-testid="media-create-new-playlist"
                          >
                            {UITEXT.createNewPlaylist}
                          </span>
                        </button>
                        <ul className="header-submenu">
                          <li className="header-submenu__item">
                            <button
                              type="button"
                              onClick={() =>
                                openModal('addMediaToPlaylist', { items: selectedMediaItems })
                              }
                            >
                              <AddToPlaylistIcon className="header-submenu__icon header-submenu__icon--smaller" />
                              <span
                                className="header-submenu__item-text"
                                data-testid="media-add-selected"
                              >
                                {`${UITEXT.addSelected} (${selectedMediaItems.length}) ${UITEXT.toExistingPlaylist}`}
                              </span>
                            </button>
                          </li>
                        </ul>
                      </>
                    </li>
                  )}
                </>
              )}
            </>
          )}
        />
        <Route
          path="/media/upload"
          exact
          render={({ history: { push } }) => (
            <button type="button" onClick={() => push('/media')}>
              <li className="header-actions__item">
                <GlIcon name="arrow-left" className="header-actions__icon arrow-left" />
                <span className="header-actions__item-text" data-testid="media-back-button">
                  {UITEXT.backToList}
                </span>
              </li>
            </button>
          )}
        />
        <Route
          path="/admin-panel"
          exact
          render={({ history: { push } }) => (
            <>
              {showEditRoles && (
                <>
                  <button type="button" onClick={() => openModal('userWorkgroup')}>
                    <li className="header-actions__item">
                      <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span
                        className="header-actions__item-text"
                        data-testid="administration-assign-edit"
                      >
                        {UITEXT.assignEditWorkgroups}
                      </span>
                    </li>
                  </button>

                  <button type="button" onClick={() => openModal('userRole')}>
                    <li className="header-actions__item">
                      <EditIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span
                        className="header-actions__item-text"
                        data-testid="administration-edit-roles"
                      >
                        {UITEXT.editRoles}
                      </span>
                    </li>
                  </button>
                </>
              )}
              <button type="button" onClick={() => openModal('userCreation')}>
                <li className="header-actions__item">
                  <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                  <span className="header-actions__item">{UITEXT.createUser}</span>
                </li>
              </button>
              {showEditRoles && (
                <button type="button" onClick={() => openModal('userDeletion')}>
                  <li className="header-actions__item">
                    <DeleteIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="administration-edit-player-metadata"
                    >
                      {UITEXT.deleteUser}
                    </span>
                  </li>
                </button>
              )}
              {!showEditRoles && (
                <button type="button" onClick={() => push('/admin-panel/metadata')}>
                  <li className="header-actions__item">
                    <EditIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span
                      className="header-actions__item-text"
                      data-testid="administration-edit-player-metadata"
                    >
                      {UITEXT.editPlayerMetadata}
                    </span>
                  </li>
                </button>
              )}
            </>
          )}
        />
        <Route
          path="/"
          exact
          render={() => (
            <>
              {step === 1 && (
                <>
                  {selectedPlayers.length > 0 && (
                    <button
                      type="button"
                      onClick={() => generatePlan(selectedPlayers.map(spl => spl.id))}
                    >
                      <div className="header-actions__item">
                        <RefreshIcon className="header-actions__icon header-actions__icon--smaller" />
                        <span
                          className="header-actions__item-text"
                          data-testid="players-scheduling-generate-plan-button"
                        >
                          {`${UITEXT.generatePlan}${selectedPlayers.length > 1 ? 's' : ''}`}
                        </span>
                      </div>
                    </button>
                  )}
                </>
              )}
            </>
          )}
        />
        <Route
          path="/schedules/:pId/:pName"
          exact
          render={({ history: { push } }) => (
            <>
              {userPrivilege.isAllowed && (isAdmin || isPowerUser) && (
                <li className="header-actions__item">
                  <button
                    type="button"
                    onClick={() => {
                      openModal('addEditFrames');
                    }}
                  >
                    <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                    <span className="header-actions__item-text" data-testid="add-edit-frames">
                      {UITEXT.frameConfiguration}
                    </span>
                  </button>
                </li>
              )}
              {userPrivilege.isAllowed && (
                <>
                  <li className="header-actions__item">
                    <Tooltip
                      position="bottom"
                      followCursor
                      disabled={generatePlanText.length === 0}
                      html={<div>{generatePlanText}</div>}
                    >
                      <button type="button" onClick={() => generatePlan([currentPlayerId])}>
                        <RefreshIcon className="header-actions__icon header-actions__icon--smaller" />
                        <span
                          className="header-actions__item-text"
                          data-testid="players-scheduling-generate-plan-button"
                        >
                          {UITEXT.generatePlan}
                        </span>
                      </button>
                    </Tooltip>
                  </li>
                  <li className="header-actions__item">
                    <button
                      type="button"
                      onClick={() => {
                        push('/');
                        selectItems('dashboard:1', [
                          {
                            id: Number(currentPlayerId),
                            name: currentPlayerName
                          }
                        ]);
                        updateStep(2);
                      }}
                    >
                      <AddToPlaylistIcon className="header-actions__icon header-actions__icon--smaller" />
                      <span className="header-actions__item-text">{UITEXT.addPlaylist}</span>
                    </button>
                  </li>
                </>
              )}
            </>
          )}
        />
      </ul>
    </div>
  );
};

ActionBar.propTypes = {
  openModal: PropTypes.func,
  resetUploadFiles: PropTypes.func,
  selectedMediaItems: PropTypes.arrayOf(PropTypes.object),
  selectedPlaylistItems: PropTypes.arrayOf(PropTypes.object),
  selectedItemsInPlaylist: PropTypes.arrayOf(PropTypes.object),
  selectedWorkgroups: PropTypes.arrayOf(PropTypes.string),
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  isReloadPrevented: PropTypes.bool,
  pNotFound: PropTypes.bool,
  selectedPlayers: PropTypes.arrayOf(PropTypes.object),
  step: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.string),
  currentPlayerId: PropTypes.number,
  currentPlayerName: PropTypes.string,
  updateStep: PropTypes.func,
  selectItems: PropTypes.func,
  generatePlanText: PropTypes.string,
  selectedUsersAdminPanel: PropTypes.arrayOf(Number),
  userPrivilege: PropTypes.objectOf(PropTypes.bool)
};

const mapStateToProps = state => ({
  selectedMediaItems: state.table.media.selected,
  selectedPlaylistItems: state.table.playlists.selected,
  selectedItemsInPlaylist: state.table.playlistselected.selected,
  selectedWorkgroups: state.table.workgroups.selected,
  selectedUsers: state.table.users.selected,
  isReloadPrevented: state.modals.isDirty,
  pNotFound: state.modals.notFound,
  selectedPlayers: state.table['dashboard:1'] ? state.table['dashboard:1'].selected : [],
  step: state.scheduleV2.step,
  userPrivilege: state.auth.userPrivilege,
  roles: state.auth.roles,
  currentPlayerId: state.schedule.selectedPlayer.playerId || null,
  currentPlayerName: state.schedule.selectedPlayer.playerName || '',
  generatePlanText: state.table.generatePlanText || '',
  selectedUsersAdminPanel: state.adminPanel.selectedUsers || []
});

const mapDispatchToProps = {
  resetUploadFiles: uploadFilesAction,
  openModal: openModalAction,
  updateStep,
  selectItems: selectItemsAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionBar));
