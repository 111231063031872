import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

import {
  bytesToSize,
  formatCloudinaryFields,
  transformCloudinaryImage,
  shortenStringWithElipsis,
  getExpirationKey,
  formatDuration,
  calculateNumberOfDays,
  prettifyDuration,
  parseAssetName
} from '../../helpers/utils';
import { cloudinaryThumbnailTransforms, datesTimes } from '../../helpers/misc';
import constants from '../../helpers/constants';
import Thumbnail from '../Thumbnail/Thumbnail';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi } from '../../serviceLayer/services';

export const tableOptions = {
  sort: {
    by: 'sortOrder',
    order: 'desc'
  }
};

export const tableSchema = [
  {
    id: 'thumbnail',
    title: 'Thumbnail'
  },
  {
    id: 'name',
    title: 'Name'
  },
  {
    id: 'duration',
    title: 'Duration'
  },
  {
    id: 'type',
    title: 'Type'
  },
  {
    id: 'size',
    title: 'Size'
  },
  {
    id: 'validFrom',
    title: 'Valid From'
  },
  {
    id: 'validTo',
    title: 'Valid Until'
  },
  {
    id: 'lastModified',
    title: 'Last Modified'
  }
];

export const fetchPlaylistItemData = pid =>
  getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }))
    .then(res => res)
    .catch(() => {});
export const formatPlaylistItems = (items = []) => {
  const tableDataSelected = [];
  items.forEach((item, rowIndex) => {
    const sub = item.media || item.subplaylist;
    const isMessage = sub.mediaType === 'MESSAGE';

    const expiryStatus = getExpirationKey(
      item.playlistItemType === 'SUB_PLAYLIST' ? item.endValidDate : sub.endValidDate
    );

    tableDataSelected[rowIndex] = {
      id: item.id,
      expiryStatus,
      sortOrder: item.sortOrder
    };

    let thumbnailUrl =
      item.thumbnailDownloadPaths &&
      constants.SCALA_API_URL + item.thumbnailDownloadPaths.extraSmall;
    let cloudinaryData = {};

    if (isMessage) {
      cloudinaryData = formatCloudinaryFields(sub.fields);
      thumbnailUrl = transformCloudinaryImage(
        cloudinaryData,
        cloudinaryThumbnailTransforms.medialist
      );

      tableDataSelected[rowIndex].thumbnail = (
        <Thumbnail
          item={item}
          thumbnailUrl={thumbnailUrl}
          type="media"
          cloudinaryData={cloudinaryData}
        />
      );
    }

    if (item.subplaylist) {
      const { playlistItems } = item;
      let loopCount = 0;

      cloudinaryData = {};

      // go through all items;
      // if any thumbnail was found break loop
      while (playlistItems && !thumbnailUrl && loopCount < playlistItems.length) {
        // check if item has no media object
        // if so and the item is playlist then fetch more data
        if (
          playlistItems[loopCount].media &&
          playlistItems[loopCount].media.mediaType === 'MESSAGE'
        ) {
          cloudinaryData = formatCloudinaryFields(playlistItems[loopCount].media.fields);
          thumbnailUrl = transformCloudinaryImage(
            cloudinaryData,
            cloudinaryThumbnailTransforms.medialist
          );
        }

        loopCount += 1;
      }

      tableDataSelected[rowIndex].thumbnail = (
        <Thumbnail
          item={item}
          thumbnailUrl={thumbnailUrl}
          type="playlist-single"
          cloudinaryData={cloudinaryData}
        />
      );
    }

    const subName = parseAssetName(sub.name);

    const maxChar = 40;
    const finalName = shortenStringWithElipsis(subName, maxChar);
    const shortenedName = (
      <Tooltip
        position="bottom"
        followCursor
        disabled={subName.length < maxChar}
        html={<div className="tooltipWidth">{subName}</div>}
      >
        <div className="core-green__main">{finalName}</div>
      </Tooltip>
    );

    const itemName = sub.playlistType ? (
      <NavLink target="_blank" to={`/playlists/${sub.id}`}>
        {shortenedName}
      </NavLink>
    ) : (
      shortenedName
    );

    // name
    // Commented out the 'Message' text below the playlist name.
    tableDataSelected[rowIndex].name = <div>{itemName}</div>;

    // duration
    if (cloudinaryData.duration) {
      tableDataSelected[rowIndex].duration = (
        <span>
          {prettifyDuration(cloudinaryData.resource_type === 'image' ? 7 : cloudinaryData.duration)}
        </span>
      );
    } else {
      tableDataSelected[rowIndex].duration = item.duration
        ? prettifyDuration(item.duration)
        : formatDuration(sub.prettifyDuration || item.prettifyDuration || '');
    }

    // type
    tableDataSelected[rowIndex].type = (
      <span>{isMessage ? cloudinaryData.resource_type : sub.prettifyType}</span>
    );
    let sizes = sub;
    if (isMessage) {
      const width = sub.fields.filter(field => field.name === 'width');
      const height = sub.fields.filter(field => field.name === 'height');
      const length = sub.fields.filter(field => field.name === 'bytes');
      sizes = {
        height: height[0] && height[0].value,
        width: width[0] && width[0].value,
        length: length[0] && length[0].value
      };
    }

    if (sizes.height && sizes.width && sizes.length) {
      // sizesize
      tableDataSelected[rowIndex].size = (
        <div>
          <div>
            <span>
              {sizes.width}x{sizes.height}
            </span>
          </div>
          <div>{isMessage ? sizes.length : bytesToSize(sizes.length)}</div>
        </div>
      );
    }

    const validFrom =
      item.playlistItemType === 'SUB_PLAYLIST' ? item.startValidDate : sub.startValidDate;
    const validToLabel =
      item.playlistItemType === 'SUB_PLAYLIST' ? item.endValidDate : sub.endValidDate;
    const validTo = (
      <div>
        {expiryStatus !== 'expired' && expiryStatus !== 'expires-soon' && (
          <div>{validToLabel === datesTimes.scalaNeverEndsDate ? '' : validToLabel}</div>
        )}
        {expiryStatus === 'expired' && (
          <div className="expiration-badge expiration-badge--expired">
            expired on {validToLabel}
          </div>
        )}
        {expiryStatus === 'expires-soon' && (
          <div className="expiration-badge expiration-badge--expires-soon">
            expires soon in {calculateNumberOfDays(validToLabel)}{' '}
            {calculateNumberOfDays(validToLabel) > 1 ? 'days' : 'day'} on {validToLabel}
          </div>
        )}
      </div>
    );

    tableDataSelected[rowIndex].disabled = item.disabled;

    tableDataSelected[rowIndex].validFrom = validFrom;

    tableDataSelected[rowIndex].validTo = validTo;
    // update date
    tableDataSelected[rowIndex].lastModified = sub.lastModified;

    tableDataSelected[rowIndex].selectInfo = {
      id: item.id,
      name: subName,
      cloudinary: cloudinaryData
    };
  });

  return tableDataSelected;
};
