import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GlCallout, GlTable } from '@adl/foundation';
import { Tooltip } from 'react-tippy';
import { extractFromMetadata } from '../MediaList/schema';
import { cloudinaryThumbnailTransforms } from '../../helpers/misc';
import {
  bytesToSize,
  transformCloudinaryImage,
  transformCloudinaryVideo,
  formatCloudinaryFields,
  parseAssetName
} from '../../helpers/utils';

import Modal from '../commons/Modal/Modal';
import { closeModal as closeModalAction } from '../../actions/modal';
import constants from '../../helpers/constants';
import Loader from '../commons/Loader/Loader';
import CarouselSlider from '../commons/CarouselSlider/CarouselSlider';

import './Modals.scss';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi, callUrlWrapperApi } from '../../serviceLayer/services';

class PreviewItemsModal extends Component {
  state = {
    playlist: [],
    isFetching: true,
    loadError: false,
    finishedLoading: false,
    itemsToDisplay: []
  };

  async componentDidMount() {
    const { type, previewItem } = this.props;
    let name = (previewItem && previewItem.name) || '';
    name = parseAssetName(name);
    if (type === 'playlist') {
      const id = previewItem.subplaylist ? previewItem.subplaylist.id : previewItem.id;
      const itemType = 'playlist';
      let issubPlaylist = false;

      this.fetchPlaylistItemData(id).then(
        async (res) => {
          let playlist = res;
          if (playlist.playlistItems !== undefined) {
            const items = await playlist.playlistItems.map(async (item, index) => {
              const preview = await this.getPlaylistGalleryElement(item, index);
              let description = '';

              if (item.media) {
                const data = this.formatPlaylistResponse(item.media, false);
                description = this.getItemDescription(
                  data,
                  index,
                  playlist.playlistItems.length,
                  (issubPlaylist = false),
                  itemType
                );
              }

              if (item.subplaylist) {
                const data = this.formatPlaylistResponse(item.subplaylist, true);
                issubPlaylist = true;
                description = this.getItemDescription(
                  data,
                  index,
                  playlist.playlistItems.length,
                  issubPlaylist,
                  itemType
                );
              }
              const displayName = (
                <Tooltip
                  position="bottom"
                  followCursor
                  html={<div className="tooltipWidth">{name}</div>}
                >
                  <h4>
                    {name}
                    <span style={{ fontSize: '16px', whiteSpace: 'pre', fontWeight: 'normal' }}>
                      {' '}
                      [Item {index + 1} of {playlist.itemCount}]
                    </span>
                  </h4>
                </Tooltip>
              );
              const itemInfo = (
                <div>
                  {displayName}
                  {preview}
                  {description}
                </div>
              );
              return itemInfo;
            });
            Promise.all(items).then(items => {
              this.setState({ itemsToDisplay: items });
            });
          }

          this.setState({ isFetching: false, finishedLoading: true });
        },
        () => {
          this.setState({ isFetching: false, loadError: true, finishedLoading: true });
        }
      );
    } else {
      if (previewItem.media) {
        name = previewItem.media.name;
      }
      name = parseAssetName(name);
      const item = previewItem.playlistItemType ? previewItem.media : previewItem;
      const itemType = this.findMediaType(item);
      const previewData = itemType ? item : this.fetchPreviewData(item);
      const preview = await this.getGalleryElement(previewData);
      const data = itemType ? item : this.formatPlaylistResponse(item);
      const description = this.getItemDescription(data);
      const itemInfo = (
        <div>
          <h4>{name}</h4>
          {preview}
          {description}
        </div>
      );
      this.setState({ itemsToDisplay: itemInfo, isFetching: false, finishedLoading: true });
    }
  }

  fetchPreviewData = previewItem => {
    // Fetch preview data from media inside playlist
    // As its coming in old format.
    // mediatype, large, secureUrl, fileType, fileFormat
    const { mediaType = '-', thumbnailDownloadPaths = {} } = previewItem;
    const cloudinaryData = formatCloudinaryFields(previewItem.fields);
    const {
      secure_url: secureUrl = '',
      format: fileFormat = '',
      resource_type: fileType = '',
      width = 0,
      height = 0
    } = cloudinaryData;
    const dimension = `${width}x${height}`;
    const { large = '' } = thumbnailDownloadPaths;
    return { mediaType, large, secureUrl, fileType, fileFormat, dimension };
  };

  fetchPlaylistItemData = async pid => {
    return new Promise((resolve, reject) => {
      this.setState({
        isFetching: true
      });

      getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }))
        .then(res => {
          this.setState(next => ({
            ...next,
            playlist: res,
            isFetching: false,
            finishedLoading: true
          }));
          resolve(res);
        })
        .catch(() => {
          this.setState({ isFetching: false, loadError: true, finishedLoading: true });
        });
    });
  };

  getGalleryElement = previewItem =>
    new Promise((resolve, reject) => {
      const { mediaType } = previewItem;
      const isMessage = mediaType === 'MESSAGE' || mediaType === 'SCALA_SCRIPT';

      let imageSRC = previewItem.large && constants.THUMBNAIL_URL + previewItem.large;

      const cloudinaryData = {
        secure_url: previewItem.secureUrl,
        resource_type: previewItem.fileType,
        format: previewItem.fileFormat
      };
      const isVideo = cloudinaryData.resource_type === 'video';

      const { secure_url: originalURL } = cloudinaryData;
      let videoURL = originalURL;

      let returnBlock;

      if (isMessage) {
        const transformedIMG = transformCloudinaryImage(
          cloudinaryData,
          cloudinaryThumbnailTransforms.previewmodal
        );
        if (transformedIMG) {
          imageSRC = transformedIMG;
        }
      }

      if (isVideo) {
        videoURL = transformCloudinaryVideo(cloudinaryData);
      }
      const visibleHeight = 480;
      const visibleWidth = 720;
      let style = {};
      // Find dimensions.
      const dimension = previewItem && previewItem.dimension;
      const imageWidth = Number(dimension.split('x')[0]);
      const imageHeight = Number(dimension.split('x')[1]);
      let aspectRatio = 0;
      let widthAspectRatio = 0;
      let aspectHeight = imageHeight;
      let aspectWidth = imageWidth;
      // Reduce the aspect ratio
      if (imageHeight > visibleHeight) {
        aspectRatio = visibleHeight / imageHeight;
        aspectHeight = visibleHeight;
        aspectWidth = aspectRatio * imageWidth;
      }

      // If width is overlapping the height -> reduce the width & height by maintaing the aspect ratio.
      if (aspectWidth > visibleWidth) {
        widthAspectRatio = visibleWidth / aspectWidth;
        aspectWidth = visibleWidth;
        aspectHeight *= widthAspectRatio;
      }
      style = { height: aspectHeight, width: aspectWidth };
      if (isMessage && !isVideo) {
        getAccessTokenAndMakeCalls(token => callUrlWrapperApi(token, { url: imageSRC }))
          .then(res => {
            const d = res && Buffer.from(res).toString('base64');
            const messageImg = d ? `data:image/png;base64,${d}` : '';
            returnBlock = (
              <div className="modal-items__preview">
                <img key={previewItem.id} src={messageImg} alt="" style={style} />
              </div>
            );
            resolve(returnBlock);
          })
          .catch(e => console.log('error', e));
      } else {
        returnBlock =
          isVideo && originalURL ? (
            // eslint-disable-next-line
          <div className="modal-items__preview">
            <video key={previewItem.id} controls autoPlay poster={imageSRC} style={style}>
                <source src={videoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
          </div>
          ) : (
            <div className="modal-items__preview">
              <img key={previewItem.id} src={imageSRC} alt="" style={style} />
            </div>
          );
        resolve(returnBlock);
      }
    });

  getPlaylistGalleryElement = previewItem =>
    new Promise((resolve, reject) => {
      const { mediaType } = previewItem;

      if (
        previewItem.media === undefined &&
        mediaType !== 'SCALA_SCRIPT' &&
        mediaType !== 'MEDIA_ITEM'
      ) {
        resolve(<div className="no-preview-info">NO PREVIEW AVAVAILABLE FOR THIS ITEM</div>);
      }

      const isMessage =
        mediaType === 'MESSAGE' || mediaType === 'SCALA_SCRIPT' || mediaType !== 'MEDIA_ITEM';

      let imageSRC =
        previewItem.media &&
        previewItem.media.thumbnailDownloadPaths &&
        constants.THUMBNAIL_URL + previewItem.media.thumbnailDownloadPaths.large;

      const cloudinaryData = formatCloudinaryFields(previewItem.media.fields);
      const isVideo = cloudinaryData.resource_type === 'video';

      const { secure_url: originalURL } = cloudinaryData;
      let videoURL = originalURL;

      let returnBlock;

      if (isMessage) {
        const transformedIMG = transformCloudinaryImage(
          cloudinaryData,
          cloudinaryThumbnailTransforms.previewmodal
        );

        if (transformedIMG) {
          imageSRC = transformedIMG;
        }
      }

      if (isVideo) {
        videoURL = transformCloudinaryVideo(cloudinaryData);
      }
      const visibleHeight = 1200;
      // Find dimensions.
      const imageWidth = (cloudinaryData && cloudinaryData.width) || 0;
      const imageHeight = (cloudinaryData && cloudinaryData.height) || 0;
      let aspectRatio = 0;
      let aspectHeight = imageHeight;
      let aspectWidth = imageWidth;
      // Reduce the aspect ratio
      if (imageHeight > visibleHeight) {
        aspectRatio = visibleHeight / imageHeight;
        aspectHeight = visibleHeight;
        aspectWidth = aspectRatio * imageWidth;
      }
      const style = { height: aspectHeight, width: aspectWidth };
      if (isMessage && !isVideo) {
        getAccessTokenAndMakeCalls(token => callUrlWrapperApi(token, { url: imageSRC }))
          .then(res => {
            const d = res && Buffer.from(res).toString('base64');
            const messageImg = d ? `data:image/png;base64,${d}` : '';
            returnBlock = (
              <div className="modal-items__preview">
                <img key={previewItem.id} src={messageImg} alt="" style={style} />
              </div>
            );
            resolve(returnBlock);
          })
          .catch(e => console.log('error', e));
      } else {
        returnBlock =
          isVideo && originalURL ? (
            // eslint-disable-next-line
          <div className="modal-items__preview-playlist">
            <video key={previewItem.id} controls poster={imageSRC} style={{ height: '60vh' }}>
                <source src={videoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
          </div>
          ) : (
            <div className="modal-items__preview-playlist">
              <img key={previewItem.id} src={imageSRC} alt="" style={{ style }} />
            </div>
          );
          resolve(returnBlock);
      }
    });

  findMediaType = item => {
    // To differetiate from where the Media is accessed.
    // Reason: Media data from inside playlist is coming in older format.
    const { fileFormat, fileType } = item;
    if (typeof fileFormat !== 'undefined' && typeof fileType !== 'undefined') {
      // Item is coming from media library else it is coming from playlist.
      return true;
    }
    return false;
  };

  getItemDescription = (
    itemData,
    index = 0,
    maxLength = 0,
    issubPlaylist = false,
    itemType = 'media'
  ) => {
    let { createdDate, lastModified, fileType, name } = itemData;
    const { createdBy, fileFormat, size, dimension, duration, lastModifiedBy } = itemData;
    let className = 'modal-items__description';

    name = (name && parseAssetName(name)) || '';
    createdDate =
      (createdDate &&
        createdDate.split('.').length >= 1 &&
        createdDate
          .split('.')[0]
          .split('T')
          .join(' ')) ||
      '-';

    lastModified =
      (lastModified &&
        lastModified.split('.').length >= 1 &&
        lastModified
          .split('.')[0]
          .split('T')
          .join(' ')) ||
      '-';

    fileType = fileType && fileType.length > 1 && fileType[0].toUpperCase() + fileType.substring(1);

    const nameRow = [
      {
        label: <span style={{ marginRight: 'auto' }}>Media Name</span>,
        values: [<span style={{ marginRight: 'auto' }}>{name || '-'}</span>]
      }
    ];
    const mediaItemRows = [
      {
        label: <span style={{ marginRight: 'auto' }}>Type</span>,
        values: [<span style={{ marginRight: 'auto' }}>{fileType || '-'}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>File Size</span>,
        values: [<span style={{ marginRight: 'auto' }}>{size || '-'}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Format</span>,
        values: [
          <span style={{ marginRight: 'auto' }}>
            {(fileFormat && fileFormat.toUpperCase()) || '-'}
          </span>
        ]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Dimensions</span>,
        values: [<span style={{ marginRight: 'auto' }}>{`${dimension}px` || '-'}</span>]
      }
    ];

    const subPlaylistRows = [
      {
        label: <span style={{ marginRight: 'auto' }}>Type</span>,
        values: [<span style={{ marginRight: 'auto' }}>{fileType || '-'}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Duration</span>,
        values: [<span style={{ marginRight: 'auto' }}>{duration || '-'}</span>]
      }
    ];
    const fixedTableRows = [
      {
        label: <span style={{ marginRight: 'auto' }}>Created by</span>,
        values: [<span style={{ marginRight: 'auto' }}>{createdBy || '-'}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Created on</span>,
        values: [<span style={{ marginRight: 'auto' }}>{createdDate}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Updated by</span>,
        values: [<span style={{ marginRight: 'auto' }}>{lastModifiedBy || '-'}</span>]
      },
      {
        label: <span style={{ marginRight: 'auto' }}>Updated on</span>,
        values: [<span style={{ marginRight: 'auto' }}>{lastModified}</span>]
      }
    ];

    let tableRows = [];
    if (itemType === 'playlist') {
      tableRows = tableRows.concat(nameRow);
      className = 'modal-items__description-playlist';
    }
    tableRows = tableRows.concat(fixedTableRows);
    tableRows = issubPlaylist ? tableRows.concat(subPlaylistRows) : tableRows.concat(mediaItemRows);

    const table = {
      type: 'horizontal',
      rows: tableRows
    };
    return (
      <div className={className} style={{ fontSize: '12px', marginBottom: '20px' }}>
        <GlTable table={table} />
      </div>
    );
  };

  formatPlaylistResponse = (playlistItem, issubPlaylist) => {
    const { createdDate, lastModified, name } = playlistItem;

    if (issubPlaylist) {
      const { playlistType, createdByName, prettifyDuration } = playlistItem;
      const fileType = playlistType;
      const createdBy = createdByName;
      const duration = prettifyDuration
        ? prettifyDuration.substring(1, prettifyDuration.length - 1)
        : '-';
      return { name, createdDate, lastModified, fileType, createdBy, prettifyDuration, duration };
    }

    const isMessage = playlistItem.mediaType === 'MESSAGE';
    let data;
    if (playlistItem.metadataValue && playlistItem.metadataValue.length > 0) {
      data = extractFromMetadata(playlistItem.metadataValue, 'MediaItem.CFE_CreatedBy');
    }
    const createdBy = data || '';
    const itemFields = playlistItem.fields || [];
    const cloudinaryData = formatCloudinaryFields(itemFields || []);
    const fileType =
      isMessage && cloudinaryData.resource_type
        ? cloudinaryData.resource_type
        : playlistItem.prettifyType;

    let sizes = playlistItem;
    if (isMessage) {
      const width = itemFields.filter(field => field.name === 'width');
      const height = itemFields.filter(field => field.name === 'height');
      const length = itemFields.filter(field => field.name === 'bytes');
      sizes = {
        height: height[0] && height[0].value,
        width: width[0] && width[0].value,
        length: length[0] && length[0].value
      };
    }
    const dimension = sizes.width && sizes.height ? `${sizes.width}x${sizes.height}` : '-';
    const size = isMessage ? sizes.length : bytesToSize(sizes.length);
    let fileFormat;
    if (isMessage) {
      fileFormat = itemFields.filter(field => field.name === 'format');
    }

    fileFormat = isMessage
      ? fileFormat[0] && fileFormat[0].value
      : playlistItem.name.split('.').pop();

    const lastModifiedBy = playlistItem.modifiedBy && playlistItem.modifiedBy.firstname;
    return {
      name,
      createdDate,
      lastModified,
      createdBy,
      fileType,
      size,
      dimension,
      fileFormat,
      lastModifiedBy
    };
  };

  render() {
    const { closeModal, customClose } = this.props;
    const { finishedLoading, isFetching, itemsToDisplay } = this.state;

    if (finishedLoading && itemsToDisplay.length === 0) {
      return (
        <Modal
          size="large"
          className="custom-modal--fullscreen preview-modal"
          open
          enableOutsideClick
          modalCloseHandler={() => (customClose != null ? customClose() : closeModal())}
        >
          <GlCallout type="urgent">
            <h5>No items found</h5>
            <p>There is no media inside this playlist</p>
          </GlCallout>
        </Modal>
      );
    }

    if (isFetching) {
      return (
        <Modal open enableOutsideClick modalCloseHandler={() => closeModal()}>
          <Loader />
        </Modal>
      );
    }

    return (
      <Modal
        size="large"
        className="custom-modal--fullscreen preview-modal"
        open
        enableOutsideClick
        modalCloseHandler={() => (customClose != null ? customClose() : closeModal())}
      >
        {itemsToDisplay.length > 1 ? <CarouselSlider items={itemsToDisplay} /> : itemsToDisplay}
      </Modal>
    );
  }
}

PreviewItemsModal.propTypes = {
  closeModal: PropTypes.func,
  customClose: PropTypes.func,
  previewItem: PropTypes.shape({}),
  type: PropTypes.string
};

const mapStateToProps = state => ({
  previewItem: state.modals.activeModal.items[0],
  type: state.modals.activeModal.type
});

const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewItemsModal);
