import React from 'react';
import './ActivityLog.scss';
import ActivityLogs from '../../components/ActivityLogs/ActivityLogs';

const ActivityLog = () => (
  <div className="with-sidebar__container">
    <div>
      <ActivityLogs />
    </div>
  </div>
);

export default ActivityLog;