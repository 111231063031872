import produce from 'immer';
import * as adminPanelActions from '../actions/adminPanel';

const initialState = {
  selectedUsers: []
};

const adminPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminPanelActions.SELECTED_USERS:
      return produce(state, draft => {
        draft.selectedUsers = action.data.map(user => user.id || 0);
      });
    default:
      return state;
  }
};

export default adminPanelReducer;
