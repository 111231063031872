/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckboxItem from './CustomCheckboxItem';

const CustomCheckboxNode = ({
  node,
  icons,
  expanded,
  checked,
  halfChecked,
  checkCounter,
  onCheck,
  onExpand,
  singleSelect,
  id
}) => {
  const { children } = node;
  const isExpanded = expanded.includes(node.value.toString());
  const isChecked = checked.includes(node.value.toString());
  const isHalfChecked = halfChecked.includes(node.value.toString()) && checked.length > 0;
  return (
    <div className="customCheckboxNode">
      <CustomCheckboxItem
        id={id}
        hasChildren={children != null}
        expanded={isExpanded}
        checked={isChecked}
        onCheck={onCheck}
        onExpand={onExpand}
        halfChecked={isHalfChecked}
        singleSelect={singleSelect}
        checkCounter={checkCounter}
        item={node}
        icons={icons}
      />
      {children && isExpanded && (
        <div className="customCheckboxNode--children">
          {children.map(child => (
            <CustomCheckboxNode
              id={id}
              expanded={expanded}
              halfChecked={halfChecked}
              onCheck={onCheck}
              checked={checked}
              onExpand={onExpand}
              checkCounter={checkCounter}
              key={child.value}
              node={child}
              singleSelect={singleSelect}
              icons={icons}
            />
          ))}
        </div>
      )}
    </div>
  );
};

CustomCheckboxNode.propTypes = {
  node: PropTypes.object,
  icons: PropTypes.object,
  expanded: PropTypes.arrayOf(PropTypes.string),
  checked: PropTypes.arrayOf(PropTypes.string),
  onCheck: PropTypes.func,
  onExpand: PropTypes.func,
  halfChecked: PropTypes.arrayOf(PropTypes.string),
  checkCounter: PropTypes.object,
  singleSelect: PropTypes.bool
};

export default CustomCheckboxNode;
