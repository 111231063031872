import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import NavTab from '../NavTab/NavTab';

import './NavTabs.scss';

const NavTabs = ({ roles }) => {
  const isAdmin = roles.includes('ROLE_ADMIN');
  const isPowerUser = roles.includes('ROLE_POWER_USER');
  const isContentManager = roles.includes('ROLE_LOCAL_CONTENT_MANAGER');
  const visibleToAll = isAdmin || isPowerUser || isContentManager;

  return (
    <div className="navtab-container">
      <ul className="NavTabs">
        {visibleToAll && (
          <>
            <NavLink to="/" exact>
              <NavTab text="Displays" data-testid="tab-players-scheduling" />
            </NavLink>
            <NavLink to="/media">
              <NavTab text="Media" data-testid="tab-media" />
            </NavLink>
            <NavLink to="/playlists">
              <NavTab text="Playlists" data-testid="tab-playlists" />
            </NavLink>
          </>
        )}
        {isAdmin && (
          <>
            <NavLink to="/admin-panel">
              <NavTab text="Administration" data-testid="tab-administration" />
            </NavLink>
          </>
        )}
        {visibleToAll && (
          <>
            <NavLink to="/activity-log" exact>
              <NavTab text="Activity Log" data-testid="tab-activity-log" />
            </NavLink>
          </>
        )}
      </ul>
    </div>
  );
};

NavTabs.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string)
};

const mapProps = (state) => ({
  roles: state.auth.roles
});

export default withRouter(connect(mapProps)(NavTabs));
