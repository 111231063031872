import * as tableActions from '../actions/table';

const initialState = {
  settings: {},
  workgroups: {
    selected: null
  },
  users: {
    selected: []
  },
  media: {
    selected: [],
    items: []
  },
  playlists: {
    selected: [],
    items: []
  },
  playlistselected: {
    selected: []
  },
  selectPlaylistsToAddMedia: {
    selected: []
  },
  selectMediaToPlaylists: {
    selected: []
  },
  selectsubPlaylistsToPlaylists: {
    selected: []
  },
  templatesList: {
    selected: [],
    items: []
  },
  'dashboard:1': {
    selected: [],
    clicked: []
  },
  'dashboard:2': {
    selected: []
  },
  generatePlanText: '',
  shownTags: []
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case tableActions.SELECT_ITEMS:
      return {
        ...state,
        [action.tableId]: {
          ...state[action.tableId],
          selected: action.selectedItems
        }
      };
    case tableActions.CLICKED_PLAYER:
      return {
        ...state,
        [action.tableId]: {
          ...state[action.tableId],
          clicked: action.clickedPlayer ? [action.clickedPlayer] : []
        }
      };
    case tableActions.UPDATE_GENERATE_PLAN_TEXT:
      return {
        ...state,
        generatePlanText: action.generatePlanText
      };
    case tableActions.LOAD_SETTINGS:
      return {
        ...state,
        settings: action.settings
      };
    case tableActions.UPDATE_TABLE_SETTINGS:
      return {
        ...state,
        settings: action.settings
      };
    case tableActions.TOGGLE_MEDIA_TAGS:
      return {
        ...state,
        shownTags: state.shownTags.includes(action.mediaId)
          ? state.shownTags.filter((id) => id !== action.mediaId)
          : [...state.shownTags.filter((id) => id !== action.mediaId), action.mediaId]
      };
    default:
      return state;
  }
};

export default tableReducer;
