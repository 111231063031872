import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash-es/debounce';
import { GlButton } from '@adl/foundation';
import Cookies from 'universal-cookie';

import Dashboard from '../Dashboard/Dashboard';
import Media from '../Media/Media';
import AdminPanel from '../AdminPanel/AdminPanel';
import Metadata from '../AdminPanel/Metadata/Metadata';
import SetSingleDisplaySchedules from '../../components/SetSingleDisplaySchedules/SetSingleDisplaySchedules';
import PlaylistAll from '../../components/PlaylistAll/PlaylistAll';
import PlaylistSelected from '../../components/PlaylistSelected/PlaylistSelected';
import Header from '../../components/Header/Header';
import FileUpload from '../../components/FileUpload/FileUpload';
import { closeModal as closeModalAction } from '../../actions/modal';
import Modal from '../../components/commons/Modal/Modal';
import Loader from '../../components/commons/Loader/Loader';

import './App.css';
import 'react-tippy/dist/tippy.css';
import { REDIRECT_KEY } from '../../helpers/utils';
import ActivityLog from '../ActivityLog/ActivityLog';
import PlayerHealth from '../PlayerHealth/PlayerHealth';

import { getAccessTokenAndMakeCalls, acquireAccessToken } from '../../serviceLayer/utils';
import { callGetLoggedInUserInfoApi, callGetUserPreferenceApi } from '../../serviceLayer/services';
// eslint-disable-next-line

const cookies = new Cookies();
class App extends Component {
  state = {
    loading: true,
    authenticated: false,
    headerheight: 88
  };

  resizeEvent = debounce(() => {
    const { headerheight } = this.state;
    const header = document.querySelector('.Header');

    if (header && headerheight === header.clientHeight) return;

    header && this.setState({ headerheight: header.clientHeight });
  }, 200);

  componentDidMount() {
    getAccessTokenAndMakeCalls(token => this.callInitialApis(token));
    setInterval(this.resizeEvent, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEvent);
  }

  getRedirect = () => {
    const redirect = localStorage.getItem(REDIRECT_KEY);
    if (redirect) {
      localStorage.removeItem(REDIRECT_KEY);
      return <Redirect to={redirect} />;
    }

    return null;
  };

  callInitialApis(token) {
    return new Promise((resolve, reject) => {
      callGetLoggedInUserInfoApi(token)
        .then(() => {
          callGetUserPreferenceApi(token)
            .then(() => {
              this.setState({ authenticated: true, loading: false });
              resolve();
            })
            .catch(e => {
              throw e;
            });
        })
        .catch(error => {
          acquireAccessToken();
        });
    });
  }

  render() {
    const { authenticated, loading, headerheight } = this.state;
    const { roles, planGenerationModal, closeModal } = this.props;

    if (authenticated && !loading) {
      const isAdmin = roles.includes('ROLE_ADMIN');
      const isPowerUser = roles.includes('ROLE_POWER_USER');
      const isContentManager = roles.includes('ROLE_LOCAL_CONTENT_MANAGER');
      const visibleToAll = isAdmin || isPowerUser || isContentManager;
      return (
        <div className="App" style={{ paddingTop: headerheight }}>
          <Router>
            <>
              <Header onClick={() => this.resizeEvent} />
              <Switch>
                {visibleToAll ? (
                  <>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/media" exact component={Media} />
                    <Route path="/media/upload" component={FileUpload} />
                    <Route
                      path="/playlists/:pid"
                      render={props => (
                        <PlaylistSelected
                          key={props.match.params.pid}
                          pid={props.match.params.pid}
                          {...props}
                        />
                      )}
                    />
                    <Route path="/playlists" exact component={PlaylistAll} />
                    <Route
                      path="/schedules/:playerId/:playerName"
                      component={SetSingleDisplaySchedules}
                    />
                    <Route path="/player-health/:playerId/:playerName" component={PlayerHealth} />
                    {isAdmin && (
                      <>
                        <Route path="/admin-panel" exact component={AdminPanel} />
                        <Route path="/admin-panel/metadata" exact component={Metadata} />
                      </>
                    )}
                    {visibleToAll && (
                      <>
                        <Route path="/activity-log" component={ActivityLog} />
                      </>
                    )}
                  </>
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    Something went wrong.{' '}
                    <GlButton
                      tertiary
                      onClick={() => {
                        cookies.remove('access_token');
                        window.location.reload();
                      }}
                    >
                      Reload
                    </GlButton>
                  </p>
                )}
                <Redirect to="/" />
              </Switch>
              {this.getRedirect()}
            </>
          </Router>
          {planGenerationModal && (
            <Modal
              open
              size="small"
              modalCloseHandler={() => {
                if (planGenerationModal.inProgress) {
                  return;
                }
                closeModal();
              }}
            >
              {planGenerationModal.inProgress && <Loader text="Generating plan..." />}
              {planGenerationModal.completed &&
                (planGenerationModal.error ? (
                  <p className="validation-error">There was an error generating plan</p>
                ) : (
                  <p className="validation-success">Plan(s) generated successfully</p>
                ))}
            </Modal>
          )}
        </div>
      );
    }
    return <Loader className="full-height" />;
  }
}

App.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  planGenerationModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  closeModal: PropTypes.func
};

const mapStateToProps = state => ({
  roles: state.auth.roles,
  planGenerationModal:
    state.modals.activeModal.modalId === 'planGeneration' && state.modals.activeModal
});

const mapDispatch = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatch
)(App);
