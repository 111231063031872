/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton, GlTooltip, GlCheckbox, GlTextarea, GlIcon } from '@adl/foundation';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import formatDate from 'date-fns/format';
import isBefore from 'date-fns/is_before';
import addDays from 'date-fns/add_days';
import addMonths from 'date-fns/add_months';
import get from 'lodash-es/get';
import produce from 'immer';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';

import Badge from '../Badge/Badge';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import CheckboxTreeContainer from '../commons/CheckboxTreeContainer/CheckboxTreeContainer';
import * as modalActions from '../../actions/modal';
import { uploadingFilesData as uploadingFilesDataAction } from '../../actions/upload';
import { parseAssetName, shortenStringWithElipsis } from '../../helpers/utils';
import { datesTimes, UITEXT } from '../../helpers/misc';
import './Modals.scss';
import CustomSelect from '../commons/CustomSelect/CustomSelect';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callGetMediaApi,
  callGetLocationApi,
  callGetMediaSearchApi,
  callGetTaxonomyTagsApi
} from '../../serviceLayer/services';

class MediaEdit2 extends Component {
  state = {
    isFetchingInfo: true,
    fetchError: false,
    allTagsByIds: {},
    allMainCategories: [],
    allTagsByMainCategory: {},
    selectedMainCategories: {},
    groupedOption: {},
    tagsLoading: true,
    tagsLoadError: false,
    loadedInfo: {},
    loadedInfoByIds: [],
    editedInfo: {},
    isEditing: false,
    noItemsFound: false,
    isValid: {
      name: true
    },
    workgroups: [],
    selectedWorkgroupsMap: [],
    expanded: {},
    maxChar: 30
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('beforeunload', this.handlePageRefresh);

    getAccessTokenAndMakeCalls(token => callGetTaxonomyTagsApi(token))
      .then(res => {
        this.setState({ tagsLoading: false });
        const tags = get(res, 'list', []);
        const allTagsByIds = {};
        const allMainCategories = [];
        const allTagsByMainCategory = {};
        const selectedMainCategories = {};
        const defaultCategories = [];
        tags.forEach(tag => {
          tag.children.forEach(category => {
            category.children.forEach(categoryTags => {
              allTagsByIds[categoryTags.id] = {
                value: categoryTags.id,
                label: `${categoryTags.name}`
              };
            });
            allMainCategories.push({
              value: category.id,
              label: category.name
            });
            allTagsByMainCategory[category.id] = {
              id: category.id,
              name: category.name,
              options: category.children.map(subCat => subCat.id)
            };
            if (
              category.name === 'Season' ||
              category.name === 'Sport' ||
              category.name === 'Gender' ||
              category.name === 'Key City' ||
              category.name === 'Sub Brand'
            ) {
              defaultCategories.push(category.id);
            }
          });
        });
        // now copy default categories to selected items
        const { selectedItems } = this.props;
        selectedItems.forEach(item => {
          selectedMainCategories[item.id] = defaultCategories;
        });
        this.setState(
          { allTagsByIds, allMainCategories, allTagsByMainCategory, selectedMainCategories },
          () => {
            this.populateTagsBySelectedCategory();
          }
        );
      })
      .catch(() => {
        this.setState({ tagsLoading: false, tagsLoadError: true });
      });

    this.fetchMediaInfo();
    this.fetchWorkgroupsData();
    // Previous ids are required to be send
    // in order to handle DBCPMDISIP-800
    this.previousIds = {};
    this.flattenedNodes = [];
  }

  componentWillUnmount = () => {
    delete this.previousIds;
    delete this.flattenedNodes;
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  updateDimensions = () => {
    let calculatedMaxChar = window.innerWidth * 0.05;
    if (calculatedMaxChar > 30) {
      calculatedMaxChar = 30;
    }
    this.setState({ maxChar: calculatedMaxChar });
  };

  handleInfoChange = (id, data, _isValid = false) => {
    const { editedInfo } = this.state;
    const { preventReload } = this.props;
    const newData = { ...data };
    if (newData.validTo && isBefore(newData.validTo, editedInfo[id].validFrom)) {
      return;
    }
    if (newData.validFrom && isBefore(editedInfo[id].validTo, newData.validFrom)) {
      newData.validTo = addDays(newData.validFrom, 1);
    }

    preventReload(true);

    const key = Object.keys(newData)[0];
    this.setState(next =>
      produce(next, draft => {
        draft.isValid = Object.assign({}, draft.isValid, { [key]: _isValid });
        draft.editedInfo[id] = Object.assign({}, draft.editedInfo[id], newData);
      })
    );
  };

  applyToAll = (fields, id) => {
    const { loadedInfoByIds, editedInfo } = this.state;
    const { preventReload } = this.props;

    preventReload(true);

    const item = editedInfo[id];
    const itemsToBulkEdit = { ...editedInfo };

    fields.forEach(field => {
      loadedInfoByIds.forEach(itemId => {
        itemsToBulkEdit[itemId][field] = item[field];
      });
    });

    this.setState({ editedInfo: itemsToBulkEdit });
  };

  applyToAllWG = id => {
    const { selectedWorkgroupsMap, loadedInfoByIds } = this.state;
    const selectedWG = selectedWorkgroupsMap[id];
    const newSWG = {};
    loadedInfoByIds.forEach(s => {
      newSWG[s] = selectedWG;
    });
    this.setState({ selectedWorkgroupsMap: newSWG });
  };

  fetchMediaInfo = () => {
    const { selectedItems } = this.props;
    const existingItems = selectedItems.filter(si => si.existing);
    const itemIds = existingItems.map(eI => eI.id);
    const newItems = selectedItems.filter(si => !si.existing);

    const promises = [
      getAccessTokenAndMakeCalls(token =>
        callGetMediaSearchApi(token, {
          params: {
            limit: 1000,
            listOnly: true,
            count: 0,
            offset: 0,
            sort: 'id',
            filters: `{id : {values :[${itemIds.join(',')}], comparator: 'eq' }}`
          }
        })
      )
    ];
    itemIds &&
      itemIds.length > 0 &&
      Promise.all(promises)
        .then(response => {
          const res = response[0];
          if (res.list) {
            this.setState(next =>
              produce(next, draft => {
                draft.isFetchingInfo = false;

                res.list.forEach(item => {
                  draft.loadedInfo[item.id] = item;
                  draft.loadedInfoByIds.push(item.id);

                  const defaultValidTo = addMonths(new Date(), 3);

                  draft.editedInfo[item.id] = {
                    id: item.id,
                    name: item.name,
                    description:
                      item.description === 'Automatically created from the backend'
                        ? ''
                        : item.description,
                    validFrom: new Date(item.startValidDate),
                    validTo: item.endValidDate ? new Date(item.endValidDate) : defaultValidTo,
                    neverEnds: typeof item.endValidDate === 'undefined',
                    tags: this.getItemTagIds(item.categories),
                    existing: true
                  };
                });
              })
            );
          } else {
            this.setState({ noItemsFound: true });
          }
        })
        .catch(() => {
          this.setState({ isFetchingInfo: false, fetchError: true });
        });

    newItems &&
      newItems.length > 0 &&
      this.setState(next =>
        produce(next, draft => {
          draft.isFetchingInfo = false;
          newItems.forEach(item => {
            draft.loadedInfo[item.id] = item;
            draft.loadedInfoByIds.push(item.id);
            const defaultValidTo = addMonths(new Date(), 3);
            draft.editedInfo[item.id] = {
              name: item.name,
              description: 'Automatically created from the backend',
              validFrom: new Date(),
              validTo: defaultValidTo,
              neverEnds: true,
              tags: this.getItemTagIds(item.categories),
              existing: false
            };
          });
        })
      );
  };

  fetchWorkgroupsData = () => {
    const { selectedItems } = this.props;
    const promises = [
      getAccessTokenAndMakeCalls(token => callGetLocationApi(token)),
      ...selectedItems.map(media =>
        getAccessTokenAndMakeCalls(token => callGetMediaApi(token, { mediaId: media.id }))
      )
    ];
    Promise.all(promises)
      .then(res => {
        const selectedWorkgroupsMap = {};
        for (let i = 1; i < res.length; i += 1) {
          const data = res[i];
          if (data && data.length > 0) {
            selectedWorkgroupsMap[data[0].mediaId] = data
              .map(el => el.locationId)
              .reduce((prev, curr) => [...prev, curr], []);
          }
        }
        this.setState({
          workgroups: this.formItems(res[0]),
          selectedWorkgroupsMap
        });
      })
      .catch(() => {
        this.setState({
          workgroups: [],
          selectedWorkgroupsMap: []
        });
      });
  };

  formItems = items =>
    items.map(item => {
      this.flattenedNodes.push(item.id.toString());
      const formatedObj = {
        label: item.name,
        value: item.id,
        id: item.id,
        parentId: item.parentId,
        children: item.children ? this.formItems(item.children) : null,
        type: item.type,
        description: item.playerDescription
      };

      return formatedObj;
    });

  storeBaseWgIds = (id, wg) => {
    this.previousIds[id] = wg.reduce((a, c) => a.concat(Number(c)), []);
  };

  onCheckHandler = (currentSelected, id) => {
    const { selectedWorkgroupsMap } = this.state;
    const { preventReload } = this.props;

    const copy = { ...selectedWorkgroupsMap };
    copy[id] = currentSelected;

    preventReload(true);

    this.setState({ selectedWorkgroupsMap: copy });
  };

  getItemTagIds = (tags = []) => tags.map(t => t.id);

  formatTagsToCategories = tagIds => {
    const { allTagsByIds } = this.state;
    return tagIds.reduce((res, id) => {
      if (allTagsByIds[id]) {
        res.push({
          id: allTagsByIds[id].value,
          name: allTagsByIds[id].label
        });
      }
      return res;
    }, []);
  };

  saveAllItems = () => {
    this.setState({ isEditing: true });
    const { editedInfo } = this.state;
    const { uploadingFilesData } = this.props;
    const allItemsData = Object.keys(editedInfo).map(id => this.saveSingleItem(id));
    this.setState({ isEditing: false });
    uploadingFilesData(allItemsData);
  };

  handleCategorySelection = (mediaId, category) => {
    const { selectedMainCategories } = this.state;
    if (selectedMainCategories[mediaId] && selectedMainCategories[mediaId].includes(category)) {
      // already present, now remove
      this.setState(
        {
          selectedMainCategories: {
            ...selectedMainCategories,
            [mediaId]: selectedMainCategories[mediaId].filter(cid => cid !== category)
          }
        },
        () => {
          this.populateTagsBySelectedCategory();
        }
      );
    } else {
      this.setState(
        prev => ({
          selectedMainCategories: {
            ...selectedMainCategories,
            [mediaId]: [category, ...prev.selectedMainCategories[mediaId]]
          }
        }),
        () => {
          this.populateTagsBySelectedCategory();
        }
      );
    }
  };

  submitForm = () => {
    const { selectedWorkgroupsMap, loadedInfo, editedInfo } = this.state;
    const { closeModal, uploadFilesWithData } = this.props;
    // console.log('submit form', selectedWorkgroupsMap, loadedInfo, editedInfo);
    const existingItemIds = Object.keys(editedInfo).filter(id => editedInfo[id].existing);
    const keys = Object.keys(selectedWorkgroupsMap).filter(id => existingItemIds.includes(id));
    const payload = {};
    for (const id of keys) {
      payload[id] = this.buildUserRequest(
        id,
        selectedWorkgroupsMap[id],
        loadedInfo[id].name,
        this.previousIds[id]
      );
    }
    closeModal('editMedia2');
    uploadFilesWithData(payload);
  };

  buildUserRequest = (userId, groups, fileName, previousIds) => {
    const { roles } = this.props;
    const payload = {
      groups,
      fileName,
      previousIds: roles && !roles.includes('ROLE_ADMIN') ? previousIds : null
    };
    return payload;
    // axiosOrg.post(`${constants.MEDIA_URL}/${userId}/location`, payload).catch(() => {
    //   this.setState(state => ({ notAuthToAssignWG: [...state.notAuthToAssignWG, Number(userId)] }));
    // });
  };

  populateTagsBySelectedCategory = () => {
    const { selectedMainCategories, allTagsByMainCategory, allTagsByIds } = this.state;
    let groupedOption = {};
    Object.keys(selectedMainCategories).forEach(mediaItemId => {
      selectedMainCategories[mediaItemId].forEach(selectedId => {
        let associatedTags = [];
        const tagIds = allTagsByMainCategory[selectedId]
          ? allTagsByMainCategory[selectedId].options
          : null;
        if (tagIds && tagIds.length > 0) {
          tagIds.forEach(tagId => {
            associatedTags = [...associatedTags, allTagsByIds[tagId]];
          });
        }
        groupedOption = {
          ...groupedOption,
          [mediaItemId]: [
            ...(groupedOption[mediaItemId] || []),
            {
              label: allTagsByMainCategory[selectedId]
                ? allTagsByMainCategory[selectedId].name
                : '',
              options: associatedTags
            }
          ]
        };
      });
    });
    this.setState({ groupedOption });
  };

  handleTagRemoval = (mediaId, tagId) => {
    const { editedInfo } = this.state;
    const newData = { ...editedInfo[mediaId] };
    newData.tags = newData.tags.filter(id => id !== tagId);
    this.setState(next =>
      produce(next, draft => {
        draft.editedInfo[mediaId] = Object.assign({}, draft.editedInfo[mediaId], newData);
      })
    );
  };

  extractFromMetadata = (metadata, column) => {
    const fieldIndex = metadata.findIndex(meta => meta.metadata.name === column);
    return fieldIndex;
  };

  formatMediaMetaData = data => {
    const { name } = this.props;
    // update last modified by
    if (data && data.length > 0) {
      const index = this.extractFromMetadata(data, 'MediaItem.CFE_LastModifiedBy');
      if ((index !== null || typeof index !== 'undefined') && index > -1) {
        data[index].value = name;
      }
    }
    return data;
  };

  onExpandHanlder = (value, id) => {
    const { expanded } = this.state;
    const isChildrenExpanded = expanded[id] && expanded[id][value];
    const updatedExpanded = {
      ...expanded,
      [id]: {
        ...expanded[id],
        [value]: !isChildrenExpanded
      }
    };

    this.setState({ expanded: updatedExpanded });
  };

  expandAll = id => {
    const { expanded, workgroups } = this.state;

    const findAllByKey = (obj, keyToFind) =>
      Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value)
            : typeof value === 'object' && value
            ? acc.concat(findAllByKey(value, keyToFind))
            : acc,
        []
      ) || [];

    const ids = findAllByKey(workgroups, 'id');
    // eslint-disable-next-line no-sequences
    const reducedIds = ids.reduce((acc, curr) => ((acc[curr] = true), acc), {});
    const expandedData = { ...expanded, [id]: reducedIds };

    this.setState({ expanded: expandedData });
  };

  collapseAll = id => {
    const { expanded } = this.state;
    const expandedData = { ...expanded, [id]: {} };

    this.setState({ expanded: expandedData });
  };

  saveSingleItem(itemId) {
    const { loadedInfo, editedInfo, selectedWorkgroupsMap } = this.state;
    const itemOrg = loadedInfo[itemId];
    const itemEdited = editedInfo[itemId];
    const toBeMerged = {
      categories: this.formatTagsToCategories(itemEdited.tags),
      startValidDate: formatDate(itemEdited.validFrom, 'YYYY-MM-DD'),
      name: itemEdited.name,
      description: itemEdited.description
    };
    if (itemOrg.metadataValue) {
      toBeMerged.metadataValue = this.formatMediaMetaData(itemOrg.metadataValue);
    }
    if (itemEdited.validTo && !itemEdited.neverEnds) {
      toBeMerged.endValidDate = formatDate(itemEdited.validTo, 'YYYY-MM-DD');
    } else {
      toBeMerged.endValidDate = '';
    }
    if (itemEdited && itemEdited.existing) {
      if (itemOrg.mediaType === 'MESSAGE') {
        toBeMerged.name = itemEdited.name;
      }
    } else if (itemEdited && !itemEdited.existing) {
      if (selectedWorkgroupsMap) {
        toBeMerged.location = selectedWorkgroupsMap[itemId] || [];
      }
      toBeMerged.name = itemEdited.name;
    }

    const merged = Object.assign({}, itemOrg, toBeMerged);
    // console.log('itemOrg',itemEdited,  itemOrg, toBeMerged, merged);
    return merged;
  }

  render() {
    const { closeModal, roles, isReloadPrevented, clearCurrentFiles } = this.props;
    const {
      isFetchingInfo,
      fetchError,
      noItemsFound,
      allTagsByIds,
      loadedInfoByIds,
      loadedInfo,
      editedInfo,
      isEditing,
      isValid,
      selectedWorkgroupsMap,
      workgroups,
      tagsLoading,
      tagsLoadError,
      allMainCategories,
      groupedOption,
      selectedMainCategories,
      expanded,
      maxChar
    } = this.state;
    if (isFetchingInfo || fetchError) {
      return (
        <Modal size="small" open enableOutsideClick modalCloseHandler={() => closeModal()}>
          {isFetchingInfo && !noItemsFound && <Loader />}
          {(fetchError || noItemsFound) && (
            <div>There was an error loading media asset(s) info</div>
          )}
        </Modal>
      );
    }
    if (isEditing) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }
    let disableSaveButton = false;

    if (
      (Object.keys(selectedWorkgroupsMap).length === 0 &&
        selectedWorkgroupsMap.constructor === Object) ||
      Object.keys(selectedWorkgroupsMap).length < loadedInfoByIds.length
    ) {
      disableSaveButton = true;
    } else {
      const isAnyEmpty = Object.values(selectedWorkgroupsMap).filter(vl => !vl || vl.length === 0)
        .length;
      disableSaveButton = isAnyEmpty;
    }

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />
        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            clearCurrentFiles(loadedInfo);
            closeModal();
          }}
        >
          <h4>Edit media assets</h4>
          <Container fluid style={{ paddingRight: 0 }}>
            <Row align="start" justify="start">
              {loadedInfoByIds.map(id => {
                if (!editedInfo[id]) {
                  return null;
                }

                const item = editedInfo[id];
                const { tags, name, description, validFrom, validTo, neverEnds } = item;
                const validToText = validTo;
                const colSize = loadedInfoByIds.length === 1 ? 12 : 6;
                let selectedTags = tags.map(tid => allTagsByIds[tid]);

                selectedTags = selectedTags
                  .filter(t => typeof t !== 'undefined')
                  .map(tag => {
                    if (tag) {
                      return {
                        value: tag.value,
                        label: tag.label
                      };
                    }
                    return {};
                  });

                return (
                  <Col key={id} xl={colSize} style={{ marginBottom: '20px' }}>
                    <div>
                      <div>
                        <div className="gl-label gl-label--large gl-vspacing-s marginTop" />
                        {parseAssetName(name).length > maxChar && (
                          <Tooltip
                            position="bottom"
                            followCursor
                            html={<div className="tooltipWidth">{parseAssetName(name)}</div>}
                          >
                            <h5 className="breakWord leftAlignEditModal">
                              {shortenStringWithElipsis(parseAssetName(name), maxChar)}
                            </h5>
                          </Tooltip>
                        )}
                        {parseAssetName(name).length < maxChar && (
                          <h5 className="breakWord leftAlignEditModal">{parseAssetName(name)}</h5>
                        )}
                      </div>
                      <div className="desc__container leftAlignEditModal">
                        <GlTextarea
                          placeholder="Description"
                          value={description}
                          onChange={e => this.handleInfoChange(id, { description: e.target.value })}
                        />
                      </div>
                      <div className="tags__container leftAlignEditModal">
                        <div className="tags__header">
                          <div className="gl-label gl-label--large">Tags</div>
                        </div>
                        {tagsLoadError && (
                          <div className="validation-error">
                            Unable to load tags. Please try again.
                          </div>
                        )}
                        {tagsLoading && <div style={{ marginBottom: '10px' }}>Loading tags...</div>}
                        {allMainCategories && allMainCategories.length > 0 && (
                          <>
                            <div
                              className={`tags__selections ${
                                loadedInfoByIds.length > 1 ? 'tags__multiple' : ''
                              }`}
                            >
                              <h6 className="gl-label gl-label--large gl-vspacing-s marginTop">
                                Choose Categories:
                                <Tooltip title={UITEXT.mainCategoriesHelpText} position="bottom">
                                  <GlIcon
                                    name="alert-info"
                                    className="gl-icon--size-communication info-icon "
                                  />
                                </Tooltip>
                              </h6>
                              <div className="tags__maincategory">
                                {allMainCategories &&
                                  allMainCategories.map(cat => (
                                    <Badge
                                      categoryPill
                                      mediaId={id}
                                      key={cat.value}
                                      id={cat.value}
                                      label={cat.label}
                                      handleRemove={this.handleTagRemoval}
                                      selectedIcon={selectedMainCategories[id].includes(cat.value)}
                                      clickHandler={this.handleCategorySelection}
                                    />
                                  ))}
                              </div>
                              <div className="tags__subcategory">
                                <h6 className="gl-label gl-label--large gl-vspacing-s marginTop">
                                  Choose Tags:
                                  <Tooltip title={UITEXT.tagsHelpText} position="bottom">
                                    <GlIcon
                                      name="alert-info"
                                      className="gl-icon--size-communication info-icon "
                                    />
                                  </Tooltip>
                                </h6>
                                <CustomSelect
                                  backspaceRemovesValue={false}
                                  placeholder="Search and select tags"
                                  options={groupedOption[id] || []}
                                  selectedOption={selectedTags}
                                  memoKey={
                                    (selectedMainCategories[id] || []).join('') +
                                    (groupedOption[id] || []).map(opt => opt.label).join(',') +
                                    (selectedTags || []).map(opt => opt.label).join(',')
                                  }
                                  isMulti
                                  closeMenuOnSelect={false}
                                  noOptionsMessage={() =>
                                    'Select atleast one of the main categories above to populate tags'
                                  }
                                  onChange={sel => {
                                    this.handleInfoChange(id, {
                                      tags: sel.map(st => st.value)
                                    });
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                />
                              </div>
                            </div>

                            <div
                              className={`tags__selectedtags ${
                                loadedInfoByIds.length > 1 ? 'tags__multiple' : ''
                              }`}
                            >
                              <h6 className="gl-label gl-label--large gl-vspacing-s marginTop">
                                Selected Tags:
                              </h6>
                              <div className="tags__badges marginLeftTags">
                                {selectedTags &&
                                  selectedTags.map(tag => (
                                    <Badge
                                      id={tag.value}
                                      key={tag.value}
                                      label={tag.label}
                                      handleRemove={() => this.handleTagRemoval(id, tag.value)}
                                    />
                                  ))}
                              </div>
                            </div>
                            {loadedInfoByIds.length > 1 && (
                              <GlButton
                                tertiary
                                aria-label="apply to all"
                                onClick={() => this.applyToAll(['tags'], id)}
                                className="tags__container--button"
                              >
                                apply to all
                              </GlButton>
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <div className="validity-row leftAlignEditModal">
                          <div className="validity-row__header">
                            <div className="gl-label gl-label--large">Validity</div>
                          </div>
                          <div className="validity-row__column">
                            <div className="gl-label gl-label--large gl-vspacing-s marginTop">
                              Valid from:
                            </div>
                            <div className="marginLeftValidityDates">
                              <GlTooltip
                                label={formatDate(validFrom, datesTimes.scalaDateFormatPretty)}
                                size="large"
                              >
                                <DatePicker
                                  todayButton="Today"
                                  inline
                                  selected={validFrom}
                                  onChange={date => this.handleInfoChange(id, { validFrom: date })}
                                />
                              </GlTooltip>
                            </div>
                          </div>
                          <div className="validity-row__column">
                            <div className="gl-label gl-label--large gl-vspacing-s marginTop">
                              Valid to:
                            </div>
                            <div className="marginLeftValidityDates">
                              <GlTooltip
                                className={neverEnds ? 'date-hidden' : ''}
                                label={formatDate(validToText, datesTimes.scalaDateFormatPretty)}
                                size="large"
                              >
                                <DatePicker
                                  todayButton="Today"
                                  inline
                                  selected={validTo}
                                  onChange={date => this.handleInfoChange(id, { validTo: date })}
                                />
                              </GlTooltip>
                            </div>
                          </div>
                          <div className="validity-row__column">
                            <GlCheckbox
                              className="validity-row-input"
                              isChecked={neverEnds}
                              onChange={e =>
                                this.handleInfoChange(id, { neverEnds: e.target.checked })
                              }
                              label="Never ends"
                            />
                          </div>
                          {loadedInfoByIds.length > 1 && (
                            <div className="validity-row__applyAll">
                              <GlButton
                                tertiary
                                aria-label="Tertiary"
                                onClick={() =>
                                  this.applyToAll(['validFrom', 'validTo', 'neverEnds'], id)
                                }
                                className="validity-row__column--button"
                              >
                                Apply to all
                              </GlButton>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="workgroup__container leftAlignEditModal"
                        style={{ display: 'block' }}
                      >
                        <div className="">
                          <div className="validity-row__header">
                            <div className="gl-label gl-label--large">Workgroups</div>
                          </div>
                          <div className="validity-row__expandAll">
                            <Tooltip
                              position="bottom"
                              followCursor
                              html={<div className="tooltipWidth">{UITEXT.expandAllButton}</div>}
                            >
                              <GlButton
                                aria-label="label"
                                icon="arrow-down"
                                noBorder
                                onClick={() => this.expandAll(id)}
                              />
                            </Tooltip>
                            <Tooltip
                              position="bottom"
                              followCursor
                              html={<div className="tooltipWidth">{UITEXT.collapseAllButton}</div>}
                            >
                              <GlButton
                                aria-label="label"
                                icon="arrow-up"
                                noBorder
                                onClick={() => this.collapseAll(id)}
                              />
                            </Tooltip>
                          </div>
                          <div>
                            <CheckboxTreeContainer
                              id={id}
                              key={(selectedWorkgroupsMap[id] || []).join(',')}
                              initChecked={
                                (selectedWorkgroupsMap[id] &&
                                  selectedWorkgroupsMap[id].map(val => val.toString())) ||
                                null
                              }
                              data={workgroups}
                              checkHandler={selectedNodes => this.onCheckHandler(selectedNodes, id)}
                              showPlayersSelected={false}
                              isLCM={roles && !roles.includes('ROLE_ADMIN')}
                              requestArea="editMedia"
                              resourceId={id}
                              storePreviousIds={this.storeBaseWgIds}
                              flattenedNodes={this.flattenedNodes}
                              onExpandHanlder={this.onExpandHanlder}
                              expanded={(expanded[id] && expanded[id]) || {}}
                            />
                          </div>
                          {loadedInfoByIds.length > 1 && (
                            <div className="validity-row__applyAll">
                              <GlButton
                                tertiary
                                aria-label="Apply to all"
                                onClick={() => this.applyToAllWG(id)}
                                className="validity-row__column--button"
                              >
                                Apply to all
                              </GlButton>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>

          <p>&nbsp;</p>
          <div>
            <Tooltip position="bottom" title="Please select atleast one workgroup">
              <GlButton
                aria-label="Save"
                className="custom-modal__button custom-modal__button--primary"
                disabled={!isValid.name || disableSaveButton}
                onClick={() => {
                  this.saveAllItems();
                  this.submitForm();
                }}
              >
                Save
              </GlButton>
            </Tooltip>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => {
                clearCurrentFiles(loadedInfo);
                closeModal();
              }}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

MediaEdit2.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  closeModal: PropTypes.func,
  roles: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  preventReload: PropTypes.func,
  isReloadPrevented: PropTypes.bool,
  uploadingFilesData: PropTypes.func,
  uploadFilesWithData: PropTypes.func,
  clearCurrentFiles: PropTypes.func
};

MediaEdit2.defaultProps = {
  // refreshHandler() {}
};

const mapStateToProps = state => ({
  selectedItems: state.modals.activeModal.items,
  roles: state.auth.roles,
  name: state.auth.name,
  isReloadPrevented: state.modals.isDirty,
  k: state.upload
});

const mapDispatchToProps = {
  ...modalActions,
  uploadingFilesData: uploadingFilesDataAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaEdit2);
