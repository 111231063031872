import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash-es/get';
import PlaylistNewModal from '../Modals/PlaylistNewModal';
import DeleteModal from '../Modals/DeleteModal';
import ShowUsageModal from '../Modals/ShowUsageModal';
import DuplicatePlaylistModal from '../Modals/DuplicatePlaylistModal';
import SelectMediaToPlaylists from '../Modals/SelectMediaToPlaylists';
import PreviewItemsModal from '../Modals/PreviewItemsModal';
import PlaylistEditModal from '../Modals/PlaylistEditModal';
import Table from '../commons/Table/Table';
import { getQueryParamsForTable, filterOutPlaylists } from '../../helpers/utils';
import { formatApiPlaylist, tableSchema } from './schema';
import { selectItems as selectItemsAction } from '../../actions/table';
import { pageTitle } from '../../helpers/pageTitles';
import { closeModal as closeModalAction } from '../../actions/modal';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistsApi } from '../../serviceLayer/services';

class PlaylistAll extends Component {
  state = {
    isFetching: true,
    limit: 20,
    list: [],
    sort: {
      by: 'lastModified',
      order: 'desc'
    },
    pageInfo: {
      total: 1,
      current: 1
    },
    filtered: {},
    isError: false
  };

  componentDidMount() {
    const { setTitle, preSelectedItems } = this.props;
    document.title = pageTitle.playlist;
    if (setTitle) {
      setTitle();
    }

    this.initData();

    const { selectItems, tableId } = this.props;

    if (tableId !== 'dashboard:2') {
      preSelectedItems ? selectItems(tableId, preSelectedItems) : selectItems(tableId, []);
    }
  }

  activeModal = modalId => {
    const { selectItems, tableId, closeModal } = this.props;

    switch (modalId) {
      case 'selectMediaToPlaylists':
        return (
          <SelectMediaToPlaylists
            refreshHandler={() => {
              selectItems(tableId, []);
              this.fetchPlaylist();
            }}
          />
        );
      case 'newPlaylist':
        return <PlaylistNewModal />;
      case 'deleteItems':
        return (
          <DeleteModal
            refreshHandler={() => {
              selectItems(tableId, []);
              this.fetchPlaylist();
            }}
          />
        );
      case 'editPlaylist':
        return (
          <PlaylistEditModal
            closeHandler={() => {
              selectItems(tableId, []);
              this.fetchPlaylist();
            }}
          />
        );
      case 'duplicatePlaylist':
        return (
          <DuplicatePlaylistModal
            closeModal={() => closeModal()}
            closeHandler={() => {
              selectItems(tableId, []);
              this.fetchPlaylist();
            }}
          />
        );
      case 'showUsage':
        return <ShowUsageModal />;
      case 'previewItems':
        return <PreviewItemsModal />;
      default:
        return null;
    }
  };

  fetchPlaylist = async (data = {}) => {
    const { limit, sort: sortState, filtered: filterState } = this.state;
    const page = data.page ? data.page : 1;
    const sort = data.sort || sortState;
    const filtered = data.filtered || filterState;
    const { by: sortBy, order: sortOrder } = sort;

    this.setState({
      isFetching: true,
      isError: false,
      list: []
    });
    const body = {
      size: limit,
      sortBy,
      sortOrder,
      page,
      filters: filtered
    };

    getAccessTokenAndMakeCalls(token => callGetPlaylistsApi(token, body))
      .then(res => {
        const playlistCount = res.count;
        const { tableId } = this.props;
        const thelist = filterOutPlaylists(get(res, 'list', []));
        this.setState(next => ({
          ...next,
          list: formatApiPlaylist(thelist, tableId),
          isFetching: false,
          pageInfo: {
            ...next.pageInfo,
            current: page,
            total: Math.ceil(playlistCount) || 0
          },
          filtered,
          sort
        }));
      })
      .catch(e => {
        console.log(e);
        if (!e.canceled) {
          this.setState({ isFetching: false, isError: true, list: [] });
        }
      });
  };

  initData() {
    const { history, tableId } = this.props;
    const queryParams = getQueryParamsForTable(tableId, history);
    const { filtered } = queryParams;
    if (filtered != null) {
      this.setState({ filtered });
    }

    this.fetchPlaylist(queryParams);
  }

  render() {
    const { tableId, activeModalId, selectedPlaylists, preSelectedItems, className } = this.props;
    const { sort, list: playlistData, isFetching, pageInfo, filtered, isError } = this.state;
    const showModals = tableId !== 'selectsubPlaylistsToPlaylists';
    const selectedPlaylistsIds =
      selectedPlaylists && selectedPlaylists.map(pl => Number(pl.id) || 0);
    let newPlaylistData = playlistData;

    if (tableId === 'selectsubPlaylistsToPlaylists') {
      newPlaylistData = playlistData.map(pl => {
        if (selectedPlaylistsIds.indexOf(pl.id) >= 0) {
          pl.disabled = true;
          pl.hideCheckbox = true;
        }
        return pl;
      });
    }

    return (
      <>
        <div className="generic-container">
          <h1 className="gl-heading--m page-title" data-testid="playlists-page-title">
            Playlist Library
          </h1>
          <Table
            activeLibrary="playlists"
            id={tableId}
            className={className}
            tableData={newPlaylistData}
            tableSchema={tableSchema}
            tableId={tableId}
            sortHandler={this.fetchPlaylist}
            filterHandler={this.fetchPlaylist}
            filteredInit={filtered}
            isFilterable
            isSelectable
            hasColumnOptions
            hasQueryParamsHandling
            isFetching={isFetching}
            isError={isError}
            sort={sort}
            pageInfo={pageInfo}
            paginationHandler={this.fetchPlaylist}
            preSelectedItems={preSelectedItems}
          />
        </div>
        {showModals ? this.activeModal(activeModalId) : null}
      </>
    );
  }
}

PlaylistAll.propTypes = {
  tableId: PropTypes.string,
  activeModalId: PropTypes.string,
  selectItems: PropTypes.func,
  setTitle: PropTypes.func,
  location: PropTypes.shape({}),
  history: PropTypes.shape({}),
  closeModal: PropTypes.func
};

PlaylistAll.defaultProps = {
  tableId: 'playlists'
};

const mapStateToProps = state => ({
  activeModalId: state.modals.activeModal.modalId
});

const mapDispatchToProps = {
  selectItems: selectItemsAction,
  closeAction: closeModalAction
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlaylistAll)
);
