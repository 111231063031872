/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton, GlToggle } from '@adl/foundation';
import { Tooltip } from 'react-tippy';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import produce from 'immer';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import Table from '../commons/Table/Table';
import * as modalActions from '../../actions/modal';
import { selectItems as selectItemsAction } from '../../actions/table';
import { UITEXT } from '../../helpers/misc';
import MediaList from '../MediaList/MediaList';
import PlaylistAll from '../PlaylistAll/PlaylistAll';

import './Modals.scss';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callAddSubPlaylistsApi,
  callGetPlaylistApi,
  callUpdatePlaylistMediaApi
} from '../../serviceLayer/services';

const tableId = 'selectMediaToPlaylists';
const tableId2 = 'selectsubPlaylistsToPlaylists';
// const tableId3 = 'selectMediaToPlaylists selectMediaToPlaylists-height';

class SelectMediaToPlaylists extends Component {
  state = {
    isAdding: false,
    addingCompleted: false,
    successItems: [],
    errorItems: [],
    notAuthToDeleteItems: [],
    playlistName: '',
    toggleValue: '0',
    addsubPlaylistResult: {},
    closedIconClicked: false
  };

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.handlePageRefresh);

    const { selectedPlaylists, selectItems } = this.props;
    selectItems(tableId, []);
    selectItems(tableId2, []);
    if (selectedPlaylists.length === 1) {
      this.getPlaylistName(selectedPlaylists);
    }
  };

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  addItems = () => {
    const { selectedItems, selectedPlaylists, selectedsubPlaylists } = this.props;

    this.setState({ isAdding: true });
    const selectedItemsIds = selectedItems.map(item => item.id);
    const selectedsubPlaylistIds = selectedsubPlaylists.map(subPl => subPl.id).reverse();
    const selectedPlaylistsIds = selectedPlaylists.map(item => item.id);
    // selectedsubPlaylistIds.push(12121121);
    // selectedItemsIds.push(1212112);

    selectedsubPlaylists &&
      selectedsubPlaylists.length > 0 &&
      Promise.all(
        selectedPlaylistsIds.map(sPl =>
          getAccessTokenAndMakeCalls(token =>
            callAddSubPlaylistsApi(token, {
              subPlaylistId: sPl,
              subPlaylistIds: selectedsubPlaylistIds
            })
          )
            .then(apiResponse => {
              const result = apiResponse;
              this.setState(next =>
                produce(next, draft => {
                  draft.addsubPlaylistResult[sPl] = result;
                })
              );
            })
            .catch(error => {
              if (
                error &&
                error.response &&
                (error.response.status === 401 || error.response.status === 402)
              ) {
                this.setState(next =>
                  produce(next, draft => {
                    draft.notAuthToDeleteItems.push(sPl);
                  })
                );
              }
              this.setState(next =>
                produce(next, draft => {
                  draft.isAdding = false;
                  draft.addingCompleted = true;
                })
              );
            })
        )
      ).then(() => {
        if (selectedItemsIds && selectedItemsIds.length === 0) {
          this.setState({ isAdding: false, addingCompleted: true });
        }
      });

    selectedItemsIds &&
      selectedItemsIds.length > 0 &&
      Promise.all(
        selectedPlaylistsIds.map(pid =>
          getAccessTokenAndMakeCalls(token =>
            callUpdatePlaylistMediaApi(token, {
              id: pid,
              items: selectedItemsIds
            })
          )
            .then(result => {
              this.setState(next =>
                produce(next, draft => {
                  draft.successItems.push(pid);
                })
              );
            })
            .catch(error => {
              if (
                error &&
                error.response &&
                (error.response.status === 401 || error.response.status === 402)
              ) {
                this.setState(next =>
                  produce(next, draft => {
                    draft.notAuthToDeleteItems.push(pid);
                  })
                );
              }
              this.setState(next =>
                produce(next, draft => {
                  draft.isAdding = false;
                  draft.addingCompleted = true;
                  draft.errorItems.push(pid);
                })
              );
            })
        )
      ).then(() => {
        this.setState({ isAdding: false, addingCompleted: true });
      });
  };

  formatTableItems = (items = []) => {
    const {
      playlistName,
      addsubPlaylistResult,
      successItems,
      errorItems,
      notAuthToDeleteItems
    } = this.state;
    const { selectedsubPlaylists, selectedItems } = this.props;
    const result = [];
    items.forEach((item, rowIndex) => {
      const displayName = items.length === 1 ? playlistName : item.name;
      // console.log('item result', addsubPlaylistResult[item.id], successItems, errorItems);
      let isALLSuccessFul = false;
      if (selectedItems.length > 0) {
        if (successItems.includes(item.id)) {
          if (addsubPlaylistResult[item.id]) {
            isALLSuccessFul = Object.values(addsubPlaylistResult[item.id]).every(
              val => val && val === 'SUCCESS'
            );
          } else {
            isALLSuccessFul = true;
          }
        }
      } else if (addsubPlaylistResult[item.id]) {
        isALLSuccessFul = Object.values(addsubPlaylistResult[item.id]).every(
          val => val && val === 'SUCCESS'
        );
      }

      let isALLError = false;
      if (selectedItems.length > 0) {
        // Media is selected.
        if (errorItems.includes(item.id)) {
          // Media api gives error.
          if (addsubPlaylistResult[item.id]) {
            // If subPL is also added.
            isALLError = Object.values(addsubPlaylistResult[item.id]).every(
              val => val && val === 'FAILED'
            );
          } else {
            isALLError = true;
          }
        }
      } else if (addsubPlaylistResult[item.id]) {
        isALLError = Object.values(addsubPlaylistResult[item.id]).every(
          val => val && val === 'FAILED'
        );
      }
      // console.log('isall success/ error', isALLSuccessFul, isALLError);
      const failedSubPlaylists = [];
      addsubPlaylistResult[item.id] &&
        Object.entries(addsubPlaylistResult[item.id])
          .filter(([subPL, subPLResult]) => subPLResult === 'FAILED')
          .forEach(([subPL, subPLResult]) => {
            const failedSubPLData = selectedsubPlaylists.filter(
              sPLData => sPLData.id === Number(subPL)
            );
            if (failedSubPLData.length > 0) {
              const subPLname =
                failedSubPLData[0] && failedSubPLData[0].name ? failedSubPLData[0].name : subPL;
              failedSubPlaylists.push([subPLname, subPLResult]);
            }
          });

      // errorItems.includes(item.id) && selectedItems.map(media => console.log(media.id, media.name));
      // let status = ''; // <span className="validation-success">added</span>;
      let newStatus = '';
      if (isALLSuccessFul) {
        newStatus = <span className="validation-success">Added</span>;
      } else if (notAuthToDeleteItems.indexOf(item.id) > -1) {
        newStatus = <span className="validation-error">Error</span>;
      } else {
        newStatus = (
          <div style={{ marginBottom: '5px' }}>
            <>
              {isALLError ? (
                <span className="validation-error">
                  No items added. Please find the summary below:
                </span>
              ) : (
                <span className="validation-success">
                  All items are successfully added except the following:
                </span>
              )}
            </>
            <br />
            <div>
              <ul style={{ listStyleType: 'disc', marginLeft: '10px' }}>
                {failedSubPlaylists.map(([subPL, subPLResult]) => (
                  <li
                    className={!isALLError ? 'validation-error' : ''}
                    style={{ marginLeft: '10px', fontSize: '13px' }}
                  >
                    {subPL}(p) {subPLResult}
                  </li>
                ))}
              </ul>
              <ul style={{ listStyleType: 'disc', marginLeft: '10px' }}>
                {errorItems.includes(item.id) &&
                  selectedItems.map(media => (
                    <li
                      className="validation-error"
                      style={{ marginLeft: '10px', fontSize: '13px' }}
                    >
                      {media.name || 'Media'}(m) Error
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        );
      }
      result[rowIndex] = {
        id: item.id,
        name: (
          <NavLink
            className="ellipsis"
            target="_blank"
            to={`/playlists/${item.id}${'?newTab=true'}`}
          >
            <div className="core-green__main">{displayName}</div>
          </NavLink>
        ),
        status: newStatus
      };
    });

    return result;
  };

  getPlaylistName = selectedPlaylists => {
    const playlistId = selectedPlaylists.map(item => item.id);
    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId }))
      .then(res => {
        this.setState({ playlistName: res.name });
      })
      .catch(() => {
        console.log('err');
      });
  };

  changeToggle = e => {
    this.setState({ toggleValue: e.target.value });
  };

  showConfirmationModal = () => {
    const { closeModal } = this.props;
    return (
      <div className="customized-modal">
        <div className="customized-modal-body">
          <div className="customized-modal-container">
            <h5>{UITEXT.confirmation}</h5>
            <div style={{ padding: '5px 0px 15px' }}>
              {UITEXT.navigationConfirmationOnAddingItemsToPlaylist}
            </div>
            <GlButton
              aria-label="Save"
              className="custom-modal__button custom-modal__button--primary"
              onClick={() => closeModal()}
            >
              {UITEXT.confirm}
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => this.setState({ closedIconClicked: false })}
            >
              {UITEXT.cancel}
            </GlButton>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isAdding, addingCompleted, playlistName, toggleValue, closedIconClicked } = this.state;
    const {
      selectedItems,
      closeModal,
      selectedPlaylists,
      refreshHandler,
      isReloadPrevented,
      selectedsubPlaylists
    } = this.props;
    const showResultModal = true; // errorItems && errorItems.length > 0;
    const tableSchema = [
      {
        id: 'name',
        title: 'Playlist'
      },
      {
        id: 'status',
        title: 'Status'
      }
    ];

    if (isAdding) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    const toggleItems = [
      `Media ${selectedItems.length > 0 ? `(${selectedItems.length})` : ''}`,
      `Playlist ${selectedsubPlaylists.length > 0 ? `(${selectedsubPlaylists.length})` : ''}`
    ];

    if (addingCompleted && !showResultModal) {
      refreshHandler();
      closeModal();
      return null;
    }
    // console.log('Render', selectedItems, selectedsubPlaylists, selectedPlaylists);
    if (addingCompleted && showResultModal) {
      return (
        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            refreshHandler();
            closeModal();
          }}
        >
          <h4>
            Summary of adding items to {selectedPlaylists.length > 1 ? 'playlists' : 'playlist'}
          </h4>
          <div className="assets">
            {selectedItems && selectedItems.length > 0 ? (
              <div className="selected-media">
                <strong>Selected Media:</strong>
                <ul className="selected-items marginLeft2">
                  {selectedItems.map(item => (
                    <li key={item.id}>
                      <div>{item.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {selectedsubPlaylists && selectedsubPlaylists.length > 0 ? (
              <div
                className={selectedItems.length > 0 ? 'selected-sub-playlists' : 'selected-media'}
              >
                <strong>Selected Subplaylists:</strong>
                <ul className="selected-items  marginLeft2">
                  {selectedsubPlaylists.map(item => (
                    <li key={item.id}>
                      <NavLink
                        to={`/playlists/${item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="core-green__main">{item.name}</div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="addition-summary">
            <Table
              tableData={this.formatTableItems(selectedPlaylists)}
              className="addition-summary-table"
              isFilterable
              tableSchema={tableSchema}
              tableId="results"
            />
          </div>
        </Modal>
      );
    }
    return (
      <>
        <Modal
          className="custom-modal--fullscreen"
          open
          size="large"
          enableOutsideClick
          modalCloseHandler={() => {
            if (!isReloadPrevented) {
              closeModal();
            }
            this.setState({ closedIconClicked: true });
          }}
        >
          <>
            {isReloadPrevented && closedIconClicked && this.showConfirmationModal()}
            <div className="modal-header select-items-header">
              <h4>Add items to {selectedPlaylists.length > 1 ? 'playlists' : 'playlist'}</h4>
              <span className="secondary-info">
                {selectedItems.length > 0 || selectedsubPlaylists.length > 0 ? 'Selected ' : ''}
                {selectedItems.length > 0 ? `${selectedItems.length} media` : ''}
                {selectedItems.length > 0 && selectedsubPlaylists.length > 0 ? ', ' : ''}
                {selectedsubPlaylists.length > 0
                  ? selectedsubPlaylists.length === 1
                    ? `${selectedsubPlaylists.length} playlist`
                    : `${selectedsubPlaylists.length} playlists`
                  : null}
              </span>
            </div>
            {/* <div>
            {toggleValue === '0'
              ? 'Please select media from Media Library below to add to the following playlist:'
              : 'Please select subplaylist from Playlist Library below to add to the following playlist:'}
          </div> */}
            <div>
              <ul
                className={`selected-items selected-items-list multi-column-list${
                  selectedPlaylists.length > 4 ? ' multi-column-list-height' : ''
                }`}
              >
                {selectedPlaylists.length === 1 ? (
                  <li>
                    <strong>{playlistName}</strong>
                  </li>
                ) : (
                  selectedPlaylists.map(item => (
                    <li key={item.id}>
                      <Tooltip
                        position="bottom"
                        followCursor
                        html={<div className="tooltipWidth">{item.name}</div>}
                      >
                        <div className="truncate-items-list">{item.name}</div>
                      </Tooltip>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div style={{ display: 'flex' }}>
              {toggleItems && (
                <>
                  <div className="select-frame">Select Library</div>
                  <GlToggle
                    items={toggleItems}
                    onChange={this.changeToggle}
                    value={toggleValue || '0'}
                  />
                </>
              )}
            </div>
            <div>
              {toggleValue === '0' ? (
                <MediaList
                  tableId={tableId}
                  className={selectedPlaylists.length > 4 ? ' selectMediaToPlaylists-height' : ''}
                  preSelectedItems={selectedItems}
                />
              ) : (
                <PlaylistAll
                  tableId={tableId2}
                  className={
                    selectedPlaylists.length > 4 ? ' selectsubPlaylistsToPlaylists-height' : ''
                  }
                  selectedPlaylists={selectedPlaylists}
                  preSelectedItems={selectedsubPlaylists}
                />
              )}
            </div>
            <div
              className={`buttonsR fixed-buttons${
                selectedPlaylists.length > 4 ? ' fixed-buttons-height' : ''
              }`}
            >
              <GlButton
                disabled={selectedItems.length === 0 && selectedsubPlaylists.length === 0}
                aria-label="Save"
                className="custom-modal__button custom-modal__button--primary"
                onClick={() => this.addItems()}
              >
                Save
              </GlButton>
              <GlButton
                secondary
                aria-label="Cancel"
                className="custom-modal__button"
                onClick={() => closeModal()}
              >
                Cancel
              </GlButton>
            </div>
          </>
        </Modal>
      </>
    );
  }
}

SelectMediaToPlaylists.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  selectedPlaylists: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  selectItems: PropTypes.func,
  refreshHandler: PropTypes.func,
  preventReload: PropTypes.func,
  isReloadPrevented: PropTypes.bool,
  selectedsubPlaylists: PropTypes.arrayOf(PropTypes.object)
};

SelectMediaToPlaylists.defaultProps = {
  refreshHandler() {}
};

const mapStateToProps = state => ({
  selectedItems: state.table[tableId].selected,
  selectedPlaylists: state.modals.activeModal.items,
  isReloadPrevented:
    state.table[tableId].selected.length > 0 || state.table[tableId2].selected.length > 0,
  selectedsubPlaylists: state.table[tableId2].selected
});

const mapDispatchToProps = {
  ...modalActions,
  selectItems: selectItemsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectMediaToPlaylists);
