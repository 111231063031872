import React, { Component } from 'react';
import Papa from 'papaparse';
import get from 'lodash-es/get';
import { GlButton } from '@adl/foundation';

import StrayAssets from './StrayAssets';
import './Metadata.scss';
import { getAccessTokenAndMakeCalls } from '../../../serviceLayer/utils';
import {
  callGetPlayersSearchApi,
  callUpdatePlayerMetadataApi
} from '../../../serviceLayer/services';

class Metadata extends Component {
  state = {
    players: {},
    playersByNames: [],
    mapping: {
      BCS: 10182275,
      City: 10182269,
      Country: 10182268,
      Environment: 10182273,
      Market: 10182267,
      Resolution: 10182271,
      'SSM ID': 10182279,
      'Store/Building/Event Name': 10182270,
      Storecode: 10230714,
      'Use Case': 10182274,
      'TV ID': 10230655,
      'TV Password': 10230656,
      '2nd Level Support Vendor': 10235809,
      Hostname: 10261481
    },
    meta: {},
    failedItems: [],
    errorFetchingPlayers: false,
    page: 'showStrayAssets'
  };

  componentDidMount() {
    this.getPlayers();
  }

  formatMetadata = playerName => {
    const { mapping, players, meta } = this.state;
    const playerInfo = players[playerName];
    const metas = [];
    Object.keys(mapping).forEach(m => {
      metas.push({
        value: meta[playerName][m],
        playerMetadata: { id: mapping[m] }
      });
    });
    return { ...playerInfo, metadataValue: metas };
  };

  getPlayers = async () =>
    getAccessTokenAndMakeCalls(token =>
      callGetPlayersSearchApi(token, {
        params: {
          limit: 2000,
          listOnly: true,
          count: 0
        }
      })
    )
      .then(res => {
        const list = get(res, 'list', []);
        const newPlayers = {};
        const playersByNames = [];
        const playersByIds = [];
        list.forEach(item => {
          const itemName = item.name.split('_NoMonitoring')[0].trim();
          // const itemName = item.name;
          playersByNames.push(itemName);
          playersByIds.push(item.id);
          newPlayers[itemName] = item;
        });
        // console.log('TCL: Metadata -> newPlayers', newPlayers);
        this.setState({ players: newPlayers, playersByNames });
      })
      .catch(() => {
        this.setState({ errorFetchingPlayers: true });
      });

  updatePlayer = playerName => {
    const playerInfo = this.formatMetadata(playerName);
    getAccessTokenAndMakeCalls(token => callUpdatePlayerMetadataApi(token, playerInfo))
      .then(() => {
        this.setState(prev => ({
          players: {
            ...prev.players,
            [playerName]: {
              ...prev.players[playerName],
              updated: true
            }
          }
        }));
      })
      .catch(() => {
        this.setState(prev => ({
          players: {
            ...prev.players,
            [playerName]: {
              ...prev.players[playerName],
              error: true
            }
          },
          failedItems: [...prev.failedItems, playerName]
        }));
      });
  };

  parseFile = async event => {
    event.persist();
    const { mapping, players } = this.state;

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    const scope = this;
    reader.onload = function(e) {
      const readcsv = e.target.result;
      const { data: parsed } = Papa.parse(readcsv, {
        header: true
      });
      const add = { ...parsed[40], Hostname: 'IBRAHIM_TEST' };
      parsed.push(add);

      const meta = {};
      const parsedMeta = [];

      const mapKeys = Object.keys(mapping);

      const notOnScala = [];

      parsed.forEach(row => {
        const playerName = row.Hostname;

        if (!players[playerName]) {
          notOnScala.push(playerName);
        }

        parsedMeta.push(playerName);

        mapKeys.forEach(col => {
          if (row[col]) {
            meta[playerName] = meta[playerName] || {};
            meta[playerName][col] = row[col];
          }
        });
      });
      scope.setState({ parsed: parsed.length > 0, meta, parsedMeta });
    };
    reader.readAsText(file);
  };

  render() {
    const {
      playersByNames,
      players,
      parsed,
      meta,
      mapping,
      errorFetchingPlayers,
      page
    } = this.state;

    const loadingPlayers = errorFetchingPlayers
      ? 'Error loading players. Please reload page again.'
      : 'Loading players...';

    return (
      <div className="generic-container">
        <h3>
          Update metadata
          <span style={{ float: 'right' }}>
            {playersByNames.length > 0 && (
              <div className="metadata-buttons">
                <GlButton
                  className="metadata-buttons__button"
                  tertiary
                  onClick={() => {
                    this.setState({ page: 'showStrayAssets' });
                  }}
                >
                  Show player information
                </GlButton>
                <GlButton
                  className="metadata-buttons__button"
                  tertiary
                  onClick={() => {
                    this.setState({ page: 'showMetadata' });
                  }}
                >
                  Update player metadata
                </GlButton>
              </div>
            )}
          </span>
        </h3>

        {playersByNames.length > 0 ? (
          <div>
            {page === 'showMetadata' && (
              <div>
                <input
                  id="upload"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={event => {
                    this.parseFile(event);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          loadingPlayers
        )}

        {parsed && playersByNames.length > 0 && page === 'showMetadata' && (
          <>
            <table className="gl-table gl-table--horizontal">
              <thead>
                <tr>
                  <td>Display Name</td>
                  <td>#</td>

                  {Object.keys(mapping).map(m => (
                    <td key={m}>{m}</td>
                  ))}
                  <td />
                </tr>
              </thead>

              <tbody>
                {playersByNames.map(player => {
                  if (!meta[player]) {
                    // console.log('not found in meta', player);
                    return null;
                  }

                  return (
                    <tr key={player}>
                      <td>{players[player].name}</td>
                      <td>{players[player].id}</td>
                      {Object.keys(mapping).map(m => (
                        <td key={m}>{meta[player][m]}</td>
                      ))}
                      <td>
                        {players[player].updated && (
                          <span className="meta-status validation-success">Updated</span>
                        )}
                        {players[player].error && (
                          <span className="meta-status validation-error">Couldnt update</span>
                        )}
                        {!players[player].updated && !players[player].error && (
                          <GlButton secondary onClick={() => this.updatePlayer(player)}>
                            Update
                          </GlButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        {page === 'showStrayAssets' && playersByNames.length > 0 && (
          <StrayAssets players={players} playersByNames={playersByNames} />
        )}
      </div>
    );
  }
}

export default Metadata;
