import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GlButton } from '@adl/foundation';
import * as modalActions from '../../actions/modal';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import Table from '../commons/Table/Table';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callUserDeletionApi } from '../../serviceLayer/services';

class UserDeletion extends Component {
  state = {
    inProgress: false,
    responseMessage: '',
    successIds: [],
    failedIds: [],
    isDeleteCompleted: false,
    isRetryCompleted: false,
    successErrorMessage: 'User(s) deleted successfully',
    successErrorClass: 'validation-success',
    selectedInitialUsers: []
  };

  componentDidMount() {
    this.setState({ selectedInitialUsers: this.props.selectedUsers });
  }

  deleteUsers = deleteButtonVal => {
    const { fetchUsers } = this.props;
    this.setState({
      inProgress: true
    });
    const userIds =
      deleteButtonVal === 'Retry'
        ? this.state.failedIds.join()
        : this.props.selectedUsers.map(selectedUser => selectedUser.id).toString();
    let failureUsersData = this.state.selectedInitialUsers;
    if (deleteButtonVal === 'Retry') {
      const failedIds = this.state.failedIds;
      failureUsersData = this.state.selectedInitialUsers.filter(userData =>
        failedIds.includes(userData.id)
      );
    }
    // api call to delete users
    getAccessTokenAndMakeCalls(token =>
      callUserDeletionApi(token, {
        userIds
      })
    )
      .then(res => {
        const sIds =
          deleteButtonVal === 'Retry'
            ? [...this.state.successIds, ...res.successIds]
            : res.successIds;
        this.setState({
          inProgress: false,
          successIds: sIds,
          failedIds: res.failedIds,
          isDeleteCompleted: true,
          isRetryCompleted: deleteButtonVal === 'Retry' && true,
          selectedInitialUsers: failureUsersData
        });
        fetchUsers();
      })
      .catch(error => {
        this.setState({
          inProgress: false,
          isDeleteCompleted: true,
          successErrorMessage: 'There is some issue while deleting user(s)',
          successErrorClass: 'validation-error'
        });
      });
  };

  formatTableItems = () => {
    const result = [];
    this.state.selectedInitialUsers.forEach((selectedUser, rowIndex) => {
      result[rowIndex] = {
        id: selectedUser.id,
        name: (
          <div className="ellipsis" title={selectedUser.label}>
            {selectedUser.label}
          </div>
        ),
        status: this.state.successIds.includes(selectedUser.id) ? (
          <span className="validation-success">Deleted</span>
        ) : (
          <span className="validation-error">Error</span>
        )
      };
    });
    return result;
  };

  render() {
    const { closeModal, isReloadPrevented, selectedUsers } = this.props;
    const {
      inProgress,
      failedIds,
      isDeleteCompleted,
      isRetryCompleted,
      successErrorMessage,
      successErrorClass
    } = this.state;
    const tableSchema = [
      {
        id: 'name',
        title: 'Users'
      },
      {
        id: 'status',
        title: 'Status'
      }
    ];
    if (isDeleteCompleted) {
      if (failedIds.length === 0) {
        return (
          <Modal
            size="medium"
            open
            enableOutsideClick
            modalCloseHandler={() => {
              if (isReloadPrevented) {
                return;
              }
              closeModal();
            }}
          >
            <h4>DELETE USER(S)</h4>
            <p className={successErrorClass}>
              <strong>{successErrorMessage}</strong>
            </p>
          </Modal>
        );
      }

      return (
        <Modal
          size="medium"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented) {
              return;
            }
            closeModal();
          }}
        >
          <h4>DELETE USER(S)</h4>
          <p>Please check the status below:</p>
          <Table
            tableData={this.formatTableItems()}
            isFilterable
            tableSchema={tableSchema}
            tableId="user-deletion-status-table"
          />
          {!isRetryCompleted && (
            <div className="custom-modal__button-container">
              <GlButton
                aria-label="Create User"
                className="custom-modal__button custom-modal__button--primary"
                onClick={() => this.deleteUsers('Retry')}
              >
                Retry
              </GlButton>
            </div>
          )}
          {inProgress && (
            <Modal size="small" open modalCloseHandler={() => {}}>
              <Loader />
            </Modal>
          )}
        </Modal>
      );
    }
    return (
      <Modal
        size="medium"
        open
        enableOutsideClick
        modalCloseHandler={() => {
          if (isReloadPrevented) {
            return;
          }
          closeModal();
        }}
      >
        <h4>CONFIRM DELETION</h4>
        <strong> Delete these user(s)?</strong>
        <br />
        <p>You cannot undo this action!</p>
        <ul>
          {selectedUsers.map(user => (
            <li key={user.value}>{user.label}</li>
          ))}
        </ul>
        <div className="custom-modal__button-container">
          <GlButton
            aria-label="Create User"
            className="custom-modal__button custom-modal__button--primary"
            onClick={() => this.deleteUsers('Delete')}
          >
            Delete
          </GlButton>
          <GlButton
            secondary
            aria-label="Cancel"
            className="custom-modal__button"
            onClick={closeModal}
          >
            Cancel
          </GlButton>
        </div>
        {inProgress && (
          <Modal size="small" open modalCloseHandler={() => {}}>
            <Loader />
          </Modal>
        )}
      </Modal>
    );
  }
}
UserDeletion.propTypes = {
  closeModal: PropTypes.func,
  isReloadPrevented: PropTypes.bool,
  preventReload: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.object),
  fetchUsers: PropTypes.func
};
const mapState = state => ({
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  ...modalActions
};

export default connect(
  mapState,
  mapDispatchToProps
)(UserDeletion);
