import React, { Component } from 'react';

class FramesPreview extends Component {
  state = {};

  render() {
    const { frames, screenWidth, screenHeight } = this.props;
    const largerDimension = screenWidth > screenHeight ? screenWidth : screenHeight; //this becomes 100%
    const screenWidthPercentage = (screenWidth / largerDimension) * 100;
    const screenHeightPercentage = (screenHeight / largerDimension) * 100;
    const viewBox = '337.25px'; //  not sure how it is calculated as per resolution in scala, keeping const
    return (
      <div style={{ maxWidth: viewBox, maxHeight: viewBox, width: viewBox, height: viewBox, position: 'relative', marginTop: '15px', overflow: 'hidden' }}>
        <div style={{ width: screenWidthPercentage + '%', height: screenHeightPercentage + '%', background: 'black' }}>
          {frames.map((frame, index) => {
            // calc the % of width and height in comparison to largerDimension
            const width = (Number(frame.width) / largerDimension) * 100;
            const height = (Number(frame.height) / largerDimension) * 100;
            const left = (Number(frame.left) / largerDimension) * 100;
            const top = (Number(frame.top) / largerDimension) * 100;
            return (
              <div
                key={index}
                style={{
                  background: frame.color,
                  width: width + '%',
                  height: height + '%',
                  left: left + '%',
                  top: top + '%',
                  position: 'absolute',
                  padding: '3px'
                }}
              >
                <div style={{width: 'fit-content', height: 'fit-content', background: 'white'}}>{frame.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default FramesPreview;
