import React, { Component } from 'react';
import { GlInput, GlButton, GlRadioGroup } from '@adl/foundation';
import Loader from '../commons/Loader/Loader';
import Dropdown from '../commons/Dropdown/Dropdown';
import './Modals.scss';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetTemplateFieldsApi, callCreateMessageApi } from '../../serviceLayer/services';

class CreateMessageModel extends Component {
  state = {
    name: '',
    loading: false,
    responseMessage: '',
    responseMessageSuccess: false,
    responseClass: null,
    templateFields: [],
    disableSubmit: true,
    errorMessages: {}
  };

  componentDidMount() {
    const { selectedTemplateId } = this.props;
    this.setState({
      loading: true
    });
    getAccessTokenAndMakeCalls(token =>
      callGetTemplateFieldsApi(token, { id: selectedTemplateId[0].id })
    )
      .then(res => {
        this.setState({
          loading: false,
          templateFields: res
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
      });
  }

  createMessage = () => {
    this.setState({
      loading: true
    });
    const { selectedTemplateId, refreshHandler } = this.props;
    const { name, templateFields } = this.state;
    const body = {
      name,
      template: {
        id: selectedTemplateId[0].id,
        newMsgFlag: true,
        name: selectedTemplateId[0].name
      },
      fields: templateFields
    };
    getAccessTokenAndMakeCalls(token => callCreateMessageApi(token, body))
      .then(res => {
        this.setState({
          loading: false,
          responseMessageSuccess: 'Message created successfully',
          responseClass: 'validation-success'
        });
        refreshHandler();
      })
      .catch(error => {
        this.setState({
          responseMessage:
            (error && error.response && error.response.description) ||
            (error.message === 'Network Error'
              ? 'There was a network error'
              : 'There is some issue while creating message'),
          responseClass: 'validation-error',
          loading: false
        });
      });
  };

  handleOnChange = event => {
    const value = event.target.value;
    const errorMessages = this.state;
    this.setState({
      name: value,
      disableSubmit:
        value.length === 0 ||
        (!Object.keys(errorMessages).length === 0 && Object.values(errorMessages).includes(true))
    });
  };

  handleOnChangeFields(value, key, param, required, type) {
    const updatedTemplateFields = this.state.templateFields;
    const updatedErrorMessages = this.state.errorMessages;
    updatedTemplateFields[key][param] = value;
    updatedErrorMessages[key] =
      (required && value.length === 0) || (!!type && this.renderErrorMessage(value, type));

    this.setState({
      templateFields: updatedTemplateFields,
      disableSubmit:
        Object.values(updatedErrorMessages).includes(true) || this.state.name.length === 0,
      errorMessages: updatedErrorMessages
    });
  }

  required() {
    return <span className="red-text">*</span>;
  }

  validateRequiredFields() {
    const { name } = this.state;
    if (name.length === 0) return true;
  }

  getSelectedWidgetValue(widgetValues, value) {
    const data = widgetValues.filter(element => element.label === value);
    return data[0].value;
  }

  renderField(field, key) {
    if (field.editable) {
      switch (
        field.widgetType // future need to handle for FILTER and SLIDER
      ) {
        case 'PICKLIST':
          if (field.name === 'crawlDirection') {
            const data = field.widgetValues.filter(element => element.value === field.value);
            let selectedCrawlDirection;
            data.length === 0
              ? (selectedCrawlDirection = field.value)
              : (selectedCrawlDirection = data[0].label);
            const items = field.widgetValues.map(label => label.label);
            return (
              <Dropdown
                className="padding-left-5"
                items={items}
                value={selectedCrawlDirection}
                onChange={value =>
                  this.handleOnChangeFields(
                    this.getSelectedWidgetValue(field.widgetValues, value),
                    key,
                    'value',
                    field.required
                  )
                }
              />
            );
          }

          const items = field.widgetValues.map(value => value.value);
          return (
            <Dropdown
              className="padding-left-5"
              items={items}
              value={field.value}
              onChange={value => this.handleOnChangeFields(value, key, 'value', field.required)}
            />
          );

        case 'RADIO':
          const options = field.widgetValues.map(value => value.value);
          return (
            <GlRadioGroup
              checkedOption={field.value}
              onChange={e =>
                this.handleOnChangeFields(e.target.value, key, 'value', field.required)
              }
              optionLabels={options}
              optionValues={options}
              optionClassName="uppercase"
            />
          );
        default:
          // handles for widgetType BASIC or null (null means the widget is not generated for that template)
          return (
            <div>
              <GlInput
                className="padding-left-5"
                value={field.value}
                onChange={e =>
                  this.handleOnChangeFields(
                    e.target.value,
                    key,
                    'value',
                    field.required,
                    field.type
                  )
                }
              />
              {this.state.errorMessages[key] && !(field.required && field.value.length === 0) && (
                <p className="validation-error font-size-14">
                  <strong>{`The input value of this field must be ${field.type.toLowerCase()}`}</strong>
                </p>
              )}
              {/* errorMessages are boollean stored as per key */}
              {/* We do not show any message if field is empty, but button should e disabled */}
            </div>
          );
      }
    }
  }

  renderErrorMessage(value, type) {
    const reg = /^\d+$/;
    const fieldType = type === 'STRING' ? 'string' : 'number'; // we get STRING and INTEGER as type, just to handle in case of FLOAT values
    if (fieldType === 'string') return false;
    const newValueType = reg.test(value) ? 'number' : 'string'; // only accepts int values, not float for now
    if (value.length !== 0 && fieldType !== newValueType) {
      // need not check of no value is entered
      return true;
    }
    return false;
  }

  renderFields() {
    const { templateFields } = this.state;
    return templateFields.map((field, key) => (
      <div key={key} className="height-auto margin-bottom-15">
        {/* <hr /> */}
        <div className="display-flex messages-input">
          <div className="padding-right-5 gl-form-item gl-form-item--required">
            <div className="messages-title">
              {field.label}
              {field.required && this.required()}
            </div>
            <div>{field.description}</div>
          </div>
          {this.renderField(field, key)}
        </div>
      </div>
    ));
  }

  render() {
    const { backToList } = this.props;
    const {
      loading,
      responseMessage,
      responseMessageSuccess,
      responseClass,
      disableSubmit
    } = this.state;
    return (
      <>
        <h4>Create Message</h4>
        {loading ? (
          <Loader />
        ) : responseMessageSuccess ? (
          <p className={responseClass}>
            <strong>{responseMessageSuccess}</strong>
          </p>
        ) : (
          <>
            <div className="gl-vspace-bpall-small container-row-input-mail">
              <GlInput
                required
                placeholder="message name"
                value={this.state.name}
                onChange={this.handleOnChange}
              />
            </div>
            {this.renderFields()}
            <div className="custom-modal__button-container">
              <GlButton
                id="button__previous"
                secondary
                aria-label="Back to List"
                className="custom-modal__button gl-cta--hide-arrow gl-cta--margin-right-top"
                onClick={backToList}
              >
                {'<'} Back to list
              </GlButton>
              <GlButton
                aria-label="Create Message"
                className="custom-modal__button custom-modal__button--primary"
                disabled={disableSubmit}
                onClick={this.createMessage}
              >
                Create
              </GlButton>
            </div>
            {responseMessage && (
              <p className={`${responseClass} margin-top-15`}>
                <strong>{responseMessage}</strong>
              </p>
            )}
          </>
        )}
      </>
    );
  }
}

export default CreateMessageModel;
