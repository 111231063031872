/* eslint-disable no-control-regex */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import get from 'lodash-es/get';
import Dropzone from 'react-dropzone';
import { GlButton } from '@adl/foundation';
import { Tooltip } from 'react-tippy';

import { ReactComponent as UploadIcon } from '../../assets/icons/cloud-upload-alt.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file-regular.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/close-simple.svg';

import MediaEditModal from '../Modals/MediaEditModal';
import MediaEdit2 from '../Modals/MediaEdit2';

import {
  getCancelToken,
  validFileExt,
  bytesToSize,
  shortenStringWithElipsis
} from '../../helpers/utils';
import { openModal as openModalAction } from '../../actions/modal';

import './FileUpload.scss';
import { maxFileSize, UITEXT, acceptableUploadFormats } from '../../helpers/misc';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callGetMediaSearchApi,
  callUpdateMediaInfoApi,
  callUpdateMessageInfoApi,
  callUpdateMediaLocationApi,
  callUploadMediaApi
} from '../../serviceLayer/services';

class FileUpload extends Component {
  state = {
    selectedFiles: [],
    uploadStatus: {},
    isUploading: false,
    invalidFiles: [],
    alreadyUploading: []
  };

  componentDidMount() {
    const { files } = this.props;
    if (files.length > 0) {
      this.onFormSubmit(files);
    }

    window.addEventListener('beforeunload', this.handlePageRefresh);
  }

  componentWillUnmount() {
    const { isUploading } = this.state;
    if (isUploading && this.CANCEL_TOKEN) {
      Object.keys(this.CANCEL_TOKEN).forEach(token => this.killUploadRequest(token));
    }
    window.removeEventListener('beforeunload', this.handlePageRefresh);
    delete this.CANCEL_TOKEN;
    return true;
  }

  handlePageRefresh = e => {
    // different browsers display prompts diffrently
    // more details on: https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup/38880926
    const { isUploading } = this.state;
    if (isUploading) {
      e.preventDefault();
      e.returnValue = UITEXT.pendingUploadWarning;
    }
  };

  checkExistingFile = async file => {
    const name = `Retail/Campaigns/${file.name}`;
    const fileExists = await getAccessTokenAndMakeCalls(token =>
      callGetMediaSearchApi(token, {
        params: {
          limit: 1000,
          filters: `{name : {values:['${name}'], comparator : 'eq'}}`
        }
      })
    )
      .then(response => get(response, 'list', []))
      .catch(err => {
        console.log(err);
        return [];
      });
    return fileExists;
  };

  onFormSubmit = files => {
    const { openModal } = this.props;
    const items = [];

    // this.setState({ isUploading: true, uploadCompleted: false });
    // Starting from 2 because for media Id = 1 , location api is giving some w/g data in MediaEdit2 fetchWorkgroupsData()
    let cId = 2;
    files.forEach(file => {
      const currentItem = {};
      const fileExists = this.checkExistingFile(file).then(result => result);
      fileExists.then(result => {
        if (result.length === 0) {
          currentItem.id = cId;
          cId += 1;
          currentItem.name = file.name;
          currentItem.existing = false;
        } else {
          currentItem.id = result[0].id;
          currentItem.name = file.name;
          currentItem.existing = true;
        }
        items.push(currentItem);
        if (items.length === files.length) {
          this.setState({ files });
          openModal('editMedia2', { items });
        }
      });
    });
  };

  uploadFilesWithData = payload => {
    const { files } = this.state;
    // eslint-disable-next-line no-unused-vars
    let upFiles = [];
    // files.push(file);
    Promise.all(
      (upFiles = files.map(file => {
        this.checkExistingFile(file).then(result => {
          this.fileUpload(file, result, payload)
            .then(res => {
              if (res.data === 'existing') {
                this.setState(prevState =>
                  produce(prevState, draft => {
                    draft.uploadStatus[file.name] = { success: false, ended: true, existing: true };
                  })
                );
              } else if (res.data === 'FAILED') {
                this.setState(prevState =>
                  produce(prevState, draft => {
                    draft.uploadStatus[file.name] = {
                      success: false,
                      ended: true,
                      existing: false,
                      error: true,
                      errorMessage: 'Upload Failed'
                    };
                  })
                );
              } else if (res.data === 'error') {
                this.setState(prevState =>
                  produce(prevState, draft => {
                    const hasErrorMessage =
                      draft.uploadStatus[file.name] && draft.uploadStatus[file.name].errorMessage;
                    draft.uploadStatus[file.name] = {
                      success: false,
                      ended: true,
                      existing: false,
                      error: true,
                      errorMessage: hasErrorMessage || 'File type is not allowed.'
                    };
                  })
                );
              } else {
                this.setState(prevState =>
                  produce(prevState, draft => {
                    draft.uploadStatus[file.name] = {
                      success: true,
                      ended: true,
                      existing: false
                    };
                  })
                );
              }
            })
            .catch(error => {
              this.setState(prevState =>
                produce(prevState, draft => {
                  const errorMessage =
                    error.response && error.response.data && error.response.data.message;
                  draft.uploadStatus[file.name] = {
                    ...draft.uploadStatus[file.name],
                    success: false,
                    ended: true,
                    existing: false,
                    error: true,
                    errorMessage: errorMessage || 'Server Error'
                  };
                })
              );
            });
        });
        return file;
      }))
    );
  };

  checkInvalidFile = file => {
    const errorMessage = [];
    // if (/^([a-zA-Z0-9 ()._-]+)$/g.test(file.name) !== true) {
    //   errorMessage.push(UITEXT.invalidFileName);
    // }

    const fileSansExt = file.name.substring(0, file.name.lastIndexOf('.'));
    const endsWithSpace = fileSansExt[fileSansExt.length - 1] === ' ';

    if (/[\x00-\x08\x0E-\x1F]/.test(file.name) === true || endsWithSpace) {
      errorMessage.push(UITEXT.invalidFileName);
    }

    if (file.size > maxFileSize) {
      errorMessage.push(UITEXT.filesizeShouldNotExceed);
    }
    if (file.name.length > 255) {
      errorMessage.push(UITEXT.fileNameIsTooLong);
    }

    // when user selects a file, browser sends file.type
    // which browser gets from window's registry mapping
    // if there is no mapping of file and content type then it return null
    // source: https://textslashplain.com/2018/07/26/be-skeptical-of-client-reported-mime-content-types/

    // hence, check for extension if valid then send the file
    // from backend we will check actual validity viz. content-type/mime-type
    if (!file.type && file.type === '' && !validFileExt(file.name)) {
      errorMessage.push(UITEXT.fileTypeNotAllow);
    }
    // if there is file type then check for mime
    if (file.type && file.type !== '' && !acceptableUploadFormats.includes(file.type)) {
      errorMessage.push(UITEXT.fileTypeNotAllow);
    }

    if (errorMessage.length) {
      this.setState(prevState =>
        produce(prevState, draft => {
          draft.uploadStatus[file.name] = {
            progress: 0,
            success: false,
            ended: true,
            existing: false,
            error: true,
            errorMessage: errorMessage.map(mes => <li key={mes}>{mes}</li>)
          };
        })
      );
    }
    return errorMessage.length > 0;
  };

  fileUpload = async (file, fileExists, payload) => {
    const { filesData } = this.props;

    const filteredData =
      filesData.filter(fD =>
        fileExists && fileExists.length === 0 ? fD.name === file.name : fD.name.includes(file.name)
      ) || [];
    const currentFileData = filteredData.length > 0 ? filteredData[0] : {};
    const currentPayload = payload[currentFileData.id];

    const { invalidFiles } = this.state;
    this.CANCEL_TOKEN = {
      ...this.CANCEL_TOKEN,
      [file.name]: getCancelToken()
    };

    if (fileExists && fileExists.length > 0) {
      if (currentFileData.mediaType && currentFileData.mediaType === 'MESSAGE') {
        return getAccessTokenAndMakeCalls(token =>
          callUpdateMessageInfoApi(token, { itemId: currentFileData.id, ...currentFileData })
        )
          .then(() => {
            getAccessTokenAndMakeCalls(token =>
              callUpdateMediaLocationApi(token, {
                userId: currentFileData.id,
                ...currentPayload
              })
            );
          })
          .catch(err => console.log(err));
      }
      this.setState(next =>
        produce(next, draft => {
          draft.isUploading = true;
          draft.uploadStatus[file.name] = {};
          draft.uploadStatus[file.name].started = true;
          draft.uploadStatus[file.name].ended = false;
          draft.uploadStatus[file.name].editing = true;
        })
      );

      return getAccessTokenAndMakeCalls(token =>
        callUpdateMediaInfoApi(token, { itemId: currentFileData.id, ...currentFileData })
      )
        .then(() => {
          getAccessTokenAndMakeCalls(token =>
            callUpdateMediaLocationApi(token, { userId: currentFileData.id, ...currentPayload })
          );
        })
        .catch(err => console.log(err));
    }

    const formData = new FormData();
    formData.append('file', file);
    this.setState(next =>
      produce(next, draft => {
        draft.isUploading = true;
        draft.uploadStatus[file.name] = {};
        draft.uploadStatus[file.name].progress = 0;
        draft.uploadStatus[file.name].started = true;
        draft.uploadStatus[file.name].ended = false;
      })
    );

    const error = this.checkInvalidFile(file);
    if (error) {
      // return new Error(error)
      return { data: 'error' };
    }
    if (invalidFiles.indexOf(file.name) > -1) {
      return false;
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      cancelToken: this.CANCEL_TOKEN[file.name].token,
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState(next =>
          produce(next, draft => {
            draft.uploadStatus[file.name].progress = percentCompleted;
          })
        );
      }
    };
    const {
      startValidDate: startDate = new Date(),
      endValidDate: endDate = new Date(),
      categories,
      description = [],
      location = []
    } = currentFileData;
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('categoryIds', categories.map(c => c.id));
    formData.append('locationIds', location);
    formData.append('description', description);
    const uploadInfo = await getAccessTokenAndMakeCalls(token =>
      callUploadMediaApi(token, {
        formData,
        config
      })
    );
    return uploadInfo;
  };

  killUploadRequest = name => {
    this.CANCEL_TOKEN[name].cancel(`Upload canceled`);
  };

  onDrop = accepted => {
    const { selectedFiles, uploadStatus } = this.state;
    const currentFiles = selectedFiles.length > 0 ? selectedFiles : [];
    const currentFileNames = currentFiles.map(fl => fl.name || '');
    const newFiles = [];
    const alreadyUploading = [];
    accepted.forEach(fl => {
      if (!currentFileNames.includes(fl.name)) {
        newFiles.push(fl);
        currentFiles.push(fl);
      } else if (uploadStatus[fl.name] && uploadStatus[fl.name].error === true) {
        newFiles.push(fl);
      } else {
        alreadyUploading.push(fl.name);
      }
    });
    this.setState(
      {
        selectedFiles: currentFiles,
        alreadyUploading
      },
      () => this.onFormSubmit(newFiles)
    );
  };

  activeModal = modalId => {
    const {
      history: { push }
    } = this.props;
    if (modalId === 'editMedia') {
      return <MediaEditModal refreshHandler={() => push('/media')} />;
    }
    if (modalId === 'editMedia2') {
      return (
        <MediaEdit2
          refreshHandler={() => push('/media')}
          uploadFilesWithData={this.uploadFilesWithData}
          clearCurrentFiles={this.clearCurrentFiles}
        />
      );
    }
    return null;
  };

  clearCurrentFiles = loadedInfo => {
    // this.setState({ })
    const { selectedFiles } = this.state;
    const deleteFiles = [];

    Object.values(loadedInfo).forEach(rF => {
      if (rF.existing === false) {
        const toBeRemoved = selectedFiles.find(sF => sF.name && sF.name === rF.name);
        toBeRemoved && deleteFiles.push(toBeRemoved);
      } else {
        const toBeRemoved = selectedFiles.find(sF => sF.name && rF.name.includes(sF.name));
        toBeRemoved && deleteFiles.push(toBeRemoved);
      }
    });
    const remainingFiles = selectedFiles.filter(sF => !deleteFiles.includes(sF));
    this.setState({ selectedFiles: remainingFiles });
  };

  render() {
    const { uploadStatus, isUploading, alreadyUploading } = this.state;
    const { files: filesFromProps, activeModalId } = this.props;
    const { selectedFiles: filesFromState } = this.state;
    const files = filesFromState.length > 0 ? filesFromState : filesFromProps;
    const showUploading = files && files.length > 0 && isUploading;
    // const showEdit = filesNamesToBeChecked && filesNamesToBeChecked.length > 0;

    return (
      <>
        <Dropzone onDrop={this.onDrop} disableClick>
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            // eslint-disable-next-line
            <div {...getRootProps()} className="uploading__container">
              <input {...getInputProps()} style={{ display: 'none' }} />
              {isDragActive ? (
                <div className="drag-and-drop__overlay">
                  <p className="drag-and-drop__overlay--message">Drop files here</p>
                </div>
              ) : (
                <>
                  <div
                    role="button"
                    className="uploading__dndzone"
                    onClick={open}
                    onKeyDown={open}
                    tabIndex="0"
                  >
                    <input style={{ display: 'none' }} type="text" id="mimetypes" />
                    <UploadIcon className="uploading__icon" data-testid="upload-icon" />
                    <h5 className="uploading__title__browsed" data-testid="upload-drag-text">
                      Drag files to upload <br />
                      or
                    </h5>
                    <div className="upload-wrapper">
                      <GlButton type="button" data-testid="upload-browse-button">
                        Browse
                      </GlButton>
                    </div>
                  </div>
                  {showUploading && (
                    <div className="uploading__status">
                      <h5 className="uploading__title" data-testid="upload-uploading-heading">
                        Uploading files
                      </h5>
                      <>
                        <div className="uploading-wrapper">
                          {files.map((file, i) => {
                            const fileStatus = uploadStatus[file.name] || {};

                            const {
                              progress,
                              started,
                              ended,
                              existing,
                              success,
                              error,
                              errorMessage,
                              editing
                            } = fileStatus;
                            const errors = errorMessage || 'Error';
                            const showExisting = ended && existing;
                            const showSuccess = !existing && ended && success;
                            const showProgress = started && !ended && !error && !editing;
                            const filesizePretty = bytesToSize(file.size);
                            const shortenedFileName = shortenStringWithElipsis(file.name, 40);
                            const showThumbnail =
                              ['jpg', 'jpeg', 'png', 'gif'].indexOf(file.type.split('/').pop()) >
                              -1 ? (
                                <img
                                  className="uploaded-image__image"
                                  src={URL.createObjectURL(file)}
                                  alt="File"
                                />
                              ) : (
                                <FileIcon className="file__icon" />
                              );
                            const currentlyUploading = alreadyUploading.includes(file.name);
                            alreadyUploading.indexOf(file.name) > -1 &&
                              alreadyUploading.splice(alreadyUploading.indexOf(file.name), 1);

                            return (
                              <div
                                key={file.name}
                                className={`uploaded-image ${
                                  currentlyUploading ? 'highlighter' : ''
                                }`}
                                data-testid={`upload-asset-${i + 1}`}
                              >
                                <div className="uploaded-image__thumb">{showThumbnail}</div>
                                <div className="uploaded-image__details">
                                  <Tooltip position="bottom" followCursor title={file.name}>
                                    <p className="uploaded-image__name">{shortenedFileName}</p>
                                  </Tooltip>
                                  {showProgress && progress !== 100 && (
                                    <div
                                      className="uploaded-image__cancel"
                                      role="button"
                                      onClick={() => this.killUploadRequest(file.name)}
                                      onKeyDown={() => this.killUploadRequest(file.name)}
                                      title="Cancel Upload"
                                      tabIndex="0"
                                    >
                                      <CancelIcon />
                                    </div>
                                  )}
                                  {showProgress && !error && (
                                    <>
                                      <div className="uploaded-image__progress gl-box gl-time-progress">
                                        <div className="progress-bar">
                                          <div
                                            className="progress-bar__filler"
                                            style={{ width: `${progress}%` }}
                                          />
                                        </div>
                                      </div>
                                      <div className="uploaded-image__upload-stats">
                                        {progress === 100 ? 'Processing' : `${progress}% done`}
                                        <span>
                                          {bytesToSize((progress / 100) * file.size)}
                                          {'/'}
                                          {filesizePretty}
                                        </span>
                                      </div>
                                    </>
                                  )}

                                  {showExisting && (
                                    <p className="validation-error">File already exists!</p>
                                  )}
                                  {showSuccess && <p className="validation-success">Completed</p>}
                                  {error && (
                                    <ul
                                      className="validation-error invalid-file-errors"
                                      style={{
                                        listStyleType: errors.length === 1 ? 'none' : 'disc'
                                      }}
                                    >
                                      {errors}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Dropzone>
        {this.activeModal(activeModalId)}
      </>
    );
  }
}

FileUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  openModal: PropTypes.func,
  activeModalId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  push: PropTypes.func,
  rejected: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  files: state.upload.files || [],
  activeModalId: state.modals.activeModal.modalId,
  rejected: state.upload.rejected || [],
  filesData: state.upload.filesData || []
});

const mapDispatchToProps = {
  openModal: openModalAction
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FileUpload)
);
