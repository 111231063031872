import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { GlButton } from '@adl/foundation';

import Table from '../../commons/Table/Table';
import * as scheduleUtils from '../../SetMultipleDisplaySchedules/scheduleUtils';
import { tableSchema, formatLogs } from './schema';
import { getQueryParamsForTable, appendQueryParam } from '../../../helpers/utils';
import { UITEXT } from '../../../helpers/misc';
import CustomSelect from '../../commons/CustomSelect/CustomSelect';
import '../SchedulingLogs/SchedulingLogs.scss';
import { getAccessTokenAndMakeCalls } from '../../../serviceLayer/utils';
import { callFetchFrameManagementActivityLogsFiltersApi } from '../../../serviceLayer/services';

class FrameManagementLogs extends Component {
  state = {
    logs: [],
    isFetching: true,
    isFetchingFilters: true,
    filterFetchFailed: false,
    sort: {
      by: 'createTimestamp',
      order: 'desc'
    },
    pageInfo: {
      total: 1,
      limit: 20,
      current: 1
    },
    filtered: {},
    isError: false,
    multiFilters: null,
    multiSelectionList: null,
    showFilters: false
  };

  componentDidMount = () => {
    this.initTable();
  };

  initTable = () => {
    const { history, tableId } = this.props;
    const queryParams = getQueryParamsForTable(tableId, history);
    if (queryParams != null) {
      this.setState({ filtered: queryParams });
    }
    this.fetchSchedulingFilters();
    this.fetchSchedulingLogs(queryParams);
  };

  fetchSchedulingFilters = () => {
    const { tableId } = this.props;
    this.setState({
      isFetchingFilters: true,
      filterFetchFailed: false
    });

    getAccessTokenAndMakeCalls(token => callFetchFrameManagementActivityLogsFiltersApi(token))
      .then(res => {
        this.setState(
          {
            isFetchingFilters: false,
            multiFilters: res,
            showFilters: true
          },
          () => {
            this.applySavedFilters(tableId);
          }
        );
      })
      .catch(err => {
        if (!err.canceled) {
          this.setState({ isFetchingFilters: false, filterFetchFailed: true });
        }
      });
  };

  applySavedFilters = tableId => {
    const showFilters = window.sessionStorage.getItem(`${tableId}__filters__open`);
    if (showFilters) {
      this.setState({
        showFilters: showFilters === '1'
      });
    }
  };

  fetchSchedulingLogs = async (data = {}) => {
    const { sort: sortState, pageInfo, multiSelectionList } = this.state;
    const { limit } = pageInfo;
    const page = data.page ? data.page : 1;
    const sort = data.sort || sortState;
    const filtered = multiSelectionList || {};
    const { by: sortBy, order: sortOrder } = sort;

    this.setState({
      isFetching: true,
      isError: false
    });

    const params = {
      size: limit,
      sortOrder,
      sortBy,
      page
    };

    if (filtered && Object.keys(filtered).length !== 0) {
      const allFilter = {};
      Object.keys(filtered).forEach(key => {
        const workspace = filtered[key];
        if (workspace && workspace.length > 0) {
          allFilter[key] = workspace.reduce((a, c) => a.concat(c.value), []);
        }
      });
      params.filters = allFilter;
    }

    scheduleUtils
      .fetchFrameManagementLogs(params)
      .then(res => {
        this.setState(next => ({
          ...next,
          logs: formatLogs(res.framesActivtyLogs),
          isFetching: false,
          pageInfo: {
            ...next.pageInfo,
            current: page,
            total: res.metadata.count
          },
          filtered,
          sort
        }));
      })
      .catch(err => {
        if (!err.canceled) {
          this.setState({ isFetching: false, isError: true, logs: [] });
        }
      });
  };

  toggleFilter = () => {
    this.setState(
      prevState => ({
        showFilters: !prevState.showFilters
      }),
      () => {
        const { showFilters } = this.state;
        if (showFilters) {
          window.sessionStorage.setItem(`schedulingLogs__filters__open`, '1');
        } else {
          window.sessionStorage.setItem(`schedulingLogs__filters__open`, '0');
        }
      }
    );
  };

  handleMultiSelection = (selection, columnId) => {
    this.setState(draft => ({
      multiSelectionList: {
        ...draft.multiSelectionList,
        [columnId]: selection
      }
    }));
  };

  clearAllFilters = () => {
    this.setState(
      {
        multiSelectionList: null
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    this.handleURLQuery();
    this.fetchSchedulingLogs();
  };

  handleURLQuery = () => {
    const { history, tableId } = this.props;
    const {
      sort: { by, order }
    } = this.state;
    const filters = {
      order,
      page: 1,
      sortBy: by
    };
    appendQueryParam(tableId, history, filters);
  };

  deriveFilterName = col => {
    switch (col) {
      case 'operationType':
        return UITEXT.operationType;
      case 'playerName':
        return UITEXT.displayName;
      case 'frameName':
        return UITEXT.frameName;
      case 'userId':
        return UITEXT.users;
      default:
        return col;
    }
  };

  render() {
    const {
      isFetching,
      pageInfo,
      logs,
      sort,
      isError,
      filtered,
      multiFilters,
      multiSelectionList,
      showFilters,
      isFetchingFilters,
      filterFetchFailed
    } = this.state;
    const { tableId } = this.props;
    const showApplyFilters =
      multiSelectionList &&
      Object.keys(multiSelectionList).length > 0 &&
      !Object.keys(multiSelectionList).every(k => multiSelectionList[k].length === 0);
    return (
      <>
        <button
          type="button"
          className="gl-cta gl-cta--secondary"
          onClick={this.toggleFilter}
          style={{ float: 'right', marginTop: '-80px', marginLeft: 'auto' }}
        >
          {`${showFilters ? UITEXT.hide : UITEXT.show} ${UITEXT.filters}`}
        </button>
        <div className="row no-gutters">
          <aside
            className={`${
              showFilters
                ? 'col-s-3 logs__container__filters'
                : 'logs__container__filters logs__container__filters--hidden'
            }`}
          >
            {isFetchingFilters && (
              <div className="logs__container__filters--loading">
                <div className="gl-loader gl-loader--black" />
              </div>
            )}
            {multiSelectionList && Object.keys(multiSelectionList).length > 0 && (
              <div className="logs__container__filters__refine">
                <div className="logs__container__filters__refine--buttons">
                  {showApplyFilters && (
                    <button
                      type="button"
                      className="gl-text-end gl-cta gl-cta--primary"
                      aria-label="Apply Filters"
                      onClick={this.applyFilters}
                      disabled={isFetching}
                    >
                      {UITEXT.applyFilters}
                    </button>
                  )}
                  <button
                    type="button"
                    className="gl-text-end gl-cta gl-cta--secondary"
                    aria-label="Clear All Filters"
                    onClick={this.clearAllFilters}
                    title="This will clear all applied filters."
                    disabled={isFetching}
                  >
                    {UITEXT.clearAll}
                  </button>
                </div>
              </div>
            )}
            {multiFilters &&
              Object.keys(multiFilters).length > 0 &&
              Object.keys(multiFilters).map(columnId => {
                const filterName = this.deriveFilterName(columnId);
                return (
                  <div
                    className={`logs__container__filters__item logs__container__filters__item--${columnId}`}
                    key={columnId}
                  >
                    <div className="gl-label">{filterName}</div>
                    <CustomSelect
                      options={multiFilters[columnId]}
                      onChange={sel => this.handleMultiSelection(sel, columnId)}
                      selectedOption={
                        multiSelectionList && multiSelectionList[columnId]
                          ? multiSelectionList[columnId]
                          : null
                      }
                      memoKey={((multiSelectionList && multiSelectionList[columnId]) || [])
                        .map(s => s.label)
                        .join(',')}
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  </div>
                );
              })}
            {filterFetchFailed && (
              <div className="logs__container__filters--error">
                <p className="validation-error">
                  {UITEXT.errorFetchingFilters}
                  <GlButton tertiary onClick={() => this.fetchSchedulingFilters()}>
                    {UITEXT.retry}
                  </GlButton>
                </p>
              </div>
            )}
          </aside>
          <div className={`${showFilters ? 'col-s-9 logs__table' : 'col-s-12 logs__table'}`}>
            <Table
              id={tableId}
              tableData={logs}
              tableSchema={tableSchema}
              tableId={tableId}
              filterHandler={this.fetchSchedulingLogs}
              sortHandler={this.fetchSchedulingLogs}
              filteredInit={filtered}
              isFilterable
              hasColumnOptions
              hasQueryParamsHandling
              isFetching={isFetching}
              isError={isError}
              sort={sort}
              pageInfo={pageInfo}
              paginationHandler={this.fetchSchedulingLogs}
              multiFilters={multiFilters}
              hasMultiSelectFilter
            />
          </div>
        </div>
      </>
    );
  }
}

FrameManagementLogs.propTypes = {
  tableId: PropTypes.string,
  location: PropTypes.shape({}),
  history: PropTypes.shape({})
};

FrameManagementLogs.defaultProps = {
  tableId: 'schedulingLogs'
};

export default withRouter(FrameManagementLogs);
