import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GlTooltip } from '@adl/foundation';

import NavTabs from '../NavTabs/NavTabs';
import ActionBar from '../ActionBar/ActionBar';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as EllipsisIcon } from '../../assets/icons/ellipsis-v.svg';
import './Header.scss';
import { logoutUser } from '../../serviceLayer/utils';

const LogoContainer = () => (
  <div className="logo-container">
    <Link to="/">
      <Logo />
    </Link>
  </div>
);

const Header = ({ email }) => (
  <div className="Header">
    <LogoContainer />
    <NavTabs />
    <div className="header-right">
      <div className="header-user">
        <div className="header-user__inner">
          <div className="header-user__name" data-testid="header-username">
            {email}
          </div>
          <div className="header-user__ellipsis">
            <GlTooltip
              placement="left"
              size="large"
              label={<EllipsisIcon className="header-user__ellipsis" />}
            >
              <button
                type="button"
                className="header-user__logout-button"
                onClick={() => logoutUser()}
                data-testid="header-logout"
              >
                Logout
              </button>
            </GlTooltip>
          </div>
        </div>
      </div>
      <ActionBar />
    </div>
  </div>
);

Header.propTypes = {
  email: PropTypes.string
};

const mapStateToProps = state => ({
  email: state.auth.email
});

export default withRouter(connect(mapStateToProps)(Header));
