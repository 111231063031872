import { combineReducers } from 'redux';
import tableReducer from './tableReducer';
import modalReducer from './modalReducer';
import scheduleReducer from './scheduleReducer';
import scheduleReducerV2 from './scheduleReducerV2';
import uploadReducer from './uploadReducer';
import authReducer from './authReducer';
import playersListReducer from './playersListReducer';
import adminPanelReducer from  './adminPanelReducer'

export default combineReducers({
  auth: authReducer,
  table: tableReducer,
  modals: modalReducer,
  schedule: scheduleReducer,
  scheduleV2: scheduleReducerV2,
  upload: uploadReducer,
  playersList: playersListReducer,
  adminPanel: adminPanelReducer
});
