/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton, GlInput } from '@adl/foundation';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import get from 'lodash-es/get';

import { trimInputValue } from '../../helpers/utils';
import { UITEXT } from '../../helpers/misc';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import * as modalActions from '../../actions/modal';
import './Modals.scss';
import { ALLOWED_CHARACTERS_COUNT_MSG, allowedCharactersCount } from '../../helpers/validators';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi, callDuplicatePlaylistApi } from '../../serviceLayer/services';

class DuplicatePlaylistModal extends Component {
  constructor(props) {
    super(props);
    const { selectedPlaylistItems } = this.props;

    this.state = {
      isDuplicatingPlaylist: false,
      duplicatingPlaylistCompleted: false,
      newPlaylistId: null,
      success: false,
      isNameInUse: null,
      isNameToShort: null,
      name: `Copy of ${selectedPlaylistItems[0].name || selectedPlaylistItems[0].id}`,
      isFetching: true
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);

    const { selectedPlaylistItems } = this.props;
    if (selectedPlaylistItems[0].name === undefined) {
      this.getPlaylistName(selectedPlaylistItems);
    } else {
      this.setState({ isFetching: false });
    }
  }

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  duplicatePlaylist = id => {
    const { name } = this.state;

    this.setState({ isDuplicatingPlaylist: true });

    getAccessTokenAndMakeCalls(token =>
      callDuplicatePlaylistApi(token, {
        id,
        name
      })
    )
      .then(res => {
        // eslint-disable-next-line no-throw-literal
        if (!res) throw 'Something went wrong';
        this.setState({
          isDuplicatingPlaylist: false,
          duplicatingPlaylistCompleted: true,
          isNameInUse: false,
          newPlaylistId: res.id,
          success: true
        });
      })
      .catch(e => {
        const errorCode = get(e, 'response.data.cmsResponse.code');
        this.setState({
          isDuplicatingPlaylist: false,
          duplicatingPlaylistCompleted: true,
          isNameInUse: errorCode === 'NameAlreadyExists',
          success: false,
          newPlaylistId: null
        });
      });
  };

  handleInfoChange = name => {
    const { preventReload } = this.props;
    preventReload(true);

    this.setState({
      name,
      isNameInUse: false,
      duplicatingPlaylistCompleted: false,
      isNameToShort: !allowedCharactersCount(trimInputValue(name), 6, 100)
    });
  };

  getPlaylistName = selectedPlaylists => {
    const playlistId = selectedPlaylists.map(item => item.id);
    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId }))
      .then(res => {
        this.setState({ name: `Copy of ${res.name}`, isFetching: false });
      })
      .catch(() => {
        console.log('err');
      });
  };

  render() {
    const { closeModal, selectedPlaylistItems, history, isReloadPrevented } = this.props;
    const {
      isDuplicatingPlaylist,
      duplicatingPlaylistCompleted,
      success,
      isNameInUse,
      name,
      isNameToShort,
      isFetching
    } = this.state;
    const playlistItem = selectedPlaylistItems[0];

    if (isDuplicatingPlaylist) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
          }}
        >
          <Loader />
        </Modal>
      );
    }

    if (duplicatingPlaylistCompleted && !isNameInUse) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            const { newPlaylistId } = this.state;
            if (newPlaylistId != null) {
              history.push({ pathname: `/playlists/${newPlaylistId}` });
              closeModal();
            } else closeModal();
          }}
        >
          <h4>Duplicate playlist</h4>
          {success ? (
            <p className="validation-success">Playlist has been duplicated successfully.</p>
          ) : (
            <p className="validation-error">Something went wrong.</p>
          )}
        </Modal>
      );
    }

    const nameError = isNameInUse || isNameToShort;

    const nameInUseError = isNameInUse && 'Playlist with this name already exists.';
    const nameShortError = isNameToShort && ALLOWED_CHARACTERS_COUNT_MSG('Name', 6, 100);

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <h4>Duplicate playlist</h4>
              <GlInput
                className="generic-table__input"
                placeholder="New name"
                value={name}
                valid={!nameError}
                hint={nameInUseError || nameShortError || ''}
                onChange={e => {
                  const { value } = e.target;
                  this.handleInfoChange(value);
                }}
                required
              />
              <div className="custom-modal__button-container">
                <GlButton
                  aria-label="Duplicate playlist"
                  className="custom-modal__button custom-modal__button--primary"
                  onClick={() => this.duplicatePlaylist(playlistItem.id)}
                  disabled={nameError}
                >
                  Duplicate
                </GlButton>
                <GlButton
                  secondary
                  aria-label="Cancel"
                  className="custom-modal__button"
                  onClick={() => closeModal()}
                >
                  Cancel
                </GlButton>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

DuplicatePlaylistModal.propTypes = {
  selectedPlaylistItems: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  history: PropTypes.shape({}),
  preventReload: PropTypes.func,
  isReloadPrevented: PropTypes.bool
};

const mapStateToProps = state => ({
  selectedPlaylistItems: state.modals.activeModal.items,
  isReloadPrevented: state.modals.isDirty
});
const mapDispatchToProps = {
  ...modalActions
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DuplicatePlaylistModal)
);
