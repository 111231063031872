/* eslint-disable one-var */
(function() {
  if (typeof window.Event === 'function') {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.Event = CustomEvent;
  return false;
})();

(function(w) {
  w.URLSearchParams =
    w.URLSearchParams ||
    function(searchString) {
      const self = this;
      self.searchString = searchString;
      self.get = function(name) {
        const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(self.searchString);
        if (results == null) {
          return null;
        }
        return decodeURI(results[1]) || 0;
      };
    };
})(window);

(function() {
  if (!Object.entries) {
    Object.entries = function(obj) {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = Array.from({ length: i });
      // eslint-disable-next-line no-const-assign
      // eslint-disable-next-line no-plusplus
      while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
      }

      return resArray;
    };
  }
})();

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;

    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
