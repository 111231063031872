import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import formatDate from 'date-fns/format';
import { datesTimes, UITEXT } from '../../helpers/misc';

import './PlayerStatus.scss';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlayerPlansApi, callGetPlayerStatusApi } from '../../serviceLayer/services';

class PlayerStatus extends PureComponent {
  state = {
    playerState: null,
    uuid: null
  };

  timer = null;

  componentDidMount() {
    this.getPlayerState();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  getPlayerState = () => {
    const { playerId, updateGeneratePlanText } = this.props;

    getAccessTokenAndMakeCalls(token =>
      callGetPlayerStatusApi(token, {
        playerId
      })
    ).then(async (data = {}) => {
      const plans = (await this.getPlanDetails(playerId)) || [];
      const playerState = { ...data, ...plans };
      this.setState(prev => ({
        ...prev,
        playerState
      }));
      const lastGenerated =
        playerState.plans && playerState.plans.length > 0 && playerState.plans[0].generated;
      const generatePlanText =
        playerState.planState === 'PLAYER_HAS_LATEST_PLAN'
          ? UITEXT.playerHasLatestPlan
          : `${UITEXT.playerDoesntHaveLatestPlan} ${formatDate(
              lastGenerated,
              datesTimes.scalaDateWithTimePretty
            )}`;
      updateGeneratePlanText(generatePlanText);
      this.timer = setTimeout(this.getPlayerState, 10000);
    });
  };

  getPlanDetails = async playerId =>
    getAccessTokenAndMakeCalls(token =>
      callGetPlayerPlansApi(token, {
        playerId
      })
    );

  showPlayerHealth = () => {
    const {
      history: { push },
      playerId,
      playerName
    } = this.props;
    push(`/player-health/${playerId}/${playerName}`);
  };

  render() {
    const { playerState } = this.state;

    const {
      playerId,
      playerDescription,
      playerTitle,
      playerName,
      saveButton,
      width,
      height,
      responseError
    } = this.props;

    let showHealthLogs = true;
    let statusText;
    let statusClass;
    let lastReported = '';
    const conversionTable = {
      'time.second.ago': ' second ago ',
      'time.seconds.ago': ' seconds ago ',
      'time.minute.ago': ' minute ago ',
      'time.minutes.ago': ' minutes ago ',
      'time.hour.ago': ' hour ago ',
      'time.hours.ago': ' hours ago ',
      'time.hour': ' hour ',
      'time.hours': ' hours ',
      'time.minute': ' minute ',
      'time.minutes': ' minutes ',
      'time.seconds': ' seconds ',
      'time.some.seconds': ' seconds ago ',
      'time.just.now': 'Just now',
      'time.some.minutes': 'minutes ago'
    };

    if (responseError) {
      showHealthLogs = false;
    }

    if (playerState && playerState.lastReported) {
      if (
        formatDate(playerState.lastReported, datesTimes.scalaDateWithTimePretty) !== 'Invalid Date'
      )
        lastReported = formatDate(playerState.lastReported, datesTimes.scalaDateWithTimePretty);
      else {
        Object.keys(conversionTable).forEach(con => {
          lastReported = playerState.lastReported.replace(`{${con}}`, conversionTable[con]);
        });
      }
    }

    if (playerState) {
      if (playerState.active === 'ACTIVE') {
        statusText = UITEXT.playerActiveStatusText;
      } else if (playerState.active === 'INACTIVE') {
        statusText = UITEXT.playerInactiveStatusText;
      } else if (playerState.active === 'UNKNOWN') {
        statusText = UITEXT.playerUnknownStatusText;
      }
      if (playerState.state === 'HEALTHY') {
        statusText += UITEXT.playerHealthyStatusText;
      } else if (playerState.state === 'UNHEALTHY') {
        statusText += UITEXT.playerUnhealthyStatusText;
        showHealthLogs = false;
      } else if (playerState.state === 'HEARTBEAT_OVERDUE') {
        statusText += UITEXT.playerHeartbeatOverdueStatusText + lastReported;
      } else if (playerState.state === 'NOT_REPORTED') {
        statusText += UITEXT.playerNotReportedStatusText;
      }
      if (
        playerState.active === 'ACTIVE' &&
        (playerState.state === 'HEALTHY' || playerState.state === 'UNHEALTHY')
      ) {
        statusClass = 'status-active'; // green
      } else if (playerState.active === 'ACTIVE' && playerState.state !== 'HEALTHY') {
        statusClass = 'status-unhealthy'; // yellow
      } else if (playerState.active === 'UNKNOWN' && playerState.state === 'NOT_REPORTED') {
        statusClass = 'status-unknown'; // grey
      } else {
        // HEARTBEAT_OVERDUE & INACTIVE
        statusClass = 'status-inactive'; // red
      }
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ display: 'flex' }}>
              {showHealthLogs ? (
                <Link to={`/player-health/${playerId}/${playerName}`} target="_blank">
                  <Tooltip
                    position="bottom"
                    followCursor
                    style={{ maxWidth: 'auto' }}
                    html={<div>{statusText}</div>}
                  >
                    <div className={`status-dot ${statusClass}`} />
                  </Tooltip>
                </Link>
              ) : (
                <>
                  <Tooltip
                    position="bottom"
                    followCursor
                    style={{ maxWidth: 'auto' }}
                    html={<div>{statusText}</div>}
                  >
                    <div className={`status-dot ${statusClass}`} />
                  </Tooltip>
                </>
              )}
              <div>
                <Tooltip position="bottom" followCursor title={playerTitle}>
                  <div style={{ display: 'flex' }}>
                    <h1 className="gl-heading--m page-title" style={{ marginBottom: '0px' }}>
                      {playerName}
                    </h1>
                    {width && height && (
                      <div style={{ margin: 'auto 0px 0px 10px', fontSize: '18px' }}>
                        {`[${width} x ${height}]`}
                      </div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>

            {playerDescription && playerDescription.length > 0 && (
              <div className="player-description">{playerDescription}</div>
            )}
          </div>
          {saveButton}
        </div>
      </>
    );
  }
}

PlayerStatus.propTypes = {
  playerId: PropTypes.string,
  history: PropTypes.shape({}),
  playerName: PropTypes.string,
  responseError: PropTypes.bool
};

export default withRouter(PlayerStatus);
