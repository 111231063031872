export const isRequired = value => value.trim() !== '';
export const minLength = (value, _minLenght) => (value && value.length >= _minLenght) || false;

export const allowedCharactersCount = (value, _minLength, _maxLength) =>
  (value && value.length >= _minLength && value.length <= _maxLength) || false;

export const IS_REQUIRED_MSG = label => `${label} Is Rquired`;
export const MIN_LENGTH_MSG = (label, _minLength) =>
  `${label} should be min. ${_minLength} characters long`;
export const ALLOWED_CHARACTERS_COUNT_MSG = (label, _minLength, _maxLength) =>
  `Please use between ${_minLength} and ${_maxLength} characters`;

export const checkEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
