export const pageTitle = {
  default: 'Digital Signage - Custom Front-End',
  media: 'Media',
  adminPanel: 'Administration',
  playlist: 'Playlists',
  playlistSelected: name => `Playlist - ${name}`,
  scheduling: 'Scheduling',
  scheduleSelected: name => `Schedule - ${name}`,
  scheduleStep: step => `Scheduling - Step #${step}`,
  activityLog: 'Activity Log'
};
