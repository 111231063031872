import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Pagination.scss';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeArr = [];

  while (i <= to) {
    rangeArr.push(i);
    i += step;
  }

  return rangeArr;
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;

    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

    this.pageNeighbours =
      typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    };

    this.setState(() => onPageChanged(paginationData));
  };

  handleClick = (page, evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    const { currentPage } = this.props;
    this.gotoPage(currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    const { currentPage } = this.props;
    this.gotoPage(currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const { totalPages, pageNeighbours } = this;
    const { currentPage } = this.props;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.totalRecords || this.totalPages === 1) {
      return null;
    }

    const { currentPage } = this.props;
    // this is hack a lint
    const pages = this.fetchPageNumbers().map((page, index) => ({
      id: `page-${index}`,
      value: page
    }));
    return (
      <>
        <nav aria-label="Pagination">
          <ul className="custom-pagination">
            {pages.map(page => {
              if (page.value === LEFT_PAGE) {
                return (
                  <li key={page.id} className="custom-pagination__item">
                    <button
                      type="button"
                      className="custom-pagination__link"
                      aria-label="Previous"
                      onClick={this.handleMoveLeft}
                    >
                      <span aria-hidden="true" data-testid="pagination-previous">
                        &laquo;
                      </span>
                    </button>
                  </li>
                );
              }

              if (page.value === RIGHT_PAGE) {
                return (
                  <li key={page.id} className="custom-pagination__item">
                    <button
                      type="button"
                      className="custom-pagination__link"
                      aria-label="Next"
                      onClick={this.handleMoveRight}
                    >
                      <span aria-hidden="true" data-testid="pagination-next">
                        &raquo;
                      </span>
                    </button>
                  </li>
                );
              }

              return (
                <li
                  key={page.id}
                  className={`custom-pagination__item${
                    currentPage === page.value ? '--active' : ''
                  }`}
                >
                  <button
                    type="button"
                    className="custom-pagination__link"
                    onClick={e => this.handleClick(page.value, e)}
                    data-testid={`pagination-page-${page.value}`}
                  >
                    {page.value}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
      </>
    );
  }
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  currentPage: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default Pagination;
