import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { GlButton } from '@adl/foundation';
import { openModal as openModalAction } from '../../actions/modal';
import { selectItems as selectItemsAction } from '../../actions/table';

import './AdminPanel.scss';
import SelectUsers from '../../components/SelectUsers/SelectUsers';
import UserWorkgroupModal from '../../components/Modals/UserWorkgroupModal';
import UserRoleModal from '../../components/Modals/UserRoleModal';
import { pageTitle } from '../../helpers/pageTitles';
import UserCreation from '../../components/Modals/UserCreation';
import UserDeletion from '../../components/Modals/UserDeletion';
import Loader from '../../components/commons/Loader/Loader';
import { selectedUsersPanel as selectedUsersPanelAction } from '../../actions/adminPanel';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callFetchUsersApi } from '../../serviceLayer/services';

class AdminPanel extends Component {
  state = {
    selectedUsers: [],
    isLoading: true,
    users: [],
    userMap: {},
    error: false
  };

  componentDidMount() {
    document.title = pageTitle.adminPanel;
    this.fetchUsers();
  }

  componentWillUnmount() {
    const { selectItems } = this.props;
    selectItems('users', []);
  }

  activeModal = modalId => {
    const { selectedUsers, userMap } = this.state;
    const { preselectedUsers } = this.props;
    let users = [];
    if (selectedUsers && !selectedUsers.length && preselectedUsers && preselectedUsers.length) {
      users = preselectedUsers.map(id => userMap[id]);
    } else {
      users = selectedUsers;
    }
    switch (modalId) {
      case 'userWorkgroup':
        return <UserWorkgroupModal selectedUsers={users} />;
      case 'userRole':
        return <UserRoleModal selectedUsers={users} fetchUsers={this.fetchUsers} />;
      case 'userCreation':
        return <UserCreation fetchUsers={this.fetchUsers} />;
      case 'userDeletion':
        return <UserDeletion selectedUsers={users} fetchUsers={this.fetchUsers} />;
      default:
        return null;
    }
  };

  formUsers = items => {
    const formatedObj = [];
    const copyUsersMap = {};
    items.forEach((item, rowIndex) => {
      formatedObj[rowIndex] = {
        label: item.lastName ? `${item.lastName}, ${item.firstName}` : item.name,
        value: item.id,
        id: item.id,
        isAdmin: item.isAdmin || false
      };
      copyUsersMap[item.id] = formatedObj[rowIndex];
    });
    this.setState({
      userMap: copyUsersMap
    });
    return formatedObj;
  };

  fetchUsers = () => {
    this.setState({
      isLoading: true
    });
    const { selectItems } = this.props;
    selectItems('users', []);
    getAccessTokenAndMakeCalls(token => callFetchUsersApi(token))
      .then(res => {
        this.setState({
          users: this.formUsers(res.list),
          isLoading: false,
          error: false,
          selectedUsers: []
        });
      })
      .catch(error => {
        this.setState({
          users: [],
          isLoading: false,
          error: true,
          selectedUsers: []
        });
      });
  };

  selectUsersHanlder = selectedUsersIds => {
    const { selectItems, selectedUsersPanel } = this.props;
    const selectedUsers = selectedUsersIds.map(id => this.state.userMap[id]);
    this.setState({ selectedUsers }, () =>
      selectItems('users', this.state.selectedUsers.map(usr => usr.value.toString()))
    );
    selectedUsersPanel(selectedUsers);
  };

  render() {
    const { isLoading, error, users } = this.state;
    const { activeModalId } = this.props;

    if (error) {
      return (
        <p>
          Could not load user from server.{' '}
          <GlButton tertiary onClick={this.fetchUsers}>
            Reload
          </GlButton>
        </p>
      );
    }
    return (
      <div className="generic-container">
        <h1 className="gl-heading--m">Users Management</h1>
        <div style={{ marginTop: '1rem' }}>
          {isLoading ? (
            <Loader />
          ) : (
            <SelectUsers selectUsers={this.selectUsersHanlder} users={users} />
          )}
        </div>

        {this.activeModal(activeModalId)}
      </div>
    );
  }
}

AdminPanel.propTypes = {
  activeModalId: PropTypes.string,
  selectItems: PropTypes.func,
  preselectedUsers: PropTypes.arrayOf(Number)
};

const mapDispatchToProps = {
  openModal: openModalAction,
  selectItems: selectItemsAction,
  selectedUsersPanel: selectedUsersPanelAction
};

const mapStateToProps = state => ({
  activeModalId: state.modals.activeModal.modalId,
  preselectedUsers: state.adminPanel.selectedUsers
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminPanel)
);
