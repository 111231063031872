import React from 'react';
import { NavLink } from 'react-router-dom';
import { GlTooltip, GlButton } from '@adl/foundation';
import { Tooltip } from 'react-tippy';
import { shortenStringWithElipsis } from '../../helpers/utils';
import { playlistMetadataSeparator, playerMetadasToParse } from '../../helpers/misc';
import store from '../../stores';
import { openModal } from '../../actions/modal';
import Camera from '../../assets/icons/camera.svg';
import ShowFrameConfigurationModal from './ShowFrameConfigurationModal';

const tableSchema = [
  {
    id: 'name',
    title: 'Hostname',
    filter: true,
    sortable: true
    // autocomplete: true,
  },
  {
    id: 'market',
    title: 'Market',
    filter: true,
    sortable: true
  },
  {
    id: 'country',
    title: 'Country',
    filter: true,
    sortable: true
  },
  {
    id: 'city',
    title: 'City',
    filter: true,
    sortable: true
  },
  {
    id: 'storename',
    title: 'Store/Building Name',
    filter: true,
    sortable: true
  },
  {
    id: 'schedule',
    title: 'Scheduled Playlist',
    filter: true
  },
  {
    id: 'status',
    title: 'Player Status',
    filter: true,
    sortable: true
  },
  {
    id: 'resolution',
    title: 'Screen Resolution',
    filter: true,
    sortable: true
  },
  {
    id: 'secondlevelsupportvendor',
    title: '2nd Level Support Vendor',
    filter: true,
    sortable: true
  },
  {
    id: 'environment',
    title: 'Environment',
    filter: true,
    sortable: true
  },
  {
    id: 'usecase',
    title: 'Use Case',
    filter: true,
    sortable: true
  },
  {
    id: 'ssmid',
    title: 'SSM ID',
    filter: true,
    sortable: true
  },
  {
    id: 'bcs',
    title: 'BCS Code',
    filter: true,
    sortable: true
  }
];

const formatStep1 = apiRes => {
  const tableData = [];

  if (!apiRes || apiRes.length < 1) {
    return [];
  }

  apiRes.forEach((item, rowIndex) => {
    tableData[rowIndex] = {
      id: item.playerId
    };

    const { frames } = item;
    frames.sort((frame1, frame2) => frame1.zOrder - frame2.zOrder);
    const framesData = () => (
      <div style={{ width: '100%', textAlign: 'left', padding: 20 }}>
        {frames.length > 0 ? <strong>Available frames: </strong> : <div>No frames available</div>}
        <ul style={{ listStyleType: 'circle' }}>
          {frames.map(frame => (
            <li key={frame.name}>{frame.name}</li>
          ))}
        </ul>
      </div>
    );

    // name
    const { name, description, market } = item;
    const maxDescChars = 25;
    let descriptionElem = null;

    // player description may or may not be there
    if (description && description.length > maxDescChars) {
      const shortenedDesc = shortenStringWithElipsis(description, maxDescChars);
      descriptionElem = (
        <div>
          <Tooltip
            position="bottom"
            followCursor
            html={<div className="tooltipWidth">{description}</div>}
          >
            <div className="player__description">{shortenedDesc}</div>
          </Tooltip>
        </div>
      );
    } else {
      descriptionElem = <div className="player__description">{description}</div>;
    }

    const disableScreenshotOption = false;

    tableData[rowIndex].name = (
      <>
        <div className="d-flex">
          <div className="flex-grow-1 justify-content-start player__name">
            <NavLink to={`/schedules/${item.playerId}/${name}`}>
              <Tooltip position="bottom" followCursor title="View and edit schedules">
                <div className="core-green__main">{name}</div>
              </Tooltip>
            </NavLink>
          </div>

          <div className="justify-content-end">
            <ShowFrameConfigurationModal
              frames={frames}
              framesData={framesData}
              playerId={item.playerId}
              playerName={name}
            />
          </div>
          {/* name should have player name market as NAM and Europe */}
          {disableScreenshotOption && market && (market.toLowerCase() === 'nam' || market.toLowerCase() === 'europe') && name && name.length > 0 && 
            <div className="screenshot-icon">
              <GlButton
                className="button--viewscreenshot"
                icon=""
                onClick={() =>
                  store.dispatch(openModal('playerScreenshots', { id: item.playerId, name }))
                }
              >
                <img className="screenshot-icon-styling" src={Camera} alt="Take Screenshot" />
              </GlButton>
            </div>
          }
        </div>
        {description && <div>{descriptionElem}</div>}
      </>
    );

    const parsedMeta = {
      city: item.city,
      country: item.country,
      environment: item.environment,
      market: item.market,
      ssmid: item.ssmid,
      storename: item.store,
      usecase: item.usecase,
      resolution: item.resolution,
      bcs: item.bcsCode,
      secondlevelsupportvendor: item.secondLevelSupport
    };

    tableData[rowIndex].index = {
      id: item.playerId,
      name: name.toLowerCase(),
      status: item.status,
      ...parsedMeta
    };

    playerMetadasToParse.forEach(meta => {
      tableData[rowIndex][meta] = parsedMeta[meta] || '';
    });

    const { playlistNames, playlistIds } = item || '';
    const playlistNamesArray =
      (playlistNames && playlistNames.split(playlistMetadataSeparator)) || [];
    const playlistIdsArray = (playlistIds && playlistIds.split(playlistMetadataSeparator)) || [];
    const validPlaylistLinks =
      playlistNamesArray.length === playlistIdsArray.length && playlistIdsArray.length > 0;
    const playlistsByIds = {};

    if (validPlaylistLinks) {
      playlistIdsArray.forEach((pid, i) => {
        playlistsByIds[pid] = playlistNamesArray[i];
      });
    }
    tableData[rowIndex].index.schedule = validPlaylistLinks ? playlistNamesArray.join('') : '';

    const playlistLinks = [];
    const playlistLinksExtras = [];

    Object.keys(playlistsByIds).forEach((pid, index) => {
      const maxChar = 40;
      const shortenedName = shortenStringWithElipsis(playlistsByIds[pid], maxChar);
      const pllink = (
        <div key={pid}>
          <Tooltip
            position="bottom"
            followCursor
            html={<div className="tooltipWidth">{playlistsByIds[pid]}</div>}
          >
            <NavLink to={`/playlists/${pid}`}>
              <div className="core-green__main">{shortenedName}</div>
            </NavLink>
          </Tooltip>
        </div>
      );
      if (index < 3) {
        playlistLinks.push(pllink);
      } else {
        playlistLinksExtras.push(pllink);
      }
    });

    tableData[rowIndex].schedule = validPlaylistLinks ? (
      <>
        {playlistLinks}
        {playlistLinksExtras.length > 0 && (
          <div className="showMore">
            <GlTooltip label={`Show ${playlistLinksExtras.length} more`} size="large">
              {playlistLinksExtras}
            </GlTooltip>
          </div>
        )}
      </>
    ) : (
      'N/A'
    );

    // status
    tableData[rowIndex].status = item.status;

    tableData[rowIndex].selectInfo = {
      id: item.playerId,
      name: item.name
    };
  });

  return tableData;
};

const sortOptions = {
  by: 'name',
  order: 'asc'
};

export { formatStep1, tableSchema, sortOptions };
