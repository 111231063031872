import React from 'react';
import PropTypes from 'prop-types';
import './Badge.scss';

const Badge = ({
  label,
  id,
  mediaId,
  selectedIcon = true,
  categoryPill,
  clickHandler,
  handleRemove
}) => (
  <button
    type="button"
    className={`badge badge--${label} ${categoryPill ? 'badge--outline' : ''} ${
      selectedIcon ? 'selected' : ''
    }`}
    onClick={categoryPill ? () => clickHandler(mediaId, id) : null}
  >
    <div className="badge__label">{label}</div>
    {selectedIcon && (
      <button
        tabIndex="0"
        type="button"
        className="badge__remove"
        onKeyDown={!categoryPill ? () => handleRemove(id) : null}
        onClick={!categoryPill ? () => handleRemove(id) : null}
      >
        <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </button>
    )}
  </button>
);

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  mediaId: PropTypes.number,
  selectedIcon: PropTypes.bool,
  categoryPill: PropTypes.bool,
  clickHandler: PropTypes.func,
  id: PropTypes.number,
  handleRemove: PropTypes.func
};

export default Badge;
