import produce from 'immer';
import isBefore from 'date-fns/is_before';
import addMonths from 'date-fns/add_months';

import * as actionTypes from '../actions/schedule';
import { daysOfWeek } from '../helpers/misc';

const initialState = {
  step: 1,
  savingStatus: {
    isSaving: false,
    completed: false,
    errorItems: [],
    completedItems: [],
    allItems: [],
    status: {}
  },
  cantSaves: [],
  acknowledged: true,
  submitDisabled: false,
  showCantDisable: null,
  playersFetched: {},
  playersFetchedByIds: [],
  selectedPlaylistsByPlayer: {},
  playerPlaylistsDates: {},
  playlistsFetchedByIds: [],
  playlistsFetched: {},
  preTaskInfo: {},
  disableCurrentPlaylists: {},
  advancedDeactivated: {},
  scheduleDetails: {},
  takeoverItems: {},
  playersWithErrors: [],
  playersWithErrorsOrg: [],
  selectedPlayer: {},
  selectedPlaylistPerFrame: {}
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GOTO_STEP:
      return produce(state, (draft) => {
        draft.step = action.step;
        if (action.step < 3) {
          draft.playerPlaylistsDates = {};
        }
      });
    case actionTypes.CANT_SAVE:
      return produce(state, (draft) => {
        draft.cantSaves = draft.cantSaves.filter((id) => id !== action.playerId);
        if (action.add) {
          draft.cantSaves.push(action.playerId);
        }
      });
    case actionTypes.ADD_PLAYER_WITH_ERROR:
      return produce(state, (draft) => {
        const alreadyExist = state.playersWithErrors.includes(action.playerId);
        if (!alreadyExist) {
          draft.playersWithErrors.push(action.playerId);
          draft.playersWithErrorsOrg.push(action.playerId);
        }
      });
    case actionTypes.SET_ACKNOWLEDGE:
      return produce(state, (draft) => {
        draft.acknowledged = action.acknowledged;
      });
    case actionTypes.DISABLE_SUBMIT_BUTTON:
      return produce(state, (draft) => {
        draft.submitDisabled = action.disabled;
      });
    case actionTypes.SHOW_CANT_DISABLE_ERROR:
      return produce(state, (draft) => {
        draft.showCantDisable = action.error;
      });
    case actionTypes.SET_PLAYER_STATUS:
      return produce(state, (draft) => {
        draft.savingStatus.status[action.playerId] = {
          ...draft.savingStatus.status[action.playerId],
          ...action.status
        };

        if (action.status.error) {
          draft.savingStatus.errorItems.push(action.playerId);
        }

        if (action.status.completed) {
          draft.savingStatus.completedItems.push(action.playerId);
        }
      });
    case actionTypes.START_SCHEDULING:
      return produce(state, (draft) => {
        draft.savingStatus.isSaving = true;
        draft.savingStatus.completed = false;
        draft.savingStatus.errorItems = [];
        draft.savingStatus.completedItems = [];
        draft.savingStatus.allItems = action.allItems;
      });
    case actionTypes.COMPLETE_SCHEDULING:
      return produce(state, (draft) => {
        draft.savingStatus.completed = true;
        draft.savingStatus.allItems = [];
      });
    case actionTypes.FINISH_SCHEDULING:
      return produce(state, (draft) => {
        const { keepStep } = action.options;
        draft.savingStatus.isSaving = false;
        draft.savingStatus.completed = true;
        if (action.reset) {
          draft.savingStatus.completed = false;
          if (!keepStep) {
            draft.step = 1;
          }
          draft.savingStatus.allItems = [];
          draft.savingStatus.errorItems = [];
          draft.savingStatus.completedItems = [];
          draft.savingStatus.status = {};
          draft.playersFetched = {};
          draft.playersFetchedByIds = [];
          draft.selectedPlaylistsByPlayer = {};
          draft.playerPlaylistsDates = {};
          draft.playlistsFetchedByIds = [];
          draft.playlistsFetched = {};
          draft.preTaskInfo = {};
          draft.takeoverItems = {};
          draft.cantSaves = [];
          draft.playersWithErrors = [];
          draft.playersWithErrorsOrg = [];
          draft.acknowledged = true;
          draft.submitDisabled = false;
          draft.showCantDisable = null;
          draft.disableCurrentPlaylists = {};
          draft.advancedDeactivated = {};
        }
      });
    case actionTypes.PUT_PRE_TASK_INFO:
      return produce(state, (draft) => {
        draft.preTaskInfo[action.playerId] = {
          ...draft.preTaskInfo[action.playerId],
          ...action.info
        };
      });
    case actionTypes.CHANGE_PLAYLIST_VALIDITY:
      return produce(state, (draftState) => {
        draftState.playerPlaylistsDates[action.playerId] =
          draftState.playerPlaylistsDates[action.playerId] || {};
        draftState.playerPlaylistsDates[action.playerId][action.playlistId] =
          draftState.playerPlaylistsDates[action.playerId][action.playlistId] || {};

        const { validFrom: oldValidFrom, validTo: oldValidTo } = state.playerPlaylistsDates[
          action.playerId
        ][action.playlistId];

        const dateData = { ...action.dateData };
        if (dateData.neverEnds) {
          dateData.validTo = new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999));
        }

        if (dateData.validFrom) {
          if (isBefore(oldValidTo, dateData.validFrom)) {
            dateData.validTo = new Date(new Date(dateData.validFrom).setHours(23, 59, 59, 999));
          }
        }
        if (dateData.validFrom && isBefore(oldValidTo, dateData.validFrom)) {
          return;
        }

        if (dateData.validTo && isBefore(dateData.validTo, oldValidFrom)) {
          if (dateData.neverEnds && isBefore(dateData.validTo, oldValidFrom)) {
            dateData.validTo = new Date(addMonths(oldValidFrom, 3).setHours(23, 59, 59, 999));
          } else {
            return;
          }
        }

        draftState.playerPlaylistsDates[action.playerId][action.playlistId] = Object.assign(
          {},
          draftState.playerPlaylistsDates[action.playerId][action.playlistId],
          dateData
        );
      });
    case actionTypes.SELECT_SCHEDULE_ITEMS:
      return produce(state, (draft) => {
        draft.selectedPlaylistsByPlayer[action.playerId] = action.selectedItems;
        if (!action.selectedItems.length) {
          draft.playersWithErrors = draft.playersWithErrors.filter((id) => id !== action.playerId);
        } else if (draft.playersWithErrorsOrg.includes(action.playerId)) {
          draft.playersWithErrors = [
            ...draft.playersWithErrors.filter((id) => id !== action.playerId),
            action.playerId
          ];
        }
      });
    case actionTypes.SELECT_TAKEOVER_ITEM:
      return produce(state, (draftState) => {
        draftState.takeoverItems[action.playerId] = action.selectedItem;
      });
    case actionTypes.PUT_PLAYER_DATA:
      return produce(state, (draft) => {
        draft.playersFetched = action.playersFetched;
        draft.playersFetchedByIds = action.playersFetchedByIds;
        action.playersFetchedByIds.forEach((p) => {
          draft.playerPlaylistsDates[p] = {};
          draft.disableCurrentPlaylists[p] = false;
        });
      });
    case actionTypes.PUT_PLAYLIST_DATA:
      return produce(state, (draft) => {
        draft.playlistsFetched = action.playlistsFetched;
        draft.playlistsFetchedByIds = action.playlistsFetchedByIds;
        draft.playersFetchedByIds.forEach((pl) => {
          action.playlistsFetchedByIds.forEach((plist) => {
            draft.playerPlaylistsDates[pl][plist] = {
              validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
              validTo: new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999)),
              neverEnds: true,
              selectedDays: daysOfWeek
            };
          });
        });
      });
    case actionTypes.ACTIVATE_DISABLED_PLAYLIST:
      return produce(state, (draft) => {
        draft.selectedPlaylistsByPlayer[action.playerId].push(action.playlistItemId);
        draft.scheduleDetails[action.playerId].activeItemsIds.push(action.playlistItemId);
        draft.scheduleDetails[action.playerId].disabledItemsIds = draft.scheduleDetails[
          action.playerId
        ].disabledItemsIds.filter((id) => id !== action.playlistItemId);

        draft.scheduleDetails[action.playerId].schedules[action.playlistItemId] = {
          ...draft.scheduleDetails[action.playerId].schedules[action.playlistItemId],
          validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
          neverEnds: true,
          validTo: new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999))
        };
        draft.scheduleDetails[action.playerId].activatedItemIds = draft.scheduleDetails[
          action.playerId
        ].activatedItemIds
          ? [...draft.scheduleDetails[action.playerId].activatedItemIds, action.playlistItemId]
          : [action.playlistItemId];
      });
    case actionTypes.PUT_SCHEDULE_FROM_PLAYLIST:
      return produce(state, (draft) => {
        draft.selectedPlaylistsByPlayer[action.playerId] = action.playlistSchedules.activeItemsIds;
        draft.scheduleDetails = {
          [action.playerId]: { ...action.playlistSchedules }
        };
      });
    case actionTypes.SET_SCHEDULE_DATES:
      return produce(state, (draftState) => {
        draftState.scheduleDetails[action.playerId].isDirty = true;
        const { validFrom: oldValidFrom, validTo: oldValidTo } = state.scheduleDetails[
          action.playerId
        ].schedules[action.playlistItemId];

        const dateData = { ...action.dateData };
        if (dateData.neverEnds) {
          dateData.validTo = new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999));
        }

        if (dateData.validFrom) {
          // dateData.validFrom = new Date(new Date().setHours(0, 0, 0, 0));

          if (isBefore(oldValidTo, dateData.validFrom)) {
            dateData.validTo = new Date(new Date(dateData.validFrom).setHours(23, 59, 59, 999));
          }
        } else if (isBefore(oldValidTo, dateData.validFrom)) {
          return;
        }

        // const today00 = new Date();

        if (dateData.validTo && isBefore(dateData.validTo, oldValidFrom)) {
          return;
        }

        draftState.scheduleDetails[action.playerId].schedules[action.playlistItemId] = {
          ...draftState.scheduleDetails[action.playerId].schedules[action.playlistItemId],
          ...dateData
        };
      });
    case actionTypes.DISABLE_CURRENT_PLAYLISTS:
      return produce(state, (draft) => {
        draft.disableCurrentPlaylists[action.playerId] = action.isChecked;
      });
    case actionTypes.DEACTIVATE_ADVANCED:
      return produce(state, (draft) => {
        draft.advancedDeactivated[action.playerId] = action.isChecked;
      });
    case actionTypes.PLAYER_APPLY_ALL:
      return produce(state, (draft) => {
        const playerIds = Object.keys(draft.playerPlaylistsDates);
        playerIds.forEach((pid) => {
          draft.advancedDeactivated[pid] = draft.advancedDeactivated[action.playerId];
          draft.disableCurrentPlaylists[pid] = draft.disableCurrentPlaylists[action.playerId];
          draft.selectedPlaylistsByPlayer[pid] = draft.selectedPlaylistsByPlayer[action.playerId];
          draft.takeoverItems[pid] = draft.takeoverItems[action.playerId];
          draft.playerPlaylistsDates[pid] = draft.playerPlaylistsDates[action.playerId];
        });
      });
    case actionTypes.SET_PLAYER_DATA:
      return produce(state, (draft) => {
        draft.selectedPlayer.playerId = Number(action.playerId);
        draft.selectedPlayer.playerName = action.playerName;
      });
    case actionTypes.CHECK_ERRORS_WITH_PLAYER:
      return produce(state, (draft) => {
        if (action.selectedItems && !action.selectedItems.length) {
          draft.playersWithErrors = draft.playersWithErrors.filter((id) => id !== action.playerId);
        } else if (draft.playersWithErrorsOrg.includes(action.playerId)) {
          draft.playersWithErrors = [
            ...draft.playersWithErrors.filter((id) => id !== action.playerId),
            action.playerId
          ];
        }
      });
    case actionTypes.SELECT_PLAYLIST_PER_FRAME:
      return produce(state, (draft) => {
        draft.selectedPlaylistPerFrame[action.playerId] = action.playlistPerFrame;
      });
    default:
      return state;
  }
};

export default scheduleReducer;
