import React, { Component } from 'react';

import {
  formatCloudinaryFields,
  transformCloudinaryImage,
  copyToClipboard,
  cnSecureUrl
} from '../../helpers/utils';
import { cloudinaryThumbnailTransforms } from '../../helpers/misc';

import store from '../../stores';
import { openModal } from '../../actions/modal';
import ActionButton from '../commons/ActionButton/ActionButton';
import { ReactComponent as LinkIcon } from '../../assets/icons/link-solid.svg';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi, callUrlWrapperApi } from '../../serviceLayer/services';

export default class Thumbnail extends Component {
  state = {
    thumbnailUrl: '',
    originalURL: '',
    hasVideoIcon: false,
    single: true,
    messageImg: ''
  };

  componentDidMount() {
    const { type } = this.props;

    if (type === 'media') {
      this.getThumbnailForMedia();
    }

    if (type === 'playlist') {
      this.getThumbnailForPlaylist();
    }

    if (type === 'playlist-single') {
      this.getThumbnailForPlaylistItems();
    }
  }

  getThumbnailForMedia = () => {
    const { thumbnailUrl, cloudinaryData } = this.props;
    let hasVideoIcon = false;
    let originalURL = '';
    let messageImg = '';

    const { secure_url: secureUrl } = cloudinaryData;
    originalURL = secureUrl;
    if (cloudinaryData.resource_type === 'message') {
      getAccessTokenAndMakeCalls(token => callUrlWrapperApi(token, { url: thumbnailUrl })).then(
        res => {
          const d = res && Buffer.from(res).toString('base64');
          messageImg = d ? `data:image/png;base64,${d}` : '';
          hasVideoIcon = cloudinaryData.resource_type === 'video';
          this.setState({ thumbnailUrl, messageImg, hasVideoIcon, originalURL });
        }
      );
    } else {
      hasVideoIcon = cloudinaryData.resource_type === 'video';
      this.setState({ thumbnailUrl, hasVideoIcon, originalURL });
    }
  };

  getThumbnailForPlaylist = () => {
    const { thumbnailUrl, cloudinaryData } = this.props;
    let messageImg = '';
    const hasVideoIcon = cloudinaryData.resource_type === 'video';

    if (cloudinaryData.resource_type === 'message') {
      getAccessTokenAndMakeCalls(token => callUrlWrapperApi(token, { url: thumbnailUrl })).then(
        res => {
          const d = res && Buffer.from(res).toString('base64');
          messageImg = d ? `data:image/png;base64,${d}` : '';
          this.setState({
            thumbnailUrl,
            messageImg,
            hasVideoIcon,
            single: false
          });
        }
      );
    } else {
      this.setState({
        thumbnailUrl,
        hasVideoIcon,
        single: false
      });
    }
  };

  getThumbnailForPlaylistItems = () => {
    const {
      item,
      thumbnailUrl,
      item: { playlistItems },
      cloudinaryData
    } = this.props;
    let hasVideoIcon = false;
    let newData = {};
    let messageImg = '';

    if (thumbnailUrl) {
      hasVideoIcon = cloudinaryData.resource_type === 'video';
      if (cloudinaryData.resource_type === 'message') {
        getAccessTokenAndMakeCalls(token => callUrlWrapperApi(token, { url: thumbnailUrl })).then(
          res => {
            const d = res && Buffer.from(res).toString('base64');
            messageImg = d ? `data:image/png;base64,${d}` : '';
            this.setState({
              thumbnailUrl,
              messageImg,
              hasVideoIcon,
              single: false
            });
          }
        );
      } else {
        this.setState({
          thumbnailUrl,
          hasVideoIcon,
          single: false
        });
      }
      return;
    }

    if (playlistItems && playlistItems.length) {
      // if nothing was found go through playlistitems
      // and search until newData.thumbnailUrl will be returned
      playlistItems.some(playlistItem => {
        playlistItem.subplaylist &&
          this.fetchPlaylistItemData(playlistItem.subplaylist.id).then(response => {
            if (response.playlistItems) {
              newData = this.getDataForPlaylistItems(response.playlistItems);
              this.setState({
                ...newData
              });
            }
          });
        return newData.thumbnailUrl;
      });
    } else if (item.subplaylist) {
      this.fetchPlaylistItemData(item.subplaylist.id).then(response => {
        if (response.playlistItems) {
          newData = this.getDataForPlaylistItems(response.playlistItems);
          this.setState({
            ...newData
          });
        }
      });
    }
  };

  fetchPlaylistItemData = pid =>
    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }));

  getDataForPlaylistItems = playlistItems => {
    let thumbnailUrl;
    let loopCount = 0;
    let cloudinaryData = {};
    let hasVideoIcon = false;

    // go through all items;
    // if any thumbnail was found break loop
    while (playlistItems && !thumbnailUrl && loopCount < playlistItems.length) {
      if (
        playlistItems[loopCount].media &&
        playlistItems[loopCount].media.mediaType === 'MESSAGE'
      ) {
        cloudinaryData = formatCloudinaryFields(playlistItems[loopCount].media.fields);
        hasVideoIcon = cloudinaryData.resource_type === 'video';
        thumbnailUrl = transformCloudinaryImage(
          cloudinaryData,
          cloudinaryThumbnailTransforms.medialist
        );
      }

      loopCount += 1;
    }

    return { thumbnailUrl, hasVideoIcon, single: false };
  };

  render() {
    const { item, findId } = this.props;
    const { thumbnailUrl, hasVideoIcon, originalURL, single, messageImg } = this.state;

    if (thumbnailUrl && single) {
      return (
        <div className="thumbnail__wrapper">
          <button
            type="button"
            className={`image__wrapper ${hasVideoIcon ? 'image__wrapper--video' : ''}`}
            onClick={() => {
              const body = { items: [item], type: 'media' };
              if (findId) {
                body.find = findId;
              }
              store.dispatch(openModal('previewItems', body));
            }}
          >
            {<img className="image-styling" src={messageImg || thumbnailUrl} alt="" />}
          </button>
          <div className="thumbnail__action-buttons">
            <ActionButton
              click={() => {
                copyToClipboard(cnSecureUrl(originalURL || thumbnailUrl));
              }}
              startMessage="Copy URL"
              successMessage="Copied!"
            >
              <LinkIcon />
            </ActionButton>
          </div>
        </div>
      );
    }

    if (thumbnailUrl && !single) {
      return (
        <div className="paper-effect">
          <div className="thumbnail__wrapper thumbnail__wrapper--playlist">
            <button
              type="button"
              className={`image__wrapper ${hasVideoIcon ? 'image__wrapper--video' : ''}`}
              onClick={() => {
                const body = { items: [item], type: 'playlist' };
                if (findId) {
                  body.find = findId;
                }
                store.dispatch(openModal('previewItems', body));
              }}
            >
              <img className="image-styling" src={messageImg || thumbnailUrl} alt="" />
            </button>
          </div>
        </div>
      );
    }

    return <div>No image</div>;
  }
}
