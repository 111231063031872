import { LOAD_AUTH, USER_PRIVILEGE } from '../actions/auth';

const initialState = {
  roles: [],
  name: '',
  email: '',
  userPrivilege: { isAllowed: true }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUTH:
      return {
        ...state,
        ...action.data
      };
    case USER_PRIVILEGE:
      return {
        ...state,
        userPrivilege: {
          isAllowed: action.data.isAllowed
        }
      };

    default:
      return state;
  }
};

export default authReducer;
