import get from 'lodash-es/get';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import differenceInDays from 'date-fns/difference_in_days';
import addDays from 'date-fns/add_days';
import addMonths from 'date-fns/add_months';
import addMins from 'date-fns/add_minutes';
import subDays from 'date-fns/sub_days';

import { axios, trimInputValue, parseMetadata, axiosOrg } from '../../helpers/utils';
import constants from '../../helpers/constants';
import {
  daysOfWeek,
  datesTimes,
  playlistMetadataSeparator,
  ActivityLogOperationTypes,
  ActivityLogScheduleTypes,
  UITEXT
} from '../../helpers/misc';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callCreatePlaylistApi,
  callFetchFrameManagementActivityLogsApi,
  callFetchSchedulingActivityLogsApi,
  callGetChannelApi,
  callGetPlayerApi,
  callGetPlaylistApi,
  callUpdatePlaylistApi,
  callSendLogApi,
  callUpdatePlayerSchedulesApi,
  callPlayerSchedulesApi,
  callRemovePlaylistApi
} from '../../serviceLayer/services';

export const createPlaylist = async (name, description = '') => {
  const params = {
    name,
    description
  };
  getAccessTokenAndMakeCalls(token => callCreatePlaylistApi(token, params));
};

export const cachebust = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const findPlaylistByName = async name =>
  axios.get(`/api/rest/playlists/findByName/${name}`, {
    params: {
      field: 'id,name',
      cachebust: cachebust()
    }
  });

export const findChannelByName = async channelName =>
  axios.get('/api/rest/channels', {
    params: {
      filters: `{name: {values : ['${channelName}', '%${channelName}', '%${channelName}%', '${channelName}%']}, comparator : "like"}`,

      fields: 'id,name,frameset',
      cachebust: cachebust()
    }
  });

export const randomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.round(Math.random() * 15)];
  }
  return color;
};

export const createChannel = (channelName, resolution) => {
  const framesetId =
    constants.framesetTempIds[resolution] || constants.framesetTempIds['1920x1080'];
  const channelTemp = {
    frameset: {
      id: framesetId
    },
    name: channelName,
    playDedicatedAudioTrack: false,
    type: 'AUDIOVISUAL'
  };

  return axios.post('/api/rest/channels', channelTemp);
};

export const getChannelInfo = (channelId, params = {}) =>
  getAccessTokenAndMakeCalls(token =>
    callGetChannelApi(token, {
      channelId,
      params
    })
  )
    .then(res => res.data)
    .catch(e => console.log(e));

export const getPlaylistInfo = playlistId =>
  getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId }));

export const getFrameDetails = async (channelId, params = {}) =>
  axiosOrg.get(`${constants.FRAMES_DETAILS_URL}`, {
    params: {
      channelIds: channelId
    }
  });

export const schedulePlaylists = params =>
  axiosOrg
    .post(constants.SCHEDULE_PLAYLISTS, params)
    .then(res => res.data)
    .catch(e => e);

export const updatePlaylistInfo = (playlistId, newPlaylistInfo) => {
  getAccessTokenAndMakeCalls(token =>
    callUpdatePlaylistApi(token, { playlistId, ...newPlaylistInfo })
  );
};

export const updateChannelInfo = (channelId, newChannelInfo) =>
  axios.put(`/api/rest/channels/${channelId}`, newChannelInfo);

export const updatePlayerInfo = (playerId, playerInfo) =>
  axios.put(`/api/rest/players/${playerId}`, playerInfo);

export const getPlayerInfo = (playerId, params = {}) =>
  getAccessTokenAndMakeCalls(token =>
    callGetPlayerApi(token, {
      playerId,
      params
    })
  );

export const getTimeslots = async (channelId, frameId, params = {}) =>
  axios.get(`/api/rest/channels/${channelId}/frames/${frameId}/timeslots`, {
    params: {
      fromDate: '1900-01-01',
      toDate: '2030-01-02',
      ...params
    }
  });
export const joinPlaylists = (playlists, key) =>
  playlists
    .map(p => {
      const value = trimInputValue(String(p[key]));
      const maxChars = (1024 - (playlists.length - 1) * playlists.length) / playlists.length;
      const substr = value.length > maxChars ? `${value.substr(0, maxChars - 3)}...` : value;
      return substr;
    })
    .join(playlistMetadataSeparator);

export const addPlaylistMetadata = async ({ playerId, playerInfo, playlists }) => {
  const metadataIds = Object.values(constants.playerMetadata);
  const { ScheduledPlaylistIds, ScheduledPlaylistNames } = constants.playerMetadata;
  const meta = get(playerInfo, 'metadataValue', []);
  const without = meta.filter(m => !metadataIds.includes(m.playerMetadata.id));

  const metadataValue = [
    ...without,
    {
      value: joinPlaylists(playlists, 'id'),
      playerMetadata: { id: ScheduledPlaylistIds }
    },
    {
      value: joinPlaylists(playlists, 'name'),
      playerMetadata: { id: ScheduledPlaylistNames }
    }
  ];
  const newPlayerInfo = { ...playerInfo, metadataValue };
  return updatePlayerInfo(playerId, newPlayerInfo);
};

export const removePlaylistFromMetadata = async (playerIds, playlistId) => {
  if (!playerIds.length) {
    return false;
  }
  const { ScheduledPlaylistIds, ScheduledPlaylistNames } = constants.playerMetadata;

  playerIds.forEach(async playerId => {
    const playerInfo = (await getPlayerInfo(playerId).then(res => res.data)) || {};
    const meta = get(playerInfo, 'metadataValue', []);
    const { value: idsStr = '' } =
      meta.find(m => m.playerMetadata.id === ScheduledPlaylistIds) || {};
    const { value: namesStr = '' } =
      meta.find(m => m.playerMetadata.id === ScheduledPlaylistNames) || {};
    const ids = idsStr.split(playlistMetadataSeparator);
    const index = ids.indexOf(playlistId);
    ids.splice(index, 1);
    const names = namesStr.split(playlistMetadataSeparator);
    names.splice(index, 1);

    if (!ids.length || !names.length) {
      return false;
    }

    const playlists = ids.map((id, index1) => ({
      id,
      name: names[index1]
    }));

    await addPlaylistMetadata({ playerId, playerInfo, playlists });

    return false;
  });

  return false;
};

export const splitSchedule = formatted => {
  const { startValidDate: startDate, endValidDate: endDate, timeSchedules } = formatted;
  const validFrom = new Date(startDate);
  const validTo = new Date(endDate);
  const { startTime } = timeSchedules[0];
  let { endTime } = timeSchedules[0];

  if (endDate === datesTimes.scalaNeverEndsDate) {
    endTime = '23:59';
  }

  const newPlaylistItemsFormatted = [];

  const moreThan1d = differenceInDays(validTo, validFrom) > 1;

  if (startDate !== endDate) {
    if (startTime === '00:00' && endTime === '23:59') {
      newPlaylistItemsFormatted.push(formatted);
    } else {
      if (startTime !== '00:00') {
        newPlaylistItemsFormatted.push({
          ...formatted,
          endValidDate: startDate,
          timeSchedules: [
            {
              ...timeSchedules[0],
              startTime,
              endTime: '23:59'
            }
          ]
        });
      }

      if (endTime !== '23:59') {
        if (moreThan1d) {
          newPlaylistItemsFormatted.push({
            ...formatted,
            startValidDate:
              startTime !== '00:00'
                ? formatDate(
                    addDays(new Date(formatted.startValidDate), 1),
                    datesTimes.scalaDateFormat
                  )
                : formatted.startValidDate,
            endValidDate: formatDate(
              subDays(new Date(formatted.endValidDate), 1),
              datesTimes.scalaDateFormat
            ),
            timeSchedules: [
              {
                ...timeSchedules[0],
                startTime: '00:00',
                endTime: '23:59'
              }
            ]
          });
        }
        newPlaylistItemsFormatted.push({
          ...formatted,
          startValidDate: endDate,
          endValidDate: endDate,
          timeSchedules: [
            {
              ...timeSchedules[0],
              startTime: '00:00',
              endTime
            }
          ]
        });
      } else {
        newPlaylistItemsFormatted.push({
          ...formatted,
          startValidDate: formatDate(
            addDays(new Date(formatted.startValidDate), 1),
            datesTimes.scalaDateFormat
          ),

          timeSchedules: [
            {
              ...timeSchedules[0],
              startTime: '00:00',
              endTime: '23:59'
            }
          ]
        });
      }
    }
  } else {
    newPlaylistItemsFormatted.push(formatted);
  }

  return newPlaylistItemsFormatted;
};

export const formatScheduleToPut = (
  id,
  subItem,
  itemId = null,
  disabled = false,
  split = false
) => {
  const { validFrom, neverEnds, playlistName = '' } = subItem;
  let { validTo } = subItem;

  validTo = isBefore(validTo, validFrom) ? new Date(addMins(new Date(validFrom), 1)) : validTo;
  const startValidDate = formatDate(validFrom, datesTimes.scalaDateFormat);
  const startTime = formatDate(validFrom, datesTimes.scalaTimeFormat);
  const endValidDate = neverEnds
    ? datesTimes.scalaNeverEndsDate
    : formatDate(validTo, datesTimes.scalaDateFormat);
  let endTime = formatDate(validTo, datesTimes.scalaTimeFormat);

  if (startValidDate === endValidDate && startTime === endTime && endTime !== '23:59') {
    endTime = formatDate(addMins(validTo, 1), datesTimes.scalaTimeFormat);
  }

  const schedule = {
    disabled,
    subplaylist: {
      id,
      name: playlistName
    },
    startValidDate,
    endValidDate,
    useValidRange: true,
    timeSchedules: [
      {
        startTime,
        endTime,
        days: daysOfWeek
      }
    ]
  };
  if (itemId) {
    schedule.id = itemId;
  }

  return split ? splitSchedule(schedule) : schedule;
};

const channelVariables = {
  cloudinary_urls: 'STRING',
  content_has_played: 'BOOLEAN',
  playedfiles: 'STRING',
  FallbackClock: 'INTEGER',
  social_tags: 'STRING'
};

export const getMissingVars = (vars = []) => {
  const hasVars = [];
  vars.forEach(v => {
    if (
      channelVariables[v.sharedName] &&
      channelVariables[v.sharedName] === v.type &&
      v.name === `Channel.${v.sharedName}`
    ) {
      hasVars.push(v.sharedName);
    }
  });

  return Object.keys(channelVariables).filter(ov => !hasVars.includes(ov));
};

export const createChannelVariables = async channelInfo => {
  const { variables = [] } = channelInfo;
  const missingVars = getMissingVars(variables);

  if (!missingVars.length) {
    return channelInfo;
  }

  const newVariables = missingVars.map(mv => ({
    controlScript: { id: -1 },
    name: `Channel.${mv}`,
    sharedName: mv,
    type: channelVariables[mv]
  }));

  const newChannelInfo = {
    ...channelInfo,
    variables: [...(channelInfo.variables || []), ...newVariables]
  };

  return updateChannelInfo(channelInfo.id, newChannelInfo).then(() => newChannelInfo);
};

const createChannelDataFrame = async channelInfo => {
  const height = 1080;
  const width = 1920;
  // const validFrames = ['Main'];
  const frames = get(channelInfo, 'frameset.frames', []);
  const mainFrame = frames.find(f => f.name === 'Main');
  const dataFrame = frames.find(f => f.name === 'DATA');
  // remove other frames
  // const newFrames = [...frames].filter(frame => validFrames.includes(frame.name));

  // incase main or data frame isnt present we add without id which gets generated in backend
  const mainFrameNew = mainFrame || {
    top: 0,
    left: 0,
    width,
    height,
    name: 'Main',
    hidden: false,
    autoscale: 'FILL_EXACTLY',
    id: '',
    color: `#ccccff`,
    zOrder: 2
  };

  const dataFrameNew = dataFrame || {
    top: 0,
    left: 0,
    width,
    height,
    name: 'DATA',
    hidden: true,
    autoscale: 'FIT_INSIDE',
    id: '',
    color: `#99ccff`,
    zOrder: 1
  };

  if (!mainFrame) {
    frames.push({ ...mainFrameNew });
  }

  if (!dataFrame) {
    frames.push({ ...dataFrameNew });
  }

  const newChannelInfo = {
    ...channelInfo,
    frameset: {
      ...channelInfo.frameset,
      frames
    }
  };
  return updateChannelInfo(channelInfo.id, newChannelInfo).then(() => Boolean(true));
};

export const checkChannel = async channelInfo => {
  const hasVars = await createChannelVariables(channelInfo);
  const hasData = await createChannelDataFrame(hasVars);

  return hasVars && hasData;
};

export const addRemoveCisFromPlaylist = async (playlistId, action) => {
  const playlistInfo = await getPlaylistInfo(playlistId).then(resPlaylist => resPlaylist.data);

  if (!playlistInfo) {
    return false;
  }

  let { playlistItems = [] } = playlistInfo;

  const hasCis = playlistItems.find(
    item => item.subplaylist && item.subplaylist.id === constants.cisId
  );

  if (action === 'remove') {
    playlistItems = playlistItems.map(item => {
      if (item.subplaylist && item.subplaylist.id === constants.cisId) {
        return {
          ...item,
          deleteFlag: true
        };
      }

      return item;
    });
  } else if (!hasCis) {
    playlistItems = [
      ...playlistItems,
      {
        playlistItemType: 'SUB_PLAYLIST',
        subplaylist: {
          id: constants.cisId
        },
        startValidDate: formatDate(new Date(), datesTimes.scalaDateFormat),
        endValidDate: datesTimes.scalaNeverEndsDate,
        useValidRange: true,
        sortOrder: playlistItems.length
      }
    ];
  }
  return updatePlaylistInfo(playlistId, { ...playlistInfo, playlistItems });
};

export const newParsePlaylistItemsToSchedules = (playlistItems = []) => {
  const allExceptCis = playlistItems.filter(
    item => item.subplaylist && item.subplaylist.id !== constants.cisId
  );

  const playlistSchedules = [];
  const toBeSkipped = [];
  const allGroupings = {};

  allExceptCis.forEach((playlistItem, index) => {
    if (toBeSkipped.includes(playlistItem.id)) {
      return;
    }
    const secondItem = allExceptCis[index + 1];
    const thirdItem = allExceptCis[index + 2];
    const isDisabled = playlistItem.disabled;
    const startTime = get(playlistItem, 'timeSchedules.0.startTime', '00:00');
    let endTime = get(playlistItem, 'timeSchedules.0.endTime', '23:59');
    let endDate = get(playlistItem, 'endValidDate', datesTimes.scalaNeverEndsDate);

    endTime = endTime === '24:00' ? '23:59' : endTime;
    const fromStr =
      playlistItem.startValidDate || formatDate(new Date(), datesTimes.scalaDateFormat);

    const groupName = playlistItem.id;

    let grouping = [];

    if (
      secondItem &&
      secondItem.subplaylist.id === playlistItem.subplaylist.id &&
      secondItem.startValidDate ===
        formatDate(addDays(new Date(endDate), 1), datesTimes.scalaDateFormat) &&
      get(secondItem, 'timeSchedules.0.startTime', '00:00') === '00:00'
    ) {
      toBeSkipped.push(secondItem.id);
      if (
        thirdItem &&
        thirdItem.subplaylist.id === secondItem.subplaylist.id &&
        get(secondItem, 'endValidDate', datesTimes.scalaNeverEndsDate) !==
          datesTimes.scalaNeverEndsDate &&
        thirdItem.startValidDate ===
          formatDate(addDays(new Date(secondItem.endValidDate), 1), datesTimes.scalaDateFormat)
      ) {
        endDate = thirdItem.endValidDate;
        endTime = get(thirdItem, 'timeSchedules.0.endTime', '23:59');

        toBeSkipped.push(thirdItem.id);
        grouping = [playlistItem.id, secondItem.id, thirdItem.id];
      } else {
        endDate = secondItem.endValidDate;
        endTime = get(secondItem, 'timeSchedules.0.endTime', '23:59');
        grouping = [playlistItem.id, secondItem.id];
      }
    } else {
      grouping = [playlistItem.id];
    }
    allGroupings[groupName] = grouping;
    let neverEnds = false;
    if (endDate === datesTimes.scalaNeverEndsDate) {
      neverEnds = true;
    }

    const hasExpired = isBefore(`${endDate} ${endTime}`, new Date());

    const schedule = {
      id: playlistItem.id,
      playlistId: playlistItem.subplaylist.id,
      validFrom: new Date(`${fromStr} ${startTime}`),
      validTo: new Date(`${endDate} ${endTime}`),
      neverEnds,
      selectedDays: daysOfWeek,
      hasExpired,
      disabled: isDisabled,
      item: playlistItem
    };

    playlistSchedules.push(schedule);
  });

  return { parsed: playlistSchedules, grouping: allGroupings };
};

export const isTakeoverSlot = slot =>
  slot.alternateType === 'TRANSPARENT' &&
  slot.color === '#ff0000' &&
  slot.priorityClass === 'ALWAYS_ON_TOP';

export const getTakeoverList = (timeslots = []) => {
  const takeovers = timeslots.filter(slot => isTakeoverSlot(slot)).sort((a, b) => a.id - b.id);

  let takeoverPlaylist;

  if (takeovers.length) {
    const { id: takeoverPlaylistId, name: takeoverPlaylistName } = takeovers[0].playlist;
    takeoverPlaylist = {
      id: takeoverPlaylistId,
      name: takeoverPlaylistName,
      startDate: takeovers[0].startDate,
      startTime: takeovers[0].startTime.slice(0, -3),
      endDate: takeovers.slice(-1)[0].endDate,
      endTime: takeovers.slice(-1)[0].endTime.slice(0, -3)
    };
  }

  return takeoverPlaylist;
};

export const cleanUpTakeover = async data => {
  const { playlistItems = [], channelId, mainFrameId } = data;
  const timeslots = await getTimeslots(channelId, mainFrameId).then(res =>
    get(res, 'data.timeslots', [])
  );

  const { parsed: parsedPlaylistItems } = newParsePlaylistItemsToSchedules(playlistItems);

  const takeoverPlaylist = getTakeoverList(timeslots);

  if (takeoverPlaylist) {
    const found = parsedPlaylistItems.find(item => {
      const { validFrom, validTo } = item;
      const startDate = formatDate(validFrom, datesTimes.scalaDateFormat);
      const endDate = formatDate(validTo, datesTimes.scalaDateFormat);
      const startTime = formatDate(validFrom, datesTimes.scalaTimeFormat);
      const endTime = formatDate(validTo, datesTimes.scalaTimeFormat);
      const checkPart1 =
        item.playlistId === takeoverPlaylist.id &&
        startDate === takeoverPlaylist.startDate &&
        endDate === takeoverPlaylist.endDate;

      if (
        checkPart1 &&
        startTime === takeoverPlaylist.startTime &&
        endTime === takeoverPlaylist.endTime
      ) {
        return true;
      }
      return false;
    });

    if (found && (found.disabled || found.hasExpired)) {
      const scheduleData = {
        frames: [
          {
            timeslots: timeslots.map(slot => ({ ...slot, deleteFlag: isTakeoverSlot(slot) })),
            id: mainFrameId
          }
        ]
      };
      return axios.put(`/api/rest/channels/${channelId}/schedules`, scheduleData);
    }
  }

  return false;
};

export const getPreviousTakeoverDetails = async (channelId, frameId) => {
  const allTimeslots = await getTimeslots(channelId, frameId).then(res =>
    get(res, 'data.timeslots', [])
  );
  const previousTakeover = allTimeslots.find(slot => isTakeoverSlot(slot)) || {};
  if (previousTakeover && Object.keys(previousTakeover).length > 0) {
    const { playlist } = previousTakeover;
    const { endDate, endTime, startDate, startTime } = previousTakeover;
    return {
      operationType: ActivityLogOperationTypes.edit,
      playlistName: playlist.name,
      playlistId: Number(playlist.id),
      scheduleType: ActivityLogScheduleTypes.normal,
      validFrom: `${formatDate(startDate, datesTimes.scalaDateFormatPretty)} - ${
        startTime.length >= 5 ? startTime.substring(0, 5) : '00:00'
      }`,
      validTo: `${formatDate(endDate, datesTimes.scalaDateFormatPretty)} - ${
        endTime.length >= 5 ? endTime.substring(0, 5) : '23:59'
      }`
    };
  }
  return previousTakeover;
};

export const splitTakeoverSchedule = raw => {
  const { validFrom, validTo } = raw;

  const startDate = formatDate(validFrom, datesTimes.scalaDateFormat);
  const endDate = formatDate(validTo, datesTimes.scalaDateFormat);

  const startTime = formatDate(validFrom, datesTimes.scalaTimeFormat);
  const endTime = formatDate(validTo, datesTimes.scalaTimeFormat);

  const newTakeoverPlaylists = [];
  const moreThan1d = differenceInDays(validTo, validFrom) > 1;

  const formatted = {
    startDate,
    endDate,
    startTime,
    endTime
  };

  if (startDate !== endDate) {
    if (startTime === '00:00' && endTime === '23:59') {
      newTakeoverPlaylists.push(formatted);
    } else {
      if (startTime !== '00:00') {
        newTakeoverPlaylists.push({
          ...formatted,
          endDate: startDate,
          startTime,
          endTime: '23:59'
        });
      }

      if (endTime !== '23:59') {
        if (moreThan1d) {
          newTakeoverPlaylists.push({
            startDate:
              startTime !== '00:00'
                ? formatDate(addDays(validFrom, 1), datesTimes.scalaDateFormat)
                : startDate,
            endDate: formatDate(subDays(validTo, 1), datesTimes.scalaDateFormat),
            startTime: '00:00',
            endTime: '23:59'
          });
        }
        newTakeoverPlaylists.push({
          startDate: endDate,
          endDate,
          startTime: '00:00',
          endTime
        });
      } else {
        newTakeoverPlaylists.push({
          ...formatted,
          startDate: formatDate(addDays(validFrom, 1), datesTimes.scalaDateFormat),
          startTime: '00:00',
          endTime: '23:59'
        });
      }
    }
  } else {
    newTakeoverPlaylists.push(formatted);
  }

  return newTakeoverPlaylists;
};

export const schedulePlaylistToFrame = async (channelId, frameId, playlistId, takeover) => {
  const allTimeslots = await getTimeslots(channelId, frameId).then(res =>
    get(res, 'data.timeslots', [])
  );

  let currentTimeslots = allTimeslots
    .filter(slot => !isTakeoverSlot(slot))
    .map(slot => ({ ...slot, deleteFlag: true }));

  if (takeover && takeover.id) {
    const splitTakover = splitTakeoverSchedule(takeover);
    currentTimeslots = [
      ...allTimeslots.map(slot => ({ ...slot, deleteFlag: true })),
      ...splitTakover.map(({ startDate, endDate, startTime, endTime }) => ({
        alternateType: 'TRANSPARENT',
        color: '#ff0000',
        priorityClass: 'ALWAYS_ON_TOP',

        startDate,
        endDate,
        startTime: `${startTime}:00`,
        endTime: `${endTime}:59`,
        playlist: {
          id: takeover.id
        },
        recurrencePattern: 'WEEKLY',
        weekdays: daysOfWeek
      }))
    ];
  }

  if (takeover === null) {
    currentTimeslots = allTimeslots.map(slot => ({ ...slot, deleteFlag: true }));
  }

  const timeslots = [
    ...currentTimeslots,
    {
      playlist: {
        id: playlistId
      },
      recurrencePattern: 'WEEKLY',
      startDate: formatDate(new Date(), datesTimes.scalaDateFormat),
      weekdays: daysOfWeek,
      color: `#${randomColor()}`,
      hasPriorityClassChanged: false
    }
  ].map((slot, i) => ({ ...slot, sortOrder: i + 1 }));

  const scheduleData = {
    frames: [
      {
        timeslots,
        id: frameId
      }
    ]
  };

  return axios.put(`/api/rest/channels/${channelId}/schedules`, scheduleData);
};

export const checkMPScripts = async playlistInfo => {
  const { playlistItems: oldPlaylistItems = [] } = { ...playlistInfo };
  let sortOrder = 0;
  const removed = oldPlaylistItems.map(opi => {
    if (opi.subplaylist && opi.subplaylist.id === constants.cisId) {
      return {
        ...opi,
        deleteFlag: true
      };
    }
    sortOrder += 1;

    return { ...opi, sortOrder };
  });
  const added = [
    ...removed,
    {
      playlistItemType: 'SUB_PLAYLIST',
      subplaylist: {
        id: constants.cisId
      },
      startValidDate: formatDate(new Date(), datesTimes.scalaDateFormat),
      endValidDate: datesTimes.scalaNeverEndsDate,
      useValidRange: true,
      sortOrder: sortOrder + 1
    }
  ];

  return updatePlaylistInfo(playlistInfo.id, { ...playlistInfo, playlistItems: added });
};

export const parseScalaScheduleToDate = playlistItem => {
  const startTime = get(playlistItem, 'timeSchedules.0.startTime', '00:00');
  const days = get(playlistItem, 'timeSchedules.0.days', daysOfWeek);
  let endTime = get(playlistItem, 'timeSchedules.0.endTime', '23:59');
  const endDate = get(playlistItem, 'endValidDate', datesTimes.scalaNeverEndsDate);
  endTime = endTime === '24:00' ? '23:59' : endTime;

  const validTo = new Date(`${endDate} ${endTime}`);
  const validFrom = playlistItem.startValidDate
    ? new Date(`${playlistItem.startValidDate} ${startTime}`)
    : new Date();
  const hasExpired = isBefore(validTo, new Date());
  const parsed = {
    validFrom,
    validTo,
    neverEnds: endDate === datesTimes.scalaNeverEndsDate,
    selectedDays: days,
    hasExpired
  };
  return parsed;
};

export const checkInvalidSchedules = schedules => {
  const cantDisableIds = [];
  schedules.forEach(_act => {
    const act = _act.validFrom ? _act : parseScalaScheduleToDate(_act);
    const { validFrom, validTo } = act;

    const hasGap = isAfter(validFrom, new Date());
    const endsWithinWeek = isBefore(new Date(validTo), addDays(new Date(), 7));

    if (hasGap || endsWithinWeek) {
      cantDisableIds.push(act.id);
    }
  });

  return cantDisableIds;
};

const thr = text => {
  throw new Error(text);
};

export const getChannelSchedule = async playerId =>
  getAccessTokenAndMakeCalls(token =>
    callPlayerSchedulesApi(token, {
      id: playerId
    })
  );

export const saveChannelSchedule = async data =>
  getAccessTokenAndMakeCalls(token => callUpdatePlayerSchedulesApi(token, [...data]));

export const removePlaylist = async data =>
  getAccessTokenAndMakeCalls(token => callRemovePlaylistApi(token, { ...data }));

export const getPlayerSchedule = async playerId => {
  const playerInfo = await getPlayerInfo(playerId)
    .then(res => res.data)
    .catch(() => thr('Oops! Something went wrong.'));

  const { name: playerName, metadataValue: metadata } = playerInfo;
  const parsedMetadata = parseMetadata(metadata);

  const channelId = get(playerInfo, 'playerDisplays.0.channel.id', null);
  if (!channelId) {
    thr(UITEXT.noChannelAssignedMessage);
  }

  const channelInfo = await getChannelInfo(channelId);

  const frames = await getFrameDetails(channelId).then(res => res.data);
  const framesData = [];
  for (const frame of frames[channelId]) {
    let frameData = {};
    const frameName = frame.name;
    const allTimeslots = await getTimeslots(channelId, frame.frameId).then(resTimeslots =>
      get(resTimeslots, 'data.timeslots', [])
    );
    let playlist = {};
    let nonMps = [];
    // For Main frame we get the playlist from the timeslot that has playerName_frameName / playerName
    // playerName is ignored over playerName_frameName
    // any other playlists / media items are ignored
    playlist = allTimeslots.find(slot => slot.playlist.name === `${playerName}_${frameName}`);
    if (!playlist) {
      // if playlist is undefined
      playlist = allTimeslots.find(slot => slot.playlist.name === playerName);
      nonMps = allTimeslots.filter(slot => slot.playlist.name !== playerName);
    }
    const playlistId = get(playlist, 'playlist.id', null);

    let playlistFound = true;
    let playlistInfo = null;
    let allSubplaylists = null;
    let allExceptCis = null;
    let cis = null;
    let takeoverPlaylist = null;

    const playlistSchedules = {};
    const activeItemsIds = [];
    const disabledItemsIds = [];
    const allItems = {};
    const toBeSkipped = [];
    const allGroupings = {};
    if (!playlistId) {
      playlistFound = false;
    } else {
      // for each timeslot needs to updated
      playlistInfo = await getPlaylistInfo(playlistId).then(resPlaylist => resPlaylist.data);
      allSubplaylists = get(playlistInfo, 'playlistItems', []).filter(
        item => item.playlistItemType === 'SUB_PLAYLIST'
      );
      allExceptCis = allSubplaylists.filter(item => item.subplaylist.id !== constants.cisId);
      cis = allSubplaylists.find(item => item.subplaylist.id === constants.cisId);
      takeoverPlaylist = getTakeoverList(allTimeslots);
      if (allExceptCis) {
        allExceptCis.forEach((playlistItem, index) => {
          if (toBeSkipped.includes(playlistItem.id)) {
            return;
          }
          const secondItem = allExceptCis[index + 1];
          const thirdItem = allExceptCis[index + 2];
          const isDisabled = playlistItem.disabled;
          const startTime = get(playlistItem, 'timeSchedules.0.startTime', '00:00');
          let endTime = get(playlistItem, 'timeSchedules.0.endTime', '23:59');

          const defaultNeverEndsDate = formatDate(
            new Date(addMonths(new Date(), 3).setHours(23, 59, 59, 999)),
            datesTimes.scalaDateFormat
          );

          let endDate = get(playlistItem, 'endValidDate', datesTimes.scalaNeverEndsDate);

          endTime = endTime === '24:00' ? '23:59' : endTime;
          const fromStr =
            playlistItem.startValidDate || formatDate(new Date(), datesTimes.scalaDateFormat);

          const groupName = playlistItem.id;

          let grouping = [];

          if (
            secondItem &&
            secondItem.subplaylist.id === playlistItem.subplaylist.id &&
            secondItem.startValidDate ===
              formatDate(addDays(new Date(endDate), 1), datesTimes.scalaDateFormat) &&
            get(secondItem, 'timeSchedules.0.startTime', '00:00') === '00:00'
          ) {
            toBeSkipped.push(secondItem.id);
            if (
              thirdItem &&
              thirdItem.subplaylist.id === secondItem.subplaylist.id &&
              get(secondItem, 'endValidDate', datesTimes.scalaNeverEndsDate) !==
                datesTimes.scalaNeverEndsDate &&
              thirdItem.startValidDate ===
                formatDate(
                  addDays(new Date(secondItem.endValidDate), 1),
                  datesTimes.scalaDateFormat
                )
            ) {
              endDate = thirdItem.endValidDate;
              endTime = get(thirdItem, 'timeSchedules.0.endTime', '23:59');

              toBeSkipped.push(thirdItem.id);
              grouping = [playlistItem.id, secondItem.id, thirdItem.id];
            } else {
              endDate = secondItem.endValidDate;
              endTime = get(secondItem, 'timeSchedules.0.endTime', '23:59');
              grouping = [playlistItem.id, secondItem.id];
            }
          } else {
            grouping = [playlistItem.id];
          }
          allGroupings[groupName] = grouping;
          let neverEnds = false;
          if (endDate === datesTimes.scalaNeverEndsDate) {
            endDate = defaultNeverEndsDate;
            neverEnds = true;
          }
          const hasExpired = isBefore(`${endDate} ${endTime}`, new Date());
          const schedule = {
            validFrom: parseDate(`${fromStr} ${startTime}`),
            validTo: parseDate(`${endDate} ${endTime}`),
            neverEnds,
            selectedDays: daysOfWeek,
            hasExpired
          };
          if (isDisabled || hasExpired) {
            disabledItemsIds.push(playlistItem.id);
          } else {
            activeItemsIds.push(playlistItem.id);
          }
          playlistSchedules[playlistItem.id] = schedule;
          allItems[playlistItem.id] = playlistItem;
        });
      }
    }
    frameData = {
      frameId: frame.frameId,
      frameOrder: frame.zOrder,
      frameName,
      playlistId,
      playlistFound,
      playlistInfo,
      allItems,
      cis,
      activeItemsIds,
      disabledItemsIds,
      schedules: playlistSchedules,
      grouping: allGroupings,
      skipped: toBeSkipped,
      takeoverPlaylist,
      extraTimeslots: nonMps
    };
    framesData.push(frameData);
  }
  return {
    playerInfo,
    playerId,
    channelId,
    metadata: parsedMetadata,
    channelInfo,
    visibleframesData: framesData
  };
};


export const getEarliestDate = schedules => {
  let earliestDate = new Date('2050-01-01');
  let earliestId = null;

  schedules.forEach(({ validFrom, id }) => {
    if (isBefore(validFrom, earliestDate)) {
      earliestDate = validFrom;
      earliestId = id;
    }
  });

  return {
    earliestDate,
    earliestId
  };
};

export const isInvalidDate = schedules => {
  if (!schedules.length) {
    return false;
  }
  const earliest = getEarliestDate(schedules);
  const { earliestDate, earliestId } = earliest;
  const { validTo: _validTo } = schedules.find(item => item.id === earliestId);

  let dateEnd = _validTo;

  schedules
    .filter(({ id }) => id !== earliestId)
    .forEach(({ validFrom, validTo }) => {
      if (isBefore(validFrom, addMins(dateEnd, 2)) && isBefore(dateEnd, addMins(validTo, 2))) {
        dateEnd = validTo;
      }
    });

  const isInvalid = isBefore(new Date(), earliestDate) || isBefore(dateEnd, addDays(new Date(), 7));
  return isInvalid;
};

export const sendLog = async data =>
  getAccessTokenAndMakeCalls(token =>
    callSendLogApi(token, {
      ...data
    })
  );

// this is post as we have many filters (multi select) that can be applied
// hence sending them as query params might give issues due to URL max limit
export const fetchLogs = async data =>
  getAccessTokenAndMakeCalls(token =>
    callFetchSchedulingActivityLogsApi(token, {
      ...data
    })
  );

export const fetchFrameManagementLogs = async data =>
  getAccessTokenAndMakeCalls(token =>
    callFetchFrameManagementActivityLogsApi(token, {
      ...data
    })
  );
