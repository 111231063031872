import React, { Component } from 'react';
import { GlInput, GlButton, GlIcon, GlCheckbox, GlCallout } from '@adl/foundation';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import { Tooltip } from 'react-tippy';
import Dropdown from '../commons/Dropdown/Dropdown';
import {
  tableSchema,
  applyToAllTableSchema,
  defaultFrame,
  autoscaleOptions,
  framePresetDimensions,
  maxScreenDimensions,
  randomIntFromInterval,
  getRandomColor,
  getValidationError,
  setFrameValidations,
  confirmationModalMessage,
  isInputValid,
  getFailedSuccessChannelNames,
  isAnyFrameValueEmpty,
  canFramesBeSaved,
  frameColors
} from './schema';
import { ReactComponent as AddToPlaylistIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import Loader from '../commons/Loader/Loader';
import { UITEXT } from '../../helpers/misc';
import Table from '../commons/Table/Table';
import './FrameConfiguration.scss';
import { ReactComponent as DragIcon } from '../../assets/icons/drag-handle.svg';
import { ReactComponent as DragIconGreen } from '../../assets/icons/drag-handle-green.svg';
import FramesPreview from './FramesPreview';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callGetPlayerApi,
  callGetChannelApi,
  callGetFrameDetailsApi,
  callUpdateFramesDataApi
} from '../../serviceLayer/services';

class FrameConfiguration extends Component {
  state = {
    showSpinner: true,
    originalData: {},
    data: {},
    commonFrameData: cloneDeep(defaultFrame),
    collapseFrames: [],
    collapseAddToAll: false,
    modalOpen: false,
    showResult: false,
    modalId: '',
    actionParams: {},
    frameValidations: {},
    framesDataApiResult: {},
    irregularNamedPlayers: [],
    sortedChannelIds: [],
    channelData: {},
    updatedChannels: [],
    isDirty: false
  };

  componentDidMount() {
    this.fetchFramesData();
  }

  async fetchFramesData() {
    const self = this;
    const { selectedPlayers } = this.props;
    const channelData = {};
    const irregularNamedPlayers = [];
    const noChannelAssigned = [];
    const fetchedChannelIds = selectedPlayers.map(async player => {
      const playerInfo = await getAccessTokenAndMakeCalls(token =>
        callGetPlayerApi(token, { playerId: player.id })
      );
      const channelId = get(playerInfo, 'playerDisplays.0.channel.id', null);
      if (channelId !== null) {
        const channelName = get(playerInfo, 'playerDisplays.0.channel.name', null);
        const channelInfo = await getAccessTokenAndMakeCalls(token =>
          callGetChannelApi(token, { channelId })
        );
        const width = channelInfo && channelInfo.frameset && channelInfo.frameset.width;
        const height = channelInfo && channelInfo.frameset && channelInfo.frameset.height;
        let preset = '';
        framePresetDimensions.forEach(dimension => {
          const extractedDimensions = dimension.value.split('x');
          if (
            Number(extractedDimensions[0]) === Number(width) &&
            Number(extractedDimensions[1]) === Number(height)
          )
            preset = dimension.value;
        });
        channelData[channelId] = {
          channelName,
          width,
          height,
          ogWidth: width,
          ogHeight: height,
          preset
        };
        if (player.name !== channelName) irregularNamedPlayers.push(channelId);
        return channelId;
      }
      noChannelAssigned.push(player.name);
      return Promise.resolve('noChannelId');
    });
    // check for deleted channel scenario
    Promise.all(fetchedChannelIds).then(async channelIds => {
      channelIds = channelIds.filter(value => value !== 'noChannelId');
      if (channelIds.length > 0) {
        const fetchedFramesData = await getAccessTokenAndMakeCalls(token =>
          callGetFrameDetailsApi(token, { channelIds: channelIds.join() })
        );
        Object.keys(fetchedFramesData).forEach(channelId => {
          fetchedFramesData[channelId].reverse();
        });
        const originalData = cloneDeep(fetchedFramesData);

        // default expand if only one channel
        let collapseFrames = [];
        if (Object.keys(fetchedFramesData).length === 1) collapseFrames.push(true);
        else collapseFrames = Object.keys(fetchedFramesData).map(d => false);

        let frameValidations = setFrameValidations(fetchedFramesData); // check
        const commonFrameData = cloneDeep(defaultFrame);
        const updatedChannels = [];

        let sortedChannelIds = [];
        channelIds.forEach(channelId => {
          channelData[channelId].originalFrameOrder = originalData[channelId]
            .sort((a, b) => b.zOrder - a.zOrder)
            .map(frame => frame.frameId);
          sortedChannelIds.push([channelId, channelData[channelId].channelName]);
        });
        sortedChannelIds.sort((a, b) => a[1].localeCompare(b[1]));
        sortedChannelIds = sortedChannelIds.map(value => value[0].toString());
        frameValidations = this.checkValidationOfFrames(
          originalData,
          sortedChannelIds,
          frameValidations
        );
        self.setState({
          data: fetchedFramesData,
          originalData,
          collapseFrames,
          showSpinner: false,
          frameValidations,
          channelData,
          commonFrameData,
          collapseAddToAll: false,
          updatedChannels,
          irregularNamedPlayers,
          sortedChannelIds,
          isDirty: false,
          noChannelAssigned: noChannelAssigned.sort((a, b) => a.localeCompare(b))
        });
      } else
        this.setState({
          showSpinner: false,
          noChannelAssigned: noChannelAssigned.sort((a, b) => a.localeCompare(b))
        });
    });
  }

  confirmModalAction() {
    let { modalId } = this.state;
    const { actionParams } = this.state;
    const { showCancelConfirmation, closeModal } = this.props;
    if (showCancelConfirmation) modalId = 'cancelFrameConfiguration';
    switch (modalId) {
      case 'cancelAddFrameToAll':
        this.cancelAddFrameToAll();
        break;
      case 'cancelFrameConfiguration':
        closeModal();
        break;
      case 'addFrameToAll':
        this.addFrameToAll();
        break;
      case 'deleteFrame':
        this.deleteFrame(actionParams);
        break;
      case 'saveFrameData':
        this.saveFrameData();
        break;
      case 'resetFrameData':
        this.fetchFramesData();
        break;
      default:
        break;
    }
    this.closeConfirmationModal();
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  shouldOpenConfirmationModal(modalId, actionParams) {
    const { updatedChannels } = this.state;
    if (updatedChannels.length === 0) {
      switch (modalId) {
        case 'saveFrameData':
          this.saveFrameData();
          break;
        case 'resetFrameData':
          this.fetchFramesData();
          break;
        default:
          break;
      }
    } else {
      this.openConfirmationModal(modalId, actionParams);
    }
  }

  openConfirmationModal(modalId, actionParams) {
    this.setState({ modalId, modalOpen: true, actionParams });
  }

  closeConfirmationModal() {
    const { showCancelConfirmation, toggleCancelConfirmationModal } = this.props;
    if (showCancelConfirmation) toggleCancelConfirmationModal();
    else this.setState({ modalId: '', modalOpen: false });
  }

  confirmationModal() {
    let { modalId, updatedChannels } = this.state;
    const { showCancelConfirmation } = this.props;
    if (showCancelConfirmation)
      modalId =
        updatedChannels.length > 0
          ? 'cancelFrameConfiguration'
          : 'cancelFrameConfigurationNoChanges';
    return (
      <div className="customized-modal">
        <div className="customized-modal-body">
          <div className="customized-modal-container">
            <h5>{UITEXT.confirmation}</h5>
            <div style={{ padding: '5px 0px 15px' }}>{confirmationModalMessage(modalId)}</div>
            <GlButton
              aria-label="Save"
              className="custom-modal__button custom-modal__button--primary"
              onClick={() => this.confirmModalAction()}
            >
              {UITEXT.confirm}
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => this.closeConfirmationModal()}
            >
              {UITEXT.cancel}
            </GlButton>
          </div>
        </div>
      </div>
    );
  }

  resultModal() {
    const { framesDataApiResult, data, channelData } = this.state;
    const { framesDataSaved, areChannelsUpdated } = framesDataApiResult;
    if (typeof areChannelsUpdated != 'undefined' && !areChannelsUpdated) {
      return (
        <div className="customized-modal">
          <div className="customized-modal-body">
            <div className="customized-modal-container">
              <h5>{UITEXT.noChangesToSave}</h5>
              <GlButton
                aria-label="Save"
                className="custom-modal__button custom-modal__button--primary"
                onClick={() => this.setState({ showResult: false })}
              >
                {UITEXT.continue}
              </GlButton>
            </div>
          </div>
        </div>
      );
    }
    if (framesDataSaved) {
      const { failedChannels, successChannelIds } = framesDataApiResult;
      const { failed, success } = getFailedSuccessChannelNames(
        data,
        channelData,
        failedChannels,
        successChannelIds
      );
      return (
        <div className="customized-modal">
          <div className="customized-modal-body">
            <div className="customized-modal-container">
              {success.length > 0 && (
                <h5>{`${UITEXT.successfulDisplayText}${success.length > 1 ? 's' : ''}:`}</h5>
              )}
              {success.map((channel, index) => (
                <div key={index} style={{ padding: '0px 0px 15px 5px' }}>
                  {channel}
                </div>
              ))}
              {failed.length > 0 && (
                <h5>{`${UITEXT.failedDisplayText}${failed.length > 1 ? 's' : ''}:`}</h5>
              )}
              {failed.map((channel, index) => (
                <div key={index} style={{ padding: '0px 0px 15px 5px' }}>
                  {channel}
                </div>
              ))}
              <GlButton
                aria-label="Ok"
                className="custom-modal__button custom-modal__button--primary"
                onClick={() => {
                  this.setState({
                    showResult: false,
                    framesDataApiResult: {},
                    updatedChannels: []
                  });
                }} // make the updated fetch again
              >
                {UITEXT.continue}
              </GlButton>
            </div>
          </div>
        </div>
      );
    }
  }

  checkValidationOfFrames(data, sortedChannelIds, frameValidations) {
    sortedChannelIds.forEach(channelId => {
      data[channelId].forEach(frame => {
        // prevents the defaut highlight
        const nameValidation = getValidationError(
          'name',
          frame.name,
          frame.frameId,
          data[channelId]
        );
        const leftValidation = getValidationError(
          'left',
          frame.left,
          frame.frameId,
          data[channelId]
        );
        const topValidation = getValidationError('top', frame.top, frame.frameId, data[channelId]);
        const widthValidation = getValidationError(
          'width',
          frame.width,
          frame.frameId,
          data[channelId]
        );
        const heightValidation = getValidationError(
          'height',
          frame.height,
          frame.frameId,
          data[channelId]
        );
        if (nameValidation) frameValidations[channelId][frame.frameId].name = nameValidation;
        if (leftValidation) frameValidations[channelId][frame.frameId].left = leftValidation;
        if (topValidation) frameValidations[channelId][frame.frameId].top = topValidation;
        if (widthValidation) frameValidations[channelId][frame.frameId].width = widthValidation;
        if (heightValidation) frameValidations[channelId][frame.frameId].height = heightValidation;
      });
    });
    return frameValidations;
  }

  isFrameValid(channelId, frameId, valueType, value) {
    const { frameValidations, data, commonFrameData } = this.state;
    let frameData = data[channelId];
    if (channelId && channelId === 'apply-all') frameData = commonFrameData;
    if (channelId && frameId && valueType) {
      frameValidations[channelId][frameId][valueType] = getValidationError(
        valueType,
        value,
        frameId,
        frameData
      );
      this.setState({ frameValidations });
    }
  }

  handleInputChange(channelId, frameIndex, value, placeholder) {
    const { updatedChannels } = this.state;
    let isDirty = false;
    if (channelId === 'apply-all') {
      // input change for apply all
      const commonFrameData = this.state.commonFrameData;
      commonFrameData[placeholder] = value;
      this.isFrameValid(channelId, 'frame-id', placeholder, value);
      this.setState({ commonFrameData, updatedChannels });
    } else {
      const data = this.state.data;
      Object.keys(data).forEach(channel => {
        if (channel === channelId) {
          data[channelId].forEach((frame, index) => {
            if (index === frameIndex) {
              frame[placeholder] = value;
              if (placeholder !== 'color') {
                updatedChannels.push(channelId);
                frame.updatedFrame = true;
                isDirty = true;
                this.isFrameValid(channelId, frame.frameId, placeholder, value);
              }
            }
          });
        }
      });
      this.setState({ data, updatedChannels, isDirty });
    }
  }

  handleDimensionsChange(channelId, property, value) {
    const { channelData } = this.state;
    if (property === 'preset') {
      const dimensions = value.split('x');
      channelData[channelId].width = dimensions[0];
      channelData[channelId].height = dimensions[1];
      channelData[channelId].preset = value;
    } else if (property === 'custom') {
      if (value) {
        channelData[channelId].preset = '';
      } else {
        const newDimensions = framePresetDimensions[framePresetDimensions.length - 1];
        const dimensions = newDimensions.value.split('x');
        channelData[channelId].width = dimensions[0];
        channelData[channelId].height = dimensions[1];
        channelData[channelId].preset = newDimensions.value;
      }
    } else {
      channelData[channelId][property] = value;
      // check if entered value is same as a preset
      let presetUpdated = false;
      framePresetDimensions.forEach(dimension => {
        const dimensions = dimension.value.split('x');
        if (
          Number(dimensions[0]) === Number(channelData[channelId].width) &&
          Number(dimensions[1]) === Number(channelData[channelId].height)
        ) {
          channelData[channelId].preset = dimension.value;
          presetUpdated = true;
        }
      });
      if (!presetUpdated) channelData[channelId].preset = '';
    }
    if (channelData[channelId].width.toString() !== channelData[channelId].ogWidth.toString())
      channelData[channelId].updatedDimensionsWidth = true;
    else channelData[channelId].updatedDimensionsWidth = false;
    if (channelData[channelId].height.toString() !== channelData[channelId].ogHeight.toString())
      channelData[channelId].updatedDimensionsHeight = true;
    else channelData[channelId].updatedDimensionsHeight = false;
    this.setState({ channelData });
  }

  addFrame(channelId, index) {
    const { data, collapseFrames, frameValidations, updatedChannels } = this.state;
    const newDefaultFrame = defaultFrame;
    newDefaultFrame.frameId = randomIntFromInterval(100, 9999);
    newDefaultFrame.color = getRandomColor(data[channelId]);
    newDefaultFrame.newFrame = true;
    newDefaultFrame.autoscale = 'FILL_EXACTLY';
    data[channelId].unshift(cloneDeep(newDefaultFrame));
    updatedChannels.push(channelId);
    collapseFrames[index] = true;
    frameValidations[channelId][newDefaultFrame.frameId] = {};
    this.setState({ data, collapseFrames, frameValidations, updatedChannels, isDirty: true });
  }

  deleteFrame({ channelId, frameId, frameIndex }) {
    const { data, frameValidations, updatedChannels } = this.state;
    Object.keys(data).forEach(channel => {
      if (data[channel].length !== 0 && channel === channelId) {
        data[channel] = data[channel].filter(frame => frame.frameId !== frameId);
        delete frameValidations[channelId][frameId];
        updatedChannels.push(channelId); // if new frame no need to show as updated
      }
    });
    this.setState({ data, frameValidations, updatedChannels, isDirty: true });
  }

  async saveFrameData() {
    const { data, updatedChannels, channelData } = this.state;
    let framesDataApiResult = {};
    // Incase only screen size is updated
    let screenSizeUpdate = 0;
    Object.keys(channelData).forEach(channelId => {
      if (
        channelData[channelId].updatedDimensionsWidth ||
        channelData[channelId].updatedDimensionsHeight
      )
        screenSizeUpdate += 1;
    });
    if ((updatedChannels && updatedChannels.length) || screenSizeUpdate > 0) {
      const allChannelsData = {};
      allChannelsData.channelFrames = [];
      Object.keys(data).forEach(channelId => {
        if (
          updatedChannels.includes(channelId) ||
          channelData[channelId].updatedDimensionsWidth ||
          channelData[channelId].updatedDimensionsWidth
        ) {
          const framesData = cloneDeep(data[channelId]) || [];
          framesData.reverse();
          let reordered = false;
          const resolutionUpdated =
            (channelData[channelId] &&
              (channelData[channelId].updatedDimensionsWidth ||
                channelData[channelId].updatedDimensionsWidth)) ||
            false;
          // Update zOrder & remove id property for all new frames
          for (let i = 0; i < framesData.length; i += 1) {
            framesData[i].zOrder = i + 1;
            framesData[i].name = framesData[i].name.trim();
            if (framesData[i].updatedOrder) reordered = true;
            if (framesData[i].newFrame) {
              // only delete ids for new frames
              delete framesData[i].frameId;
            }
          }
          const currentChannelData = {
            channelId,
            frames: framesData,
            width: channelData[channelId].width,
            height: channelData[channelId].height,
            reordered,
            resolutionUpdated
          };
          allChannelsData.channelFrames.push(currentChannelData);
        }
      });
      let framesDataSaved = true;
      try {
        framesDataApiResult = await getAccessTokenAndMakeCalls(token =>
          callUpdateFramesDataApi(token, allChannelsData)
        );
      } catch (e) {
        console.log('Couldnt save Channels Data.', e);
        framesDataSaved = false;
      }
      framesDataApiResult.framesDataSaved = framesDataSaved;
      framesDataApiResult.areChannelsUpdated = true;
      // update zOrder as per inverted index
      // flag to see if channel is modified - filter out
    } else {
      framesDataApiResult.areChannelsUpdated = false;
      framesDataApiResult.framesDataSaved = false;
    }
    this.fetchFramesData();
    const frameValidations = setFrameValidations(cloneDeep(data));
    this.setState({ framesDataApiResult, showResult: true, updatedChannels: [], frameValidations });
  }

  resetFrameData() {
    const { originalData } = this.state;
    const data = cloneDeep(originalData);
    const commonFrameData = cloneDeep(defaultFrame);
    const frameValidations = setFrameValidations(data);
    const updatedChannels = [];
    this.setState({ data, commonFrameData, frameValidations, updatedChannels });
  }

  toggleCollapse(index) {
    if (index === 'apply-all') {
      const { collapseAddToAll } = this.state;
      this.setState({ collapseAddToAll: !collapseAddToAll });
    } else {
      const { collapseFrames } = this.state;
      collapseFrames[index] = !collapseFrames[index];
      this.setState({ collapseFrames });
    }
  }

  collapseAll() {
    const { data } = this.state;
    const collapseFrames = Object.keys(data).map(d => false);
    this.setState({ collapseFrames });
  }

  expandAll() {
    const { data } = this.state;
    const collapseFrames = Object.keys(data).map(d => true);
    this.setState({ collapseFrames });
  }

  addFrameToAll() {
    const { commonFrameData, data, frameValidations, updatedChannels } = this.state;
    Object.keys(data).forEach(channelId => {
      commonFrameData.frameId = randomIntFromInterval(100, 9999);
      commonFrameData.color = getRandomColor(data[channelId]);
      commonFrameData.newFrame = true;
      commonFrameData.name = commonFrameData.name.trim();
      commonFrameData.left = commonFrameData.left.trim();
      commonFrameData.top = commonFrameData.top.trim();
      commonFrameData.width = commonFrameData.width.trim();
      commonFrameData.height = commonFrameData.height.trim();
      commonFrameData.autoscale = 'FILL_EXACTLY';
      frameValidations[channelId][commonFrameData.frameId] = {
        name: getValidationError(
          'name',
          commonFrameData.name,
          commonFrameData.frameId,
          data[channelId]
        ),
        left: false,
        top: false,
        width: false,
        height: false,
        autoscale: false
      };
      data[channelId].unshift(cloneDeep(commonFrameData));
      updatedChannels.push(channelId);
    });
    frameValidations['apply-all'] = {};
    frameValidations['apply-all']['frame-id'] = {};
    this.setState({
      data,
      commonFrameData: cloneDeep(defaultFrame),
      frameValidations,
      collapseAddToAll: false,
      isDirty: true
    });
  }

  cancelAddFrameToAll() {
    this.setState({ commonFrameData: cloneDeep(defaultFrame), collapseAddToAll: false });
  }

  dropdown(channelId, frameId, frameIndex, value) {
    const { frameValidations } = this.state;
    const edited = 'edited-field';
    const noSelection = ' no-selection';
    const isValid = isInputValid(channelId, frameId, 'autoscale', frameValidations);
    return (
      <Dropdown
        className={`padding-left-5 ${isValid !== null && !isValid ? edited : ''}${
          isValid ? noSelection : ''
        }`}
        items={autoscaleOptions}
        value={value}
        error={isValid}
        errorText={UITEXT.autoScaleErrorText}
        placeholder={`${UITEXT.select}...`}
        onChange={value => {
          this.handleInputChange(channelId, frameIndex, value, 'autoscale');
        }}
      />
    );
  }

  input(channelId, frameId, frameIndex, value, placeholder, emptyField, color, updatedOrder) {
    const { frameValidations } = this.state;
    const isValid = isInputValid(channelId, frameId, placeholder, frameValidations);
    const removeMarginClass = isValid ? 'frame-input-margin-bottom ' : '';
    const hideHoistedLabelBg = value.toString().length > 0 ? 'frame-input-label' : '';
    return (
      <div style={{ display: 'flex' }}>
        {placeholder === 'name' && channelId !== 'apply-all' && (
          <>
            <div style={{ marginTop: '28px' }}>
              {updatedOrder ? <DragIconGreen /> : <DragIcon />}
            </div>
            <div style={{ background: color }} className="frame-color-icon" />
          </>
        )}
        <GlInput
          className={`frames-edit-input generic-table__input ${removeMarginClass}${hideHoistedLabelBg}`}
          placeholder={emptyField ? placeholder : null}
          required={emptyField}
          value={value}
          noIcon
          valid={isValid === null ? null : !isValid} // checkValidity
          hint={isValid}
          onChange={e => {
            const { value } = e.target;
            this.handleInputChange(channelId, frameIndex, value, placeholder);
          }}
        />
      </div>
    );
  }

  addFrameIcon(channelId, index) {
    return (
      <button
        className="clickable add-new-frame"
        type="button"
        onClick={() => this.addFrame(channelId, index)}
      >
        <AddToPlaylistIcon className="frames-edit-icons header-actions__icon header-actions__icon--smaller" />
        <span style={{ marginLeft: '10px' }}>{UITEXT.addNewFrame}</span>
      </button>
    );
  }

  deleteFrameIcon(channelId, frameId, frameIndex) {
    const { data } = this.state;
    const disabledButton = data[channelId] && data[channelId].length === 1;
    return (
      <button
        className={`clickable delete-icon${disabledButton ? ' disabled-icon' : ''}`}
        type="button"
        disabled={disabledButton}
        onClick={() =>
          this.openConfirmationModal('deleteFrame', {
            channelId,
            frameId,
            frameIndex
          })
        }
      >
        <DeleteIcon className="frames-edit-icons header-actions__icon header-actions__icon--smaller" />
      </button>
    );
  }

  makeFrameValueEditableInput(channelId, frames) {
    const updatedFrames = [];
    frames &&
      frames.length > 0 &&
      frames.forEach((frame, index) => {
        if (!frameColors.includes(frame.color))
          this.handleInputChange(channelId, index, getRandomColor(frames), 'color');
        updatedFrames.push({
          id: frame.frameId,
          index,
          name: this.input(
            channelId,
            frame.frameId,
            index,
            frame.name,
            'name',
            frame.name === '',
            frame.color,
            frame.updatedOrder
          ),
          left: this.input(channelId, frame.frameId, index, frame.left, 'left', frame.left === ''),
          top: this.input(channelId, frame.frameId, index, frame.top, 'top', frame.top === ''),
          width: this.input(
            channelId,
            frame.frameId,
            index,
            frame.width,
            'width',
            frame.width === ''
          ),
          height: this.input(
            channelId,
            frame.frameId,
            index,
            frame.height,
            'height',
            frame.height === ''
          ),
          autoscale: this.dropdown(channelId, frame.frameId, index, frame.autoscale),
          action: this.deleteFrameIcon(channelId, frame.frameId, index)
        });
      });
    return updatedFrames;
  }

  renderApplyAll() {
    const frame = this.state.commonFrameData;
    frame.frameId = 'frame-id';
    const frames = this.makeFrameValueEditableInput('apply-all', [frame]);
    const disabled = isAnyFrameValueEmpty(frame);
    return (
      <div>
        <div className="display-flex">
          <h5 className="add-new-frame-header">{UITEXT.addNewFrameButton}</h5>
          <button
            className="clickable add-new-frame right-text-button"
            type="button"
            onClick={() => this.openConfirmationModal('cancelAddFrameToAll')}
          >
            <GlIcon name="cross-small" className="header-actions__icon" />
          </button>
        </div>
        <div className="apply-all-table add-edit-table">
          <Table
            className="frames-add-edit"
            tableData={frames}
            isFilterable
            tableSchema={applyToAllTableSchema}
            tableId="results"
          />
          <GlButton
            aria-label="Save"
            className="custom-modal__button custom-modal__button--primary float-right apply-all-button"
            onClick={() => this.openConfirmationModal('addFrameToAll')}
            disabled={disabled}
          >
            {UITEXT.applyToAllButton}
          </GlButton>
        </div>
      </div>
    );
  }

  renderFrameData(channelId, frameDataObj) {
    const { channelData } = this.state;
    const clonedFrames = cloneDeep(frameDataObj);
    const frames = this.makeFrameValueEditableInput(channelId, frameDataObj);
    const hasRowOrdering = frames.length !== 1;
    const rowOrderHandler = ({ oldIndex, newIndex }) => {
      // Retrieve the channel getting updated.
      const { data, updatedChannels } = this.state;
      updatedChannels.push(channelId);
      this.setState({ updatedChannels, isDirty: true });
      if (newIndex >= 0 && newIndex < frames.length && oldIndex !== newIndex) {
        const copiedList = [...frameDataObj];
        const element = copiedList.splice(oldIndex, 1)[0];
        copiedList.splice(newIndex, 0, element);
        const newFramesData = copiedList.map((el, index) => {
          if (el.frameId !== channelData[channelId].originalFrameOrder[index])
            el.updatedOrder = true;
          else el.updatedOrder = false;
          return { ...el, sortOrder: index + 1 };
        });
        data[channelId] = newFramesData;
        this.setState({ data });
      }
    };
    const showMaxDimensionsError =
      Number(Number(channelData[channelId].height) * Number(channelData[channelId].width)) >
      Number(maxScreenDimensions);
    const showIncorrectDimensionValueError =
      !Number(channelData[channelId].height) || !Number(channelData[channelId].width);
    const dimensionHeightValue =
      channelData[channelId] &&
      channelData[channelId].height &&
      channelData[channelId].height.toString();
    const dimensionWidthValue =
      channelData[channelId] &&
      channelData[channelId].width &&
      channelData[channelId].width.toString();
    const presetValue =
      channelData[channelId] &&
      channelData[channelId].preset !== '' &&
      channelData[channelId].preset;
    return (
      <div className="add-edit-table">
        <div className="display-flex">
          <div className="dimension-label">{`${UITEXT.screenSize}:`}</div>
          <div className="dimension-input">
            <Dropdown
              items={framePresetDimensions}
              value={channelData[channelId].preset}
              placeholder={`${UITEXT.select}...`}
              onChange={value => {
                this.handleDimensionsChange(channelId, 'preset', value);
              }}
            />
          </div>
          {!presetValue && (
            <>
              <div className="dimension-label">{`${UITEXT.width}: `} </div>
              <div className="dimension-input">
                <GlInput
                  className=""
                  value={channelData[channelId].width}
                  noIcon
                  valid={
                    channelData[channelId].updatedDimensionsWidth
                      ? !(dimensionWidthValue === '' || isNaN(Number(dimensionWidthValue)))
                      : null
                  }
                  onChange={e => {
                    const { value } = e.target;
                    this.handleDimensionsChange(channelId, 'width', value);
                  }}
                />
              </div>
              <div className="dimension-label">{`${UITEXT.height}: `}</div>
              <div className="dimension-input">
                <GlInput
                  className=""
                  value={channelData[channelId].height}
                  noIcon
                  valid={
                    channelData[channelId].updatedDimensionsHeight
                      ? !(dimensionHeightValue === '' || isNaN(Number(dimensionHeightValue)))
                      : null
                  }
                  onChange={e => {
                    const { value } = e.target;
                    this.handleDimensionsChange(channelId, 'height', value);
                  }}
                />
              </div>
            </>
          )}
          <div className="custom-screen-size-container">
            <GlCheckbox
              className="custom-screen-size"
              isChecked={!presetValue}
              onChange={e => this.handleDimensionsChange(channelId, 'custom', e.target.checked)}
              label="Custom"
            />
          </div>
          {(showMaxDimensionsError || showIncorrectDimensionValueError) && (
            <div
              className="gl-form-hint--error gl-form-message"
              style={{ display: 'block', margin: 'auto 0px' }}
            >
              {showIncorrectDimensionValueError
                ? UITEXT.dimensionsIncorrectValueError
                : `${UITEXT.dimensionsMaxScreenSizeError} ${maxScreenDimensions}`}
            </div>
          )}
        </div>
        <Table
          className="frames-add-edit"
          tableData={frames}
          isFilterable
          tableSchema={tableSchema}
          tableId="results"
          hasRowOrdering={hasRowOrdering}
          rowOrderHandler={rowOrderHandler}
        />
        <FramesPreview
          frames={clonedFrames.reverse()}
          screenWidth={Number(channelData[channelId].width)}
          screenHeight={Number(channelData[channelId].height)}
        />
      </div>
    );
  }

  renderChannelData() {
    const {
      data,
      collapseFrames,
      channelData,
      irregularNamedPlayers,
      sortedChannelIds
    } = this.state;
    return sortedChannelIds.map((channelId, index) => {
      const frames = data[channelId];
      const multipleFrames = frames.length > 1 ? UITEXT.pluralS : '';
      const irregular = irregularNamedPlayers.includes(Number(channelId));
      return (
        <div key={index}>
          <div className="display-flex">
            <div
              className="clickable display-flex player-headers"
              onClick={() => this.toggleCollapse(index)}
            >
              <GlButton
                className="collapse-icon"
                icon={collapseFrames[index] ? 'arrow-down' : 'arrow-right'}
              />
              <h5>{channelData[channelId].channelName.replace(/_/g, ' ')}</h5>
              <h5 className="number-of-frames">
                {`(${frames.length || 0} ${UITEXT.frame}${multipleFrames})`}
              </h5>
            </div>
            {irregular && (
              <Tooltip
                position="bottom"
                followCursor
                title={UITEXT.differentChannelPlayerNameTooltip}
              >
                <GlButton className="collapse-icon warning-icon" icon="alert-info" />
              </Tooltip>
            )}
            {collapseFrames[index] && (
              <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
                {this.addFrameIcon(channelId, index)}
              </div>
            )}
          </div>
          {collapseFrames[index] && this.renderFrameData(channelId, frames)}
        </div>
      );
    });
  }

  render() {
    const {
      data,
      showSpinner,
      collapseAddToAll,
      collapseFrames,
      modalOpen,
      showResult,
      frameValidations,
      channelData,
      updatedChannels,
      isDirty,
      noChannelAssigned
    } = this.state;
    const { showCancelConfirmation } = this.props;

    let hideExpandAll = false;
    collapseFrames.forEach(frame => {
      if (frame) hideExpandAll = frame;
    });
    const channelLength = Object.keys(data).length > 1 ? UITEXT.pluralS : '';
    const onlyOneSelectedPlayer =
      Object.keys(data).length === 1 ||
      (Object.keys(data).length === 0 && noChannelAssigned && noChannelAssigned.length === 1);
    return (
      <>
        {(modalOpen || showCancelConfirmation) && this.confirmationModal()}
        {showResult && this.resultModal()}
        <div className="frame-container">
          <div className="display-flex">
            <h4 style={{ marginBottom: '0px' }}>{UITEXT.frameConfiguration}</h4>
            <h5 className="selected-channels">
              {`(${Object.keys(data).length} ${UITEXT.selectedChannel}${channelLength})`}
            </h5>
            {!onlyOneSelectedPlayer && (
              <button
                type="button"
                className={`clickable right-text-button ${collapseAddToAll && 'disabled'}`}
                onClick={() => {
                  !collapseAddToAll && this.toggleCollapse('apply-all');
                }}
              >
                <AddToPlaylistIcon className="frames-edit-icons header-actions__icon header-actions__icon--smaller add-icon" />
                {UITEXT.addNewFrameToPlayers}
              </button>
            )}
            {hideExpandAll && !onlyOneSelectedPlayer && (
              <div
                className="clickable right-text-button collapse-button"
                onClick={() => this.collapseAll()}
              >
                <div className="expand-collape-label">{UITEXT.collapseAllButton}</div>
                <GlButton className="collapse-icon collapse-all-icon" icon="arrow-up" />
              </div>
            )}
            {!hideExpandAll && !onlyOneSelectedPlayer && (
              <div
                className="clickable right-text-button collapse-button"
                onClick={() => this.expandAll()}
              >
                <div className="expand-collape-label">{UITEXT.expandAllButton}</div>
                <GlButton className="collapse-icon collapse-all-icon" icon="arrow-down" />
              </div>
            )}
          </div>
          <div className="scrollable-content">
            {noChannelAssigned && noChannelAssigned.length > 0 && (
              <div className="list--error" style={{ padding: '10px' }}>
                <GlCallout>
                  <h5 className="callout-error__text" style={{ padding: '0px' }}>
                    {noChannelAssigned && noChannelAssigned.length > 1
                      ? UITEXT.noChannelsAssignedMessage
                      : UITEXT.noChannelAssignedMessage}
                  </h5>
                  {noChannelAssigned.join(', ')}
                </GlCallout>
              </div>
            )}
            {collapseAddToAll && (
              <div className="edit-table-2__column edit-table-2__column--grey edit-table-2__column--full-width edit-frame-table">
                {this.renderApplyAll()}
              </div>
            )}
            <div className="channel-data">
              {showSpinner && <Loader />}
              {!showSpinner && this.renderChannelData()}
            </div>
          </div>
        </div>

        <GlButton
          aria-label="Save"
          className="custom-modal__button custom-modal__button--primary"
          onClick={() => this.shouldOpenConfirmationModal('saveFrameData')}
          disabled={canFramesBeSaved(frameValidations, channelData, data, updatedChannels, isDirty)}
        >
          {UITEXT.save}
        </GlButton>
        <GlButton
          secondary
          aria-label="Cancel"
          className="custom-modal__button"
          onClick={() => this.shouldOpenConfirmationModal('resetFrameData')}
        >
          {UITEXT.reset}
        </GlButton>
      </>
    );
  }
}

export default FrameConfiguration;
