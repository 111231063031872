import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { GlModal } from '@adl/foundation';

const Modal = ({ modalCloseHandler, enableOutsideClick, className, ...rest }) => (
  <GlModal
    onRequestClose={e => {
      if ((enableOutsideClick || e.target.className !== 'gl-modal__overlay') && modalCloseHandler) {
        modalCloseHandler();
      }
    }}
    {...rest}
    className={classnames('custom-modal', className)}
  />
);

Modal.propTypes = {
  modalCloseHandler: PropTypes.func,
  enableOutsideClick: PropTypes.bool,
  className: PropTypes.string
};

Modal.defaultProps = {
  className: ''
};

export default Modal;
