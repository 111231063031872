import React, { Component } from 'react';
import Modal from '../commons/Modal/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modalActions from '../../actions/modal';
import CreateMessageModel from './CreateMessageModal';
import TemplateList from '../TemplateList/TemplateList';
import { selectItems as selectItemsAction } from '../../actions/table';

class ConfigureTemplateModal extends Component {
  state = {
    createMessageScreen: false
  };
  componentDidMount() {
    const { selectItems, tableId } = this.props;
    selectItems(tableId, []);
  }
  createMessage = () => {
    this.setState({
      createMessageScreen: true
    });
  };
  backToList = () => {
    this.setState({
      createMessageScreen: false
    });
  };
  render() {
    const { closeModal, selectedTemplateId, selectItems, tableId, refreshHandler } = this.props;
    const { createMessageScreen } = this.state;
    return (
      <Modal
        size="large"
        open
        modalCloseHandler={() => {
          closeModal();
        }}
        enableOutsideClick
      >
        {!createMessageScreen && (
          <TemplateList
            selectedTemplateId={selectedTemplateId}
            changeHandler={this.changeHandler}
            createMessage={this.createMessage}
            selectItems={selectItems}
            tableId={tableId}
          />
        )}

        {createMessageScreen && (
          <CreateMessageModel
            backToList={this.backToList}
            selectedTemplateId={selectedTemplateId}
            refreshHandler={refreshHandler}
          />
        )}
      </Modal>
    );
  }
}
ConfigureTemplateModal.propTypes = {
  closeModal: PropTypes.func,
  tableId: PropTypes.string,
  selectItems: PropTypes.func,
  refreshHandler: PropTypes.func
};
ConfigureTemplateModal.defaultProps = {
  tableId: 'templatesList'
};
const mapStateToProps = state => ({
  selectedTemplateId: state.table.templatesList.selected
});

const mapDispatchToProps = {
  selectItems: selectItemsAction,
  ...modalActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureTemplateModal);
