import 'babel-polyfill';
import './Polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ReactComponent as Sprite } from '@adl/foundation/dist/adidas/assets/sprite.svg';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import store from './stores/index';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <Sprite className="svgiconsprite" />
    <div id="modal-root" />
    <App />
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
