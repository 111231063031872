import React from 'react';
import PropTypes from 'prop-types';
import './WithTooltip.scss';

const WithTooltip = ({ additionalClassName, tooltip, children, ...rest }) => (
  <div className={`withTooltip ${additionalClassName}`} tooltip={tooltip} {...rest}>
    {children}
  </div>
);

WithTooltip.propTypes = {
  tooltip: PropTypes.string
};

export default WithTooltip;
