import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton } from '@adl/foundation';
import Carousel from 'nuka-carousel';
import throttle from 'lodash-es/throttle';
import './CarouselSlider.scss';

const pauseVideo = () => {
  const video =
    document
      .querySelector(`.carousel-slider .slider-slide.slide-visible`)
      .getElementsByTagName('video')[0] || false;

  if (video) {
    video.pause();
  }
};

class CarouselSlider extends Component {
  state = {
    currentSlide: 0
  };

  handleClick = throttle(type => {
    const { items } = this.props;
    const { currentSlide } = this.state;

    switch (type) {
      case 'prev':
        if (currentSlide !== 0) {
          this.setState(prevState => ({ currentSlide: prevState.currentSlide - 1 }));
        }
        break;

      case 'next':
        if (currentSlide !== items.length - 1) {
          this.setState(prevState => ({ currentSlide: prevState.currentSlide + 1 }));
        }
        break;

      default:
        break;
    }
    this.props.actionOnClick && this.props.actionOnClick();
  }, 1000);

  onItemLoad = () => {
    this.carouselRef.onResize();
  };

  render() {
    const { currentSlide } = this.state;
    const { items, showCount } = this.props;

    return (
      items.length && (
        <>
          {showCount && (
            <div className="carousel-counter">{currentSlide + 1 + ' of ' + items.length}</div>
          )}
          <div className="carousel-slider">
            <Carousel
              ref={ref => {
                this.carouselRef = ref;
              }}
              slidesToShow={1}
              slideIndex={currentSlide}
              heightMode="max"
              beforeSlide={() => pauseVideo()}
            >
              {items.map((item, index) => (
                <div key={index} onLoad={this.onItemLoad}>
                  {item}
                </div>
              ))}
            </Carousel>
            <GlButton
              className="button--left"
              aria-label="previous slide"
              icon="arrow-left"
              onClick={() => this.handleClick('prev')}
            />

            <GlButton
              className="button--right"
              aria-label="next slide"
              icon="arrow-right"
              onClick={() => this.handleClick('next')}
            />
          </div>
        </>
      )
    );
  }
}

CarouselSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object)
};

export default CarouselSlider;
