import React, { Component } from 'react';
import Table from '../commons/Table/Table';
import { tableSchema, formatLogs } from './schema';
import './PlayerHealth.scss';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlayerHealthApi } from '../../serviceLayer/services';

class PlayerHealthLog extends Component {
  state = {
    healthLogs: [],
    isFetching: true
  };

  componentDidMount() {
    const { playerId } = this.props;
    this.getPlayerHealth(playerId);
  }

  getPlayerHealth = pid => {
    const params = {
      limit: 10,
      offset: 0,
      sort: 'problemNumber',
      filters: `{"playerHealthState":{"values":["UNCLEARED"]},"players":{"values":["${pid}"]}}`
    };
    getAccessTokenAndMakeCalls(token =>
      callGetPlayerHealthApi(token, {
        params
      })
    ).then(response => {
        this.setState({
          isFetching: false,
          healthLogs: formatLogs(response.list)
        });
      })
      .catch(() => {
        this.setState({
          isFetching: false
        });
      });
  };

  render() {
    const { healthLogs, isFetching } = this.state;
    return (
      <div className="health-logs__container">
        <Table
          id="player-health"
          tableData={healthLogs}
          tableSchema={tableSchema}
          tableId="player-health"
          isFilterable
          isFetching={isFetching}
          isSelectable={false}
          hasColumnOptions={false}
        />
      </div>
    );
  }
}

export default PlayerHealthLog;
