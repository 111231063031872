import {
  INIT_DATA,
  SET_COUNT,
  FAILED_REQUEST,
  GO_TO_PAGE,
  UPDATE_FILTER,
  CLEAR_FILTERS,
  UPDATE_DISPLAY_KEY
} from '../actions/playersList';

import { formatStep1 } from '../components/PlayersList/schema';

const initialState = {
  displayKey: 1,
  isFetching: true,
  list: [],
  pageInfo: {
    total: 1,
    limit: 60,
    current: 1
  },
  sort: {},
  filtered: {},
  isError: false,
  count: 0
};

const playersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DATA: {
      const formattedList = formatStep1(action.list);
      return {
        ...state,
        list: formattedList,
        count: action.count,
        isFetching: false
      };
    }
    case UPDATE_DISPLAY_KEY:
      return {
        ...state,
        displayKey: state.displayKey + 1
      };
    case SET_COUNT:
      return {
        ...state,
        count: action.count
      };
    case FAILED_REQUEST:
      return {
        ...state,
        isFetching: false,
        isError: true,
        list: []
      };
    case GO_TO_PAGE:
      return {
        ...state,
        pageInfo: {
          ...state.pageInfo,
          current: action.page
        }
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filtered: {},
        sort: {}
      };
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.optns
      };

    default:
      return state;
  }
};

export default playersListReducer;
