import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import get from 'lodash-es/get';

import { closeModal as closeModalAction, openModal as openModalAction } from '../../actions/modal';
import Table from '../commons/Table/Table';
import DeleteModal from '../Modals/DeleteModal';
import ShowUsageModal from '../Modals/ShowUsageModal';
import MediaEditModal from '../Modals/MediaEditModal';
import MediaAddToPlaylistModal from '../Modals/MediaAddToPlaylistModal';
import CreatePlaylistFromMediaModal from '../Modals/CreatePlaylistFromMediaModal';
import PreviewItemsModal from '../Modals/PreviewItemsModal';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import CustomSelect from '../commons/CustomSelect/CustomSelect';

import { getQueryParamsForTable, kllDowloadRequest } from '../../helpers/utils';
import { selectItems as selectItemsAction } from '../../actions/table';
import { uploadFiles as uploadFilesAction } from '../../actions/upload';
import { tableSchema, formatMediaList } from './schema';

import './MediaList.scss';
import { pageTitle } from '../../helpers/pageTitles';
import ConfigureTemplateModal from '../Modals/ConfigureTemplateModal';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetMediasApi, callGetTaxonomyTagsApi } from '../../serviceLayer/services';

class MediaList extends Component {
  state = {
    mediaList: [],
    isFetching: true,
    limit: 20,
    sort: {
      by: 'lastModified',
      order: 'desc'
    },
    pageInfo: {
      total: 1,
      // offet = (page - 1) * limit -- maybe as prop
      current: 1
    },
    isDropped: false,
    isError: false,
    showFilters: false,
    tagsLoading: true,
    allTags: [],
    allTagsByIds: {},
    selectedTags: [],
    // contain all the filter data : {page, sort, filters, tags}
    allFilterData: {}
  };

  componentDidMount() {
    const { selectItems, tableId, preSelectedItems } = this.props;
    tableId === 'selectMediaToPlaylists'
      ? (document.title = pageTitle.playlist)
      : (document.title = pageTitle.media);
    this.initData();
    preSelectedItems ? selectItems(tableId, preSelectedItems) : selectItems(tableId, []);

    this.fetchTags();
  }

  fetchTags = () => {
    getAccessTokenAndMakeCalls(token => callGetTaxonomyTagsApi(token))
      .then(res => {
        this.setState({ tagsLoading: false });
        const tags = get(res, 'list', []);
        const allTags = [];
        const allTagsByIds = {};

        tags.forEach(tag => {
          tag.children.forEach(category => {
            category.children.forEach(categoryTags => {
              allTags.push({
                value: String(categoryTags.id),
                label: `${category.name}/${categoryTags.name}`
              });
              allTagsByIds[categoryTags.id] = {
                id: String(categoryTags.id),
                name: `${categoryTags.name}`
              };
            });
          });
        });
        this.setState({ allTags, allTagsByIds });
      })
      .catch(() => {
        this.setState({ tagsLoading: false, tagsLoadError: true });
      });
  };

  refresh = () => {
    this.fetchMediaItems();
  };

  onDrop = (accepted, rejected) => {
    this.setState({ isDropped: true });
    const { uploadFiles } = this.props;
    uploadFiles({
      accepted,
      rejected
    });
  };

  fetchMediaItems = (data = {}) => {
    const { selectedTags, allFilterData, sort: sortState } = this.state;

    const page = data.page ? data.page : 1;
    const sort = data.sort || (allFilterData && allFilterData.sort) || sortState;
    const filtered = data.filtered || (allFilterData && allFilterData.filtered);

    const { by: sortBy, order: sortOrder } = sort;
    this.setState({
      isFetching: true,
      isError: false,
      mediaList: []
    });
    const tagFilters = {};

    if (selectedTags.length) {
      tagFilters.tags = selectedTags.map(tag => tag.value).join(',');
    }
    const filters = { ...filtered, ...tagFilters };
    const newFilter = { page, sort, filtered, tagFilters };
    this.setState({ allFilterData: newFilter });

    getAccessTokenAndMakeCalls(token =>
      callGetMediasApi(token, {
        size: 20,
        sortBy,
        sortOrder,
        page,
        filters
      })
    )
      .then(res => {
        const mediaList = get(res, 'data.list', []);
        this.setState(next => ({
          ...next,
          mediaList,
          isFetching: false,
          pageInfo: {
            ...next.pageInfo,
            current: page,
            // resultCount for /search
            total: res && res.data && res.data.count
          },
          sort
        }));
      })
      .catch(err => {
        if (!err.canceled) {
          this.setState({ isFetching: false, isError: true, mediaList: [] });
        }
      });
  };

  activeModal = modalId => {
    const {
      selectItems,
      closeModal,
      tableId,
      activeModalFind,
      selectedItems,
      openModal
    } = this.props;
    switch (modalId) {
      case 'deleteItems':
        return (
          <DeleteModal
            refreshHandler={() => {
              selectItems(tableId, []);
              this.fetchMediaItems();
            }}
          />
        );
      case 'addMediaToPlaylist':
        return (
          <MediaAddToPlaylistModal
            refreshHandler={() => {
              selectItems(tableId, []);
            }}
          />
        );
      case 'createPlaylistFromMedia':
        return (
          <CreatePlaylistFromMediaModal
            refreshHandler={() => {
              selectItems(tableId, []);
            }}
          />
        );
      case 'editMedia':
        return (
          <MediaEditModal
            refreshHandler={() => {
              selectItems('media', []);
              this.fetchMediaItems();
            }}
          />
        );
      case 'showUsage':
        if (activeModalFind === 'playlist') {
          return (
            <ShowUsageModal
              customClose={() => openModal('addMediaToPlaylist', { items: selectedItems })}
            />
          );
        }

        return <ShowUsageModal />;
      case 'previewItems':
        if (activeModalFind === 'playlist') {
          return (
            <PreviewItemsModal
              customClose={() => openModal('addMediaToPlaylist', { items: selectedItems })}
            />
          );
        }

        return <PreviewItemsModal />;
      case 'download':
        return (
          <Modal
            open
            size="small"
            modalCloseHandler={() => {
              kllDowloadRequest();
              closeModal();
            }}
          >
            <Loader />
          </Modal>
        );
      case 'downloadError':
        return (
          <Modal size="small" open enableOutsideClick modalCloseHandler={() => closeModal()}>
            {<div>There was an error during downloading media asset(s)</div>}
          </Modal>
        );
      case 'createTemplate':
        return (
          <ConfigureTemplateModal
            refreshHandler={() => {
              selectItems(tableId, []);
              this.fetchMediaItems();
            }}
          />
        );
      default:
        return null;
    }
  };

  toggleFilter = () => {
    this.setState(prev => ({
      ...prev,
      showFilters: !prev.showFilters
    }));
  };

  selectTag = selected => {
    if (selected.length > 10) {
      return;
    }

    this.setState(prev => ({
      ...prev,
      selectedTags: selected
    }));
  };

  clearFilters = () => {
    this.setState(
      {
        selectedTags: []
      },
      () => {
        this.fetchMediaItems();
      }
    );
  };

  initData() {
    const { history, tableId } = this.props;
    const queryParams = getQueryParamsForTable(tableId, history);

    this.fetchMediaItems(queryParams);
  }

  render() {
    const {
      isFetching,
      pageInfo,
      mediaList,
      isDropped,
      sort,
      isError,
      showFilters,
      selectedTags,
      tagsLoading,
      allTags
    } = this.state;
    const { tableId, activeModalId, shownTags, preSelectedItems, className } = this.props;
    if (isDropped) {
      return <Redirect to="/media/upload" />;
    }

    const newMediaList = formatMediaList(
      mediaList.map(item => ({
        ...item,
        showTags: shownTags.includes(item.id)
      }))
    );

    return (
      <>
        <div className="generic-container">
          <h1 className="gl-heading--m page-title" data-testid="media-page-title">
            Media Library
            <button
              type="button"
              className="gl-cta gl-cta--secondary"
              onClick={this.toggleFilter}
              style={{ float: 'right', marginTop: '-10px' }}
              data-testid="media-toggle-filters"
            >
              {showFilters ? 'Hide' : 'Show'} Filters
              {selectedTags.length ? ` (selected ${selectedTags.length} tag(s))` : ''}
            </button>
          </h1>
          <div className="row no-gutters">
            <aside
              className={`${
                showFilters
                  ? 'col-s-3 logs__container__filters'
                  : 'logs__container__filters logs__container__filters--hidden'
              }`}
            >
              <div
                className="logs__container__filters__item logs__container__filters__item--tags"
                key="tags-filter"
              >
                {tagsLoading ? (
                  'Loading filters...'
                ) : (
                  <>
                    <div className="filter-buttons">
                      <button
                        type="button"
                        className="gl-text-end gl-cta gl-cta--primary"
                        aria-label="Apply Filters"
                        onClick={this.fetchMediaItems}
                        disabled={isFetching}
                        data-testid="media-filters-apply-button"
                      >
                        Apply Filters
                      </button>

                      <button
                        type="button"
                        className="gl-text-end gl-cta gl-cta--secondary"
                        aria-label="Clear All Filters"
                        onClick={this.clearFilters}
                        title="This will clear all applied filters."
                        disabled={isFetching}
                        data-testid="media-filters-clear-button"
                      >
                        Clear All
                      </button>
                    </div>
                    <div className="gl-label">Tags</div>
                    {allTags && allTags.length > 0 && (
                      <CustomSelect
                        options={allTags}
                        onChange={sel => this.selectTag(sel)}
                        selectedOption={selectedTags}
                        isMulti
                        memoKey={(selectedTags || []).map(stag => stag.label).join('')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        data-testid="media-filters-select"
                        // closeMenuOnSelect={false}
                      />
                    )}

                    <div
                      className="validation validation-error"
                      style={{ display: selectedTags.length > 9 ? 'block' : 'none' }}
                    >
                      Cannot select more than 10 tags at a time
                    </div>
                  </>
                )}
              </div>
            </aside>
            <div className={`${showFilters ? 'col-s-9 logs__table' : 'col-s-12 logs__table'}`}>
              <Dropzone onDrop={this.onDrop} onClick={evt => evt.preventDefault()}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()} style={{ position: 'relative' }}>
                    <input {...getInputProps()} style={{ display: 'none' }} />
                    {isDragActive && (
                      <div className="drag-and-drop__overlay__media">
                        <p className="drag-and-drop__overlay--message__media">Drop files here</p>
                      </div>
                    )}
                    <input style={{ display: 'none' }} type="text" id="mimetypes" />
                    <Table
                      activeLibrary="media"
                      tableData={newMediaList}
                      tableSchema={tableSchema}
                      tableId={tableId}
                      className={className}
                      isFilterable
                      isSelectable
                      hasColumnOptions
                      hasQueryParamsHandling
                      sort={sort}
                      isFetching={isFetching}
                      isError={isError}
                      filterHandler={this.fetchMediaItems}
                      sortHandler={this.fetchMediaItems}
                      pageInfo={pageInfo}
                      refreshHandler={this.refresh}
                      paginationHandler={this.fetchMediaItems}
                      preSelectedItems={preSelectedItems}
                    />
                    {this.activeModal(activeModalId)}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MediaList.propTypes = {
  selectItems: PropTypes.func,
  uploadFiles: PropTypes.func,
  closeModal: PropTypes.func,
  tableId: PropTypes.string,
  activeModalFind: PropTypes.string,
  activeModalId: PropTypes.string,
  location: PropTypes.shape({}),
  history: PropTypes.shape({}),
  openModal: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  shownTags: PropTypes.arrayOf(PropTypes.number)
};

MediaList.defaultProps = {
  tableId: 'media'
};

const mapStateToProps = state => ({
  activeModalId: state.modals.activeModal.modalId,
  activeModalFind: state.modals.activeModal.find,
  selectedItems: state.table.media.selected,
  shownTags: state.table.shownTags
});

const mapDispatchToProps = {
  selectItems: selectItemsAction,
  uploadFiles: uploadFilesAction,
  closeModal: closeModalAction,
  openModal: openModalAction
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MediaList)
);
