import React, { Component } from 'react';
import { GlDropdown } from '@adl/foundation';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  render() {
    return (
      <GlDropdown
        onRequestOpen={() => this.setState({ open: true })}
        onRequestClose={() => this.setState({ open: false })}
        value={this.state.value}
        open={this.state.open}
        // onChange={val => this.setState({ value: val })}
        {...this.props}
      />
    );
  }
}

export default Dropdown;
