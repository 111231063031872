import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

const Loader = props => {
  const { text, className } = props;
  return (
    <div className={`custom-loader ${className || ''}`}>
      <div className="gl-loader gl-loader--black" />
      {text && <p className="custom-loader__text"> {text} </p>}
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default Loader;
