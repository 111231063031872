import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GlToggle } from '@adl/foundation';

import SchedulingLogs from './SchedulingLogs/SchedulingLogs'
import FrameManagementLogs from './FrameManagementLogs/FrameManagementLogs'

import { pageTitle } from '../../helpers/pageTitles';
import './SchedulingLogs/SchedulingLogs.scss';

class ActivityLogs extends Component {
  state = {
    toggleValue: '1'
  };

  componentDidMount = () => {
    const { roles } = this.props;
    document.title = pageTitle.activityLog || 'Activity Log';
    let showBothTabs = roles.includes('ROLE_ADMIN') || roles.includes('ROLE_POWER_USER');
    this.setState({ showBothTabs, toggleValue: showBothTabs ? '1': '0'})
  };


  changeToggle(e) {
    this.setState({ toggleValue: e.target.value })
  }

  render() {
    const {
      toggleValue,
      showBothTabs
    } = this.state;
    
    return (
      <div className="generic-container">
        <div style={{display: 'flex'}}>
          <div>
          <h1 className="gl-heading--m page-title activity-log-title" data-testid="activity-log-page-title">
          Activity Log</h1>
          <GlToggle
            items={showBothTabs ? ['Frame Management', 'Scheduling'] : ['Scheduling']}
            onChange={(e) => this.changeToggle(e)}
            value={toggleValue}
          />
          </div>
        
        </div>
       {toggleValue === '0' && (showBothTabs ? <FrameManagementLogs /> : <SchedulingLogs />)}
       {toggleValue === '1' && showBothTabs && <SchedulingLogs />}
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles
});

export default withRouter(connect(mapStateToProps, null)(ActivityLogs));