/* eslint-disable no-console */
import { apiEndpoints, clearCookieIfExpired } from './utils';
import { callApiEndpoint } from './routes';

export const callGetLoggedInUserInfoApi = token =>
  callApiEndpoint(apiEndpoints.getLoggedInUserInfo, token)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetUserPreferenceApi = token =>
  callApiEndpoint(apiEndpoints.getUserPreferences, token)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateUserPreferenceApi = (token, data) =>
  callApiEndpoint(apiEndpoints.updateUserPreferences, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchPlayerApi = token =>
  callApiEndpoint(apiEndpoints.fetchPlayers, token)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callSchedulePlaylistsApi = (token, data) =>
  callApiEndpoint(apiEndpoints.schedulePlaylists, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callPlayerSchedulesApi = (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayerSchedules, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callSendLogApi = (token, data) =>
  callApiEndpoint(apiEndpoints.sendLog, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetFrameDetailsApi = (token, data) =>
  callApiEndpoint(apiEndpoints.getFrameDetails, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateFramesDataApi = (token, data) =>
  callApiEndpoint(apiEndpoints.updateFramesData, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callTimeslotsDataApi = (token, data) =>
  callApiEndpoint(apiEndpoints.getTimeslotsData, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetMediaSearchApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getMediaSearch, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlaylistsSearchApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlaylistsSearch, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlayersSearchApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayersSearch, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetLocationApi = async token =>
  callApiEndpoint(apiEndpoints.getLocation, token)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateLocationApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updateLocation, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callDeleteLocationApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.deleteLocation, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetTaxonomyTagsApi = async token =>
  callApiEndpoint(apiEndpoints.getTaxonomyTags, token)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetMediaApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getMedia, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetMediasApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getMedias, token, data)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateMediaInfoApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updateMediaInfo, token, data)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateMessageInfoApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updateMessageInfo, token, data)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUploadMediaApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.uploadMedia, token, data)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateMediaLocationApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updateMediaLocation, token, data)
    .then(response => response)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlaylistsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlaylists, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetAutocompleteApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getAutoComplete, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetTemplatesApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getTemplates, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetTemplateFieldsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getTemplateFields, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callCreateMessageApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.createMessage, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetFilteredPlaylistsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getFilteredPlaylists, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callDeletePlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.deletePlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callRemovePlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.removePlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callDeleteFromAMSApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.deleteFromAMS, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGenericScalaCMSRestApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.genericCMSRest, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callCreatePlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.createPlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
      throw e;
    });

export const callUpdatePlaylistMediaApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updatePlaylistMedia, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
      throw e;
    });

export const callUpdatePlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updatePlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdatePlaylistv2Api = async (token, data) =>
  callApiEndpoint(apiEndpoints.updatePlaylistv2, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
      throw e;
    });

export const callGetChannelsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getChannels, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetChannelApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getChannel, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callAddSubPlaylistsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.addSubPlaylists, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callDuplicatePlaylistApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.duplicatePlaylist, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
      throw e;
    });

export const callGetPlayerApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayer, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlayerStatusApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayerStatus, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlayerPlansApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayerPlans, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchSchedulingActivityLogsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.fetchSchedulingActivityLogs, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchSchedulingActivityLogsFiltersApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.fetchSchedulingActivityLogsFilters, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchFrameManagementActivityLogsApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.fetchFrameManagementActivityLogs, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchFrameManagementActivityLogsFiltersApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.fetchFrameManagementActivityLogsFilters, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUserCreationApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.userCreation, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
      return e;
    });

export const callUserDeletionApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.userDeletion, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUserInfoApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.userInfo, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUserBuildRequestApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.userBuildRequest, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callFetchUsersApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.fetchUsers, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdatePlayerSchedulesApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updatePlayerSchedules, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetPlayerHealthApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getPlayerHealth, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetRoleApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getRoles, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetUserRoleApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getUserRole, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdateUserRoleApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updateUserRole, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGeneratePlanApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.generatePlan, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callGetUuidApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.getUuid, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callDownloadMediaApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.downloadMedia, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUrlWrapperApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.urlWrapper, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });

export const callUpdatePlayerMetadataApi = async (token, data) =>
  callApiEndpoint(apiEndpoints.updatePlayerMetadata, token, data)
    .then(response => response.data)
    .catch(e => {
      console.log(e);
      clearCookieIfExpired(e);
    });
