export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_CAN_SAVE = 'UPDATE_CAN_SAVE';
export const UPDATE_IS_SAVING = 'UPDATE_IS_SAVING';
export const UPDATE_COMPLETED = 'UPDATE_COMPLETED';
export const UPDATE_DATA_TO_SCHEDULE = 'UPDATE_DATA_TO_SCHEDULE';
export const UPDATE_WARNING_ACKNOWLEDGED = 'UPDATE_WARNING_ACKNOWLEDGED';
export const RESET_SCHEDULING = 'RESET_SCHEDULING';

export const updateStep = (value) => ({
  type: UPDATE_STEP,
  value
});

export const updateCanSave = (value) => ({
  type: UPDATE_CAN_SAVE,
  value
});

export const updateIsSaving = (value) => ({
  type: UPDATE_IS_SAVING,
  value
});

export const updateCompleted = (value, result, optimizedScheduleList) => ({
  type: UPDATE_COMPLETED,
  value,
  result,
  optimizedScheduleList
});

export const updateWarningAcknowledged = (value) => ({
  type: UPDATE_WARNING_ACKNOWLEDGED,
  value
});

export const updateDataToSchedule = (dataToSchedule, selectedPlayerIds) => ({
  type: UPDATE_DATA_TO_SCHEDULE,
  dataToSchedule,
  selectedPlayerIds
});

export const resetScheduling = () => ({
  type: RESET_SCHEDULING
});
