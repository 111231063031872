import React from 'react';
import { playerHealthProblemMap } from '../../helpers/misc';

export const tableSchema = [
  {
    id: 'problems',
    title: 'Problems'
  },
  {
    id: 'description',
    title: 'Description'
  },
  {
    id: 'lastOccurrence',
    title: 'Last Occurrence'
  },
  {
    id: 'details',
    title: 'Details'
  }
];

export const formatLogs = (healthLogs = []) => {
  const tableDataSelected = [];
  healthLogs.forEach((item, rowIndex) => {
    tableDataSelected[rowIndex] = {
      id: rowIndex
    };

    tableDataSelected[rowIndex].problems = (
      <div>
        {item.problemNumber}:{playerHealthProblemMap[item.problemNumber].title || ''}
      </div>
    );
    tableDataSelected[rowIndex].description = <div>{item.problemMessage}</div>;
    tableDataSelected[rowIndex].lastOccurrence = `${item.last}`;
    tableDataSelected[rowIndex].details = (
      <div>
        {item.descriptionDetails && item.descriptionDetails.length > 0 ? (
          <ul>
            {item.descriptionDetails.map(deet => (
              <li>{deet}</li>
            ))}
          </ul>
        ) : (
          'NA'
        )}
      </div>
    );
  });

  return tableDataSelected;
};
