import axiosOriginal from 'axios';
import FileSaver from 'file-saver';
import omit from 'lodash-es/omit';
import get from 'lodash-es/get';
import qs from 'qs';

import store from '../stores/index';
import { closeModal, openModal } from '../actions/modal';
import constants from './constants';
import {
  validFileExtensions,
  validImageFormats,
  supportedForTransformations,
  cloudinaryDownloadFileName,
  playlistsToHide,
  playerMetadasToParse
} from './misc';
import { updateTableSettings } from '../actions/table';
import { loadAuth } from '../actions/auth';
import { getAccessTokenAndMakeCalls } from '../serviceLayer/utils';
import {
  callDownloadMediaApi,
  callGeneratePlanApi,
  callGenericScalaCMSRestApi,
  callGetChannelsApi,
  callGetLoggedInUserInfoApi,
  callGetUuidApi,
  callUpdateUserPreferenceApi
} from '../serviceLayer/services';

export const redirectUserToLogin = () => {
  window.location.replace(constants.LOGIN_URL);
};
export const REDIRECT_KEY = 'app-redirect';

// use it as true if working on local enviroment
// it disables authetication
const disableAuth = false;

const addInterceptor = _axios => {
  _axios.interceptors.response.use(
    _response => _response,
    error => {
      if (error.response) {
        const {
          response: { status },
          config: { url, method }
        } = error;
        if (status === 401 || status === 403) {
          const { pathname, search } = document.location;
          const methodMatches = method === 'delete' || method === 'put' || method === 'post';
          const locationMatches = url.includes('playlist') || url.includes('location');
          const handleSpecificUrl = url.includes('schedules'); // temporary fix
          if (
            url === constants.DELETE_URL ||
            (methodMatches && locationMatches) ||
            handleSpecificUrl
          ) {
            return Promise.reject(error);
          }
          localStorage.setItem(REDIRECT_KEY, `${pathname}${search}`);
          redirectUserToLogin();
        }
      }
      return Promise.reject(error);
    }
  );
};

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 'n/a';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) {
    return `${bytes} ${sizes[i]})`;
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export const emptyStringOnCriteria = (string, stringsExludedArr, fail = '', predicate = null) => {
  let test = stringsExludedArr.includes(string);
  if (predicate != null) {
    test = test || predicate(string);
  }
  return test ? fail : string;
};

export const shortenStringWithElipsis = (word, maxLength) =>
  word.length > maxLength ? `${word.slice(0, maxLength)}...` : word;

export const validFileExt = filename => {
  if (typeof filename !== 'string') {
    return false;
  }

  const ext = filename
    .split('.')
    .pop()
    .toLowerCase();

  return validFileExtensions.indexOf(ext) > -1;
};

export const getInvalidFiles = (files = []) =>
  files.map(file => (validFileExt(file.name) ? null : file.name));

export const checkImageFormat = (file = '') =>
  validImageFormats.indexOf(
    String(file)
      .split('.')
      .pop()
  ) > -1;

export const canBeTransformed = (file = '') =>
  supportedForTransformations.indexOf(
    String(file)
      .split('.')
      .pop()
  ) > -1;

// use this only while disableAuth !!!!
const addHeader = () =>
  disableAuth ? { 'x-disip-auto-login': process.env.REACT_APP_GOD_LOGIN } : null;

export const axios = axiosOriginal.create({
  baseURL: constants.SCALA_API_URL,
  headers: addHeader(),
  paramsSerializer: params =>
    qs.stringify(params, {
      encodeValuesOnly: true,
      encoder: str => encodeURI(str)
    })
});

addInterceptor(axios);

export const axiosOrg = axiosOriginal.create({
  headers: addHeader(),
  paramsSerializer: params =>
    qs.stringify(params, {
      encodeValuesOnly: true,
      encoder: str => encodeURI(str)
    })
});
addInterceptor(axiosOrg);

export const logoutUser = () => {
  window.location.replace(constants.LOGOUT_URL);
};
export const getLoginInfo = () =>
  disableAuth
    ? Promise.resolve({
        data: {
          roles: ['ROLE_ADMIN'],
          name: 'User',
          email: 'test@test.com'
        }
      }).then(res => store.dispatch(loadAuth(res.data)))
    : getAccessTokenAndMakeCalls(token => callGetLoggedInUserInfoApi(token));

export const getCancelToken = () => axiosOriginal.CancelToken.source();
// use this method if you want canceling posibility
let CANCEL_TOKEN;
export const getWrapper = (_axios, url, params) => {
  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel('Operation canceled due to new request.');
  }

  CANCEL_TOKEN = getCancelToken();
  // decorationg axios to have interceptor handling unauthorized
  return _axios
    .get(url, {
      params,
      cancelToken: CANCEL_TOKEN.token
    })
    .then(res => res)
    .catch(err => {
      if (axiosOriginal.isCancel(err)) {
        throw Object.assign({}, { canceled: true });
      } else {
        throw err;
      }
    });
};

export const generatePlan = async playerIds => {
  store.dispatch(
    openModal('planGeneration', {
      inProgress: true,
      error: false
    })
  );

  const uuid = await getAccessTokenAndMakeCalls(token =>
    callGetUuidApi(token, { ids: playerIds })
  ).catch(() => {
    store.dispatch(
      openModal('planGeneration', {
        inProgress: false,
        error: true,
        completed: true
      })
    );
  });

  if (!uuid) {
    return;
  }

  getAccessTokenAndMakeCalls(token => callGeneratePlanApi(token, { uuid: uuid.value }))
    .then(() => {
      store.dispatch(
        openModal('planGeneration', {
          inProgress: false,
          error: false,
          completed: true
        })
      );
    })
    .catch(() => {
      store.dispatch(
        openModal('planGeneration', {
          inProgress: false,
          error: true,
          completed: true
        })
      );
    });
};

export const downloadCloudinaryItems = (files = []) => {
  if (files.length < 1) {
    return;
  }
  const validCloudinaryFiles = files
    .filter(file => file.cloudinary && file.cloudinary.secure_url)
    .map(file => file.cloudinary.secure_url);

  CANCEL_TOKEN = getCancelToken();

  getAccessTokenAndMakeCalls(token =>
    callDownloadMediaApi(token, {
      zipfilename: cloudinaryDownloadFileName,
      files: validCloudinaryFiles
    })
  )
    .then(res => {
      const newBlob = new Blob([res], { type: 'application/octet-stream' });
      FileSaver.saveAs(newBlob, 'scala-adidas-download.zip');

      store.dispatch(closeModal());
    })
    .catch(err => {
      if (!axiosOriginal.isCancel(err)) {
        store.dispatch(openModal('downloadError'));
      }

      store.dispatch(closeModal());
    });
};

export const kllDowloadRequest = () => {
  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel('Operation canceled due to change requests.');
  }
};

export const fetchItemsByIds = (type, itemIds, params = {}) => {
  const end = type === 'channels' ? '' : 'search';
  return getAccessTokenAndMakeCalls(token =>
    callGenericScalaCMSRestApi(token, {
      params: {
        limit: 1000,
        listOnly: true,
        count: 0,
        offset: 0,
        sort: 'id',
        filters: `{id : {values :[${itemIds.join(',')}], comparator: 'eq' }}`,
        ...params
      },
      type,
      end
    })
  );
};

export const formatCloudinaryFields = (fields = []) => {
  const data = {};
  if (!Array.isArray(fields)) {
    return data;
  }
  fields.forEach(field => {
    data[field.name] = field.value;
  });
  return data;
};

export const cnSecureUrl = (url = '') => url;

export const transformCloudinaryImage = (
  { secure_url: secureUrl, resource_type: resourceType, format },
  transform
) => {
  if (!secureUrl) {
    return false;
  }
  let image = secureUrl;
  const canTransformToJPG = canBeTransformed(secureUrl);

  if (image && format !== 'jpg' && (resourceType !== 'image' || canTransformToJPG)) {
    const ext = `.${format}`;
    image = secureUrl.substring(0, secureUrl.length - ext.length);
    image += '.jpg';
  }

  return cnSecureUrl(
    image.replace(
      `/${resourceType}/upload/`,
      `/${resourceType}/upload/${transform ? `${transform}/` : ''}`
    )
  );
};

export const transformCloudinaryVideo = ({
  secure_url: secureUrl,
  resource_type: resourceType,
  format
}) => {
  if (!secureUrl) {
    return false;
  }

  if (format === 'mp4') {
    return cnSecureUrl(secureUrl);
  }

  const ext = `.${format}`;
  return cnSecureUrl(`${secureUrl.substring(0, secureUrl.length - ext.length)}.mp4`);
};

export const copyToClipboard = text => {
  const textField = document.createElement('textarea');
  textField.setAttribute('style', 'width:1px;border:0;opacity:0;position:fixed');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const getQueryParamValue = (location, key) => {
  if (location.search) {
    const query = new URLSearchParams(location.search);

    for (const [_key, value] of Object.entries(query)) {
      if (_key === key) {
        return value;
      }
    }
  }

  return null;
};

export const getQueryParamsFromLocalStore = tableId => {
  const savedColumnsOrder = localStorage.getItem(`${tableId}-query-params`);
  if (savedColumnsOrder != null) {
    return JSON.parse(savedColumnsOrder);
  }

  return null;
};

export const setQueryParamsToLocalStore = (tableId, params) => {
  if (Object.keys(params).length > 0) {
    localStorage.setItem(`${tableId}-query-params`, JSON.stringify(params));
  }
};

// exception list when we have tableID that doesnt match route
export const exceptionList = tableId => {
  switch (tableId) {
    case 'dashboard:1':
    case 'dashboard:2':
    case 'schedulingLogs':
      return true;
    default:
      return false;
  }
};

// decidec if should interact with URL we need that for exception like Modals on media
export const shouldWorkWithQueryParams = (location, tableId) =>
  location.pathname.replace('/', '') === tableId || exceptionList(tableId);

export const appendQueryParam = (tableId, history, queryParamsObj, init = false) => {
  const { location } = history;
  const oldParams = new URLSearchParams(location.search);
  const newParams = { ...oldParams };
  const isCurrentRoute = shouldWorkWithQueryParams(location, tableId);

  Object.keys(queryParamsObj).forEach(key => {
    if (queryParamsObj[key] !== '') {
      newParams[key] = queryParamsObj[key];
    } else {
      delete newParams[key];
    }
  });

  if (!init) {
    setQueryParamsToLocalStore(tableId, omit(newParams, ['page', 'filterBy', 'values']));
  }

  if (isCurrentRoute) {
    const query = Object.keys(newParams)
      .filter(key => ['get', 'searchString'].includes(key) === false)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newParams[k])}`)
      .join('&');

    history.push({
      pathname: location.pathname,
      search: `?${query}`
    });
  }
};

export const getExpirationKey = endDate => {
  if (endDate) {
    const date1 = new Date(endDate);
    const date2 = new Date();
    const timeDiff = date1.getTime() - date2.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays < 0) {
      return 'expired';
    }

    if (diffDays <= 30) {
      return 'expires-soon';
    }
  }

  return '';
};

export const getQueryParamsForTable = (tableId, history) => {
  const { location } = history;
  const page = getQueryParamValue(location, 'page');
  const sortBy = getQueryParamValue(location, 'sortBy');
  const filterBy = getQueryParamValue(location, 'filterBy');
  let getParamsFromLocalStorage = true;
  const isCurrentRoute = shouldWorkWithQueryParams(location, tableId);
  let params = {};

  const getFiltered = (_filterBy, _values) => {
    if (_filterBy && _values) {
      const filterBySplited = _filterBy.split(',');
      const valuesSplited = _values.split(',');
      if (filterBySplited.length === valuesSplited.length) {
        const filtered = valuesSplited.reduce((acc, curr, i) => {
          const key = filterBySplited[i];
          acc[key] = curr;
          return acc;
        }, {});
        return filtered;
      }
    }
    return null;
  };

  if (isCurrentRoute && page != null) {
    params.page = +page;
    getParamsFromLocalStorage = false;
  }

  if (isCurrentRoute && sortBy != null) {
    const order = getQueryParamValue(location, 'order') || 'desc';
    params.sort = { by: sortBy, order };
    getParamsFromLocalStorage = false;
  }

  if (isCurrentRoute && filterBy != null) {
    const values = getQueryParamValue(location, 'values');
    const res = getFiltered(filterBy, values);
    if (res != null) {
      params.filtered = res;
      getParamsFromLocalStorage = false;
    }
  }

  if (getParamsFromLocalStorage) {
    const fromStore = getQueryParamsFromLocalStore(tableId);
    if (fromStore != null) {
      params = { page: 1 };
      if (fromStore.sortBy != null) {
        params.sort = { by: fromStore.sortBy, order: fromStore.order };
      }
      if (isCurrentRoute) {
        appendQueryParam(tableId, history, { page: 1, ...fromStore }, true);
      }
    }
  }

  return params;
};

export const transformFilterObjectToQueryParams = object => {
  const queryParams = { page: 1 };
  const [filterBy, values] = Object.keys(object).reduce(
    (acc, curr) => {
      acc[0].push(curr);
      acc[1].push(object[curr]);
      return acc;
    },
    [[], []]
  );
  queryParams.filterBy = filterBy.join(',');
  queryParams.values = values.join(',');

  return queryParams;
};

export const saveColumnOptions = (tableId, options) => {
  store.dispatch(updateTableSettings(tableId, options));
  getAccessTokenAndMakeCalls(token => callUpdateUserPreferenceApi(token, options));
};

export const checkEmptyPlaylist = (items = []) => {
  const invalidItems = [];
  if (!items.length) {
    return true;
  }

  items
    .filter(item => {
      if (item.subplaylist && item.subplaylist.id !== constants.cisId) {
        return false;
      }

      return true;
    })
    .forEach(item => {
      const isDisabled = item.disabled;
      const expired = (item.status || [])
        .join(',')
        .toLowerCase()
        .includes('expired');

      const mediaExpired = item.media && item.media.validDateStatus === 'EXPIRED';
      const hasEmptySubplaylist = item.subplaylist && item.subplaylist.itemCount === 0;
      if (isDisabled || expired || hasEmptySubplaylist || mediaExpired) {
        invalidItems.push(item.id);
      }
    });

  return invalidItems.length === items.length;
};

export const trimInputValue = value =>
  value
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/\t/, '');

export const filterOutPlaylists = (playlists = []) =>
  playlists.filter(
    ({ id, description, subplaylist = {} }) =>
      // description !== masterPlaylistDescription &&
      !playlistsToHide.includes(subplaylist.id || id)
  );

export const calculateNumberOfDays = date => {
  const currentDate = new Date();
  const formatedInputDate = new Date(date);
  return Math.ceil((formatedInputDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
};

export const formatDuration = (duration = '') =>
  String(duration)
    .replace('(0)', '00:00:00')
    .replace('(...)', '')
    .split('.')
    .shift()
    .replace('(', '')
    .replace(')', '');
// .substring(2);

export const prettifyDuration = seconds =>
  // eslint-disable-next-line radix
  [parseInt(seconds / 60 / 60), parseInt((seconds / 60) % 60), parseInt(seconds % 60)]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');

export const findPlaylistInPlayers = async playlistId => {
  const inPlaylists = await fetchItemsByIds('playlists', [], {
    filters: `{playlist: {values:[${playlistId}], comparator : 'eq'}}`,
    fields: 'id,name,playlistItems',
    limit: 1000
  }).then(res => {
    const actives = [];
    const mainPlaylists = get(res, 'list', []);

    mainPlaylists.forEach(mp => {
      mp.playlistItems.forEach(pl => {
        const expired = (pl.status || []).includes('TIMESCHEDULE_EXPIRED');
        const subpl = pl.subplaylist || {};

        if (subpl.id === playlistId && !pl.disabled && !expired) {
          actives.push(mp.id);
        }
      });
    });
    return actives;
  });

  let channels = [];

  if (inPlaylists.length) {
    const inChannels = await getAccessTokenAndMakeCalls(token =>
      callGetChannelsApi(token, {
        params: {
          filters: `{playlists: {values : [${inPlaylists.join(',')}]}, comparator : "in"}`,
          fields: 'id,name'
        }
      })
    ).then(res => get(res, 'list', []).map(item => item.id));

    channels = [...inChannels];
  }

  const directChannels = await getAccessTokenAndMakeCalls(token =>
    callGetChannelsApi(token, {
      params: {
        filters: `{playlists: {values : [${playlistId}]}, comparator : "in"}`,
        fields: 'id,name'
      }
    })
  ).then(res => get(res, 'list', []).map(item => item.id));

  if (directChannels.length) {
    channels = [...channels, ...directChannels];
  }

  if (!channels.length) {
    return [];
  }

  const players = fetchItemsByIds('players', [], {
    filters: `{channels : {values:[${channels.join(',')}], comparator : 'in'}}`,
    fields: 'id,name'
  }).then(res => get(res, 'list', []));

  return players || [];
};

export const parseMetadata = (metadata = []) => {
  const exported = {};

  metadata
    .map(meta => {
      const name = meta.playerMetadata.name.toLowerCase().slice(8);
      const { value } = meta;
      return playerMetadasToParse.includes(name)
        ? {
            name,
            value
          }
        : false;
    })
    .filter(Boolean)
    .forEach(meta => {
      const { name, value } = meta;
      exported[name] = value;
    });

  return exported;
};
export const setPropertInThreeLeafs = (treeObj, prop, ids) => {
  if (treeObj != null) {
    return treeObj.map(el => {
      let newEl;

      if (ids.includes(el.id)) {
        newEl = { ...el, [prop]: true };
      } else if (el.children) {
        newEl = { ...el, children: setPropertInThreeLeafs(el.children, prop, ids) };
      } else {
        newEl = el;
      }

      return newEl;
    });
  }

  return treeObj;
};

export const intersectionArrays = (arrA, arrB) => arrA.filter(x => arrB.includes(x));

export const arrayToObjectByIds = array =>
  array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

// in axiosOrg above, we are unable to test axiosOrg.isCancel(thrownError)
// eslint-disable-next-line no-underscore-dangle
export const isCancel = value => !!(value && value.__CANCEL__);

export const calculatePlaylistDuration = (items = []) => {
  let allDuration = 0;
  items.forEach(item => {
    if (!item.media) {
      return;
    }

    const { duration } = item;
    const prettyDuration = get(item, 'media.prettifyDuration', '');
    const mediaFields = get(item, 'media.fields');
    const durationField = mediaFields && mediaFields.find(mf => mf.name === 'duration');

    if (duration) {
      allDuration += Number(duration);
    } else if (durationField) {
      allDuration += Number(durationField.value || 0);
    } else if (!duration && prettyDuration) {
      const split = prettyDuration.split('.');
      if (split.length) {
        const times = split[0].split(':');
        const [hours, minutes, seconds] = times.map(d => parseInt(d.replace(/\D+/, ''), 10));

        const toAdd = Number(hours * 3600) + Number(minutes * 60) + Number(seconds);
        allDuration += toAdd;
      }
    }
  });

  return allDuration;
};

export const parseAssetName = (name = '') => {
  const parts = String(name).split('/');

  return parts[parts.length - 1];
};
