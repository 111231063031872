import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton, GlInput, GlTextarea } from '@adl/foundation';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import get from 'lodash-es/get';
import produce from 'immer';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';

import Modal from '../commons/Modal/Modal';
import Table from '../commons/Table/Table';
import Loader from '../commons/Loader/Loader';
import { closeModal as closeModalAction } from '../../actions/modal';
import { fetchItemsByIds, trimInputValue } from '../../helpers/utils';
import { UITEXT } from '../../helpers/misc';
import './Modals.scss';
import { ALLOWED_CHARACTERS_COUNT_MSG, allowedCharactersCount } from '../../helpers/validators';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callUpdatePlaylistv2Api } from '../../serviceLayer/services';

class PlaylistEditModal extends Component {
  state = {
    isFetchingInfo: true,
    fetchError: false,
    allTagsByIds: {},
    loadedInfo: {},
    loadedInfoByIds: [],
    editedInfo: {},
    successItems: [],
    notAuthToDeleteItems: [],
    isEditing: false,
    editComplete: false,
    noItemsFound: false,
    isValid: {},
    isDirty: false
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);

    this.fetchPlaylistInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  }

  handlePageRefresh = e => {
    const { isDirty } = this.state;

    if (isDirty) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  handleInfoChange = (id, data, _isValid = false) => {
    const key = Object.keys(data)[0];
    this.setState(next =>
      produce(next, draft => {
        draft.isValid = {
          ...draft.isValid,
          [id]: {
            ...draft.isValid[id],
            [key]: _isValid
          }
        };
        draft.isDirty = true;
        draft.editedInfo[id] = Object.assign({}, draft.editedInfo[id], data);
      })
    );
  };

  applyToAll = (field, id) => {
    const { loadedInfoByIds, editedInfo } = this.state;

    const item = editedInfo[id];
    const itemsToBulkEdit = { ...editedInfo };

    loadedInfoByIds.forEach(itemId => {
      itemsToBulkEdit[itemId][field] = item[field];
    });

    this.setState({ editedInfo: itemsToBulkEdit });
  };

  fetchPlaylistInfo = () => {
    const { selectedItems } = this.props;
    const itemIds = selectedItems.map(si => si.id);

    fetchItemsByIds('playlists', itemIds)
      .then(res => {
        const list = get(res, 'list', []);
        if (list.length > 0) {
          this.setState(next =>
            produce(next, draft => {
              draft.isFetchingInfo = false;

              res.list.forEach(item => {
                draft.loadedInfo[item.id] = item;
                draft.loadedInfoByIds.push(item.id);
                draft.isValid = {
                  ...draft.isValid,
                  [item.id]: {
                    name: allowedCharactersCount(trimInputValue(item.name), 6, 100)
                  }
                };
                draft.editedInfo[item.id] = {
                  id: item.id,
                  name: item.name,
                  description: item.description || '',
                  tags: this.getItemTagIds(item.categories)
                };
              });
            })
          );
        } else {
          this.setState({ noItemsFound: true });
        }
      })
      .catch(() => {
        this.setState({ isFetchingInfo: false, fetchError: true });
      });
  };

  getItemTagIds = (tags = []) => tags.map(t => t.id);

  formatTagsToCategories = tagIds => {
    const { allTagsByIds } = this.state;
    return tagIds.map(id => ({
      id,
      name: allTagsByIds[id].name
    }));
  };

  saveAllItems = () => {
    this.setState({ isEditing: true });
    const { editedInfo } = this.state;
    Promise.all(Object.keys(editedInfo).map(id => this.saveSingleItem(id))).then(() => {
      this.setState({ isEditing: false, editComplete: true });
    });
  };

  formatTableItems = (items = [], itemsDeleted, notAuthToDeleteItems) => {
    const result = [];
    items.forEach((item, rowIndex) => {
      result[rowIndex] = {
        id: item.id,
        name: (
          <Tooltip position="bottom" title={item.name}>
            <div className="ellipsis">{item.name}</div>
          </Tooltip>
        ),
        status:
          // eslint-disable-next-line no-nested-ternary
          itemsDeleted.indexOf(item.id) > -1 ? (
            <span className="validation-success">Edited</span>
          ) : notAuthToDeleteItems.indexOf(item.id) > -1 ? (
            <span className="validation-error">Not Authorized</span>
          ) : (
            <span className="validation-error">Error</span>
          )
      };
    });

    return result;
  };

  async saveSingleItem(itemId) {
    const { editedInfo } = this.state;
    const itemEdited = editedInfo[itemId];

    const newData = { name: itemEdited.name, description: itemEdited.description };

    getAccessTokenAndMakeCalls(token =>
      callUpdatePlaylistv2Api(token, {
        playlistId: itemId,
        ...newData
      })
    )
      .then(() => {
        this.setState(next =>
          produce(next, draft => {
            draft.successItems.push(+itemId);
          })
        );
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          (error.response.status === 401 || error.response.status === 402)
        ) {
          this.setState(next =>
            produce(next, draft => {
              draft.notAuthToDeleteItems.push(+itemId);
            })
          );
        }
      });
  }

  render() {
    const { closeModal, closeHandler } = this.props;
    const {
      isFetchingInfo,
      fetchError,
      noItemsFound,
      loadedInfoByIds,
      editedInfo,
      isEditing,
      editComplete,
      successItems,
      notAuthToDeleteItems,
      isValid,
      isLoading,
      isDirty
    } = this.state;
    const cannotSubmitEditPlaylistRequest = Object.values(isValid).some(item => !item.name);
    if (isFetchingInfo || fetchError) {
      return (
        <Modal size="small" open enableOutsideClick modalCloseHandler={() => closeModal()}>
          {fetchError && <div>There was an error loading media asset(s) info</div>}
          {isFetchingInfo && !noItemsFound && <Loader />}
          {(fetchError || noItemsFound) && (
            <div>There was an error loading media asset(s) info</div>
          )}
        </Modal>
      );
    }

    if (isLoading || isEditing) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    const tableSchema = [
      {
        id: 'name',
        title: 'Playlist'
      },
      {
        id: 'status',
        title: 'Status'
      }
    ];

    if (editComplete) {
      const editedItemIds = Object.keys(editedInfo).map(id => editedInfo[id]);
      return (
        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeHandler();
            closeModal();
          }}
        >
          <h4>Edit Playlist</h4>
          <p>Please check the status below:</p>
          <Table
            tableData={this.formatTableItems(editedItemIds, successItems, notAuthToDeleteItems)}
            isFilterable
            tableSchema={tableSchema}
            tableId="results"
          />
        </Modal>
      );
    }

    return (
      <>
        <Prompt when={isDirty} message={UITEXT.scheduleAway} />

        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            // eslint-disable-next-line no-restricted-globals
            if (isDirty && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          <h4>Edit playlist details</h4>
          <Container fluid>
            <Row align="start" justify="start">
              {loadedInfoByIds.map(id => {
                if (!editedInfo[id]) {
                  return null;
                }

                const item = editedInfo[id];
                const { name, description, id: itemId } = item;
                const colSize = loadedInfoByIds.length === 1 ? 12 : 6;

                return (
                  <Col key={id} xl={colSize} style={{ marginBottom: '20px' }}>
                    <div className="edit-table-2__column edit-table-2__column--grey edit-table-2__column--full-width">
                      <div>
                        <div className="gl-label gl-label--large gl-vspacing-s marginTop">Name</div>
                        <GlInput
                          className="generic-table__input"
                          value={name}
                          required
                          hint={ALLOWED_CHARACTERS_COUNT_MSG('Name', 6, 100)}
                          valid={isValid[itemId] ? isValid[itemId].name : null}
                          onChange={e => {
                            const { value } = e.target;
                            this.handleInfoChange(
                              id,
                              { name: value },
                              allowedCharactersCount(trimInputValue(value), 6, 100)
                            );
                          }}
                        />
                      </div>
                      <div>
                        <GlTextarea
                          placeholder="Description"
                          hintText="Keep it short and simple"
                          value={description}
                          onChange={e =>
                            this.handleInfoChange(id, { description: e.target.value }, true)
                          }
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>

          <p>&nbsp;</p>
          <div>
            <GlButton
              aria-label="Save"
              className="custom-modal__button custom-modal__button--primary"
              disabled={cannotSubmitEditPlaylistRequest}
              onClick={() => this.saveAllItems()}
            >
              Save
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => closeModal()}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

PlaylistEditModal.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  closeHandler: PropTypes.func
};

PlaylistEditModal.defaultProps = {
  closeHandler() {}
};

const mapStateToProps = state => ({
  selectedItems: state.modals.activeModal.items
});

const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaylistEditModal);
