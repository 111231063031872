export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOADING_FILES_DATA = 'UPLOADING_FILES_DATA';

export const uploadFiles = files => ({
  type: UPLOAD_FILES,
  payload: {
    accepted: files.accepted,
    rejected: files.rejected
  }
});

export const uploadingFilesData = fileData => ({
  type: UPLOADING_FILES_DATA,
  fileData
});
