/* eslint-disable no-case-declarations */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GlButton } from '@adl/foundation';
import { Tooltip } from 'react-tippy';
import get from 'lodash-es/get';
import formatDate from 'date-fns/format';
import isBefore from 'date-fns/is_before';

import PlaylistAll from '../../components/PlaylistAll/PlaylistAll';
import PlayersList from '../../components/PlayersList/PlayersList';
import SetMultipleDisplaySchedules from '../../components/SetMultipleDisplaySchedules/SetMultipleDisplaySchedules';
import Modal from '../../components/commons/Modal/Modal';
import ViewScreenshotsModal from '../../components/Modals/ViewScreenshotsModal';
import AddEditFramesModal from '../../components/Modals/AddEditFramesModal';
import { selectItems as selectItemsAction } from '../../actions/table';
import { closeModal as closeModalAction, openModal as openModalAction } from '../../actions/modal';
import {
  startScheduling as startSchedulingAction,
  finishScheduling as finishSchedulingAction
} from '../../actions/schedule';
import {
  updateStep,
  updateIsSaving,
  updateCompleted,
  resetScheduling
} from '../../actions/scheduleV2';
import {
  updateFiltersOnPlayers as updateFiltersOnPlayersAction,
  cancelPendingPlayersRequest as cancelPendingPlayersRequestAction,
  clearAllFiltersForPlayers as clearAllFiltersForPlayersAction,
  goToPage as goToPageAction
} from '../../actions/playersList';

import { generatePlan } from '../../helpers/utils';
import {
  UITEXT,
  datesTimes,
  ActivityLogScheduleTypes,
  ActivityLogOperationTypes
} from '../../helpers/misc';
import { pageTitle } from '../../helpers/pageTitles';

import './Dashboard.scss';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callFetchPlayerApi,
  callSchedulePlaylistsApi,
  callPlayerSchedulesApi,
  callSendLogApi
} from '../../serviceLayer/services';

class Dashboard extends Component {
  componentDidMount() {
    getAccessTokenAndMakeCalls(token => callFetchPlayerApi(token));
    document.title = pageTitle.default || 'Digital Signage - Custom Front-End';
  }

  componentWillUnmount() {
    const { cancelPendingPlayersRequest, clearAllFiltersForPlayers } = this.props;
    cancelPendingPlayersRequest();
    clearAllFiltersForPlayers();
    this.resetEverything();
  }

  getPlayersListForFrames(selectedPlayers, playersFetched) {
    const { playersWithErrors } = this.props;
    let showScheduleConfirmationModal = false;
    const playerFramesetDataFiltered = [];
    const showWarning = playersWithErrors.length > 0 && Object.keys(playersFetched).length > 0;
    if (!showWarning) {
      selectedPlayers.forEach(player => {
        const playerInfo = playersFetched[player.id];
        const channel = get(playerInfo, 'playerDisplays.0.channel', {});
        const { frameset } = channel;
        const hasAnyOtherFrame =
          frameset && frameset.frames
            ? frameset.frames.filter(frame => frame.name !== 'DATA' && frame.name !== 'Main')
            : [];
        if (hasAnyOtherFrame.length > 0) {
          playerFramesetDataFiltered.push({
            playerId: player.id,
            playerName: player.name,
            frameset
          });
          showScheduleConfirmationModal = true;
        }
      });
    }
    return {
      playerFramesetDataFiltered,
      showScheduleConfirmationModal
    };
  }

  stepHandler = direction => {
    const { updateStep, step } = this.props;

    if (step > 4 || step < 1) {
      return;
    }

    switch (direction) {
      case 'next':
        updateStep(step + 1);
        break;
      case 'prev':
        updateStep(step - 1);
        break;
      case 'playersList':
        updateStep(1);
        break;
      case 'playlistsList':
        updateStep(2);
        break;
      case 'schedulesList':
        updateStep(3);
        break;
      default:
        updateStep(1);
    }
  };

  shouldStartScheduling(scheduleItems) {
    const { playersFetched, selectedPlayers, openModal, startScheduling } = this.props;
    const { showScheduleConfirmationModal } = this.getPlayersListForFrames(
      selectedPlayers,
      playersFetched
    );
    if (showScheduleConfirmationModal) {
      openModal('showConfirmStartSchedule', {});
    } else startScheduling(scheduleItems);
  }

  schedulePlaylistsToFrames = async () => {
    const self = this;
    const { dataToSchedule, loggedInUserInfo } = this.props;
    this.props.updateIsSaving(true);
    const optimizedScheduleList = [];
    let channelIds = [];
    dataToSchedule.forEach(data => {
      const selectedPlaylists = [];
      data.playlists.forEach((playlist, index) => {
        if (playlist.neverEnds) {
          playlist.endDate = datesTimes.scalaNeverEndsDate;
          playlist.endTime = datesTimes.scalaNeverEndsTime;
        } else if (
          !isBefore(playlist.endDate, datesTimes.scalaNeverEndsDate) &&
          !playlist.takeOver
        ) {
          playlist.neverEnds = true;
        }
        if (playlist.playlistSelected) selectedPlaylists.push({ ...playlist, order: index });
      });
      if (selectedPlaylists.length > 0) {
        optimizedScheduleList.push({ ...data, playlists: selectedPlaylists });
        channelIds.push(data.channelId);
      }
    });

    channelIds = [...new Set(channelIds)];

    const frameLevelSchedules = channelIds.map(async channelId => {
      const currentSchedules = await getAccessTokenAndMakeCalls(token =>
        callPlayerSchedulesApi(token, { id: channelId })
      );
      return currentSchedules;
    });
    Promise.all(frameLevelSchedules).then(async scheduleData => {
      scheduleData = scheduleData.flat(3);
      const framesActivityLog = [];
      optimizedScheduleList.forEach(framesData => {
        const filteredSchedule = scheduleData.filter(
          schedule => schedule.frameId === framesData.frameId
        );
        const formattedPlaylists = [];
        const startDates = [];
        framesData.playlists.forEach(playlist => {
          formattedPlaylists.push({
            operationType: ActivityLogOperationTypes.create,
            playlistId: playlist.playlistId,
            playlistName: playlist.playlistName,
            scheduleType: playlist.takeOver
              ? ActivityLogScheduleTypes.takeover
              : ActivityLogScheduleTypes.normal,
            validTo: playlist.neverEnds
              ? formatDate(datesTimes.scalaNeverEndsDateTime, datesTimes.scalaDateWithTimePretty)
              : formatDate(
                  `${playlist.endDate} ${playlist.endTime}`,
                  datesTimes.scalaDateWithTimePretty
                ),
            validFrom: formatDate(
              `${playlist.startDate} ${playlist.startTime}`,
              datesTimes.scalaDateWithTimePretty
            )
          });
          startDates.push(new Date(`${playlist.startDate} ${playlist.startTime}`));
        });
        const minDate = new Date(Math.min.apply(null, startDates));
        if (framesData.deactivate) {
          filteredSchedule[0].playlists.forEach(playlist => {
            if (playlist.active) {
              formattedPlaylists.push({
                operationType: ActivityLogOperationTypes.deactivate,
                playlistId: playlist.playlistId,
                playlistName: playlist.playlistName,
                scheduleType: ActivityLogScheduleTypes.fullDeactivation,
                validTo: formatDate(
                  `${playlist.endDate} ${playlist.endTime}`,
                  datesTimes.scalaDateWithTimePretty
                ),
                validFrom: formatDate(
                  `${playlist.startDate} ${playlist.startTime}`,
                  datesTimes.scalaDateWithTimePretty
                )
              });
            }
          });
        }
        if (framesData.advanceDeactivate) {
          filteredSchedule[0].playlists.forEach(playlist => {
            if (
              playlist.active &&
              isBefore(new Date(`${playlist.startDate} ${playlist.startTime}`), new Date())
            ) {
              // check if playlist is already running
              formattedPlaylists.push({
                operationType: ActivityLogOperationTypes.deactivate,
                playlistId: playlist.playlistId,
                playlistName: playlist.playlistName,
                scheduleType: ActivityLogScheduleTypes.advanceDeactivate,
                validTo: formatDate(minDate, datesTimes.scalaDateWithTimePretty),
                validFrom: formatDate(
                  `${playlist.startDate} ${playlist.startTime}`,
                  datesTimes.scalaDateWithTimePretty
                )
              });
            }
          });
        }
        framesActivityLog.push({
          playerId: framesData.playerId,
          playerName: framesData.playerName,
          channelName: framesData.channelName,
          frameName: framesData.frameName,
          frameId: framesData.frameId,
          playlists: formattedPlaylists
        });
      });
      const activityLog = {};
      activityLog.timestamp = new Date();
      if (loggedInUserInfo) {
        activityLog.user = {
          firstName: loggedInUserInfo.firstName || loggedInUserInfo.name,
          lastName: loggedInUserInfo.lastName || loggedInUserInfo.name,
          email: loggedInUserInfo.email,
          userId: loggedInUserInfo.name
        };
      }
      activityLog.frames = [];
      const scheduledPlaylists = await getAccessTokenAndMakeCalls(token =>
        callSchedulePlaylistsApi(token, optimizedScheduleList)
      );
      Promise.resolve(scheduledPlaylists)
        .then(async data => {
          if ((data && data.response && data.response.status !== 200) || !data) {
            console.log('Some error occurred', data); // intended console
          } else {
            // log only for the frames that got scheduled
            framesActivityLog.forEach(frame => {
              if (data[frame.frameId] === 'SUCCESS') activityLog.frames.push(frame);
            });
            if (activityLog && activityLog.frames && activityLog.frames.length > 0)
              await getAccessTokenAndMakeCalls(token => callSendLogApi(token, activityLog));
          }
          self.props.updateCompleted(true, data, optimizedScheduleList);
        })
        .catch(error => {
          console.log('Some error occurred', error);
        });
    });
  };

  activeModal(modalId, scheduleItems) {
    const { closeModal, startScheduling, selectedPlayers } = this.props;
    switch (modalId) {
      case 'playerScreenshots':
        return <ViewScreenshotsModal />;
      case 'showConfirmStartSchedule': {
        const { selectedPlayers, playersFetched } = this.props;
        const { playerFramesetDataFiltered } = this.getPlayersListForFrames(
          selectedPlayers,
          playersFetched
        );
        const players = (
          <table className="custom-modal__list">
            <tbody>
              {playerFramesetDataFiltered.map(player => (
                <tr className="custom-modal__item" key={player.playerId}>
                  <td className="custom-modal__name">{player.playerName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        return (
          <Modal size="small" open enableOutsideClick modalCloseHandler={() => closeModal()}>
            <h4>{UITEXT.saveSchedules}</h4>
            <strong>{UITEXT.confirmProceed}</strong>
            <p>{UITEXT.moreFramesForPlayers}</p>
            {players}
            <div className="custom-modal__button-container">
              <GlButton
                aria-label="Create playlist"
                className="custom-modal__button custom-modal__button--primary"
                onClick={() => {
                  startScheduling(scheduleItems);
                  closeModal();
                }}
              >
                Proceed
              </GlButton>
              <GlButton
                secondary
                aria-label="Cancel"
                className="custom-modal__button"
                onClick={() => closeModal()}
              >
                Cancel
              </GlButton>
            </div>
          </Modal>
        );
      }
      case 'addEditFrames':
        return (
          <AddEditFramesModal
            selectedPlayers={selectedPlayers}
            callFetchPlayerApi={callFetchPlayerApi}
          />
        );
      default:
        return null;
    }
  }

  resetEverything() {
    const { resetScheduling, selectItems } = this.props;
    selectItems('dashboard:2', []);
    selectItems('dashboard:1', []);
    resetScheduling();
    getAccessTokenAndMakeCalls(token => callFetchPlayerApi(token));
  }

  render() {
    const {
      selectedPlaylists,
      selectItems,
      savingStatus,
      step,
      activeModalId,
      displayLibrary,
      updateFiltersOnPlayers,
      goToPage
    } = this.props;

    const { selectedPlayers } = this.props;
    const { displayKey } = displayLibrary;
    const checkStep1 = step === 1 && selectedPlayers.length > 0;
    const checkStep2 = step === 2 && selectedPlaylists.length > 0;
    const showNextButton = step < 3 && (checkStep1 || checkStep2);

    const checkPrev1 = step < 4 && step > 1;
    const checkPrev2 =
      !savingStatus.completed || (savingStatus.completed && savingStatus.errorItems.length > 0);
    const showPrevButton = checkPrev1 && checkPrev2 && !savingStatus.isSaving;

    const scheduleButtonText = this.props.isSaving ? UITEXT.saving : UITEXT.save;

    const scheduleItems =
      savingStatus.errorItems.length > 0
        ? savingStatus.errorItems
        : selectedPlayers.map(spl => spl.id);

    // const shouldDisablePlayer = savingStatus && savingStatus.isSaving;
    // const shouldDisablePlaylists =
    //   selectedPlayers.length === 0 || (savingStatus && savingStatus.isSaving);
    const shouldDisableSchedules = selectedPlaylists.length === 0;

    if (step === 1 && selectedPlayers.length === 0 && selectedPlaylists.length > 0) {
      selectItems('dashboard:2', []);
    }

    const generatePlanItems = selectedPlayers.map(spl => spl.id);
    // .filter((item) => !(savingStatus.errorItems || []).includes(item));

    const sortedSelectedPlayers = selectedPlayers.sort((a, b) => a.name.localeCompare(b.name));
    const playersBreadcrumbTitle = (
      <div style={{ width: '100%', textAlign: 'left', padding: 20 }}>
        <p>
          <strong>{`${UITEXT.selectedDisplay}${selectedPlayers.length > 1 ? 's' : ''}:`}</strong>
        </p>
        <ul style={{ listStyleType: 'circle' }}>
          {sortedSelectedPlayers.map(sp => (
            <li key={sp.name}>{sp.name}</li>
          ))}
        </ul>
      </div>
    );

    const sortedSelectedPlaylists = selectedPlaylists.sort((a, b) => a.name.localeCompare(b.name));

    const playlistsBreadcrumbTitle = (
      <div style={{ width: '100%', textAlign: 'left', padding: 20 }}>
        <p>
          <strong>{`${UITEXT.selectedPlaylist}${selectedPlaylists.length > 1 ? 's' : ''}:`}</strong>
        </p>{' '}
        <ul style={{ listStyleType: 'circle' }}>
          {sortedSelectedPlaylists.map(sp => (
            <li key={sp.name}>{sp.name}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <>
        <div className="dashboard-actions">
          <ol className="cd-breadcrumb triangle" style={{ width: '60%' }}>
            <li className={step === 1 ? 'current' : ''}>
              <button
                type="button"
                disabled={this.props.completed}
                className={
                  this.props.completed ? 'breadcrumb-button--disabled' : 'breadcrumb-button'
                }
                onClick={() => this.stepHandler('playersList')}
                data-testid="breadcrumb-step-1"
              >
                {selectedPlayers.length > 0 ? (
                  <Tooltip
                    position="bottom"
                    html={selectedPlayers.length > 0 ? playersBreadcrumbTitle : ''}
                    disabled={!selectedPlayers.length}
                  >
                    {`${UITEXT.displaySelection}${
                      selectedPlayers.length > 0 ? ` (${selectedPlayers.length})` : ''
                    }`}
                  </Tooltip>
                ) : (
                  <>
                    {`${UITEXT.displaySelection}${
                      selectedPlayers.length > 0 ? ` (${selectedPlayers.length})` : ''
                    }`}
                  </>
                )}
              </button>
            </li>
            <li className={step === 2 ? 'current' : ''}>
              <button
                disabled={this.props.completed || selectedPlayers.length === 0}
                type="button"
                className={
                  this.props.completed || selectedPlayers.length === 0
                    ? 'breadcrumb-button--disabled'
                    : 'breadcrumb-button'
                }
                onClick={() => {
                  if (this.props.completed) {
                    return;
                  }
                  this.stepHandler('playlistsList');
                }}
                data-testid="breadcrumb-step-2"
              >
                {selectedPlaylists.length > 0 ? (
                  <Tooltip
                    position="bottom"
                    html={selectedPlaylists.length > 0 ? playlistsBreadcrumbTitle : ''}
                  >
                    {`${UITEXT.playlistSelection}${
                      selectedPlaylists.length > 0 ? ` (${selectedPlaylists.length})` : ''
                    }`}
                  </Tooltip>
                ) : (
                  <>
                    {`${UITEXT.playlistSelection}${
                      selectedPlaylists.length > 0 ? ` (${selectedPlaylists.length})` : ''
                    }`}
                  </>
                )}
              </button>
            </li>
            <li className={step === 3 ? 'current' : ''}>
              <button
                disabled={shouldDisableSchedules}
                type="button"
                className={
                  shouldDisableSchedules ? 'breadcrumb-button--disabled' : 'breadcrumb-button'
                }
                onClick={() => {
                  if (shouldDisableSchedules) {
                    return;
                  }
                  this.stepHandler('schedulesList');
                }}
                data-testid="breadcrumb-step-3"
              >
                {UITEXT.scheduling}
              </button>
            </li>
          </ol>
          <div className="step-buttons">
            <ul className="step-buttons-list">
              {showPrevButton && !this.props.completed && (
                <li className="step-buttons-list__item gl-cta--hide-arrow">
                  <GlButton
                    secondary
                    aria-label={`Step ${step}`}
                    id="button__previous"
                    className="custom-modal__button"
                    onClick={() => this.stepHandler('prev')}
                    data-testid="players-scheduling-back-button"
                  >
                    {`< ${UITEXT.backToStep} ${step - 1}`}
                  </GlButton>
                </li>
              )}
              {showNextButton && (
                <li className="step-buttons-list__item">
                  <GlButton
                    aria-label={`Step ${step}`}
                    id="button__next"
                    className="custom-modal__button"
                    onClick={() => this.stepHandler('next')}
                    data-testid="players-scheduling-next-button"
                  >
                    {`${UITEXT.goToStep} ${step + 1}`}
                  </GlButton>
                </li>
              )}
              {this.props.completed && (
                <>
                  <li className="step-buttons-list__item gl-cta--hide-arrow">
                    <GlButton
                      secondary
                      aria-label={`Step ${step}`}
                      className="custom-modal__button"
                      onClick={() => {
                        this.resetEverything();
                      }}
                      data-testid="players-scheduling-reset-button"
                    >
                      {`< ${UITEXT.backToDisplays}`}
                    </GlButton>
                  </li>
                  <li className="step-buttons-list__item gl-cta--hide-arrow">
                    <GlButton
                      className="custom-modal__button"
                      id="button__refresh"
                      onClick={() => generatePlan(generatePlanItems)}
                      data-testid="players-scheduling-generate-plan-button"
                    >
                      {`${UITEXT.generatePlan}${selectedPlayers.length > 1 ? 's' : ''}`}
                    </GlButton>
                  </li>
                </>
              )}
              {step === 3 && !this.props.completed && (
                <li className="step-buttons-list__item">
                  <GlButton
                    aria-label={scheduleButtonText}
                    disabled={!(this.props.canSave && this.props.warningAcknowledged)}
                    loading={this.props.isSaving}
                    id="button__save"
                    className="custom-modal__button schedule-change-save"
                    onClick={() => this.schedulePlaylistsToFrames()}
                    data-testid="players-scheduling-save-button"
                  >
                    {scheduleButtonText}
                  </GlButton>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div>
          {step === 1 && (
            <PlayersList
              key={displayKey}
              {...displayLibrary}
              tableId="dashboard:1"
              updateFiltersOnPlayers={updateFiltersOnPlayers}
              goToPage={goToPage}
            />
          )}
          {step === 2 && (
            <PlaylistAll
              key={displayKey}
              setTitle={() => {
                document.title = pageTitle.default;
              }}
              tableId="dashboard:2"
            />
          )}
          {step === 3 && <SetMultipleDisplaySchedules />}
        </div>
        {this.activeModal(activeModalId, scheduleItems)}
      </>
    );
  }
}

Dashboard.propTypes = {
  selectedPlayers: PropTypes.arrayOf(PropTypes.object),
  selectedPlaylists: PropTypes.arrayOf(PropTypes.object),
  cantSaves: PropTypes.arrayOf(PropTypes.number),
  playersWithErrors: PropTypes.arrayOf(PropTypes.number),
  selectItems: PropTypes.func,
  updateStep: PropTypes.func,
  startScheduling: PropTypes.func,
  finishScheduling: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  selectedPlaylistsByPlayer: PropTypes.shape({}),
  step: PropTypes.number,
  submitDisabled: PropTypes.bool,
  acknowledged: PropTypes.bool,
  savingStatus: PropTypes.shape({
    isSaving: PropTypes.bool,
    completed: PropTypes.bool,
    errorItems: PropTypes.arrayOf(PropTypes.string),
    completedItems: PropTypes.arrayOf(PropTypes.string),
    allItems: PropTypes.arrayOf(PropTypes.string)
  }),
  closeModal: PropTypes.func,
  activeModalId: PropTypes.string,
  openModal: PropTypes.func,
  playersFetched: PropTypes.shape({}),

  displayLibrary: PropTypes.shape({
    isFetching: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.shape({})),
    pageInfo: PropTypes.shape({
      total: PropTypes.number,
      limit: PropTypes.number,
      current: PropTypes.number
    }),
    sort: PropTypes.shape({}),
    filtered: PropTypes.shape({}),
    isError: PropTypes.bool,
    count: PropTypes.number,
    displayKey: PropTypes.number
  }),
  fetchPlayers: PropTypes.func,
  updateFiltersOnPlayers: PropTypes.func,
  cancelPendingPlayersRequest: PropTypes.func,
  clearAllFiltersForPlayers: PropTypes.func,
  goToPage: PropTypes.func
};

const mapStateToProps = state => ({
  selectedPlayers: state.table['dashboard:1'] ? state.table['dashboard:1'].selected : [],
  selectedPlaylists: state.table['dashboard:2'] ? state.table['dashboard:2'].selected : [],
  submitDisabled: state.schedule.submitDisabled,
  savingStatus: state.schedule.savingStatus,
  selectedPlaylistsByPlayer: state.schedule.selectedPlaylistsByPlayer,
  cantSaves: state.schedule.cantSaves,
  acknowledged: state.schedule.acknowledged,
  playersWithErrors: state.schedule.playersWithErrors,
  playersFetched: state.schedule.playersFetched,
  activeModalId: state.modals.activeModal.modalId,
  displayLibrary: {
    isFetching: state.playersList.isFetching,
    list: state.playersList.list,
    pageInfo: {
      total: state.playersList.pageInfo.total,
      limit: state.playersList.pageInfo.limit,
      current: state.playersList.pageInfo.current
    },
    sort: state.playersList.sort,
    filtered: state.playersList.filtered,
    isError: state.playersList.isError,
    count: state.playersList.count,
    displayKey: state.playersList.displayKey
  },
  step: state.scheduleV2.step,
  canSave: state.scheduleV2.canSave,
  isSaving: state.scheduleV2.isSaving,
  completed: state.scheduleV2.completed,
  warningAcknowledged: state.scheduleV2.warningAcknowledged,
  dataToSchedule: state.scheduleV2.dataToSchedule,
  loggedInUserInfo: state.auth
});

const mapDispatchToProps = {
  selectItems: selectItemsAction,
  startScheduling: startSchedulingAction,
  finishScheduling: finishSchedulingAction,
  closeModal: closeModalAction,
  openModal: openModalAction,
  updateFiltersOnPlayers: updateFiltersOnPlayersAction,
  cancelPendingPlayersRequest: cancelPendingPlayersRequestAction,
  clearAllFiltersForPlayers: clearAllFiltersForPlayersAction,
  goToPage: goToPageAction,
  updateStep,
  updateIsSaving,
  updateCompleted,
  resetScheduling
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
