const LOAD_AUTH = 'LOAD_AUTH';
const USER_PRIVILEGE = 'USER_PRIVILEGE';

const loadAuth = data => ({
  type: LOAD_AUTH,
  data
});

const setUserPrivilege = data => ({
  type: USER_PRIVILEGE,
  data
});

export { LOAD_AUTH, USER_PRIVILEGE };
export { loadAuth, setUserPrivilege };
