/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton } from '@adl/foundation';
import { Container, Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import * as modalActions from '../../actions/modal';
import { UITEXT } from '../../helpers/misc';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import './Modals.scss';
import CheckboxTreeContainer from '../commons/CheckboxTreeContainer/CheckboxTreeContainer';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import {
  callGetRoleApi,
  callGetUserRoleApi,
  callUpdateUserRoleApi
} from '../../serviceLayer/services';

class UserRoleModal extends Component {
  state = {
    roles: [],
    loading: false,
    selectedRolesMap: [],
    roleMap: {},
    finished: false,
    error: false
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.fetchData();
  }

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  fetchData = () => {
    this.setState({ loading: true });
    const { selectedUsers } = this.props;
    getAccessTokenAndMakeCalls(token => callGetRoleApi(token)).then(res => {
      this.setState({ roles: this.formItems(res) });
    });
    const promises = [
      ...selectedUsers.map(usr =>
        getAccessTokenAndMakeCalls(token =>
          callGetUserRoleApi(token, { userValue: usr.value })
        ).then(res => res)
      )
    ];

    Promise.all(promises)
      .then(res => {
        const selectedRolesMap = {};
        for (let i = 0; i < res.length; i += 1) {
          const data = res[i];
          selectedRolesMap[data.userId] = [data.id];
        }
        this.setState({
          selectedRolesMap,
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          roles: [],
          selectedRolesMap: [],
          loading: false
        });
      });
  };

  formItems = items =>
    items.map(item => {
      const formatedObj = {
        label: item.name,
        value: item.id,
        id: item.id
      };

      this.setState(prev => {
        const copyRolesMap = { ...prev.roleMap };
        copyRolesMap[item.id] = formatedObj;

        return {
          ...prev,
          roleMap: copyRolesMap
        };
      });

      return formatedObj;
    });

  submitForm = () => {
    const { selectedRolesMap, roleMap } = this.state;
    this.setState({ loading: true });
    const keys = Object.keys(selectedRolesMap);
    Promise.all(
      keys.map(id => {
        const selectedRole = selectedRolesMap[id];
        return this.buildRoleRequest(id, roleMap[selectedRole[0]]);
      })
    )
      .then(() => {
        this.setState({ loading: false, finished: true });
      })
      .catch(() => {
        this.setState({ loading: false, error: true });
      });
  };

  buildRoleRequest = (userId, role) => {
    if (userId) {
      getAccessTokenAndMakeCalls(token => callUpdateUserRoleApi(token, { userId, role }));
    } else {
      return '';
    }
  };

  onCheckHanlder = (currentSelected, id) => {
    const { preventReload } = this.props;
    const { selectedRolesMap } = this.state;
    const copy = { ...selectedRolesMap };
    copy[id] = currentSelected;

    this.setState({ selectedRolesMap: copy });
    preventReload(true);
  };

  render() {
    const { closeModal, selectedUsers, isReloadPrevented, fetchUsers } = this.props;
    const { roles, selectedRolesMap, loading, error, finished } = this.state;
    const colSize = 12;

    if (loading) {
      return (
        <Modal size="small" open modalCloseHandler={() => closeModal()}>
          <Loader />
        </Modal>
      );
    }

    if (finished) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
            fetchUsers();
          }}
        >
          <h4>Success</h4>
          <p className="validation-success">Role edited successfully.</p>
        </Modal>
      );
    }

    if (error) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
            fetchUsers();
          }}
        >
          <h4>Error</h4>
          <p className="validation-error">Something went wrong.</p>
        </Modal>
      );
    }

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />

        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          <h4>Edit Role</h4>

          <Container fluid>
            <Row align="start" justify="start">
              {selectedUsers.map(usr => (
                <Col
                  className="edit-workgroup-border"
                  key={usr.value}
                  xl={colSize}
                  style={{ marginBottom: '20px' }}
                >
                  <h5>{usr.label}</h5>
                  Select the role:
                  <CheckboxTreeContainer
                    singleSelect
                    initChecked={
                      (selectedRolesMap[usr.value] &&
                        selectedRolesMap[usr.value].map(val => val.toString())) ||
                      null
                    }
                    data={roles.map(role => {
                      if (role && role.label) {
                        let labels = role.label.split('_');
                        labels = labels.length > 1 ? labels.slice(1, labels.length) : labels;
                        role.label = labels.join(' ');
                      }
                      return role;
                    })}
                    checkHandler={selectedNodes => this.onCheckHanlder(selectedNodes, usr.value)}
                    showPlayersSelected={false}
                  />
                </Col>
              ))}
            </Row>
          </Container>

          <div className="custom-modal__button-container">
            <GlButton
              className="custom-modal__button custom-modal__button--primary"
              onClick={this.submitForm}
            >
              Save
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => closeModal()}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

UserRoleModal.propTypes = {
  closeModal: PropTypes.func,
  selectedUsers: PropTypes.arrayOf(PropTypes.object),
  isReloadPrevented: PropTypes.bool,
  preventReload: PropTypes.func,
  fetchUsers: PropTypes.func
};

const mapState = state => ({
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  ...modalActions
};

export default connect(
  mapState,
  mapDispatchToProps
)(UserRoleModal);
