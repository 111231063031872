/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import get from 'lodash-es/get';
import produce from 'immer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GlButton } from '@adl/foundation';
import { isBefore } from 'date-fns';
import { Tooltip } from 'react-tippy';

import * as scheduleUtils from '../../../components/SetMultipleDisplaySchedules/scheduleUtils';
import { masterPlaylistDescription } from '../../../helpers/misc';
import {
  getPlaylistInfo,
  updatePlaylistInfo,
  getPlayerSchedule,
  getTimeslots
} from './metadataUtils';

const channelErrorTypes = {
  noMain: 'Missing Main frame',
  noData: 'Missing DATA frame',
  missingVars: 'Has missing vars',
  moreFrames: 'Has unidentified/incompatible frames',
  noDownloader: 'Data downloader is not scheduled on data frame'
};

export default class StrayAssets extends PureComponent {
  state = {
    playerStatus: {},
    validPlayers: [],
    mode: 'valid'
  };

  componentDidMount() {
    this.findIssues();
  }

  findIssues = () => {
    const { players, playersByNames } = this.props;

    this.setState(prevState =>
      produce(prevState, draft => {
        playersByNames.forEach(playerName => {
          const playerInfo = players[playerName];
          const channel = get(playerInfo, 'playerDisplays.0.channel', {});

          const { name: channelName = null, id: channelId = null } = channel;

          const { id: playerId } = playerInfo;
          draft.playerStatus[playerName] = {
            playerName,
            playerId,
            channelName,
            channelId
          };

          if (channelName === playerName) {
            draft.validPlayers.push(playerName);
          }
        });
      })
    );
  };

  fixPlaylistIssues = async playerName => {
    const { playerStatus } = this.state;
    const { playlistId } = playerStatus[playerName];
    const playlistInfo = await getPlaylistInfo(playlistId).then(({ data }) => data);

    let { playlistItems: newPlaylistItems = [] } = playlistInfo;

    newPlaylistItems = newPlaylistItems.map(item => ({
      ...item,
      deleteFlag: item.subplaylist && item.subplaylist.id === 10000044
    }));

    newPlaylistItems = [
      ...newPlaylistItems,
      {
        subplaylist: {
          id: 10000044
        },
        sortOrder: newPlaylistItems.filter(item => !item.deleteFlag).length + 1
      }
    ];

    await updatePlaylistInfo(playlistId, {
      ...playlistInfo,
      description: masterPlaylistDescription,
      playlistItems: newPlaylistItems
    }).then(() => {
      this.getMasterPlaylist(playerName);
    });
  };

  getAll = () => {
    const { validPlayers } = this.state;
    validPlayers.forEach(playerName => this.getMasterPlaylist(playerName));
  };

  getMasterPlaylist = async playerName => {
    const { playerStatus } = this.state;
    const { playerId } = playerStatus[playerName];

    try {
      const playerSchedule = await getPlayerSchedule(playerId);

      const { playlistInfo, extraTimeslots = [], channelInfo, channelId } = playerSchedule;

      const {
        playlistItems = [],
        id: playlistId,
        name: playlistName,
        description: playlistDesc
      } = playlistInfo;

      const cis = playlistItems.filter(
        item => item.subplaylist && item.subplaylist.id === 10000044
      );
      const hasMultipleCis = cis.length > 1;
      const lastItem = playlistItems[playlistItems.length - 1];
      const cisNotLast = !lastItem || !lastItem.subplaylist || lastItem.subplaylist.id !== 10000044;

      const strayItems = playlistItems.filter(pitem => !pitem.subplaylist);

      const futureTakeovers = extraTimeslots.filter(({ endDate, endTime }) => {
        if (!endDate) {
          return true;
        }
        if (isBefore(new Date(), new Date(`${endDate} ${endTime}`))) {
          return true;
        }
        return false;
      });

      const channelErrors = [];

      const missingChannelVars = scheduleUtils.getMissingVars(channelInfo.variables);

      if (missingChannelVars.length) {
        channelErrors.push('missingVars');
      }

      const frames = get(channelInfo, 'frameset.frames', []);

      const mainFrame = frames.find(({ name }) => name === 'Main');
      const dataFrame = frames.find(({ name }) => name === 'DATA');

      if (!mainFrame) {
        channelErrors.push('noMain');
      }

      if (!dataFrame) {
        channelErrors.push('noData');
        channelErrors.push('noDownloader');
      } else {
        const dataFrameTimeslots = await getTimeslots(channelId, dataFrame.id).then(
          ({ data }) => data
        );

        if (dataFrameTimeslots) {
          const downloader = get(dataFrameTimeslots, 'timeslots.0.playlist.id', null);

          if (downloader !== 10000041) {
            channelErrors.push('noDownloader');
          }
        } else {
          channelErrors.push('noDownloader');
        }
      }

      if (frames.length > 2) {
        channelErrors.push('moreFrames');
      }

      this.setState(prevState =>
        produce(prevState, draft => {
          draft.playerStatus[playerName].cisError = hasMultipleCis || cisNotLast;
          draft.playerStatus[playerName].strayItems = strayItems.length;
          draft.playerStatus[playerName].badMp = playlistName !== playerName;
          draft.playerStatus[playerName].badDesc = playlistDesc !== '===master-playlist===';
          draft.playerStatus[playerName].playlistName = playlistName;
          draft.playerStatus[playerName].playlistId = playlistId;
          draft.playerStatus[playerName].hasTakeover = futureTakeovers.length;
          draft.playerStatus[playerName].channelErrors = channelErrors;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { mode, validPlayers, playerStatus } = this.state;
    const { playersByNames } = this.props;
    const allPlayers = playersByNames;
    let playersParsed = allPlayers;

    if (mode === 'invalid') {
      playersParsed = allPlayers.filter(p => !validPlayers.includes(p));
    }

    if (mode === 'valid') {
      playersParsed = validPlayers;
    }

    if (!playersParsed.length) {
      return <div>Loading player information</div>;
    }

    return (
      <div>
        <div className="metadata-buttons">
          show{' '}
          <GlButton
            className="metadata-buttons__button"
            tertiary
            onClick={() => this.setState({ mode: 'all' })}
          >
            All
          </GlButton>
          <GlButton
            className="metadata-buttons__button"
            tertiary
            onClick={() => this.setState({ mode: 'invalid' })}
          >
            Invalid
          </GlButton>
          <GlButton
            className="metadata-buttons__button"
            tertiary
            onClick={() => this.setState({ mode: 'valid' })}
          >
            Valid
          </GlButton>
        </div>

        <div className="metadata-buttons" style={{ float: 'right' }}>
          <GlButton className="metadata-buttons__button" tertiary onClick={() => this.getAll()}>
            Get all
          </GlButton>
        </div>
        <table className="gl-table">
          <thead>
            <tr>
              <td className="gl-table__label">#</td>
              <td className="gl-table__label">Load info</td>
              <td className="gl-table__label">Fix issues</td>
              <td className="gl-table__label">Hostname</td>
              <td className="gl-table__label">Channel</td>
              <td className="gl-table__label">Cloudinary Integration Scripts</td>
              <td className="gl-table__label">Assets in MP</td>
              <td className="gl-table__label">Playlist</td>
              <td className="gl-table__label">Future takeovers</td>
              <td className="gl-table__label">Channel Issues</td>
            </tr>
          </thead>
          <tbody>
            {playersParsed.map((player, index) => {
              const playerInfo = playerStatus[player] || {};
              const {
                channelName = '',
                channelId = '',
                playerName,
                cisError,
                strayItems,
                badMp,
                badDesc,
                playlistName,
                hasTakeover,
                channelErrors
              } = playerInfo;

              let cisText;
              let cisClass = '';

              if (typeof cisError !== 'undefined') {
                if (cisError) {
                  cisText = 'Check CIS';
                  cisClass = 'validation-error';
                } else {
                  cisText = 'OK';
                  cisClass = 'validation-success';
                }
              }

              let strayItemText = '';
              let strayItemClass = '';

              if (typeof strayItems !== 'undefined') {
                if (strayItems > 0) {
                  strayItemText = `${strayItems} items`;
                  strayItemClass = 'validation-error';
                } else {
                  strayItemText = 'none';
                  strayItemClass = 'validation-success';
                }
              }

              let badMpClass = 'validation-success';

              if (typeof badMp !== 'undefined') {
                if (badMp) {
                  badMpClass = 'validation-error';
                }
              } else {
                badMpClass = '';
              }

              let possibleTakeoverText = '';
              let possibleTakeoverClass = '';

              if (typeof hasTakeover !== 'undefined') {
                if (hasTakeover > 0) {
                  possibleTakeoverText = `${hasTakeover} playlists`;
                  possibleTakeoverClass = 'validation-error';
                } else {
                  possibleTakeoverText = 'none';
                  possibleTakeoverClass = 'validation-success';
                }
              }

              let channelErrorText = '';
              let channelErrorClass = '';

              if (typeof channelErrors !== 'undefined') {
                if (channelErrors.length > 0) {
                  channelErrorText = `${channelErrors.length} issues found`;
                  channelErrorClass = 'validation-error';
                } else {
                  channelErrorText = 'none';
                  channelErrorClass = 'validation-success';
                }
              }

              const invalidPlayer = channelName !== playerName;

              return (
                <tr
                  key={playerName}
                  className={classNames({
                    'validation-error': invalidPlayer
                  })}
                >
                  <td>{index + 1}</td>
                  <td>
                    {!invalidPlayer && (
                      <GlButton tertiary onClick={() => this.getMasterPlaylist(playerName)}>
                        Load
                      </GlButton>
                    )}
                  </td>
                  <td>
                    {(badDesc || cisError) && (
                      <Tooltip
                        position="bottom"
                        html={
                          <>
                            <p>This will do one/multiple of the following:</p>
                            <div>- update master playlist to have the proper description</div>
                            <div>- Fix CIS issues</div>
                            <div>- Add missing channel settings</div>
                          </>
                        }
                      >
                        <GlButton tertiary onClick={() => this.fixPlaylistIssues(playerName)}>
                          Fix issues
                        </GlButton>
                      </Tooltip>
                    )}
                  </td>

                  <td>{playerName}</td>
                  <td id={channelId}>
                    <span>{channelName}</span>
                  </td>
                  <td className={cisClass}>{cisText}</td>
                  <td className={strayItemClass}>{strayItemText}</td>
                  <td className={badMpClass}>
                    {playlistName || ''}{' '}
                    {badDesc && <span className="validation-error">missing description</span>}
                  </td>
                  <td className={possibleTakeoverClass}>
                    <Tooltip
                      position="bottom"
                      title="The number of takeover playlists scheduled to end in the future. Takeover playlist is anything other than master playlist."
                    >
                      {possibleTakeoverText}
                    </Tooltip>
                  </td>
                  <td className={channelErrorClass}>
                    {channelErrors && channelErrors.length > 0 ? (
                      <Tooltip
                        position="bottom"
                        html={channelErrors.map(err => (
                          <div>{`-${channelErrorTypes[err]}`}</div>
                        ))}
                      >
                        {channelErrorText}
                      </Tooltip>
                    ) : (
                      channelErrorText
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

StrayAssets.propTypes = {
  players: PropTypes.shape({}),
  playersByNames: PropTypes.arrayOf(PropTypes.string)
};
