export const GOTO_STEP = 'GOTO_STEP';
export const PUT_PLAYER_DATA = 'PUT_PLAYER_DATA';
export const PUT_PLAYLIST_DATA = 'PUT_PLAYLIST_DATA';
export const SELECT_SCHEDULE_ITEMS = 'SELECT_SCHEDULE_ITEMS';
export const SELECT_TAKEOVER_ITEM = 'SELECT_TAKEOVER_ITEM';
export const CHANGE_PLAYLIST_VALIDITY = 'CHANGE_PLAYLIST_VALIDITY';
export const PUT_PRE_TASK_INFO = 'PUT_PRE_TASK_INFO';
export const SET_SAVING_STATUS = 'SET_SAVING_STATUS';
export const SET_PLAYER_STATUS = 'SET_PLAYER_STATUS';
export const PUT_SCHEDULE_FROM_PLAYLIST = 'PUT_SCHEDULE_FROM_PLAYLIST';
export const SET_SCHEDULE_DATES = 'SET_SCHEDULE_DATES';
export const START_SCHEDULING = 'START_SCHEDULING';
export const COMPLETE_SCHEDULING = 'COMPLETE_SCHEDULING';
export const FINISH_SCHEDULING = 'FINISH_SCHEDULING';
export const DISABLE_CURRENT_PLAYLISTS = 'DISABLE_CURRENT_PLAYLISTS';
export const DISABLE_SUBMIT_BUTTON = 'DISABLE_SUBMIT_BUTTON';
export const SHOW_CANT_DISABLE_ERROR = 'SHOW_CANT_DISABLE_ERROR';
export const ACTIVATE_DISABLED_PLAYLIST = 'ACTIVATE_DISABLED_PLAYLIST';
export const DEACTIVATE_ADVANCED = 'DEACTIVATE_ADVANCED';
export const CANT_SAVE = 'CANT_SAVE';
export const SET_ACKNOWLEDGE = 'SET_ACKNOWLEDGE';
export const ADD_PLAYER_WITH_ERROR = 'ADD_PLAYER_WITH_ERROR';
export const PLAYER_APPLY_ALL = 'PLAYER_APPLY_ALL';
export const SET_PLAYER_DATA = 'SET_PLAYER_DATA';
export const CHECK_ERRORS_WITH_PLAYER = 'CHECK_ERRORS_WITH_PLAYER';
export const SELECT_PLAYLIST_PER_FRAME = 'SELECT_PLAYLIST_PER_FRAME';

export const goToStep = (step) => ({
  type: GOTO_STEP,
  step
});

export const setCantSave = (playerId, add) => ({
  type: CANT_SAVE,
  playerId,
  add
});

export const setAcknowledge = (acknowledged) => ({
  type: SET_ACKNOWLEDGE,
  acknowledged
});

export const addPlayerWithError = (playerId) => ({
  type: ADD_PLAYER_WITH_ERROR,
  playerId
});

export const disableSubmitButton = (disabled) => ({
  type: DISABLE_SUBMIT_BUTTON,
  disabled
});

export const showCantDisableError = (error) => ({
  type: SHOW_CANT_DISABLE_ERROR,
  error
});

export const selectScheduleItems = (playerId, selectedItems, playlistItemId) => ({
  type: SELECT_SCHEDULE_ITEMS,
  playerId,
  selectedItems,
  playlistItemId
});

export const selectTakeoverItem = (playerId, selectedItem) => ({
  type: SELECT_TAKEOVER_ITEM,
  playerId,
  selectedItem
});

export const changeDate = (playerId, playlistId, dateData) => ({
  type: CHANGE_PLAYLIST_VALIDITY,
  playerId,
  playlistId,
  dateData
});

export const putPlayerData = (playersFetched, playersFetchedByIds) => ({
  type: PUT_PLAYER_DATA,
  playersFetched,
  playersFetchedByIds
});

export const putPreTaskInfo = (playerId, info) => ({
  type: PUT_PRE_TASK_INFO,
  playerId,
  info
});

export const disableCurrentPlaylists = (playerId, isChecked) => ({
  type: DISABLE_CURRENT_PLAYLISTS,
  playerId,
  isChecked
});

export const deactivateAdvanced = (playerId, isChecked) => ({
  type: DEACTIVATE_ADVANCED,
  playerId,
  isChecked
});

export const putPlaylistData = (playlistsFetched, playlistsFetchedByIds) => ({
  type: PUT_PLAYLIST_DATA,
  playlistsFetched,
  playlistsFetchedByIds
});

export const setPlayerStatus = (playerId, status) => ({
  type: SET_PLAYER_STATUS,
  playerId,
  status
});

export const putScheduleFromPlaylist = (playerId, playlistSchedules) => ({
  type: PUT_SCHEDULE_FROM_PLAYLIST,
  playerId,
  playlistSchedules
});

export const activateDisabledPlaylist = (playerId, playlistItemId) => ({
  type: ACTIVATE_DISABLED_PLAYLIST,
  playerId,
  playlistItemId
});

export const setScheduleDates = (playerId, playlistItemId, dateData) => ({
  type: SET_SCHEDULE_DATES,
  playerId,
  playlistItemId,
  dateData
});

export const startScheduling = (allItems) => ({
  type: START_SCHEDULING,
  allItems
});

export const completeScheduling = () => ({
  type: COMPLETE_SCHEDULING
});

export const finishScheduling = (reset, options = {}) => ({
  type: FINISH_SCHEDULING,
  reset,
  options
});

export const playerApplyAll = (playerId) => ({
  type: PLAYER_APPLY_ALL,
  playerId
});

export const setPlayerData = (playerId, playerName) => ({
  type: SET_PLAYER_DATA,
  playerId,
  playerName
});

export const checkErrorsWithPlayer = (playerId, selectedItems, playlistItemId) => ({
  type: CHECK_ERRORS_WITH_PLAYER,
  playerId,
  selectedItems,
  playlistItemId
});

export const selectPlaylistPerFrame = (playerId, playlistPerFrame) => ({
  type: SELECT_PLAYLIST_PER_FRAME,
  playerId,
  playlistPerFrame
});
