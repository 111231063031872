import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../commons/Modal/Modal';
import * as modalActions from '../../actions/modal';
import {
  selectItems as selectItemsAction,
  clickedPlayer as clickingPlayerAction
} from '../../actions/table';
import FrameConfiguration from '../FrameConfiguration/FrameConfiguration';
import './Modals.scss';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';

class AddEditFramesModal extends Component {
  state = {
    showCancelConfirmation: false
  };

  toggleCancelConfirmationModal() {
    const { showCancelConfirmation } = this.state;
    this.setState({ showCancelConfirmation: !showCancelConfirmation });
  }

  render() {
    const {
      closeModal,
      selectedPlayers,
      clickedPlayer,
      showOnlyClickedPlayer,
      clickedPlayerAction,
      getSchedules,
      playerId,
      callFetchPlayerApi
    } = this.props;
    const players = clickedPlayer.length > 0 ? clickedPlayer : selectedPlayers;
    const { showCancelConfirmation } = this.state;
    return (
      <div className="frame-edit-modal">
        <Modal
          size="large"
          className="frame-edit-modal"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            this.setState({ showCancelConfirmation: true });
          }}
        >
          <FrameConfiguration
            selectedPlayers={showOnlyClickedPlayer ? clickedPlayer : players}
            showCancelConfirmation={showCancelConfirmation}
            closeModal={() => {
              closeModal();
              getAccessTokenAndMakeCalls(token => callFetchPlayerApi(token));
              !showOnlyClickedPlayer && clickedPlayerAction('dashboard:1');
              getSchedules && getSchedules(playerId);
            }}
            toggleCancelConfirmationModal={() => this.toggleCancelConfirmationModal()}
          />
        </Modal>
      </div>
    );
  }
}

AddEditFramesModal.prototypes = {
  closeModal: PropTypes.func,
  selectedPlayers: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  selectedPlayers: state.table['dashboard:1'] ? state.table['dashboard:1'].selected : [],
  clickedPlayer: state.table['dashboard:1'] ? state.table['dashboard:1'].clicked : []
});

const mapDispatchToProps = {
  ...modalActions,
  selectItems: selectItemsAction,
  clickedPlayerAction: clickingPlayerAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditFramesModal);
