export const SELECT_ITEMS = 'SELECT_ITEMS';
export const CLICKED_PLAYER = 'CLICKED_PLAYER';
export const UPDATE_GENERATE_PLAN_TEXT = 'UPDATE_GENERATE_PLAN_TEXT';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const UPDATE_TABLE_SETTINGS = 'UPDATE_TABLE_SETTINGS';
export const TOGGLE_MEDIA_TAGS = 'TOGGLE_MEDIA_TAGS';

export const selectItems = (tableId, selectedItems) => ({
  type: SELECT_ITEMS,
  tableId,
  selectedItems
});

export const clickedPlayer = (tableId, clickedPlayer) => ({
  type: CLICKED_PLAYER,
  tableId,
  clickedPlayer
});

export const updateGeneratePlanText = (generatePlanText) => ({
  type: UPDATE_GENERATE_PLAN_TEXT,
  generatePlanText
});

export const updateTableSettings = (tableId, settings) => ({
  type: UPDATE_TABLE_SETTINGS,
  tableId,
  settings
});

export const loadSettings = (settings) => ({
  type: LOAD_SETTINGS,
  settings
});

export const toggleMediaTags = (mediaId, shown) => ({
  type: TOGGLE_MEDIA_TAGS,
  mediaId,
  shown
});
