import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import './CustomSelect.scss';

const MultiValueContainer = props => {
  const { data } = props;
  return (
    <Tooltip position="bottom" followCursor title={data.label}>
      <components.MultiValueContainer {...props} />
    </Tooltip>
  );
};

const CustomSelect = ({ selectedOption, onChange, options, ...props }) => (
  <Select
    components={{ MultiValueContainer }}
    value={selectedOption || null}
    onChange={onChange}
    options={options}
    {...props}
  />
);

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  )
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.memoKey === nextProps.memoKey;
};

export default React.memo(CustomSelect, areEqual);
