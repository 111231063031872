/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCheckboxNode from './CustomCheckboxNode';
import { intersectionArrays } from '../../../../helpers/utils';

// eslint-disable-next-line react/prefer-stateless-function
class CustomCheckboxTree extends Component {
  state = {
    checked: {},
    expanded: {},
    halfChecked: {},
    checkCounter: {},
    selectedPlayersCount: 0,
    disabled: false
  };

  componentDidMount() {
    const {
      initExpanded,
      nodes,
      selectedUsersByIds,
      isLCM,
      requestArea,
      storePreviousIds,
      resourceId,
      flattenedNodes
    } = this.props;
    let { initChecked } = this.props;
    const newState = {
      selectedPlayersCount: 0
    };
    // In case user is admin,
    // then disabled the user selection
    if (nodes && selectedUsersByIds && selectedUsersByIds.isAdmin) {
      newState.disabled = true;
      // Dirty check but we got no other option for now
      // backend sends empty initChecked for admin user
      // and for display purposes we need to show checked
      // Solution is that backend API send by defauly all location to frontend
      // Temp solution as below
      if (initChecked === null) {
        initChecked = nodes.map(el => el.id).reduce((prev, curr) => [...prev, curr], []);
      }
    }

    if (initChecked) {
      newState.checked = initChecked.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
      initChecked.forEach(id => {
        const childrens = this.getChidren(id, nodes);
        const childrenStatus = childrens.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
        newState.checked = { ...newState.checked, ...childrenStatus };
      });
    }
    // from checked get parents
    if (newState && newState.checked) {
      const allChecked = Object.keys(newState.checked).filter(key => newState.checked[key]);
      if (isLCM && requestArea === 'editMedia' && storePreviousIds) {
        const commonIds = intersectionArrays(flattenedNodes, initChecked);
        storePreviousIds(resourceId, commonIds);
      }
      Object.keys(newState.checked).forEach(id => {
        const allParents = this.getAllParentsFromTop(id, nodes);
        const getNextLevelChilds = this.getOneLevelChild(id, nodes);
        const currentNode = this.findElement(nodes, id);
        // 4. now add this to halfchecked
        // 5. Update the counters as well
        allParents.forEach(elID => {
          newState.halfChecked = {
            ...newState.halfChecked,
            [elID]: true
          };
          // track counter
          const parentsImmediateChildsList = this.getOneLevelChild(elID, nodes);
          const intersection = intersectionArrays(parentsImmediateChildsList, allChecked);
          newState.checkCounter = {
            ...newState.checkCounter,
            [elID]: intersection.length || 0
          };
        });
        // calculate for current node's child
        if (getNextLevelChilds && getNextLevelChilds.length > 0) {
          const inter = intersectionArrays(getNextLevelChilds, allChecked);
          newState.checkCounter = {
            ...newState.checkCounter,
            [id]: inter.length || 0
          };
        }
        // calculate total players selected
        if (currentNode && currentNode.type && currentNode.type.toLowerCase() === 'player') {
          newState.selectedPlayersCount += 1;
        }
      });
    }
    if (initExpanded && selectedUsersByIds && !selectedUsersByIds.isAdmin) {
      newState.expanded = initExpanded.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
    }
    this.setState(newState);
  }

  onCheckHanlder = id => {
    const { selectedUsersByIds } = this.props;
    if (selectedUsersByIds && selectedUsersByIds.isAdmin) {
      return;
    }
    const { singleSelect, onCheck, nodes } = this.props;
    const { checked, halfChecked, checkCounter } = this.state;
    let checkedCopy;
    let halfCheckedCopy;
    let checkCounterCopy;
    if (singleSelect) {
      checkedCopy = { [id]: !checked[id] };
    } else {
      checkedCopy = { ...checked };
      halfCheckedCopy = { ...halfChecked };
      checkCounterCopy = { ...checkCounter };
      checkedCopy[id] = !checked[id];
      const childrens = this.getChidren(id, nodes);
      const childrenStatus = childrens.reduce(
        (prev, curr) => ({ ...prev, [curr]: checkedCopy[id] }),
        {}
      );
      checkedCopy = { ...checkedCopy, ...childrenStatus };
      const parentsToUnselect = this.findTopSelectedParentNode(id, nodes, checkedCopy);

      if (!checkedCopy[id] && parentsToUnselect && parentsToUnselect.length > 0) {
        parentsToUnselect.forEach(elID => {
          checkedCopy[elID] = false;
        });
      }

      // Half checked functionality
      // 1. get the current one
      const current = this.findElement(nodes, id);
      // 2. determine if checked or not
      if (checkedCopy[id]) {
        // checked
        const allChecked = Object.keys(checkedCopy).filter(key => checkedCopy[key]);
        // 3. now get parents of node to half check
        const allParents = this.getAllParentsFromTop(id, nodes);

        // 3.1 track checks start
        const immediateParent = current.parentId;
        if (immediateParent) {
          const pChild = this.getOneLevelChild(immediateParent, nodes);
          const inter = intersectionArrays(pChild, allChecked);
          checkCounterCopy[immediateParent] = inter.length || 1;
        }
        // get all childs
        const allChilds = this.getChidren(current.id, nodes);
        const nextLevelChilds = this.getOneLevelChild(current.id, nodes);
        if (allChilds && allChilds.length > 0) {
          allChilds.forEach(elID => {
            const currentChildCount = this.getOneLevelChild(elID, nodes);
            checkCounterCopy[elID] = currentChildCount.length || 1;
          });
          checkCounterCopy[current.id] = nextLevelChilds.length || 1;
        } else {
          checkCounterCopy[current.id] = 0;
        }
        // 3.1 track checks ends

        // 4. now add this to halfchecked
        allParents.forEach(elID => {
          halfCheckedCopy[elID] = true;
        });
      } else {
        // not checked

        const checkedOnes = Object.keys(checkedCopy).filter(key => checkedCopy[key]);
        // check for self
        const childOfCurrent = this.getChidren(current.id, nodes);
        const getImmIntersection = intersectionArrays(childOfCurrent, checkedOnes);
        halfCheckedCopy[current.id] = getImmIntersection.length > 0;
        if (childOfCurrent && childOfCurrent.length > 0) {
          childOfCurrent.forEach(elID => {
            const childOfEL = this.getChidren(elID, nodes);
            const getELIntersection = intersectionArrays(childOfEL, checkedOnes);
            halfCheckedCopy[elID] = getELIntersection.length > 0;
          });
        }
        // 3.1 track checks
        const immediateParentUNC = current.parentId;
        if (immediateParentUNC) {
          checkCounterCopy[immediateParentUNC] = checkCounterCopy[immediateParentUNC] - 1 || 0;
        }
        // get all childs
        const allChildsUNC = this.getChidren(current.id, nodes);
        if (allChildsUNC && allChildsUNC.length > 0) {
          allChildsUNC.forEach(elID => {
            checkCounterCopy[elID] = 0;
          });
          checkCounterCopy[current.id] = 0;
        } else {
          checkCounterCopy[current.id] = 0;
        }
        // check for parents now
        const allParentsUnC = this.getAllParentsFromTop(id, nodes);
        allParentsUnC.forEach(elID => {
          const childOfID = this.getChidren(elID, nodes);
          const immediateLevelChilds = this.getOneLevelChild(elID, nodes);
          const getMatchedSelection = intersectionArrays(childOfID, checkedOnes);
          const immediateSelection = intersectionArrays(immediateLevelChilds, checkedOnes);
          halfCheckedCopy[elID] = getMatchedSelection.length > 0;
          checkCounterCopy[elID] = immediateSelection.length;
        });
      }

      this.setState({ halfChecked: halfCheckedCopy, checkCounter: checkCounterCopy });
    }

    const selectedArr = Object.keys(checkedCopy).filter(key => checkedCopy[key]);
    this.setState({ checked: checkedCopy }, () => {
      // 5. Count all selected players
      // 5.1 filter all checked true from checkedcopy
      let count = 0;
      if (selectedArr && selectedArr.length > 0) {
        selectedArr.forEach(chkID => {
          const elemNode = this.findElement(nodes, chkID);
          if (elemNode && elemNode.type && elemNode.type.toLowerCase() === 'player') {
            count += 1;
            this.setState(() => ({
              selectedPlayersCount: count
            }));
          }
        });
      } else {
        this.setState({
          selectedPlayersCount: count
        });
      }
    });
    if (onCheck) {
      if (singleSelect) {
        onCheck(selectedArr);
      } else {
        onCheck(selectedArr.length > 0 ? this.extractTopSelected(nodes, checkedCopy) : []);
      }
    }
  };

  getAllParentsFromTop = (id, tree, result = []) => {
    if (!id) {
      return result;
    }
    const current = this.findElement(tree, id);
    if (current && current.parentId) {
      result.push(current.parentId);
      // now see if current parent also has a parent
      const parent = this.findElement(tree, current.parentId);
      return this.getAllParentsFromTop(parent.id, tree, result);
    }
    return result;
  };

  parentsToSelect = (parentId, selectedMap, tree, result = []) => {
    if (!parentId) {
      return result;
    }
    const parent = this.findElement(tree, parentId);
    let shouldSelect = true;
    const selectedMapCopy = { ...selectedMap };

    for (const el of parent.children) {
      if (!selectedMapCopy[el.id]) {
        shouldSelect = false;
        return result;
      }
    }

    result.push(parentId);
    if (parentId && shouldSelect) {
      selectedMapCopy[parentId] = true;
      return this.parentsToSelect(parent.parentId, selectedMapCopy, tree, result);
    }

    return result;
  };

  extractTopSelected = (nodes, checkedCopy, result = []) => {
    let resultCopy = [...result];
    nodes.forEach(el => {
      if (checkedCopy[el.value]) {
        resultCopy.push(el.value);
      } else if (el.children != null) {
        resultCopy = resultCopy.concat(this.extractTopSelected(el.children, checkedCopy));
      }
    });

    return resultCopy;
  };

  findTopSelectedParentNode = (id, nodes, checkedMap, result = []) => {
    const node = this.findElement(nodes, id);
    const { parentId } = node;
    if (parentId && checkedMap[parentId]) {
      const parentEl = this.findElement(nodes, parentId);
      result.push(parentEl.value);
      if (parentEl.parentId != null && checkedMap[parentEl.parentId]) {
        return result.concat(this.findTopSelectedParentNode(parentId, nodes, checkedMap));
      }
    }

    return result;
  };

  findParentsToUnselect = (id, nodes, checkedState, result = []) => {
    const el = this.findElement(nodes, id);
    if (this.shouldUnselectParent(el, checkedState)) {
      result.push(el.parentId);
      return this.findParentsToUnselect(el.parentId, nodes, checkedState, result);
    }
    return result;
  };

  shouldUnselectParent = (el, checkedState) => {
    const { parentId } = el;
    if (parentId != null && checkedState[parentId]) {
      return true;
    }

    return false;
  };

  findElement = (node, elId) => {
    let item;
    item = node.find(el => el.id === +elId);
    if (item == null) {
      for (const el of node) {
        if (el.children) {
          item = this.findElement(el.children, elId);
          if (item != null) {
            break;
          }
        }
      }
    }

    return item;
  };

  getChidren = (id, nodes, result = [], fullNode = false) => {
    const node = this.findElement(nodes, id);
    if (node && node.children) {
      node.children.forEach(childNode => {
        result.push(fullNode ? childNode : childNode.value.toString());
        result.concat(this.getChidren(childNode.value, nodes, result));
      });
    }

    return result.filter(el => el != null);
  };

  getOneLevelChild = (id, nodes, result = [], fullNode = false) => {
    const node = this.findElement(nodes, id);
    if (node && node.children) {
      node.children.forEach(childNode => {
        result.push(fullNode ? childNode : childNode.value.toString());
      });
    }
    return result.filter(el => el != null);
  };

  onExpandHanlder = id => {
    const { expanded } = this.state;
    const expandCopy = { ...expanded };
    expandCopy[id] = !expanded[id];

    this.setState({ expanded: expandCopy });
  };

  getExpanded = expanded => {
    return Object.keys(expanded).filter(key => expanded[key]);
  };

  render() {
    const {
      nodes,
      icons,
      singleSelect,
      showPlayersSelected,
      onExpandHanlder: onExpandHanlderFromProps,
      expanded: expandedFromProps,
      id
    } = this.props;

    const {
      checked: checkedState,
      halfChecked,
      checkCounter,
      selectedPlayersCount,
      disabled,
      expanded: expandedFromState
    } = this.state;

    const checkedFromState = Object.keys(checkedState).filter(key => checkedState[key]);

    const expanded = expandedFromProps
      ? this.getExpanded(expandedFromProps)
      : this.getExpanded(expandedFromState);

    const onExpandHanlder = expandedFromProps ? onExpandHanlderFromProps : this.onExpandHanlder;

    const halfCheckedFromState =
      typeof halfChecked !== 'undefined'
        ? Object.keys(halfChecked).filter(key => halfChecked[key])
        : null;

    return (
      <div className="CustomCheckboxTree">
        {showPlayersSelected && (
          <p className="checkboxTreeContainer__selection-text">
            <strong>Players Selected:</strong> {selectedPlayersCount}
          </p>
        )}
        <div className={`${disabled ? 'selection-now-allowed' : ''}`}>
          {nodes.map(node => (
            <CustomCheckboxNode
              id={id}
              expanded={expanded}
              onCheck={this.onCheckHanlder}
              onExpand={onExpandHanlder}
              singleSelect={singleSelect}
              checked={checkedFromState}
              halfChecked={halfCheckedFromState}
              checkCounter={checkCounter}
              key={node.value}
              node={node}
              icons={icons}
            />
          ))}
        </div>
      </div>
    );
  }
}

CustomCheckboxTree.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  icons: PropTypes.object,
  initChecked: PropTypes.arrayOf(PropTypes.string),
  initExpanded: PropTypes.arrayOf(PropTypes.string),
  singleSelect: PropTypes.bool,
  onCheck: PropTypes.func,
  showPlayersSelected: PropTypes.bool,
  selectedUsersByIds: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.number,
    isAdmin: PropTypes.bool,
    label: PropTypes.string,
    parentId: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.number
  }),
  isLCM: PropTypes.bool,
  requestArea: PropTypes.string,
  resourceId: PropTypes.number,
  storePreviousIds: PropTypes.func,
  flattenedNodes: PropTypes.arrayOf(String),
  onExpandHanlder: PropTypes.func,
  expanded: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number
};

export default CustomCheckboxTree;
