import React, { Component } from 'react';
import { GlToggle, GlCheckbox, GlTooltip, GlButton, GlIcon } from '@adl/foundation';
import get from 'lodash-es/get';
import { withRouter, Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import DatePicker from 'react-datepicker';
import formatDate from 'date-fns/format';
import { addMonths } from 'date-fns';
import subDays from 'date-fns/sub_days';
import isBefore from 'date-fns/is_before';

import { ReactComponent as DragIcon } from '../../assets/icons/drag-handle.svg';

import { datesTimes, UITEXT } from '../../helpers/misc';
import { shortenStringWithElipsis } from '../../helpers/utils';

import Table from '../commons/Table/Table';
import { tableSchema } from './schema';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tippy/dist/tippy.css';
import './SetMultipleDisplayPlaylists.scss';

class SetMultipleDisplayPlaylists extends Component {
  state = {
    toggleValue: '0'
  };

  componentDidMount() {
    const { player, framesData, updateSelectedFrames } = this.props;
    const channelId = get(player, 'playerDisplays.0.channel.id', null);
    const frameNames = framesData[channelId].map(frame => frame.name);
    const frameIndex = frameNames.findIndex(frameName => frameName.toLowerCase() === 'main');
    const toggleValue = frameIndex < 0 ? '0' : frameIndex.toString();
    this.setState({ toggleValue });
    updateSelectedFrames(channelId, framesData[channelId][toggleValue].frameId);
  }

  changeToggle(event) {
    const { player, framesData, updateSelectedFrames } = this.props;
    const channelId = get(player, 'playerDisplays.0.channel.id', null);
    const toggleValue = (event && event.target && event.target.value) || '0';
    this.setState({
      toggleValue
    });
    updateSelectedFrames(channelId, framesData[channelId][toggleValue].frameId);
  }

  formatTableData(playerId, frameId, playlists, disableFields) {
    const tableData = [];
    const { updatePlaylistData } = this.props;
    const maxAllowedValidFromDate = new Date(subDays(new Date(), 7).setHours(0, 0, 0, 0));
    playlists.forEach(playlist => {
      let validFrom = new Date();
      if (playlist && playlist.startDate && playlist.startTime) {
        validFrom = new Date(
          formatDate(`${playlist.startDate} ${playlist.startTime}`, datesTimes.newDateFormat)
        );
        if (isBefore(validFrom, maxAllowedValidFromDate)) {
          validFrom = maxAllowedValidFromDate;
        }
      } else {
        validFrom = new Date().setHours(0, 0);
      }
      let validTo =
        playlist && playlist.endDate && playlist.endTime
          ? new Date(
              formatDate(`${playlist.endDate} ${playlist.endTime}`, datesTimes.newDateFormat)
            )
          : addMonths(new Date().setHours(23, 59, 59, 999), 3);
      if (validTo && validFrom && isBefore(validTo, validFrom)) {
        validTo = new Date(new Date(validFrom).setHours(23, 59, 59, 999));
      }
      const startLabel = `${formatDate(validFrom, datesTimes.scalaDateWithTimePretty)}`;
      const endLabel = `${formatDate(validTo, datesTimes.scalaDateWithTimePretty)}`;
      const data = { id: playlist.playlistId };
      data.action = (
        <span style={{ display: 'flex' }}>
          <span style={{ marginTop: '10px', marginRight: '-10px' }}>
            <DragIcon />
          </span>
          <GlCheckbox
            isChecked={playlist && playlist.playlistSelected}
            inputProps={{ disabled: disableFields }}
            onChange={() =>
              updatePlaylistData(playerId, frameId, playlist.playlistId, 'playlistSelected')
            }
          />
        </span>
      );
      data.playlistName = (
        <Tooltip
          position="bottom"
          followCursor
          html={<div className="tooltipWidth">{UITEXT.playlistNameTooltip}</div>}
        >
          <Link to={`/playlists/${playlist.playlistId}?newTab=true`} target="_blank">
            <div className="core-green__main">
              {shortenStringWithElipsis(playlist.playlistName, 40)}
            </div>
          </Link>
        </Tooltip>
      );
      if (disableFields) data.startDate = startLabel;
      else {
        data.startDate = (
          <div className="schedule-playlist-info__column gl-tooltip">
            <GlTooltip label={startLabel} size="large">
              <DatePicker
                todayButton="Today"
                selected={validFrom}
                inline
                showTimeSelect
                timeIntervals={15}
                injectTimes={[new Date().setHours(23, 59, 59, 999)]}
                timeFormat="HH:mm"
                timeCaption="Start time"
                onChange={date =>
                  updatePlaylistData(
                    playerId,
                    frameId,
                    playlist.playlistId,
                    'startDateAndTime',
                    date
                  )
                }
              />
            </GlTooltip>
          </div>
        );
      }
      if (disableFields) data.endDate = playlist && !playlist.neverEnds && endLabel;
      else {
        data.endDate = (
          <div className="schedule-playlist-info__column gl-tooltip">
            <GlTooltip
              className={playlist && playlist.neverEnds && 'date-hidden'}
              label={endLabel}
              size="large"
            >
              <DatePicker
                todayButton="Today"
                selected={validTo}
                inline
                showTimeSelect
                timeIntervals={15}
                injectTimes={[new Date().setHours(23, 59, 59, 999)]}
                timeFormat="HH:mm"
                timeCaption="End time"
                onChange={date =>
                  updatePlaylistData(playerId, frameId, playlist.playlistId, 'endDateAndTime', date)
                }
              />
            </GlTooltip>
          </div>
        );
      }

      data.playForever = (
        <div className="playlist-info__column--center checkbox-styling-no-center">
          <GlCheckbox
            isChecked={playlist && playlist.neverEnds}
            inputProps={{ disabled: disableFields }}
            onChange={() => updatePlaylistData(playerId, frameId, playlist.playlistId, 'neverEnds')}
          />
          {UITEXT.neverEnds}
        </div>
      );
      data.takeOver = (
        <div className="playlist-info__column--center checkbox-styling-no-center">
          <GlCheckbox
            isChecked={playlist && playlist.takeOver}
            inputProps={{ disabled: disableFields }}
            onChange={() => updatePlaylistData(playerId, frameId, playlist.playlistId, 'takeOver')}
          />
          {UITEXT.takeOver}
        </div>
      );
      tableData.push(data);
    });
    return tableData;
  }

  renderPlaylistsTable(playerId, frameId, frameData, disableFields) {
    const { updatePlaylistData, selectedPlaylists, multiplePlayers } = this.props;
    const { playlists } = frameData;
    const rowOrderHandler = ({ oldIndex, newIndex }) => {
      if (newIndex >= 0 && newIndex < playlists.length && oldIndex !== newIndex) {
        const copiedList = [...playlists];
        const element = copiedList.splice(oldIndex, 1)[0];
        copiedList.splice(newIndex, 0, element);
        const updatedPlaylists = copiedList.map((el, index) => {
          if (selectedPlaylists[index].id !== el.playlistId) el.playlistSelected = true;
          return { ...el };
        });
        updatePlaylistData(playerId, frameId, null, 'rowsRearranged', updatedPlaylists);
      }
    };
    const tableData = this.formatTableData(playerId, frameId, playlists, disableFields);
    const applyButton = multiplePlayers ? (
      <div style={{ width: '140px' }}>
        <Tooltip
          className="tooltip-apply-button"
          title={UITEXT.applyScheduleInfo}
          position="bottom"
        >
          <GlButton
            tertiary
            className="apply-button"
            disabled={disableFields}
            onClick={() => this.props.confirmCopySchedule(frameData)}
          >
            {UITEXT.applyScheduleToAllButton}
          </GlButton>
          <GlIcon name="alert-info" />
        </Tooltip>
      </div>
    ) : (
      ''
    );
    return (
      <Table
        tableId="scheduleDetails"
        tableData={tableData}
        tableSchema={tableSchema(applyButton)}
        isFilterable
        className="scheduling-table-step3 playlist-info"
        hasRowOrdering={playlists.length > 1}
        rowOrderHandler={rowOrderHandler}
      />
    );
  }

  render() {
    const { toggleValue } = this.state;
    const {
      player,
      framesData,
      dataToSchedule,
      updatePlaylistData,
      isSaving,
      completed,
      playerDimensions,
      successChannelwise,
      errorChannelwise,
      history: { push }
    } = this.props;
    const {
      market = '',
      country = '',
      city = '',
      environment = '',
      storename = ''
    } = player.metadata;
    let locationMetadata = [];
    let noMetadata = false;
    locationMetadata.push(environment, market, country, city, storename);
    locationMetadata = locationMetadata.filter(item => item);
    if (!market && !country && !city && !environment && !storename)
      noMetadata = 'No metadata present for this player.';
    const playerId = player.id;
    const playerName = player.name;
    const playerDescription = player.description;
    const channelId = get(player, 'playerDisplays.0.channel.id', null);
    const frameNames = framesData[channelId].map(frame => frame.name);
    const frameId = framesData[channelId][toggleValue].frameId;
    const frameData = dataToSchedule.filter(
      data => data.frameId === frameId && data.playerId === playerId
    )[0];
    let success = false;
    if (
      successChannelwise.success &&
      successChannelwise.success.length > 0 &&
      errorChannelwise.error &&
      errorChannelwise.error.length === 0
    )
      success = true;
    const status = (
      <div className="text-center">
        {success ? (
          <div className="validation-success">{UITEXT.created}</div>
        ) : (
          <div className="validation-error">{UITEXT.failed}</div>
        )}
        <GlButton
          tertiary
          disabled={isSaving}
          onClick={() => {
            push(`/schedules/${playerId}/${playerName}`);
          }}
        >
          {UITEXT.viewSchedule}
        </GlButton>
      </div>
    );
    const disableFields = isSaving || completed;
    return (
      <>
        <div className="generic-container">
          <div className="player-container" key={playerId}>
            <div style={{ display: 'flex' }}>
              <div>
                <Tooltip title={noMetadata || locationMetadata.join(' / ')} position="bottom">
                  <Link to={`/schedules/${playerId}/${playerName}`} target="_blank">
                    <h5 className="player-name-styling">
                      {`${playerName.replace(/_/g, ' ')} (${playerDimensions.width} x ${
                        playerDimensions.height
                      })`}
                    </h5>
                  </Link>
                </Tooltip>{' '}
                {playerDescription && <div>{playerDescription}</div>}
              </div>
              <div style={{ marginLeft: 'auto', width: '20%' }}>{completed && status}</div>
            </div>
          </div>
          <div className="frame-names-toggle" style={{ display: 'flex' }}>
            <div className="select-frame">{UITEXT.selectFrame}</div>
            <GlToggle
              items={frameNames}
              onChange={e => this.changeToggle(e)}
              value={toggleValue || '0'}
            />

            <div className="deactivate-section" style={{ marginLeft: 'auto', marginTop: 'auto' }}>
              <GlCheckbox
                className="margin-auto-l-r"
                label={UITEXT.disableCurrent}
                isChecked={(frameData && frameData.deactivate) || false}
                inputProps={{ disabled: disableFields }}
                onChange={() => updatePlaylistData(playerId, frameId, '', 'deactivate')}
              />
              <Tooltip
                className="margin-auto-l-r"
                title={UITEXT.doSomethingTooltip}
                position="bottom"
              >
                <GlCheckbox
                  label={UITEXT.doSomething}
                  isChecked={(frameData && frameData.advanceDeactivate) || false}
                  inputProps={{ disabled: disableFields }}
                  onChange={() => updatePlaylistData(playerId, frameId, '', 'advanceDeactivate')}
                />
              </Tooltip>
            </div>
          </div>
          {this.renderPlaylistsTable(playerId, frameId, frameData || {}, disableFields)}
        </div>
      </>
    );
  }
}
export default withRouter(SetMultipleDisplayPlaylists);
