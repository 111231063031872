/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { GlButton, GlInput, GlTextarea } from '@adl/foundation';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import produce from 'immer';

import { trimInputValue } from '../../helpers/utils';
import { UITEXT } from '../../helpers/misc';
import Modal from '../commons/Modal/Modal';
import Loader from '../commons/Loader/Loader';
import * as modalActions from '../../actions/modal';
import './Modals.scss';
import { ALLOWED_CHARACTERS_COUNT_MSG, allowedCharactersCount } from '../../helpers/validators';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callCreatePlaylistApi } from '../../serviceLayer/services';

class CreatePlaylistFromMediaModal extends Component {
  state = {
    pid: null,
    inProgress: false,
    playlistCreated: false,
    playlistData: {
      name: '',
      tags: []
    },
    isValid: {
      name: null
    },
    allTagsByIds: {},
    nameError: false,
    error: false
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
  }

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  handleInfoChange = (data, _isValid = false) => {
    const { preventReload } = this.props;
    preventReload(true);

    const key = Object.keys(data)[0];
    this.setState(next =>
      produce(next, draft => {
        draft.isValid = Object.assign({}, draft.isValid, { [key]: _isValid });
        draft.playlistData = Object.assign({}, draft.playlistData, data);
      })
    );
  };

  createNewPlaylist = () => {
    const { playlistData } = this.state;
    const { name, description } = playlistData;
    const params = {
      name,
      description
      // enableSmartPlaylist: false,
      // healthy: true,
      // playlistType: 'MEDIA_PLAYLIST',
      // categories: this.formatTagsToCategories(tags)
    };

    this.setState({ inProgress: true });
    getAccessTokenAndMakeCalls(token => callCreatePlaylistApi(token, params))
      .then(res => {
        if (!res) {
          this.setState({
            inProgress: false,
            playlistCreated: false,
            error: true
          });
        } else {
          const pid = get(res, 'id', null);
          this.setState({
            inProgress: false,
            playlistCreated: true,
            error: false,
            pid
          });
        }
      })
      .catch(err => {
        const errorCode = get(err, 'response.data.cmsResponse.code');
        this.setState({
          inProgress: false,
          playlistCreated: false,
          error: true,
          nameError: errorCode === 'NameAlreadyExists'
        });
      });
  };

  formatTagsToCategories = tagIds => {
    const { allTagsByIds } = this.state;
    return tagIds.map(id => ({
      id,
      name: allTagsByIds[id].name
    }));
  };

  render() {
    const {
      closeModal,
      history: { push },
      isReloadPrevented
    } = this.props;

    const {
      inProgress,
      pid,
      playlistCreated,
      error,
      nameError,
      isValid,
      playlistData: { name, description }
    } = this.state;

    if (inProgress) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    if (playlistCreated) {
      return (
        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
            push(`/playlists/${pid}`);
          }}
        >
          <h4>Success</h4>
          <p className="validation-success">
            Your playlist <strong>{name}</strong> has been created.
          </p>
        </Modal>
      );
    }

    if (error && !nameError) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
          }}
        >
          <h4>Create new playlist</h4>
          <p className="validation-error">Something went wrong.</p>
        </Modal>
      );
    }

    const showNameError = error && nameError;

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />

        <Modal
          size="large"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          <h4>Create new playlist</h4>

          <GlInput
            className="generic-table__input"
            placeholder="name"
            required
            value={name}
            hint={ALLOWED_CHARACTERS_COUNT_MSG('Name', 6, 100)}
            valid={isValid.name}
            onChange={e => {
              const { value } = e.target;
              this.handleInfoChange(
                {
                  name: value
                },
                allowedCharactersCount(trimInputValue(value), 6, 100)
              );
            }}
          />
          {showNameError && (
            <p className="validation-error">Name is already in use. Please choose another one.</p>
          )}
          <GlTextarea
            placeholder="Description"
            hintText="Keep it short and simple"
            value={description}
            onChange={e => this.handleInfoChange({ description: e.target.value })}
          />

          <div className="custom-modal__button-container">
            <GlButton
              aria-label="Create playlist"
              className="custom-modal__button custom-modal__button--primary"
              disabled={!isValid.name}
              onClick={() => this.createNewPlaylist()}
            >
              Create
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => closeModal()}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

CreatePlaylistFromMediaModal.propTypes = {
  closeModal: PropTypes.func,
  preventReload: PropTypes.func,
  history: PropTypes.shape({}),
  isReloadPrevented: PropTypes.bool
};

const mapStateToProps = state => ({
  isReloadPrevented: state.modals.isDirty
});

const mapDispatchToProps = {
  ...modalActions
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreatePlaylistFromMediaModal)
);
