/* eslint-disable no-console */
import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../config/config.json';
import constants from '../helpers/constants';

const cookies = new Cookies();

export const apiEndpoints = {
  getUserPreferences: 'getUserPreferences',
  updateUserPreferences: 'updateUserPreferences',
  getLoggedInUserInfo: 'getLoggedInUserInfo',
  fetchPlayers: 'fetchPlayers',
  getFrameDetails: 'getFrameDetails',
  updateFramesData: 'updateFramesData',
  getPlaylists: 'getPlaylists',
  getTimeslotsData: 'getTimeslotsData',
  schedulePlaylists: 'schedulePlaylists',
  getPlayerSchedules: 'getPlayerSchedules',
  updatePlayerSchedules: 'updatePlayerSchedules',
  sendLog: 'sendLog',
  getMedias: 'getMedias',
  getTaxonomyTags: 'getTaxonomyTags',
  getLocation: 'getLocation',
  updateLocation: 'updateLocation',
  deleteLocation: 'deleteLocation',
  getMedia: 'getMedia',
  getMediaSearch: 'getMediaSearch',
  getPlaylistsSearch: 'getPlaylistsSearch',
  getPlayersSearch: 'getPlayersSearch',
  updateMediaLocation: 'updateMediaLocation',
  updateMediaInfo: 'updateMediaInfo',
  updateMessageInfo: 'updateMessageInfo',
  uploadMedia: 'uploadMedia',
  getAutoComplete: 'getAutoComplete',
  getTemplates: 'getTemplates',
  getTemplateFields: 'getTemplateFields',
  createMessage: 'createMessage',
  getFilteredPlaylists: 'getFilteredPlaylists',
  getPlaylist: 'getPlaylist',
  deletePlaylist: 'deletePlaylist',
  createPlaylist: 'createPlaylist',
  updatePlaylist: 'updatePlaylist',
  removePlaylist: 'removePlaylist',
  updatePlaylistMedia: 'updatePlaylistMedia',
  deleteFromAMS: 'deleteFromAMS',
  genericCMSRest: 'genericCMSRest',
  getChannels: 'getChannels',
  getChannel: 'getChannel',
  addSubPlaylists: 'addSubPlaylists',
  duplicatePlaylist: 'duplicatePlaylist',
  getPlayer: 'getPlayer',
  getPlayerStatus: 'getPlayerStatus',
  getPlayerPlans: 'getPlayerPlans',
  fetchSchedulingActivityLogs: 'fetchSchedulingActivityLogs',
  fetchSchedulingActivityLogsFilters: 'fetchSchedulingActivityLogsFilters',
  fetchFrameManagementActivityLogs: 'fetchFrameManagementActivityLogs',
  fetchFrameManagementActivityLogsFilters: 'fetchFrameManagementActivityLogsFilters',
  userCreation: 'userCreation',
  userDeletion: 'userDeletion',
  userInfo: 'userInfo',
  userBuildRequest: 'userBuildRequest',
  fetchUsers: 'fetchUsers',
  getPlayerHealth: 'getPlayerHealth',
  getRoles: 'getRoles',
  getUserRole: 'getUserRole',
  updateUserRole: 'updateUserRole',
  generatePlan: 'generatePlan',
  getUuid: 'getUuid',
  downloadMedia: 'downloadMedia',
  urlWrapper: 'urlWrapper',
  updatePlayerMetadata: 'updatePlayerMetadata'
};

export const getAccessTokenAndMakeCalls = async apiCalls => {
  try {
    const token =
      process.env.REACT_APP_BYPASS_AUTH === 'true' ? 'token' : cookies.get('access_token');
    if (!token) {
      acquireAccessToken();
    } else {
      return apiCalls(token)
        .then(res => {
          return res;
        })
        .catch(e => {
          throw e;
        });
    }
  } catch (e) {
    return e;
  }
  return null;
};

export const acquireAccessToken = () => {
  // retrieve code from url if present
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  if (code) {
    fetchToken(code);
  } else if (!cookies.get('access_token')) {
    // check if access_token in cookie storage
    // redirect to login page
    window.location.href = `${config.oauth2.hostname}/${config.oauth2.tenant}${config.oauth2.authorize_path}?client_id=${constants.CLIENT_ID}&response_type=${config.oauth2.response_type}&response_mode=${config.oauth2.response_mode}&redirect_uri=${constants.REDIRECT_URL}&scope=${constants.CLIENT_ID}${config.oauth2.scope}`;
  }
};

const fetchToken = code => {
  const url = constants.GET_TOKEN;
  const body = {
    code
  };
  const options = {
    method: 'POST',
    data: body,
    url
  };

  if (process.env.REACT_APP_BYPASS_AUTH === 'true') return;

  axios(options)
    .then(response => {
      cookies.set('access_token', response.data.access_token);

      // delete the code from url
      window.location.href = window.location.origin;
    })
    .catch(error => {
      window.location.href = window.location.origin;
    });
};

export const clearCookieIfExpired = error => {
  if (error && error.response && error.response.status === 401) cookies.remove('access_token');
};

export const logoutUser = () => {
  window.location.replace(
    `${config.oauth2.hostname}/${config.oauth2.tenant}${config.oauth2.logout_path}`
  );
};
