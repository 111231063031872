import * as actionTypes from '../actions/scheduleV2';

const initialState = {
  step: 1,
  canSave: false,
  isSaving: false,
  completed: false,
  warningAcknowledged: false,
  dataToSchedule: [],
  successfullyScheduled: [],
  errorWhileScheduling: [],
  optimizedScheduleList: []
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STEP:
      return Object.assign({}, state, {
        step: action.value
      });
    case actionTypes.UPDATE_CAN_SAVE:
      return Object.assign({}, state, {
        canSave: action.value
      });
    case actionTypes.UPDATE_IS_SAVING:
      return Object.assign({}, state, {
        isSaving: action.value,
        completed: false
      });
    case actionTypes.UPDATE_COMPLETED:
      let result = action.result;
      let successfullyScheduled = [];
      let errorWhileScheduling = [];
      // maintain players successful
      result && Object.keys(result).forEach((frameId) => {
        if (result[frameId] === 'SUCCESS') successfullyScheduled.push(frameId);
        else if (result[frameId] === 'FAILED') errorWhileScheduling.push(frameId);
      });
      return Object.assign({}, state, {
        completed: action.value,
        isSaving: false,
        successfullyScheduled,
        errorWhileScheduling,
        optimizedScheduleList: action.optimizedScheduleList
      });
    case actionTypes.UPDATE_WARNING_ACKNOWLEDGED:
      return Object.assign({}, state, {
        warningAcknowledged: action.value
      });
    case actionTypes.UPDATE_DATA_TO_SCHEDULE:
      const selectedPlayerIds = action.selectedPlayerIds;
      const dataToSchedule = action.dataToSchedule;
      let count = 0;
      selectedPlayerIds.forEach((playerId) => {
        let framesAssignedToThisPlayer = dataToSchedule.filter(
          (data) => data.playerId === playerId
        );
        let checkIfPLaylistIsAssignedFlag = false;
        framesAssignedToThisPlayer.forEach((frame) => {
          frame.playlists.forEach((playlist) => {
            if (playlist.playlistSelected) checkIfPLaylistIsAssignedFlag = true;
          });
        });
        if (checkIfPLaylistIsAssignedFlag) count++;
      });
      const canSave = count === selectedPlayerIds.length;
      //   const warningAcknowledged = state.warningAcknowledged;
      return Object.assign({}, state, {
        dataToSchedule,
        canSave
      });
    case actionTypes.RESET_SCHEDULING:
      return initialState;
    default:
      return state;
  }
};

export default scheduleReducer;
