import React from 'react';
import { GlButton, GlLink } from '@adl/foundation';
import { Tooltip } from 'react-tippy';

import {
  shortenStringWithElipsis,
  transformCloudinaryImage,
  getExpirationKey,
  formatDuration,
  calculateNumberOfDays,
  prettifyDuration,
  parseAssetName
} from '../../helpers/utils';

import { cloudinaryThumbnailTransforms } from '../../helpers/misc';
import constants from '../../helpers/constants';
import Thumbnail from '../Thumbnail/Thumbnail';

import store from '../../stores';
import { openModal } from '../../actions/modal';
import { toggleMediaTags } from '../../actions/table';

export const tableSchema = [
  {
    id: 'thumbnail',
    title: 'Thumbnail'
  },
  {
    id: 'name',
    title: 'Asset Name',
    placeholder: 'Search for media assets',
    filter: true,
    sortable: true,
    autocomplete: true
  },
  {
    id: 'duration',
    title: 'Duration'
  },
  {
    id: 'fileType',
    title: 'File Type',
    filter: true,
    sortable: true
  },
  {
    id: 'dimension',
    title: 'File Dimension',
    filter: true,
    sortable: true
  },
  {
    id: 'size',
    title: 'File Size',
    filter: true,
    sortable: true
  },
  {
    id: 'fileFormat',
    title: 'File Format',
    filter: true,
    sortable: true
  },
  {
    id: 'startValidDate',
    title: 'Valid From',
    filter: true,
    sortable: true
  },
  {
    id: 'endValidDate',
    title: 'Valid Until',
    filter: true,
    sortable: true
  },
  {
    id: 'createdDate',
    title: 'Upload Date',
    // filter: true,
    sortable: true
    // dateFilter: true
  },
  {
    id: 'lastModified',
    title: 'Last Modified',
    // filter: true,
    sortable: true
    // dateFilter: true
  },
  {
    id: 'tags',
    title: 'Tags'
  },
  {
    id: 'activeOn',
    title: 'Active On'
  },
  {
    id: 'lastModifiedBy',
    title: 'Last Modified By',
    filter: true,
    sortable: true
  },
  {
    id: 'createdBy',
    title: 'Created By',
    filter: true,
    sortable: true
  }
];

export const extractFromMetadata = (metadata, column) => {
  const extractedObj = metadata.filter(meta => meta.metadata.name === column);
  if (extractedObj && extractedObj.length > 0 && extractedObj[0].value) {
    return extractedObj[0].value;
  }
  return '';
};

export const formatMediaList = (mediaItems = []) => {
  const tableDataSelected = [];
  mediaItems.forEach((item, rowIndex) => {
    const isMessage = item.mediaType === 'MESSAGE';
    const expiryStatus = getExpirationKey(item.endValidDate);

    tableDataSelected[rowIndex] = {
      expiryStatus,
      id: item.id
    };

    const cloudinaryData = {
      secure_url: item.secureUrl,
      resource_type: item.fileType,
      format: item.fileFormat
    };
    let thumbnailUrl = item.extraSmall && `${constants.SCALA_API_URL}${item.extraSmall}`;
    if (isMessage) {
      thumbnailUrl = transformCloudinaryImage(
        cloudinaryData,
        cloudinaryThumbnailTransforms.medialist
      );
      if (!thumbnailUrl && item.extraSmall) {
        thumbnailUrl = `${constants.THUMBNAIL_URL}${item.extraSmall}`;
      }
    }
    tableDataSelected[rowIndex].fileType = (
      <span style={{ textTransform: 'capitalize' }}>{item.fileType ? item.fileType : ''}</span>
    );

    tableDataSelected[rowIndex].thumbnail = (
      <Thumbnail
        item={item}
        thumbnailUrl={thumbnailUrl}
        type="media"
        cloudinaryData={cloudinaryData}
      />
    );

    const maxChar = 40;
    const shortenedName = shortenStringWithElipsis(parseAssetName(item.name), maxChar);
    // name
    tableDataSelected[rowIndex].name = (
      <div>
        {item.name.length >= 0 ? (
          <Tooltip
            position="bottom"
            followCursor
            html={<div className="tooltipWidth">{parseAssetName(item.name)}</div>}
          >
            <button
              type="button"
              className="linkButton"
              onClick={() => {
                store.dispatch(openModal('editMedia', { items: [item], type: 'media' }));
              }}
            >
              {shortenedName}
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            className="linkButton"
            onClick={() => {
              store.dispatch(openModal('editMedia', { items: [item], type: 'media' }));
            }}
          >
            {shortenedName}
          </button>
        )}
      </div>
    );

    // duration
    tableDataSelected[rowIndex].duration = (
      <span>
        {item.fileType === 'image' || item.fileType === 'video'
          ? prettifyDuration(item.duration)
          : formatDuration(item.duration)}
      </span>
    );

    // dimension
    tableDataSelected[rowIndex].dimension = <span>{item.dimension ? item.dimension : ''}</span>;

    // size
    tableDataSelected[rowIndex].size = <span>{item.size ? item.size : ''}</span>;

    // format
    tableDataSelected[rowIndex].fileFormat = isMessage
      ? item.fileFormat
      : item.name.split('.').pop();
    tableDataSelected[rowIndex].startValidDate = item.startValidDate;

    tableDataSelected[rowIndex].endValidDate =
      (
        <div>
          {expiryStatus !== 'expired' && expiryStatus !== 'expires-soon' && (
            <div>{item.endValidDate}</div>
          )}
          {expiryStatus === 'expired' && (
            <div className="expiration-badge expiration-badge--expired">
              expired on {item.endValidDate}
            </div>
          )}
          {expiryStatus === 'expires-soon' && (
            <div className="expiration-badge expiration-badge--expires-soon">
              expires soon in {calculateNumberOfDays(item.endValidDate)}{' '}
              {calculateNumberOfDays(item.endValidDate) > 1 ? 'days' : 'day'} on {item.endValidDate}
            </div>
          )}
        </div>
      ) || 'never';

    // tags
    const { categories } = item;
    if (categories && categories.length > 0) {
      const tags = categories.map((category, i) => (
        <li
          key={category.id}
          style={{ display: (item.showTags && i > 4) || i < 5 ? 'block' : 'none' }}
        >
          {category.name}
        </li>
      ));
      tableDataSelected[rowIndex].tags = (
        <>
          <div className={item.showTags ? 'generic-table__column--showtags' : ''} />
          <ul className="media-tags">{tags}</ul>
          {categories.length > 5 && (
            <GlLink
              aria-label="Show all tags"
              className="show-all-tags-button"
              onClick={() => {
                store.dispatch(toggleMediaTags(item.id));
              }}
            >
              Show {item.showTags ? 'less' : 'all'}
            </GlLink>
          )}
        </>
      );
    } else {
      tableDataSelected[rowIndex].tags = '';
    }

    // created Date
    const createdDate =
      item.createdDate
        .split('.')[0]
        .split('T')
        .join(' ') || item.createdDate;
    tableDataSelected[rowIndex].createdDate = createdDate;

    // last modified
    const lastModified =
      item.lastModified
        .split('.')[0]
        .split('T')
        .join(' ') || ' ';
    tableDataSelected[rowIndex].lastModified = lastModified;

    // active on
    tableDataSelected[rowIndex].activeOn = (
      <GlButton
        tertiary
        aria-label="Show Usage"
        onClick={() => {
          store.dispatch(openModal('showUsage', { find: 'media', id: item.id }));
        }}
      >
        Show Usage
      </GlButton>
    );

    // last modified By & Created By
    tableDataSelected[rowIndex].lastModifiedBy = <div>{item.lastModifiedBy || ''}</div>;
    tableDataSelected[rowIndex].createdBy = <div>{item.createdBy || ''}</div>;

    tableDataSelected[rowIndex].selectInfo = {
      id: item.id,
      originalName: item.name,
      name: parseAssetName(item.name),
      thumb: thumbnailUrl,
      cloudinary: cloudinaryData
    };
  });

  return tableDataSelected;
};
