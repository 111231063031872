export const tableSchema = [
  {
    id: 'action',
    title: 'Action'
  },
  {
    id: 'playlistName',
    title: 'Playlist Name'
  },
  {
    id: 'startDate',
    title: 'Start Date'
  },
  {
    id: 'endDate',
    title: 'End Date'
  },
  {
    id: 'playForever',
    title: 'Play Forever?'
  },
  {
    id: 'takeOver',
    title: 'Takeover'
  }
];
