/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { GlButton } from '@adl/foundation';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import produce from 'immer';

import Modal from '../commons/Modal/Modal';
import Table from '../commons/Table/Table';
import Loader from '../commons/Loader/Loader';
import * as modalActions from '../../actions/modal';
import { UITEXT } from '../../helpers/misc';
import PlaylistAll from '../PlaylistAll/PlaylistAll';

import './Modals.scss';
import { pageTitle } from '../../helpers/pageTitles';

import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callUpdatePlaylistMediaApi } from '../../serviceLayer/services';

class MediaAddToPlaylistModal extends Component {
  state = {
    isAdding: false,
    addingCompleted: false,
    successItems: []
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
  }

  componentWillUnmount() {
    const { preventReload } = this.props;

    this.removePreventEvent();
    preventReload(false);
  }

  removePreventEvent = () => {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  };

  handlePageRefresh = e => {
    const { isReloadPrevented } = this.props;

    if (isReloadPrevented) {
      e.preventDefault();
      e.returnValue = UITEXT.scheduleAway;
    }
  };

  addToSinglePlaylist = (pid, items) =>
    getAccessTokenAndMakeCalls(token =>
      callUpdatePlaylistMediaApi(token, {
        id: pid,
        items
      })
    )
      .then(() => {
        this.setState(next =>
          produce(next, draft => {
            draft.successItems.push(+pid);
          })
        );
      })
      .catch(() => {
        this.setState({
          isAdding: false,
          addingCompleted: true
        });
      });

  addItems = () => {
    const { selectedMediaItems, selectedPlaylistItems } = this.props;
    this.setState({ isAdding: true });

    Promise.all(
      selectedPlaylistItems
        .map(item => item.id)
        .map(pid => this.addToSinglePlaylist(pid, selectedMediaItems.map(item => item.id)))
    ).then(() => {
      this.setState({ isAdding: false, addingCompleted: true });
    });
  };

  formatTableItems = (items = [], itemsAdded) => {
    const result = [];
    items.forEach((item, rowIndex) => {
      result[rowIndex] = {
        id: item.id,
        name: (
          <div className="ellipsis" title={item.name}>
            {item.name}
          </div>
        ),
        status:
          itemsAdded.indexOf(item.id) > -1 ? (
            <span className="validation-success">Added</span>
          ) : (
            <span className="validation-error">Error</span>
          )
      };
    });

    return result;
  };

  render() {
    const { isAdding, addingCompleted, successItems } = this.state;
    const {
      selectedMediaItems,
      closeModal,
      selectedPlaylistItems,
      refreshHandler,
      isReloadPrevented
    } = this.props;

    const tableSchema = [
      {
        id: 'name',
        title: 'Playlist'
      },
      {
        id: 'status',
        title: 'Status'
      }
    ];

    if (isAdding) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    if (addingCompleted) {
      return (
        <Modal
          size="large"
          open
          modalCloseHandler={() => {
            refreshHandler();
            closeModal();
          }}
          enableOutsideClick
        >
          <h4>
            Summary of adding media to {selectedPlaylistItems.length > 1 ? 'playlists' : 'playlist'}
          </h4>
          <p>Selected media assets:</p>
          <ul className="selected-items">
            {selectedMediaItems.map(item => (
              <li key={item.id}>
                <strong className="breakWord">{item.name}</strong>
              </li>
            ))}
          </ul>
          <p>&nbsp;</p>
          <p>Please check the status below:</p>
          <Table
            tableData={this.formatTableItems(selectedPlaylistItems, successItems)}
            isFilterable
            tableSchema={tableSchema}
            tableId="results"
          />
        </Modal>
      );
    }

    return (
      <>
        <Prompt when={isReloadPrevented} message={UITEXT.scheduleAway} />
        <Modal
          className="custom-modal--fullscreen"
          open
          size="large"
          enableOutsideClick
          modalCloseHandler={() => {
            if (isReloadPrevented && !confirm(UITEXT.scheduleAway)) {
              return;
            }
            closeModal();
          }}
        >
          <div className="modal-header">
            <h4>Add Media to playlist</h4>
            {selectedPlaylistItems.length > 0 && (
              <span className="secondary-info">
                Selected {selectedPlaylistItems.length} {` `}
                {selectedPlaylistItems.length > 1 ? 'playlists' : 'playlist'}
              </span>
            )}
          </div>
          <ul
            className={`selected-items multi-column-list${
              selectedMediaItems.length > 4 ? ' multi-column-list-height' : ''
            }`}
          >
            {selectedMediaItems.map(item => (
              <li key={item.id}>
                <Tooltip
                  position="bottom"
                  followCursor
                  html={<div className="tooltipWidth">{item.name}</div>}
                >
                  <div className="truncate-items-list">{item.name}</div>
                </Tooltip>
              </li>
            ))}
          </ul>
          <div className="scrollableContent">
            <PlaylistAll
              setTitle={() => {
                document.title = pageTitle.media;
              }}
              tableId="selectPlaylistsToAddMedia"
              className={selectedMediaItems.length > 4 ? ' selectPlaylistsToAddMedia-height' : ''}
            />
          </div>
          <div
            className={`buttonsR fixed-buttons-media${
              selectedMediaItems.length > 4 ? ' fixed-buttons-media-height' : ''
            }`}
          >
            <GlButton
              aria-label="Save"
              className="custom-modal__button custom-modal__button--primary"
              onClick={() => this.addItems()}
            >
              Save
            </GlButton>
            <GlButton
              secondary
              aria-label="Cancel"
              className="custom-modal__button"
              onClick={() => closeModal()}
            >
              Cancel
            </GlButton>
          </div>
        </Modal>
      </>
    );
  }
}

MediaAddToPlaylistModal.propTypes = {
  selectedMediaItems: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  refreshHandler: PropTypes.func,
  preventReload: PropTypes.func,
  isReloadPrevented: PropTypes.bool,
  selectedPlaylistItems: PropTypes.arrayOf(PropTypes.object)
};

MediaAddToPlaylistModal.defaultProps = {
  refreshHandler() {}
};

const mapStateToProps = state => ({
  selectedMediaItems: state.modals.activeModal.items,
  selectedPlaylistItems: state.table.selectPlaylistsToAddMedia.selected,
  isReloadPrevented: state.table.selectPlaylistsToAddMedia.selected.length > 0
});

const mapDispatchToProps = {
  ...modalActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaAddToPlaylistModal);
