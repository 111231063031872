import React from 'react';
import { GlIcon } from '@adl/foundation';

import constants from './constants';

export const cloudinaryDownloadFileName = 'scala-adidas-download.zip';
export const validImageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
export const supportedForTransformations = [
  ...validImageFormats,
  'ai',
  'ps',
  'ept',
  'eps',
  'eps3',
  'flif',
  'hdp',
  'ico',
  'jpe',
  'jpc',
  'jp2',
  'j2k',
  'wdp',
  'jxr',
  'hdp',
  'pdf',
  'psd',
  'svg',
  'tga',
  'tif',
  'tiff',
  'wdp',
  'webp'
];

export const acceptableUploadFormats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/tiff',
  'image/tif',
  'image/bmp',
  'video/x-ms-wmv',
  'video/mp4',
  'video/mpg',
  'video/mpeg',
  'video/quicktime',
  'video/webm',
  'video/x-flv',
  'video/mkv',
  'audio/mp3',
  'audio/wav',
  'audio/x-ms-wma',
  'audio/ogg',
  '.flv'
];

export const cloudinaryThumbnailTransforms = {
  medialist: 'w_120,h_80,c_fill',
  deletemodal: 'w_30,h_30,c_fill',
  previewmodal: 'h_700'
};

export const daysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
];

export const datesTimes = {
  scalaNeverEndsDate: '2030-01-01',
  scalaNeverEndsTime: '23:59',
  scalaNeverEndsDateTime: '2030-01-01 23:59',
  scalaDateFormat: 'YYYY-MM-DD',
  scalaDateFormatPretty: 'YYYY.MM.DD',
  scalaTimeFormat: 'HH:mm',
  scalaDateWithTimePretty: 'YYYY.MM.DD - HH:mm',
  activityLogNeverEndsDate: '2050.01.01 - 00:00',
  dateTimeHoursMinutesSeconds: 'YYYY.MM.DD - HH:mm:ss'
};

export const ActivityLogScheduleTypes = {
  fullDeactivation: 'Deactivation (Full Deactivation)',
  advanceDeactivate: 'Advanced Deactivation',
  takeover: 'Takeover',
  normal: 'Normal'
};
export const ActivityLogOperationTypes = {
  activate: 'Activate',
  deactivate: 'Deactivate',
  edit: 'Edit', // from player details view just update
  create: 'Create'
};

export const maxFileSize = 2.9e9;

export const requestStages = {
  REQUEST_ACKNOWLEDGED: 'REQUEST_ACKNOWLEDGED',
  REQUEST_IN_PROCESS: 'REQUEST_IN_PROCESS',
  REQUEST_FAILED: 'REQUEST_FAILED',
  REQUEST_COMPLETE: 'REQUEST_COMPLETE',
  SSE_TIMEOUT: 'SSE_TIMEOUT',
  HEARTBEAT: 'heartbeat'
};

export const UITEXT = {
  invalidFileName:
    'Invalid file name. File name can contain only alphanumeric characters, spaces, dashes (-), periods (.), underscores (_) and paranthesis. File name cannot end with space.',
  fileNameIsTooLong: 'File name is too long ! Max allowed length : 255 characters',
  filesizeShouldNotExceed: 'Media file is too large to upload. Maximum upload size is 2.9GB.',
  fileTypeNotAllow: 'File type is not allowed',
  pendingUploadWarning: 'Are you sure you want to navigate away? This will cancel active uploads.',
  scheduleAway: 'Are you sure you want to navigate away? You will lose unsaved changes.',
  navigateAway: 'Are you sure you want to navigate away? You will lose unsaved changes.',
  cantEditMoreThan10Assets: 'Cannot edit more than 10 assets at once',
  cantEditMoreThan10Playlists: 'Cannot edit more than 10 playlists at once',
  cantDownloadMoreThan10Assets: 'Cannot download more than 10 assets at once',
  cannotSelectMoreThan10Tags: 'Cannot select more than 10 tags',
  cantDownloadItems: 'These assets cannot be downloaded: ',
  disableCurrent: 'Deactivate all existing playlists',
  doSomething: (
    <span>
      Advanced deactivation for existing playlists{' '}
      <GlIcon name="alert-info" className="gl-icon--size-communication info-icon " />
    </span>
  ),
  playlistNotFound: 'Playlist could not be found',
  playlistLoadError: 'Could not load playlist',
  confirmUploadNew: 'Are you sure you want to drop files? This will reset current upload progress.',
  doSomethingTooltip: `This will update the end date of existing playlists (excluding future dated playlists) to the earliest start date of the newly scheduled playlist(s).`,
  saveSchedules: 'Save Schedules',
  moreFramesForPlayers: 'There are more than 2 frames for the display(s).',
  confirmProceed: 'Are you sure you want to proceed?',
  playerScreenshots: 'Screenshots for Display',
  somethingWentWrong: 'Something went wrong. Please try again later.',
  requestFailed: 'Request Failed',
  requestCompletedSuccessfully: 'Request Completed Successfully',
  requestAcknowledged: 'Waiting for connection...',
  requestInProcess: 'Processing your screenshot...',
  downloadImage: 'Download Image',
  getSceenshot: 'Get Latest Screenshot',
  errorConnectionToServer: 'Could not connect to server. Please try again later.',
  cantSaveSchedule: (
    <>
      <h5>UNABLE TO SAVE</h5>
      <p>
        To save this schedule, you must have at least one additional schedule in the next week.
        Please create one and try again.
      </p>
    </>
  ),
  cantDisableCurrent: (
    <>
      <h4>CANNOT DISABLE PLAYLIST</h4>
      <p>
        To disable this playlist, please schedule another one to play within the next week and
        retry.
      </p>
    </>
  ),
  cannotAdvancedDeactivate: (
    <>
      <h4>CANNOT DEACTIVATE ADVANCED</h4>
      <p>Please schedule another one to play within the next week and retry.</p>
    </>
  ),
  scheduleCaution: (
    <>
      The displays listed above does not have regular settings.
      <br />
      If you decide to continue, the old settings will be overwritten.
    </>
  ),
  scheduleCautionSingle: (
    <>
      The display listed above does not have regular settings.
      <br />
      If you decide to continue, the old settings will be overwritten.
    </>
  ),
  columnOptions: (
    <>
      <h4>Column options</h4>
      <p style={{ marginTop: '-2%' }}>
        You may uncheck boxes to hide columns and/or drag lines to change its order.
      </p>
    </>
  ),
  scheduleCautionCheckboxLabel: 'I fully understand the implications and want to continue.',
  cannotActivateLink: 'Cannot be activated [i]',
  cannotActivateTooltip:
    'Cannot activate this playlist. It might be empty or its contents may have expired',
  cantDisableAll: 'Error! You must have at least one playlist scheduled in the next 7 days.',
  mainCategoriesHelpText: 'Choose categories to populate tags in dropdown.',
  tagsHelpText: 'Search and select tags',
  save: 'Save',
  reset: 'Reset',
  frameConfiguration: 'Frame configuration',
  collapseAllButton: 'Collapse all',
  expandAllButton: 'Expand all',
  addNewFrameToPlayers: 'Add new frame to all displays',
  addNewFrame: 'Add new frame',
  applyToAllButton: 'Add frame to all displays',
  pluralS: 's',
  selectedChannel: 'Selected display',
  frame: 'Frame',
  nameValidationMessage: 'Name cannot be empty',
  nameValidationMessageFirstChar: 'Name must beign with an alphanumeric character',
  nameValidationMessageSpecialChars: 'Name can only have following special characters: -_',
  nameValidationMessageWhiteSpace: 'Name cannot have multiple spaces or begin with a space',
  heightValidationMessage: 'Height must be a number',
  widthValidationMessage: 'Width must be a number',
  topValidationMessage: 'Top must be a number',
  leftValidationMessage: 'Left must be a number',
  nameErrorMessage: 'Name must be unique',
  heightErrorMessage: 'Height must be between 1 - 64000',
  widthErrorMessage: 'Width must be between 1 - 64000',
  topErrorMessage: 'Top must be between 0 - 64000',
  leftErrorMessage: 'Left must be between 0 - 64000',
  differentChannelPlayerNameTooltip: 'The display name and channel name are different',
  fitInside: 'FIT INSIDE',
  fillExactly: 'FILL EXACTLY',
  fillAndTrim: 'FILL AND TRIM',
  cancelAddFrameToAllConfirmationMessage:
    'Are you sure you want to cancel? You will lose new frame data.',
  cancelFrameConfigurationNoChangesConfirmationMessage:
    'Are you sure you want to exit Frame Configuration?',
  cancelFrameConfigurationConfirmationMessage:
    'Are you sure you want to exit Frame Configuration? You will lose any changes made.',
  addFrameToAllConfirmationMessage: 'Are you sure you want to add this frame to every display?',
  deleteFrameConfirmationMessage:
    'Deleting this frame will delete the assigned master playlist. Are you sure you want to proceed? ',
  saveFrameDataConfirmationMessage: 'Are you sure you want to save all changes?',
  resetFrameDataConfirmationMessage: 'Are you sure you want to reset? You will lose any changes.',
  continue: 'Continue',
  confirm: 'Confirm',
  cancel: 'Cancel',
  shouldSaveCurrentFrame:
    'Please save the changes for current frame before moving to another frame.',
  dimensionsIncorrectValueError: 'Screen size must be a number',
  dimensionsMaxScreenSizeError: 'Screen size (width * height) must not exceed',
  select: 'Select',
  height: 'Height',
  width: 'Width',
  screenSize: 'Screen size',
  confirmation: 'Confirmation',
  autoScaleErrorText: 'Autoscale must be selected',
  failedDisplayText: 'Failed Saving Display',
  successfulDisplayText: 'Successfully Saved Display',
  noChangesToSave: 'No changes to save.',
  addPlaylist: 'Add playlist',
  editPlaylist: 'Edit playlist',
  deletePlaylist: 'Delete playlist',
  generatePlan: 'Generate plan',
  editPlayerMetadata: 'Edit player metadata',
  deleteUser: 'Delete user',
  createUser: 'Create user',
  editRoles: 'Edit roles',
  assignEditWorkgroups: 'Assign/edit workgroups',
  backToList: 'Back to list',
  addSelected: 'Add selected',
  toExistingPlaylist: 'to existing playlist',
  createNewPlaylist: 'Create a new playlist',
  deleteAsset: 'Delete asset',
  editAsset: 'Edit asset',
  downloadAsset: 'Download asset',
  upload: 'Upload',
  configureTemplate: 'Configure template',
  removeSelectedAsset: 'Remove selected asset',
  duplicate: 'Duplicate',
  addNewItems: 'Add new items',
  addMediaToSelectedPlaylist: 'Add items to selected playlist',
  newPlaylist: 'New playlist',
  name: 'Name',
  left: 'Left',
  top: 'Top',
  autoscale: 'Autoscale',
  action: 'Action',
  playlistName: 'Playlist name',
  startDate: 'Start date',
  endDate: 'End date',
  playForever: 'Play forever?',
  takeOver: 'Takeover',
  neverEnds: 'Never ends',
  playlistNameTooltip: 'Go to playlist (Opens in new window)',
  viewSchedule: 'View schedule',
  saving: 'Saving',
  created: 'Created',
  failed: 'Failed',
  selectFrame: 'Select frame',
  selectedDisplay: 'Selected display',
  selectedPlaylist: 'Selected playlist',
  displaySelection: 'Display selection',
  playlistSelection: 'Playlist selection',
  scheduling: 'Scheduling',
  backToStep: 'Back to step',
  goToStep: 'Go to step',
  backToDisplays: 'Back to displays',
  schedulingCompleted: 'Scheduling completed',
  scheduleSuccessMessage: 'Displays were successfully scheduled.',
  scheduleErrorMessage: 'Displays could not be scheduled. Please retry.',
  success: 'Success',
  error: 'Error',
  noActivePlaylistsMessage: 'No active playlists found for this frame.',
  noArchivedPlaylistsMessage: 'No archived playlists found for this frame.',
  deactivate: 'Deactivate',
  activePlaylists: 'Active playlists',
  inactivePlaylists: 'Inactive playlists',
  activate: 'Activate',
  remove: 'Remove',
  noSchedules: 'No schedules available',
  singleScheduleSuccessMessage: 'Display was successfully scheduled.',
  singleScheduleErrorMessage: 'Display could not be scheduled. Please retry.',
  refresh: 'Refresh',
  expired: 'Expired',
  removePlaylist: 'Remove playlist',
  removePlaylistConfirmation:
    'Are you sure you want to remove this archived playlist from the display?',
  removePlaylistError: 'Could not remove the playlist. Please try again.',
  removePlaylistSuccess: 'Successfully removed the playlist.',
  navigationConfirmationOnAddingItemsToPlaylist:
    'Are you sure you want to cancel? You will lose all the selected data.',
  applyScheduleToAllButton: 'Apply to all',
  applyScheduleHeader: 'Apply schedule',
  applyScheduleConfirmation: 'Are you sure you want to apply playlist schedules from',
  applyScheduleInfo:
    'This will apply playlist schedules for this frame to all selected frames of other displays',
  noChannelAssignedMessage: 'No channel assigned to this display.',
  noChannelsAssignedMessage: 'No channels assigned to these displays.',
  incorrectChannelAssignedMessage: 'Incorrect channel assigned to display.',
  playerNameTooltip: 'Click on display name to know more details.',
  displayName: 'Display Name',
  operationType: 'Operation Type',
  channelName: 'Channel Name',
  frameName: 'Frame Name',
  scheduleType: 'Schedule Type',
  users: 'Users',
  show: 'Show',
  hide: 'Hide',
  filters: 'Filters',
  applyFilters: 'Apply Filters',
  clearAll: 'Clear All',
  clearAllFiltersHelpText: 'This will clear all applied filters.',
  retry: 'Retry',
  errorFetchingFilters: 'Unable to fetch filters.',
  playerActiveStatusText: 'Display is online.',
  playerInactiveStatusText: 'Display is offline.',
  playerUnknownStatusText: 'Display status is unknown.',
  playerHealthyStatusText: ' Heartbeat shows no display problems.',
  playerUnhealthyStatusText: ' Heartbeat shows display problems.',
  playerHeartbeatOverdueStatusText: ' The last heartbeat was received ',
  playerNotReportedStatusText: ' Heartbeat never reported.',
  playerHasLatestPlan: 'The display has the latest plan',
  playerDoesntHaveLatestPlan: 'The display has not received the latest plan. Last plan generated on'
};

export const playlistMetadataSeparator = '||';
export const masterPlaylistDescription = '===master-playlist===';
export const playlistsToHide = [constants.cisId, constants.dataDownloaderId];
export const playerMetadasToParse = [
  'bcs',
  'city',
  'country',
  'environment',
  'market',
  'resolution',
  'ssmid',
  'storename',
  'usecase',
  'secondlevelsupportvendor'
];

export const validFileExtensions = [
  'flif',
  'bmp',
  'gif',
  'heif',
  'heic',
  'ico',
  'jpg',
  'jpe',
  'jpeg',
  'jpc',
  'jp2',
  'j2k',
  'wdp',
  'jxr',
  'hdp',
  'pdf',
  'png',
  'psd',
  'arw',
  'cr2',
  'svg',
  'tga',
  'tif',
  'tiff',
  'webp',
  '3g2',
  '3gp',
  'avi',
  'flv',
  'm2ts',
  'mov',
  'mkv',
  'mp4',
  'mpeg',
  'ogv',
  'webm',
  'wmv',
  'aac',
  'aiff',
  'amr',
  'flac',
  'm4a',
  'mp3',
  'ogg',
  'wav'
];

// mapping of problem code to title
export const playerHealthProblemMap = {
  0: { title: 'Unknown problem' },
  1: { title: 'Internal problem' },
  2: { title: 'Rebooted due to problem' },
  3: { title: 'Abnormal termination' },
  4: { title: 'Player not running' },
  5: { title: 'Transmission Client not running' },
  6: { title: 'Script played incorrectly' },
  7: { title: 'Transmission Client not Admin' },
  8: { title: 'Anonymous FTP allowed' },
  9: { title: 'Broadcast receive mis-configured' },
  10: { title: 'Broadcast receive failed' },
  11: { title: 'No Transmission Client tray icon yet' },
  12: { title: 'No Transmission Client tray icon' },
  13: { title: 'Failed to delete unused content' },
  14: { title: 'JPEG possibly corrupt' },
  15: { title: 'Module failed to start' },
  16: { title: 'FTP port 21 in use' },
  17: { title: 'FTP port in use' },
  18: { title: 'FTP server failed to start' },
  19: { title: 'FTP server rejected command' },
  20: { title: 'FTP server rejected password' },
  21: { title: 'FTP server rejected password again' },
  22: { title: 'FTP server rejected username' },
  23: { title: 'FTP server rejected username again' },
  24: { title: 'Too many errors' },
  25: { title: 'Text not displayed' },
  26: { title: 'Script failed to publish' },
  27: { title: 'Scripts failed to publish' },
  28: { title: 'Script published with errors' },
  29: { title: 'Publish Automation failed to start' },
  30: { title: 'No Publish Automation tray icon yet' },
  31: { title: 'No Publish Automation tray icon' },
  32: { title: 'Failed to resume download' },
  33: { title: 'Resume download succeeded' },
  34: { title: 'Failed to resume upload' },
  35: { title: 'Resume upload succeeded' },
  36: { title: 'Font not installed' },
  37: { title: 'Dialing limits exceeded' },
  38: { title: 'Hardware problem' },
  39: { title: 'Thumbnail not generated' },
  40: { title: 'Thumbnail has missing elements' },
  41: { title: 'Contents File format unknown' },
  42: { title: 'Playback report not uploaded (retrying)' },
  43: { title: 'Playback reports not yet uploaded (retrying)' },
  44: { title: 'Cleanup problem' },
  45: { title: 'Playback reports cannot be uploaded' },
  46: { title: 'IP Multicast receive error' },
  47: { title: 'Placeholder problem' },
  48: { title: 'No Transmission Server tray icon yet' },
  49: { title: 'No Transmission Server tray icon' },
  50: { title: 'Player not currently running' },
  51: { title: 'Cannot receive plans' },
  52: { title: 'Cannot download plans' },
  53: { title: 'Plan unreadable' },
  54: { title: 'Cannot upload plan status' },
  55: { title: 'Failed to remove preloaded content' },
  56: { title: 'Maintenance job failed' },
  57: { title: 'Maintenance job failed (retrying)' },
  58: { title: 'Maintenance job waiting for files' },
  59: { title: 'Transmission Server missing files' },
  60: { title: 'Transmission Server broadcast failed' },
  61: { title: 'Transmission server message failure' },
  62: { title: 'Player invalid license' },
  63: { title: 'Failed cleaning up health logs' },
  64: { title: 'Health logs cannot be uploaded' },
  65: { title: 'Health logs not uploaded (retrying)' },
  66: { title: 'Heartbeat not delivered' },
  67: { title: 'Failed to register Scala Maintenance' },
  68: { title: 'Configuration problem' },
  69: { title: 'Product not authorized' },
  70: { title: 'Product license invalid' },
  71: { title: 'Transmission Server broadcast send failed' },
  72: { title: 'Download failed' },
  73: { title: 'Upload failed' },
  74: { title: 'Problem reading transitions' },
  75: { title: 'Render monitor problem' },
  76: { title: 'Server Support service not running' },
  77: { title: 'No Server Support tray icon yet' },
  78: { title: 'No Server Support tray icon' },
  79: { title: 'No players defined' },
  80: { title: 'Player Config Invalid license' },
  81: { title: 'Broadcast failed' },
  82: { title: 'Broadcast send envelope failed' },
  83: { title: 'Broadcast confirmation failed' },
  84: { title: 'Broadcast Server plan download failed' },
  85: { title: 'Broadcast Server plan status upload failed' },
  86: { title: 'Broadcast Server failed to abort' },
  87: { title: 'No Broadcast Servers defined' },
  88: { title: 'Logging failed (disk space below reserve)' },
  89: { title: 'Media download failed (disk space below reserve)' },
  90: { title: 'Playback audit logging failed (disk space below reserve)' },
  91: { title: 'Transmission Client detected UAC' },
  92: { title: 'Screen problem during playback' },
  93: { title: 'Player failed to finish media asset (watchdog)' },
  94: { title: 'Cannot embed font' },
  95: { title: 'Media file missing, download failed' },
  96: { title: 'Media asset did not play' },
  97: { title: 'Flash version is known to leak resources' },
  1000: { title: 'External problem' },
  1001: { title: 'External warning' },
  1002: { title: 'External hardware problem' },
  1003: { title: 'External hardware warning' },
  1004: { title: 'External display problem' },
  1005: { title: 'External display warning' },
  1006: { title: 'External device problem' },
  1007: { title: 'External device warning' },
  1008: { title: 'External communication problem' },
  1009: { title: 'External communication warning' },
  1010: { title: 'External software problem' },
  1011: { title: 'External software warning' },
  1012: { title: 'External data problem' },
  1013: { title: 'External data warning' },
  2000: { title: 'Internal problem' },
  2001: { title: 'Abnormal termination' },
  2002: { title: 'Player not running' },
  2003: { title: 'Media played incorrectly' },
  2004: { title: 'Plan unreadable' },
  2005: { title: 'Player invalid license' },
  2006: { title: 'Logging failed (disk space below reserve)' },
  2007: { title: 'Media download failed (disk space below reserve)' },
  2008: { title: 'Playback audit logging failed (disk space below reserve)' },
  2009: { title: 'Media file missing, download failed' },
  3000: { title: 'Internal problem' },
  3003: { title: 'Media played incorrectly' },
  3004: { title: 'Plan unreadable' },
  3005: { title: 'Player invalid license' },
  3007: { title: 'Media download failed (disk space below reserve)' },
  3009: { title: 'Media file missing, download failed' },
  4000: { title: 'Internal problem' },
  4003: { title: 'Media played incorrectly' },
  4004: { title: 'Plan unreadable' },
  4005: { title: 'Player invalid license' },
  4007: { title: 'Media download failed (disk space below reserve)' },
  4009: { title: 'Media file missing, download failed' },
  10001: { title: 'Invalid player signature' },
  10101: { title: 'Internal problem' },
  10102: { title: 'Invalid appliance license' },
  10103: { title: 'Content played incorrectly' },
  10201: { title: 'Configuration problem' },
  10202: { title: 'Configuration problem' },
  10301: { title: 'Software mismatch' }
};

export const createUserResponses = {
  404: 'User is not found in adidas directory',
  409: 'User already exists'
};
