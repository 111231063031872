import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
// import { getLoginInfo } from '../../helpers/utils';
import store from '../../stores';
import { openModal } from '../../actions/modal';
import { clickedPlayer } from '../../actions/table';

class ShowFrameConfigurationModal extends Component {
  // componentDidMount() {
  //   getLoginInfo();
  // }

  showFrameConfiguration = () => {
    const { roles, playerId, playerName } = this.props;
    if (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_POWER_USER')) {
      store.dispatch(clickedPlayer('dashboard:1', { id: playerId, name: playerName }));
      store.dispatch(openModal('addEditFrames'));
    }
  };

  render() {
    const { frames, framesData, roles } = this.props;
    return (
      <>
        {frames.length >= 0 && (
          <Tooltip html={framesData()} position="bottom">
            <button className={"button--viewframeDetails" + ((roles.includes('ROLE_ADMIN') || roles.includes('ROLE_POWER_USER')) ? "" : " default-cursor" )} onClick={this.showFrameConfiguration}>
              {frames.length >= 0 ? `${frames.length}` : ''}
            </button>
          </Tooltip>
        )}
      </>
    );
  }
}

ShowFrameConfigurationModal.propTypes = {
  frames: PropTypes.arrayOf(PropTypes.shape({})),
  framesData: PropTypes.func
};

const mapStateToProps = (state) => ({
  roles: state.auth.roles
});

export default connect(mapStateToProps, null)(ShowFrameConfigurationModal);
