/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlCheckbox, GlButton } from '@adl/foundation';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { ReactComponent as EllipsisIcon } from '../../../../assets/icons/ellipsis-v.svg';
import { ReactComponent as DragHandle } from '../../../../assets/icons/dragHandleColumnOptions.svg';
import './ColumnOptions.scss';
import { UITEXT } from '../../../../helpers/misc';
import { saveColumnOptions } from '../../../../helpers/utils';
import Modal from '../../Modal/Modal';

const SortableItem = sortableElement(({ column, hideColumnHandler, myIndex }) => (
  <li className={`table-options__row ${column.disableOrdering ? 'disabled' : ''}`}>
    <div className="table-options__row--zero-column">
      <DragHandle />
    </div>
    <div className="table-options__row--first-column">#{myIndex + 1}</div>
    <div className="table-options__row--second-column">
      <GlCheckbox
        isChecked={!column.isHidden}
        onChange={e => hideColumnHandler(column.id, !e.target.checked)}
        inputProps={{ disabled: column.disableOrdering }}
      />
    </div>
    <div className="table-options__row--third-column">{column.title}</div>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <tbody>{children}</tbody>);

function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-const-assign
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing
}

class ColumnOptions extends Component {
  state = {
    modalOpen: false
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { schema, columnOrderHandler } = this.props;
    let schemaCopy = [...schema];
    if (newIndex >= 0 && newIndex < schemaCopy.length) {
      const el = schemaCopy.splice(oldIndex, 1)[0];
      schemaCopy.splice(newIndex, 0, el);
      const checkboxIndex = schemaCopy.findIndex(item => item.id === 'checkbox');
      schemaCopy = arrayMove(schemaCopy, checkboxIndex, 0);

      columnOrderHandler(schemaCopy);
    }
  };

  render() {
    const { modalOpen } = this.state;
    const { schema, hideColumnHandler, tableId } = this.props;
    return (
      <>
        <div
          type="button"
          className="table-option__container"
          onClick={() => this.setState({ modalOpen: true })}
          style={{ cursor: 'pointer' }}
        >
          <EllipsisIcon className="header-user__ellipsis" />
        </div>
        <Modal
          size="small"
          open={modalOpen}
          enableOutsideClick
          modalCloseHandler={() => this.setState({ modalOpen: false })}
        >
          <div
            className="table-option__container"
            style={{ position: 'relative', cursor: 'row-resize' }}
          >
            <div style={{ marginTop: '-5%', marginBottom: '5%' }}>{UITEXT.columnOptions}</div>
            <SortableContainer
              lockAxis="y"
              lockToContainerEdges
              helperClass="while-sorting"
              onSortEnd={this.onSortEnd}
            >
              {schema.map((column, index) => (
                <SortableItem
                  disabled={column.disableOrdering}
                  key={column.id}
                  index={index}
                  myIndex={index}
                  column={column}
                  hideColumnHandler={hideColumnHandler}
                />
              ))}
            </SortableContainer>
            <div style={{ marginTop: '5%' }}>
              <GlButton
                tertiary
                data-testid="column-reset-button"
                onClick={() => saveColumnOptions(tableId, {})}
              >
                Reset to defaults
              </GlButton>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ColumnOptions.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object),
  hideColumnHandler: PropTypes.func,
  columnOrderHandler: PropTypes.func,
  tableId: PropTypes.string
};

export default ColumnOptions;
