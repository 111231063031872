import axiosOriginal from 'axios';
import qs from 'qs';
import constants from '../helpers/constants';
import store from '../stores/index';
import { loadSettings } from '../actions/table';
import { loadAuth } from '../actions/auth';
import { openModal } from '../actions/modal';
import { clearAllFiltersForPlayers, initData, failedRequest } from '../actions/playersList';
import { apiEndpoints, clearCookieIfExpired } from './utils';

// DO WE NEED THIS??
export const getCancelToken = () => axiosOriginal.CancelToken.source();
// use this method if you want canceling posibility
let CANCEL_TOKEN;
export const getWrapper = (_axios, url, params) => {
  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel('Operation canceled due to new request.');
  }

  CANCEL_TOKEN = getCancelToken();
  // decorationg axios to have interceptor handling unauthorized
  return _axios
    .get(url, {
      params,
      cancelToken: CANCEL_TOKEN.token
    })
    .then(res => res)
    .catch(err => {
      if (axiosOriginal.isCancel(err)) {
        throw Object.assign({}, { canceled: true });
      } else {
        throw err;
      }
    });
};

export const callApiEndpoint = (endpoint, token, data, params = {}) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-console
    const getHeader = () => {
      if (process.env.REACT_APP_BYPASS_AUTH === 'true') {
        return {
          'x-disip-auto-login': process.env.REACT_APP_GOD_LOGIN
        };
      }
      return {
        Authorization: `Bearer ${token}`
      };
    };

    const axios = axiosOriginal.create({
      baseURL: constants.SCALA_API_URL,
      headers: getHeader(),
      paramsSerializer: paramsSerial =>
        qs.stringify(paramsSerial, {
          encodeValuesOnly: true,
          encoder: str => encodeURI(str)
        })
    });
    const axiosOrg = axiosOriginal.create({
      headers: getHeader(),
      paramsSerializer: paramsSerial =>
        qs.stringify(paramsSerial, {
          encodeValuesOnly: true,
          encoder: str => encodeURI(str)
        })
    });

    switch (endpoint) {
      case apiEndpoints.getUserPreferences:
        axiosOrg
          .get(constants.USER_PREFERENCES_URL)
          .then(res => {
            store.dispatch(loadSettings(res.data));
            resolve(res);
          })
          .catch(e => {
            store.dispatch(loadSettings({}));
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateUserPreferences:
        axiosOrg
          .put(constants.USER_PREFERENCES_URL, data)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getLoggedInUserInfo:
        axiosOrg
          .get(constants.LOGGED_INFO)
          .then(res => {
            store.dispatch(loadAuth(res.data));
            resolve(res);
          })
          .catch(e => {
            store.dispatch(loadAuth({}));
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchPlayers:
        store.dispatch(clearAllFiltersForPlayers());
        CANCEL_TOKEN = getCancelToken();
        axiosOrg
          .get(constants.PLAYERS_URL, {
            cancelToken: CANCEL_TOKEN.token
          })
          .then(res => {
            const responseList = res.data.players || [];
            store.dispatch(initData(responseList));
            resolve(res);
          })
          .catch(e => {
            store.dispatch(failedRequest());
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayer:
        store.dispatch(clearAllFiltersForPlayers());
        CANCEL_TOKEN = getCancelToken();
        axios
          .get(`/api/rest/players/${data.playerId}`, { params: { ...params } })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getFrameDetails:
        axiosOrg
          .get(`${constants.FRAMES_DETAILS_URL}`, { params: { channelIds: data.channelIds } })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateFramesData:
        axiosOrg
          .put(`${constants.FRAMES_UPDATE_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlaylists:
        axiosOrg
          .post(`${constants.GET_PLAYLIST_URL}`, data)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getTimeslotsData:
        axios
          .get(`/api/rest/channels/${data.channelId}/frames/${data.frameId}/timeslots`, {
            params: { fromDate: '1900-01-01', toDate: '2030-01-02', ...params }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.schedulePlaylists:
        axiosOrg
          .post(`${constants.SCHEDULE_PLAYLISTS}`, data)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayerSchedules:
        axiosOrg
          .get(`${constants.GET_PLAYER_SCHEDULES}/${data.id}`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.sendLog:
        axiosOrg
          .post(`${constants.ACTIVITY_LOG_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getMedias:
        axiosOrg
          .post(`${constants.GET_MEDIA_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getTaxonomyTags:
        axiosOrg
          .get(`${constants.TAXONOMY_TAGS}`, {
            params: {
              offset: 0,
              limit: 99999,
              count: 0,
              fields: 'id,name,parentId,children,description',
              search: 'taxonomy'
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getLocation:
        axiosOrg
          .get(`${constants.LOCATION_URL}/`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateLocation:
        axiosOrg
          .post(`${constants.LOCATION_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.deleteLocation:
        axiosOrg
          .delete(`${constants.LOCATION_URL}/${data.id}`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getMedia:
        axiosOrg
          .get(`${constants.MEDIA_URL}/${data.mediaId}/location`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getMediaSearch:
        axios
          .get(`/api/rest/media/search`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlaylistsSearch:
        axios
          .get(`/api/rest/playlists/search`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayersSearch:
        axios
          .get(`/api/rest/players/search`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateMediaInfo:
        axios
          .put(`/api/rest/media/${data.itemId}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateMessageInfo:
        axios
          .put(`/api/rest/messages/${data.itemId}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.uploadMedia:
        axiosOrg
          .post(`${constants.UPLOAD_URL}`, data.formData, { ...data.config })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateMediaLocation:
        axiosOrg
          .post(`${constants.MEDIA_URL}/${data.userId}/location`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getAutoComplete:
        axios
          .get(`/api/rest/${data.tableType}/autocomplete`, {
            params: {
              search: data.search,
              limit: 10,
              filters: data.filters
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getTemplates:
        axiosOrg
          .get(`${constants.TEMPLATE_URL}`, {
            params: {
              page: data.page - 1,
              size: data.limit,
              fields: 'id,name,lastModified',
              listOnly: true,
              sort: '-lastModified'
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getTemplateFields:
        axiosOrg
          .get(`${constants.TEMPLATE_FIELD_URL}/${data.id}/fields`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.createMessage:
        axios
          .post(`/api/rest/messages`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getFilteredPlaylists:
        axios
          .get(`/api/rest/playlists`, {
            params: {
              filters: `{"media":{"values":["${data.id}"]}}`,
              limit: 1000
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlaylist:
        axios
          .get(`/api/rest/playlists/${data.playlistId}`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.deletePlaylist:
        axiosOrg
          .delete(`${constants.DELETE_PLAYLIST_URL}/${data.id}`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.removePlaylist:
        axiosOrg
          .delete(
            `${constants.REMOVE_PLAYLIST}/${data.channelId}/schedule/${data.frameId}?playlistId=${data.playlistId}&frameName=${data.frameName}&entryIds=${data.entryIds}`
          )
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.deleteFromAMS:
        axiosOrg
          .delete(`${constants.DELETE_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.genericCMSRest:
        axios
          .get(`/api/rest/${data.type}/${data.end}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.createPlaylist:
        axiosOrg
          .post(`${constants.CREATE_PLAYLIST_URL}`, { ...data })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updatePlaylistMedia:
        axiosOrg
          .put(`${constants.DUPLICATE_PLAYLIST_URL}/${data.id}/addMedia?mediaIds=${data.items}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updatePlaylist:
        axios
          .put(`/api/rest/playlists/${data.playlistId}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updatePlaylistv2:
        axiosOrg
          .put(`${constants.UPDATE_PLAYLIST_URL}/${data.playlistId}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getChannels:
        axios
          .get(`/api/rest/channels`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getChannel:
        axios
          .get(`/api/rest/channels/${data.channelId}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.addSubPlaylists:
        axiosOrg
          .put(`${constants.ADD_SUBPLAYLISTS_URL}${data.subPlaylistId}/subPlaylist`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.duplicatePlaylist:
        axiosOrg
          .post(`${constants.DUPLICATE_PLAYLIST_URL}/${data.id}/duplicate`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayerStatus:
        axios
          .get(`/api/rest/players/${data.playerId}/state`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayerPlans:
        axios
          .get(`/api/rest/players/${data.playerId}/plans`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchSchedulingActivityLogs:
        axiosOrg
          .post(`${constants.SCHEDULING_ACTIVITY_LOG_SEARCH_URL}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchSchedulingActivityLogsFilters:
        axiosOrg
          .get(`${constants.SCHEDULING_ACTIVITY_LOG_FETCH_FILTERS}`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchFrameManagementActivityLogs:
        axiosOrg
          .post(`${constants.FRAME_MANAGEMENT_ACTIVITY_LOG_SEARCH_URL}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchFrameManagementActivityLogsFilters:
        axiosOrg
          .get(`${constants.FRAME_MANAGEMENT_ACTIVITY_LOG_FETCH_FILTERS}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.userCreation:
        axiosOrg
          .post(`${constants.USER_URL}/create`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.userDeletion:
        axiosOrg
          .delete(`${constants.USER_URL}/${data.userIds}`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.userInfo:
        axiosOrg
          .get(`${constants.USER_URL}/${data.userId}/location`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.userBuildRequest:
        axiosOrg
          .post(`${constants.USER_URL}/${data.userId}/location`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.fetchUsers:
        axiosOrg
          .get(`${constants.USER_URL}/`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updatePlayerSchedules:
        axiosOrg
          .put(`${constants.UPDATE_PLAYER_SCHEDULES}`, [...data])
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getPlayerHealth:
        axios
          .get(`/api/rest/playerhealth`, {
            ...data
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getRoles:
        axiosOrg
          .get(`${constants.ROLE_URL}/`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.getUserRole:
        axiosOrg
          .get(`${constants.ROLE_URL}/user/${data.userValue}`)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updateUserRole:
        axiosOrg
          .post(`${constants.ROLE_URL}/${data.role.value}/user/${data.userId}`, {
            ...data.role
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.generatePlan:
        axios
          .post(`/api/rest/players/${data.uuid}/generatePlan`)
          .then(res => {
            store.dispatch(
              openModal('planGeneration', {
                inProgress: false,
                error: false,
                completed: true
              })
            );
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            store.dispatch(
              openModal('planGeneration', {
                inProgress: false,
                error: true,
                completed: true
              })
            );
            reject(e);
          });
        break;
      case apiEndpoints.getUuid:
        axios
          .post(`/api/rest/storage`, {
            ids: data.ids
          })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.urlWrapper:
        axiosOrg
          .get(data.url, { responseType: 'arraybuffer' })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.updatePlayerMetadata:
        axiosOrg
          .put(
            `https://adicontentmanager.digital-signage.adidas.com/ContentManager/api/rest/players/${data.id}`,
            data
          )
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      case apiEndpoints.downloadMedia:
        axiosOrg({
          method: 'post',
          url: constants.DOWNLOAD_URL,
          responseType: 'blob',
          cancelToken: CANCEL_TOKEN.token,
          data
        })
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            clearCookieIfExpired(e);
            reject(e);
          });
        break;
      default:
        break;
    }
  });
