import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GlButton, GlCallout } from '@adl/foundation';
import { connect } from 'react-redux';

import Modal from '../commons/Modal/Modal';
import { closeModal as closeModalAction } from '../../actions/modal';

import './Modals.scss';
import Loader from '../commons/Loader/Loader';
import { getAccessTokenAndMakeCalls } from '../../serviceLayer/utils';
import { callGetPlaylistApi, callUpdatePlaylistApi } from '../../serviceLayer/services';

class RemoveMediaFromPlaylist extends Component {
  state = {
    isRemoving: false,
    removingCompleted: false,
    playlist: {},
    success: false,
    notFound: false,
    infoFetched: false,
    notAuthorized: false
  };

  componentDidMount() {
    const { pid } = this.props;
    getAccessTokenAndMakeCalls(token => callGetPlaylistApi(token, { playlistId: pid }))
      .then(res => {
        this.setState({ playlist: res, infoFetched: true });
      })
      .catch(() => {
        this.setState({ notFound: true, infoFetched: true });
      });
  }

  removeItems = () => {
    const { selectedMediaItems } = this.props;
    const { playlist } = this.state;
    const playlistItems = selectedMediaItems.map(item => ({ id: item.id, deleteFlag: true }));
    if (selectedMediaItems.length) {
      this.setState({ isRemoving: true });
      getAccessTokenAndMakeCalls(token =>
        callUpdatePlaylistApi(token, {
          ...playlist,
          playlistId: playlist.id,
          playlistItems
        })
      )
        .then(() => {
          this.setState({ isRemoving: false, removingCompleted: true, success: true });
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            (error.response.status === 401 || error.response.status === 402)
          ) {
            this.setState({ notAuthorized: true });
          }
          this.setState({ isRemoving: false, removingCompleted: true, success: false });
        });
    }
  };

  render() {
    const {
      isRemoving,
      removingCompleted,
      success,
      notFound,
      infoFetched,
      notAuthorized
    } = this.state;
    const { selectedMediaItems, closeModal, refreshHandler } = this.props;

    if (notFound && infoFetched) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            closeModal();
          }}
        >
          <GlCallout type="very-urgent" data-test="test">
            <h5>Something went wrong.</h5>
            <p>There was an error loading playlist information. Make sure the playlist exists</p>
          </GlCallout>
        </Modal>
      );
    }

    if (isRemoving) {
      return (
        <Modal size="small" open modalCloseHandler={() => {}}>
          <Loader />
        </Modal>
      );
    }

    if (removingCompleted) {
      return (
        <Modal
          size="small"
          open
          enableOutsideClick
          modalCloseHandler={() => {
            refreshHandler();
            closeModal();
          }}
        >
          <>
            <h4>Remove item from playlist</h4>
            <div className={`validation-${success ? 'success' : 'error'}`}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {success ? (
                <p>Selected items successfully removed from the playlist</p>
              ) : notAuthorized ? (
                <p>
                  You do not have sufficient permissions to remove media asset(s) from this
                  playlist.
                </p>
              ) : (
                <p>There was an error removing items from the playlist</p>
              )}
            </div>
          </>
        </Modal>
      );
    }

    return (
      <Modal
        // className="custom-modal--fullscreen"
        size="small"
        open
        enableOutsideClick
        modalCloseHandler={() => closeModal()}
      >
        <div className="modal-header">
          <h4>Remove items from playlist</h4>
          <p>Selected assets:</p>
        </div>
        <ul className="selected-items">
          {selectedMediaItems.map(item => (
            <li key={item.id}>
              <strong className="breakWord">{item.name || item.id}</strong>
            </li>
          ))}
        </ul>

        <div>
          <GlButton
            aria-label="Remove"
            disabled={!infoFetched}
            className="custom-modal__button custom-modal__button--primary"
            onClick={() => this.removeItems()}
          >
            Remove
          </GlButton>
          <GlButton
            secondary
            aria-label="Cancel"
            className="custom-modal__button"
            onClick={() => closeModal()}
          >
            Cancel
          </GlButton>
        </div>
      </Modal>
    );
  }
}

RemoveMediaFromPlaylist.propTypes = {
  selectedMediaItems: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func,
  pid: PropTypes.string,
  refreshHandler: PropTypes.func
};

const mapStateToProps = state => ({
  selectedMediaItems: state.modals.activeModal.items
});

const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveMediaFromPlaylist);
