export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const PREVENT_MODAL = 'PREVENT_MODAL';
export const PLAYLIST_NOT_FOUND = 'PLAYLIST_NOT_FOUND';

export const openModal = (modalId, data) => ({
  type: OPEN_MODAL,
  modalId,
  data
});

export const closeModal = modalId => ({
  type: CLOSE_MODAL,
  modalId
});

export const preventReload = isDirty => ({
  type: PREVENT_MODAL,
  isDirty
});

export const playlistNotFound = notFound => ({
  type: PLAYLIST_NOT_FOUND,
  notFound
});
